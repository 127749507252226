import React, { useState, useEffect } from "react";
import GameMenu from "components/game-list/game-menu";
import Slider from "react-slick";
import { map } from "lodash";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Count from "components/timer/Count";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

const CockFighting = (props) => {
  const _SPORTS_CATEGORY_ID = 4;
  const _dispatch = useDispatch();
  const _history = useHistory();
  const { t, i18n } = useTranslation();


  const [cockfightingGameCount, setCockfightingGameCount] = useState(0);
  const [sportGames, setSportGames] = useState();
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [toLobbyBtn, setToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [mpData, setMpData] = useState([]);
  const [cockfightingMainBanner, setCockfightingMainBanner] = useState("");
  const [cockfightingSubBannerList, setCockfightingSubBannerList] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);

  
  // const sportSlider = {
  //   // centerMode: cockfightingGameCount <=2 ? false : true,
  //   slidesToShow: cockfightingGameCount,
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   // swipeToSlide: true,
  //   // centerPadding: "0",
  //   autoplay: false,
  //   swipe: cockfightingGameCount <=2 ? false : true,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: cockfightingGameCount,
  //       },
  //     },
  //   ],
  // };

  const sportSlider = {
    slidesToShow: cockfightingGameCount > 3 ? 3 : cockfightingGameCount,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: cockfightingGameCount,
        },
      },
    ],
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=Cockfighting&languageCode=" + 
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en'));
    // localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempCockfightingGames = [];
      data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
        tempCockfightingGames.push(row);
      });
      setCockfightingGameCount(tempCockfightingGames.length);
      
      ///* currently number of slides is equal to slidesToShow, need to add the same slides one more time for the sliding to work */
      //data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row, index) => {
      //  tempCockfightingGames.push(row);
      //});
      setSportGames(tempCockfightingGames);
    }
    let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en') + "&page=/cockfighting" 
        // + window.location.pathname;
    responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempSliderBannerList = [];
      if (data.filter(x => x.displayDevice === 1).length > 0) {
        data.filter(x => x.displayDevice === 1).map((banner) => {
          tempSliderBannerList.push({ image: banner.desktopImage });
        });
        setCockfightingSubBannerList(tempSliderBannerList);
      }
      else {
        setCockfightingMainBanner(data.filter(x => x.displayDevice === 0)[0].desktopImage);
      }
    }

    // var apiUrl2 = ApiUrl._API_GET_WEB_CONTENT + "?Name=FIFA World Cup 2022 Groups";
    // var responseJson2 = await ApiEngine.get(apiUrl2); 
    // if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
    //   const name = responseJson2[ApiKey._API_DATA_KEY]["name"];
    //   const content = responseJson2[ApiKey._API_DATA_KEY]["content"];
    //   setTitle(name);
    //   setHtml(content);
    // }
  }

  //async function startGame(
  //  gameId,
  //  hasOwnLobby,
  //  masterProductId,
  //  gameName,
  //  isSeamless,
  //  isApp,
  //  appUrl,
  //  appDeepLink,
  //  isNewWindow,
  //  packageNameKeyword
  //) {
  //  setSelectedGameName(gameName);
  //  setMasterProductId(masterProductId);
  //  setIsApp(isApp);
  //  setAppDeepLink(appDeepLink);
  //  setPackageNameKeyword(packageNameKeyword);
  //  if (isApp && !stringIsNullOrEmpty(appUrl)) {
  //    setAppUrl(appUrl);
  //  }
  //  if (hasOwnLobby !== true) {
  //    if (isSeamless == false) {
  //      _dispatch(setBusy());
  //      var responseJson = await ApiEngine.get(
  //        ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
  //        "?masterProductId=" +
  //        masterProductId
  //      );
  //      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //        setWalletList(responseJson[ApiKey._API_DATA_KEY]);

  //        let tempFromOptions = [];
  //        let tempToOptions = [];

  //        responseJson[ApiKey._API_DATA_KEY].map((product) => {
  //          let option = {
  //            label: product.productName,
  //            value: product.productId,
  //            amount: parseFloat(product.balance),
  //          };
  //          if (product.productId === _MAIN_WALLET_ID) {
  //            tempFromOptions.push(option);
  //          } else if (product.productId === masterProductId) {
  //            setBonus(product.bonus);
  //            setCommission(product.commission);
  //            if (product.productName === "D2 Poker") {
  //              setClubFee(product.clubFee);
  //            }
  //            setGameWalletBalanceBeforeTransfer(product.balance);
  //            tempToOptions.push(option);
  //          }
  //        });
  //        setFromOptions(tempFromOptions);
  //        setToOptions(tempToOptions);

  //        var mpJson = await ApiEngine(
  //          ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
  //          "?masterProductId=" +
  //          masterProductId
  //        );
  //        if (mpJson[ApiKey._API_SUCCESS_KEY]) {
  //          let data = mpJson[ApiKey._API_DATA_KEY];
  //          setMpData(data);
  //          if (window.ReactNativeWebView && isApp) {
  //            window.ReactNativeWebView.postMessage(
  //              JSON.stringify({
  //                action: "deeplink",
  //                url: appUrl,
  //                deeplink: appDeepLink
  //                  .replace("[username]", data["username"])
  //                  .replace("[password]", data["password"]),
  //                packageKeyword: packageNameKeyword,
  //              })
  //            );
  //          }
  //        }
  //      }
  //      setProductGameId(gameId);
  //      var isNewWindowJson = await ApiEngine(
  //        ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
  //      );
  //      setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
  //      setShowPregamePopup(true);

  //      _dispatch(setIdle());
  //    } else {
  //      window.open(
  //        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
  //        }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
  //      );
  //    }
  //  } else {
  //    if (isSeamless == false) {
  //      _dispatch(setBusy());
  //      var responseJson = await ApiEngine.get(
  //        ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
  //        "?masterProductId=" +
  //        masterProductId
  //      );
  //      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //        setWalletList(responseJson[ApiKey._API_DATA_KEY]);

  //        let tempFromOptions = [];
  //        let tempToOptions = [];

  //        responseJson[ApiKey._API_DATA_KEY].map((product) => {
  //          let option = {
  //            label: product.productName,
  //            value: product.productId,
  //            amount: parseFloat(product.balance),
  //          };
  //          if (product.productId === _MAIN_WALLET_ID) {
  //            tempFromOptions.push(option);
  //          } else if (product.productId === masterProductId) {
  //            setBonus(product.bonus);
  //            setCommission(product.commission);
  //            if (product.productName === "D2 Poker") {
  //              setClubFee(product.clubFee);
  //            }
  //            setGameWalletBalanceBeforeTransfer(product.balance);
  //            tempToOptions.push(option);
  //          }
  //        });
  //        setFromOptions(tempFromOptions);
  //        setToOptions(tempToOptions);

  //        var mpJson = await ApiEngine(
  //          ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
  //          "?masterProductId=" +
  //          masterProductId
  //        );
  //        if (mpJson[ApiKey._API_SUCCESS_KEY]) {
  //          let data = mpJson[ApiKey._API_DATA_KEY];
  //          setMpData(data);
  //        }
  //      }

  //      var isNewWindowJson = await ApiEngine(
  //        ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
  //      );
  //      setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
  //      setShowPregamePopup(true);
  //      setProductGameId(gameId);
  //      setShowPregamePopup(true);
  //      setToLobbyBtn(true);
  //      _dispatch(setIdle());
  //    }
  //    else {
  //      _history.push(WebUrl._URL_GAME_LIST.replace(
  //        ":provider",
  //        gameName));
  //    }
  //  }
  //}

  async function startGame(
    gameId,
    isApp,
    categoryTitle
  ) {
    if (isSpecialRollover) {
      let productCategoryListArr = [...playableCategory];

      if (productCategoryListArr.includes(categoryTitle)) {
        window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp == true ? "1" : "0") + "&device=d");
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("ROLLOVER_EXIST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
    else {
      window.open(ApiUrl._API_START_GAME + "?gameId=" + gameId + "&isApp=" + (isApp == true ? "1" : "0") + "&device=d");
    }
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <>
      <section className='category-section'>
        <GameMenu />
      </section>
      {/*<section id='sport-section' className="position-relative">
        <div className="text-center sport-timer">
          <div className="clock-title font-16 mb-2">Tournament starts in</div>
          <Count />
        </div>
        <img
          src={require("../../assets/img/sports/banner.png")}
          className='img-100'
          alt='Sports Banner'
        />
      </section>*/}
      <section id='sports-section' className="position-relative">
        <>{!stringIsNullOrEmpty(cockfightingMainBanner) && <img
          src={cockfightingMainBanner}
          className='img-100'
          alt='Cockfighting Banner'
        />}</>
      </section>
      <section
        id='sports-section2'
        style={{
          backgroundImage: `url(${"/img/sports/background.png"})`,
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className="col-9">
              <div className="margin-negative">
                <Slider {...sportSlider}>
                  {sportGames &&
                    sportGames.length &&
                    map(sportGames, (game, index) => {
                      return (
                        <div className="hover-show-second-img sport-game-icon text-center" key={index}>
                          <a onClick={() => {
                            if(isLoggedIn && game["gameUm"] !== true){
                              startGame(
                               game["id"],
                               game["isApp"],
                               "CockFighting"
                             );
                            }
                            else if (!isLoggedIn) {
                              _dispatch(
                                showMessage({
                                  type: AlertTypes._INFO,
                                  content: t("PLEASE_LOGIN_FIRST"),
                                  onConfirm: () => {
                                    console.log("hi");
                                  },
                                })
                              );
                            }
                          }}
                          className={(isLoggedIn ? game["gameUm"] === true ? " game-um" : "" : "")}
                          >
                              <img
                                src={game["desktopImage"]}
                                className={'img-responsive show-img1'}
                                alt='Sports Banner'
                              />                              
                              <img
                                src={game["desktopImageHover"]}
                                className={'img-responsive show-img2'}
                                alt='Sports Banner'
                              />
                              {isLoggedIn && <div className="um-icon">
                                <img
                                  src="/img/maintenance.png"
                                  className='img-responsive'
                                  alt='poker-icon'
                                />
                              </div>}
                          </a>
                        </div>
                      );
                    })
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        id='sports-section3'
        style={{
          backgroundImage: `url(${require("../../assets/img/sports/background3.png")})`,
        }}
      >
        <div className="text-center font-bold text-white font-18 mb-4 pb-3">
          {parse(title)}
        </div>
        <div className="container">
          <div className="row justify-content-center">
              {!stringIsNullOrEmpty(html) ?
                parse(html)
                : 
                <>
                  {fifaGroups &&
                    fifaGroups.length &&
                    fifaGroups.map((item1, index1) => {
                      return (
                        <div className="col-lg-3 col-4 mb-4" key={index1}>
                          <div
                            className="sport-group-box"
                            style={{ backgroundImage: `url(${require("../../assets/img/sports/group-bg.png")})` }}
                          >
                            <div className="font-18 sports-group-title mb-3">
                              <div>
                                <img
                                  src={require("../../assets/img/sports/title-left.png")}
                                  className='img-responsive'
                                  alt='icon'
                                />
                              </div>
                              <div>{item1.group_title}</div>
                              <div>
                                <img
                                  src={require("../../assets/img/sports/title-right.png")}
                                  className='img-responsive'
                                  alt='icon'
                                />
                              </div>
                            </div>
                            {item1.group_list.map((item2, index2) => {
                              return (
                                <div key={index2}>
                                  <div className="font-14 sport-grouplist-box" >
                                    <div className="sport-grouplist-title">
                                      {item2.title}
                                    </div>
                                    <div className="sport-grouplist-image">
                                      <img
                                        src={item2.flag}
                                        className='img-responsive'
                                        alt='icon'
                                      />
                                    </div>
                                    <div className="sport-grouplist-title">
                                      {item2.country}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  }
                </>}
          </div>
        </div>
      </section> */}
    </>
  );
};
export default CockFighting;