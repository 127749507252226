import React, { useState, useEffect } from "react";
import {
  ApiUrl,
  ApiKey,
  SessionKey,
  AlertTypes,
  WebUrl,
  PhoneNumberPrefix,
  KEY
} from "./../../util/Constant.js";
import ApiEngine from "./../../util/ApiEngine.js";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createFormBody, stringIsNullOrEmpty } from "./../../util/Util.js";
import { performLogin, performLogout, performRegisterLogin, createGamePointsBonusRecord } from "../../redux/AuthAction.js";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import WarioIcon from "../../assets/img/home/register_wario.png";
import Background_1 from "../../assets/img/home/register_background.png";
import parse from 'react-html-parser';
import CryptoJS from "crypto-js";
import {
    Modal,
    ModalBody,
} from "reactstrap";

const Register = (props) => {
  const { t } = useTranslation();
  const _history = useHistory();
  const _dispatch = useDispatch();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [agreedTnC, setAgreedTnC] = useState(false);
  const [agreeAge18, setAgreeAge18] = useState(false);
  const [showTacEntry, setShowTacEntry] = useState(false);
  const [showTnC, setShowTnC] = useState(false);
  const [content, setContent] = useState("");
  const [showLicense, setShowLicense] = useState(false);
  const [TACBtnAble, setTACBtnAble] = useState(false);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACsent, setTACsent] = useState(false);
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [referralCode, setReferralCode] = useState("");
  const [uplineDetail, setUplineDetail] = useState({});
  const [formattedPhone, setFormattedPhone] = useState("");
  const [currentValidTAC, setCurrentValidTAC] = useState("");
  const _TAC_VALID_TIMER = 300000;
  const _SECONDS_IN_MS = 1000;
  const _MIN_PASSWORD_LENGTH = 6;
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(
    PhoneNumberPrefix[0].value
  );
  const [isDigit, setIsDigit] = useState(false);
  const {
    register: registerRegister,
    handleSubmit: handleSubmitRegister,
    errors: errorsRegister,
    setError: setErrorRegister,
    clearErrors: clearErrorsRegister,
    setValue: setValueRegister,
    getValues: getValuesRegister,
    watch: watchRegister,
    control: controlRegister,
    trigger: triggerRegister,
    phoneNumberChk: phoneNumberChkRegister,
  } = useForm({
    mode: "onBlur",
  });

  var { isLoggedIn } = useSelector((state) => state.authState);
  const _TAC_TIMEOUT_IN_MINUTES = 5;
  const [TACtimer, setTACtimer] = useState(setTimeout(() => {}, 100000));
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [canSendTAC, setCanSendTAC] = useState(true);
  const [registerPointsEarnedModal, setRegisterPointsEarnedModal] = useState(false);
  const [registerGamePoints, setRegisterGamePoints] = useState(0);
  const [depositGamePoints, setDepositGamePoints] = useState(0);
  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    // }, 10);
    }
  }, [countdownPeriod]);

  useEffect(() => {
    if (TACcountdownPeriod > 0) {
        setTACtimer(setTimeout(() => {
            let tempPeriod = TACcountdownPeriod - 1;
            setTACCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS));
    // }, 10));
    }
}, [TACcountdownPeriod]);

  useEffect(() => {
    {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const tempReceivedRreferralCode = params.get("code");
      let receivedRreferralCode = tempReceivedRreferralCode.trim();
      if (!stringIsNullOrEmpty(receivedRreferralCode)) {
        logSession(receivedRreferralCode);
        setReferralCode(receivedRreferralCode);
        getUpline(receivedRreferralCode);
      } else {
        _history.replace(WebUrl._URL_MAIN);
          }
          getRegisterPoints();
          getDepositPoints();
    }
    let tempLanguage = localStorage.getItem(SessionKey._LANGUAGE);
        if (tempLanguage === undefined || tempLanguage === null) {
            tempLanguage = "EN";
        }
        getTnC(tempLanguage);
        
    if (isLoggedIn) {
      _dispatch(performLogout());
    }

    return () => {
      window.removeEventListener("scroll", (e) => {
      });
    };
}, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function logSession(code) {
    let params = {
      referralCode: code,
    };
    //let logJson = await ApiEngine.post(
    //  ApiUrl._API_ADD_OPERATION_LOG,
    //  createFormBody(params)
    //);
    //if (logJson[ApiKey._API_SUCCESS_KEY]) {
    //}
  }

  async function getUpline(code) {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UPLINE_BY_REFERRAL_CODE + "?code=" + code
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setUplineDetail(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showMessage({
          type: AlertTypes._ERROR,
          content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
          onConfirm: () => _history.replace(WebUrl._URL_MAIN),
        })
      );
    }
  }

  const submitRegisterForm = async (data, e) => {
    _dispatch(setBusy());
    // if (data.registerTAC === currentValidTAC) {
      let TACparams = {
        TAC: data.registerTAC,
        phoneNumber:  selectedPhonePrefix + formattedPhone,
        referralCode: referralCode,
      };
      let TACjson = await ApiEngine.post(
        ApiUrl._API_VERIFY_TAC_CODE,
        createFormBody(TACparams)
      );
      if (TACjson[ApiKey._API_SUCCESS_KEY]) {
        let params = {
          username: data.registerUsername,
          password: data.registerPassword,
          userFullName: data.registerFullName,
          phoneNumber:  selectedPhonePrefix + formattedPhone,
          referralCode: referralCode,
          isMemberOperate: true,
          languageSetting: localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
          localStorage.getItem(SessionKey._LANGUAGE) : 'en' ,
          // localStorage.getItem(SessionKey._LANGUAGE),
        };
          let formBody = createFormBody(params);
          let encryptedFormBody = encryptData(formBody, KEY);
          const dataToSend = {
              es: encryptedFormBody,
          };
          await fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_2, {
              method: ApiKey._API_POST,
              headers: {
                  'Content-Type': ApiKey._API_APPLICATION_JSON,
                  "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
              },
              body: JSON.stringify(dataToSend)
          }).then(response => response.json()).then(responseJson => {
              _dispatch(
                  showMessage({
                      type: responseJson[ApiKey._API_SUCCESS_KEY]
                          ? AlertTypes._SUCCESS
                          : AlertTypes._ERROR,
                      content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                      onConfirm: async () => {
                          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                              setTACBtnAble(false);
                              clearTimeout();
                              setRegisterUsername(data.registerUsername);
                              setRegisterPassword(data.registerPassword);
                              setRegisterPointsEarnedModal(true);
                          } else {
                              _dispatch(setIdle());
                          }
                      },
                  })
              );
          })
      } else {
        _dispatch(showResponseMessage(false, TACjson[ApiKey._API_MESSAGE_KEY]));
      }
    // }
    _dispatch(setIdle());
  };

  async function checkUsername(username) {
    let avail = true;
    let responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_USERNAME + "?username=" + username
    );
    avail = responseJson[ApiKey._API_SUCCESS_KEY];
    return avail;
  }

  async function sendTACCode() {
    // setPhoneNumberError(false);
      if (
        (TACtimedOut || countdownPeriod > 0) &&
        typeof formattedPhone !== "undefined" &&
        (selectedPhonePrefix + formattedPhone).length >= 10
      ) {
        if (!errorsRegister.phoneNumber) {
          let params = {
            phoneNumber: selectedPhonePrefix + formattedPhone,
            referralCode: referralCode,
            languageCode: localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
            localStorage.getItem(SessionKey._LANGUAGE) : 'en' ,
            // localStorage.getItem(SessionKey._LANGUAGE),
            countdown: countdownPeriod,
            TACExist: !TACtimedOut,
          };
          let responseJson = await ApiEngine.post(
            ApiUrl._API_SEND_TAC_CODE,
            createFormBody(params)
          );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            // let data = responseJson[ApiKey._API_DATA_KEY];
            // setCurrentValidTAC(data["tac"]);
            setPhoneNumberError(false);
            setTACsent(true);
            // setTACtimedOut(false);
            setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
            setShowTacEntry(true);
            setTACBtnAble(false);
            setTimeout(() => {
              setTACBtnAble(true);
              setTACtimedOut(true);
            }, _TAC_VALID_TIMER);
          } else {
            setPhoneNumberError(true);
          }
        }
      } else {
        setErrorRegister("registerPhone", { message: "PHONE_NUMBER_INVALID" });
      }
      setCanSendTAC(false);
      clearTimeout(TACtimer);
      setTACCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
      setTimeout(() => {
          setCanSendTAC(true);
      }, _SECONDS_IN_MS * 60 * _TAC_TIMEOUT_IN_MINUTES);
    /*uncomment when want to TAC input without sending SMS*/
    //console.log("sendTACCode");
    //setTACsent(true);
    //setTACtimedOut(false);
    //setCountdownPeriod((_TAC_VALID_TIMER / _SECONDS_IN_MS));
    //setShowTacEntry(true);
    //setTACBtnAble(false);
    //setTimeout(() => { setTACBtnAble(true); setTACtimedOut(true); }, _TAC_VALID_TIMER);
  }

  function onchange(e) {
    const re = /^[0-9\b]+$/;
    // if (e.target.value === "" || re.test(e.target.value) )
    if(e.target.value.match(re) !== null){
      setIsDigit(true);
      //  setFormattedPhone(selectedPhonePrefix + e.target.value)
       setFormattedPhone(e.target.value)
    }
    else{
      setIsDigit(false);
    }
  };

  const getTnC = async (languageCode) => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CONTENT_LANGUAGE + "?title=TnC&languageCode=" + languageCode);
    if (responseJson[ApiKey._API_SUCCESS_KEY]){
        let data = responseJson[ApiKey._API_DATA_KEY];
        setContent(data);
    }
}

    const encryptData = (data, passphrase, iv) => {
        const encryptedData = CryptoJS.AES.encrypt(data, passphrase, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, keySize: 256 / 32 }).toString();

        return encryptedData;
    }

    //const goToDeposit = async (username, password) => {
    //  setRegisterPointsEarnedModal(false);
    //  _dispatch(setBusy());
    //  await _dispatch(performRegisterLogin(username, password));
    //  await _dispatch(createGamePointsBonusRecord());
    //  _dispatch(setIdle());
    //  _history.push(WebUrl._URL_DEPOSIT)
    //}

    const goToHome = async (username, password) => {
        setRegisterPointsEarnedModal(false);
        _dispatch(setBusy());
        await _dispatch(performRegisterLogin(username, password));
        await _dispatch(createGamePointsBonusRecord());
        _dispatch(setIdle());
        _history.push(WebUrl._URL_MAIN)
    }

    const getRegisterPoints = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_REGISTER_GAME_POINTS);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRegisterGamePoints(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    const getDepositPoints = async () => {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_DEPOSIT_GAME_POINTS);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setDepositGamePoints(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

  return (
    <div id="register" style={{ backgroundImage: `url(${Background_1})` }}>
      <section id="register-section">
        <form onSubmit={handleSubmitRegister(submitRegisterForm)}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="display-flex flex-column">
                  {Object.keys(uplineDetail).length > 0 && (
                    <h4 className="mb-1 spacing-small">
                      <b>{uplineDetail?.username ?? ""}</b>{" "}
                      {t("HAS_INVITED_YOU")}
                    </h4>
                  )}
                  <h3 className="register-page-header">
                    {t("REGISTER_OWN_ACCOUNT")}
                  </h3>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="registerFullName"
                      className="register-first-name custom-input-style-1"
                      placeholder={t("FULLNAME")}
                      autoComplete="off"
                      autoFocus={true}
                      ref={registerRegister({
                        // required: true,
                        required: "FIELD_REQUIRED",
                        pattern: {
                          value: /^[^\s\d~`!@#$%&*()-_=\+|\\\/<>,.\^:;"]+$/g,
                          message:
                            "PLEASE_REMOVE_NUMBERS_SPACES_AND_SPECIAL_CHARACTERS",
                        },
                      })}
                      errors={errorsRegister}
                    />
                    <div className="invalid-feedback">
                      {errorsRegister.registerFullName
                        ? t(errorsRegister.registerFullName.message)
                        : ""}
                    </div>
                  </div>
                  {/* middle */}
                  {/* <div className="col-md-6 col-lg-4"></div> */}
                  {/* right */}
                  <div className="col-md-6 col-lg-6"></div>
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="registerUsername"
                      className="register-username custom-input-style-1"
                      placeholder={t("USERNAME")}
                      autoComplete="off"
                      ref={registerRegister({
                        // required: true,
                        required: "FIELD_REQUIRED",
                        validate: async (value) =>
                          (await checkUsername(value)) || "USERNAME_TAKEN",
                        pattern: {
                          value: /^[a-zA-Z0-9]*$/i,
                          message:
                            "PLEASE_REMOVE_SPACES_AND_SPECIAL_CHARACTERS",
                        },
                      })}
                      errors={errorsRegister}
                    />
                    <div className="invalid-feedback">
                      {errorsRegister.registerUsername
                        ? t(errorsRegister.registerUsername.message)
                        : ""}
                    </div>
                  </div>
                  {/* middle */}
                  {/* <div className="col-md-6 col-lg-4"></div> */}
                  {/* right */}
                  <div className="col-md-6 col-lg-6"></div>
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="registerPassword"
                      className="register-password custom-input-style-1"
                      placeholder={t("PASSWORD")}
                      autoComplete="off"
                      ref={registerRegister({
                        // required: true,
                        required: "FIELD_REQUIRED",
                        minLength: {
                          value: _MIN_PASSWORD_LENGTH,
                          message: t("MIN_CHARACTERS", {
                            length: _MIN_PASSWORD_LENGTH,
                          }),
                        },
                        validate: (value) =>
                          watchRegister("registerConfirmPassword") == "" ||
                          value == watchRegister("registerConfirmPassword") ||
                          "PASSWORD_MISMATCH",
                      })}
                      errors={errorsRegister}
                    />
                    <div className="invalid-feedback">
                      {errorsRegister.registerPassword
                        ? t(errorsRegister.registerPassword.message)
                        : ""}
                    </div>
                  </div>
                  {/* middle */}
                  {/* <div className="col-md-6 col-lg-4"> </div> */}
                  {/* right */}
                  <div className="col-md-6 col-lg-6"> </div>
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="registerConfirmPassword"
                      className="register-password custom-input-style-1"
                      placeholder={t("CONFIRM_PASSWORD")}
                      autoComplete="off"
                      ref={registerRegister({
                        // required: true,
                        required: "FIELD_REQUIRED",
                        minLength: {
                          value: _MIN_PASSWORD_LENGTH,
                          message: t("MIN_CHARACTERS", {
                            length: _MIN_PASSWORD_LENGTH,
                          }),
                        },
                        validate: (value) =>
                          watchRegister("registerPassword") == "" ||
                          value == watchRegister("registerPassword") ||
                          "PASSWORD_MISMATCH",
                      })}
                      errors={errorsRegister}
                    />
                    <div className="invalid-feedback">
                      {errorsRegister.registerConfirmPassword
                        ? t(errorsRegister.registerConfirmPassword.message)
                        : ""}
                    </div>
                  </div>
                  {/* middle */}
                  {/* <div className="col-md-6 col-lg-4"> </div> */}
                  {/* right */}
                  <div className="col-md-6 col-lg-6"> </div>
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <div className="register-phone-number">
                      <select
                        className="phone-number-prefix custom-input-style-1"
                        onChange={(e) => setSelectedPhonePrefix(e.target.value)}
                      >
                        {PhoneNumberPrefix.map((prefix, index) => {
                          return (
                            <option
                            key={index}
                              className={"phone-prefix-" + (index + 1)}
                              value={prefix.value}
                            >
                              {prefix.label}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        name="registerPhone"
                        className="register-phone-number custom-input-style-1"
                        placeholder={t("PHONE_NUMBER")}
                        autoComplete="off"
                        readOnly={(!canSendTAC && TACcountdownPeriod != 0) || (TACsent && countdownPeriod != 0 ) ? true : false }
                        ref={registerRegister({
                          // required: true,
                          required: "FIELD_REQUIRED",
                          minLength: {
                            value: 8,
                            message: t("MIN_CHARACTERS", { length: 8 }),
                          },
                          pattern: {
                            value: /^[0-9]+$/g,
                            message:
                              t("MIN_CHARACTERS", { length: 8 }) +
                              " " +
                              t("AND") +
                              " " +
                              t("ONLY_NUMBERS"),
                          },
                        })}
                        errors={errorsRegister}
                        onChange={(e) =>
                          onchange(e)
                          // setFormattedPhone(
                          //   selectedPhonePrefix + e.target.value
                          // )
                        }
                      />
                     
                      <button
                        type="button"
                        className="send-tac-btn"
                        onClick={() => sendTACCode()}
                        disabled={
                        (!canSendTAC && TACcountdownPeriod != 0) || !isDigit || (selectedPhonePrefix + formattedPhone).length < 10 || TACsent && countdownPeriod > 0 || !TACtimedOut 
                        }
                      >
                        {TACsent ? t("RESEND_TAC_CODE") : t("SEND_TAC_CODE")}
                      </button>
                    </div>
                    <div
                      className={
                        "invalid-feedback" +
                        (phoneNumberError == true ? " phonenumber-error" : "")
                      }
                    >
                      {/* {errorsRegister.registerPhone
                        ? t(errorsRegister.registerPhone.message)
                        : phoneNumberError == true
                        ? t("NUMBER_NOT_AVAILABLE") 
                        : ""} */}

                        {phoneNumberError == true 
                        ? 
                        <> 
                          {t("NUMBER_NOT_AVAILABLE")}
                          {/* {!canSendTAC && (", try again later in " + TACcountdownPeriod + " seconds.") } */}
                          {!canSendTAC && TACcountdownPeriod != 0 && (", " + t("PLEASE_TRY_AGAIN_IN_SECONDS", { value: TACcountdownPeriod })) }
                            </> 
                        : errorsRegister.registerPhone
                        ?  t(errorsRegister.registerPhone.message) 
                        : "" }
                    </div>
                  </div>
                  {/* middle */}
                  <div className="col-md-6 col-lg-6"></div>
                  {/* right */}
                  {/* <div className="col-md-6 col-lg-4"></div> */}
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-4">
                    <div className="register-tac-wrapper">
                      {TACsent && countdownPeriod != 0 && (
                        <input
                          type="text"
                          name="registerTAC"
                          className="register-tac custom-input-style-1"
                          placeholder={t("TAC")}
                          autoComplete="off"
                          ref={registerRegister({
                            // required: true,
                            required: "FIELD_REQUIRED",
                            minLength: {
                              value: 6,
                              message: t("MIN_CHARACTERS", { length: 6 }),
                            },
                            maxLength: {
                              value: 6,
                              message: t("MAX_CHARACTERS", { length: 6 }),
                            },
                          })}
                          errors={errorsRegister}
                        />
                      )}
                      {TACsent && countdownPeriod != 0 && (
                        <p className="tac-countdown">
                          {t("TAC_SENT")} {t("VALID_FOR")} {countdownPeriod}s
                        </p>
                      )}
                    </div>
                  </div>
                  {/* middle */}
                  <div className="col-md-6 col-lg-6"></div>
                  {/* right */}
                  {/* <div className="col-md-6 col-lg-4"></div> */}
                </div>

                <div className="row">
                  {/* left */}
                  <div className="col-md-6 col-lg-6">
                    <div className="register-checkbox-btn-wrapper  mb-4 mt-2">                      
                      <div className="register-tnc register-checkbox">
                        <input
                          type="checkbox"
                          id="terms-and-conditions"
                          style={{ marginBottom: "5px" }}
                          //ref={register}
                          onChange={() => setAgreedTnC(!agreedTnC)}
                        />
                        <div className="tnc-agree-label">
                          <label
                            style={{ color: "white", fontSize: "14px" }}
                            htmlFor="terms-and-conditions"
                          >
                            {t("I_AGREE_TO")}
                            <div style={{ display: "contents" }}>
                              {t("TNC_i8")}
                            </div>
                          </label>
                          <div
                            style={{
                              textDecoration: "underline",
                              fontSize: "14px",
                              color: "#ffc159",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowTnC(true)}
                          >
                            {t("TNC")}
                          </div>
                        </div>
                      </div>
                      <div className="register-age-18 register-checkbox">
                        <input
                          type="checkbox"
                          id="legal-age-18"
                          style={{ marginBottom: "5px" }}
                          //ref={register}
                          onChange={() => setAgreeAge18(!agreeAge18)}
                        />
                        <div className="agree-age-label">
                          <label
                            style={{ color: "white", fontSize: "14px" }}
                            htmlFor="legal-age-18"
                          >
                            {t("I_AGREE_LEGAL_AGE_18")}
                          </label>
                        </div>
                      </div>

                      <div className="register-viewlicence register-checkbox">
                        <div className="tnc-agree-label mb-4 mt-2">
                          <img
                            src="assets/img/pagcorlogo2.svg"
                            className='img-responsive'
                            alt='pagcor'
                          />
                        </div>
                        <div className="tnc-agree-label mb-4 mt-2 mx-2">
                          <div
                            style={{
                              textDecoration: "underline",
                              fontSize: "14px",
                              color: "#ffc159",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowLicense(true)}
                          >
                            {t("VIEW_OUR_LICENSE")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="custom-btn-style-1"
                      type="submit"
                      disabled={
                        !agreedTnC ||
                        !agreeAge18 ||
                        !getValuesRegister("registerTAC") ||
                        (getValuesRegister("registerTAC") &&
                          getValuesRegister("registerTAC").length != 6)
                      }
                    >
                      {t("REGISTER")}
                    </button>
                  </div>
                  {/* middle */}
                  <div className="col-md-6 col-lg-6 icon-register" style={{ backgroundImage: `url(${WarioIcon})` }}></div>
                  {/* right */}
                  {/* <div className="col-md-6 col-lg-4"></div> */}

                  {/* <div
                    className="register-bottom-icon"
                    style={{ backgroundImage: `url(${WarioIcon})` }}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
        </form>

        {showTnC && <div>
                    <div className="tnc-popup-wrapper"></div>
                      <div className="tnc-popup">
                      <div className="tnc-popup-header">{t("TNC")}</div>
                      <div className="tnc-container">
                          {parse(content)}
                          </div>
                      <div style={{ paddingRight: "8px"}}><button type="button" onClick={() => setShowTnC(false)} className="btn btn-brand-gradient btn-block">{t('OK')}</button></div>
                    </div>
                </div>}


        {/* {showTnC && (
          <div>
            <div className="tnc-popup-wrapper"></div>
            <div className="tnc-popup">
              <div className="tnc-popup-header">{t("TNC")}</div>
              <div className="tnc-popup-body">
                <label className="tnc-popup-label" htmlFor="terms-of-use">
                  {t("TERMS_OF_USE")}
                </label>
                <div id="terms-of-use">
                  <div>{t("TERMS_OF_USE_1")}</div>
                  <div>{t("TERMS_OF_USE_2")}</div>
                </div>
                <label className="tnc-popup-label" htmlFor="tnc-general">
                  {t("GENERAL")}
                </label>
                <div id="tnc-general">
                  <div className="tnc-list">
                    {" "}
                    1.<div className="col-12">{t("GENERAL_1")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.<div className="col-12">{t("GENERAL_2")}</div>
                  </div>
                </div>
                <label className="tnc-popup-label" htmlFor="tnc-memberAcc">
                  {t("MEMBER_ACCOUNT")}
                </label>
                <div id="tnc-memberAcc">
                  <div className="tnc-list">
                    {" "}
                    1.<div className="col-12">{t("MEMBER_ACCOUNT_1")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.<div className="col-12">{t("MEMBER_ACCOUNT_2")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    3.<div className="col-12">{t("MEMBER_ACCOUNT_3")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    4.<div className="col-12">{t("MEMBER_ACCOUNT_4")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    5.<div className="col-12">{t("MEMBER_ACCOUNT_5")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    6.<div className="col-12">{t("MEMBER_ACCOUNT_6")}</div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    7.<div className="col-12">{t("MEMBER_ACCOUNT_7")}</div>
                  </div>
                </div>
                <label
                  className="tnc-popup-label"
                  htmlFor="tnc-depositNwithdrawal"
                >
                  {t("DEPOSIT_AND_WITHDRAWAL")}
                </label>
                <div id="tnc-depositNwithdrawal">
                  <div className="tnc-list">
                    {" "}
                    1.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_1")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_2")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    3.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_3")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    4.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_4")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    5.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_5")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    6.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_6")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    7.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_7")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    8.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_8")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    9.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_9")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    10.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_10")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    11.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_11")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    12.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_12")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    13.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_13")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    14.
                    <div className="col-12">
                      {t("DEPOSIT_AND_WITHDRAWAL_14")}
                    </div>
                  </div>
                  <div className="tnc-bullets-wrapper">
                    <div className="col-12 tnc-bullets">
                      {t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_1")}
                    </div>
                    <div className="col-12 tnc-bullets">
                      {t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_2")}
                    </div>
                    <div className="col-12 tnc-bullets">
                      {t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_3")}
                    </div>
                    <div className="col-12 tnc-bullets">
                      {t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_4")}
                    </div>
                    <div className="col-12 tnc-bullets">
                      {t("DEPOSIT_AND_WITHDRAWAL_14_BULLET_5")}
                    </div>
                  </div>
                </div>
                <label
                  className="tnc-popup-label"
                  htmlFor="tnc-bettingTransactions"
                >
                  {t("MANAGEMENT_OF_BETTING_TRANSACTIONS")}
                </label>
                <div id="tnc-bettingTransactions">
                  <div className="tnc-list">
                    {" "}
                    1.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_1")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_2")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    3.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_3")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    4.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_4")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    5.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_5")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    6.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_6")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    7.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_7")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    8.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_8")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    9.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_9")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    10.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_10")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    11.
                    <div className="col-12">
                      {t("MANAGEMENT_OF_BETTING_TRANSACTIONS_11")}
                    </div>
                  </div>
                </div>
                <label className="tnc-popup-label" htmlFor="tnc-collusion">
                  {t("COLLUSION_AND_ABUSE_ACTIVITIES")}
                </label>
                <div id="tnc-collusion">
                  <div className="tnc-list">
                    {" "}
                    1.
                    <div className="col-12">
                      {t("COLLUSION_AND_ABUSE_ACTIVITIES_1")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.
                    <div className="col-12">
                      {t("COLLUSION_AND_ABUSE_ACTIVITIES_2")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    3.
                    <div className="col-12">
                      {t("COLLUSION_AND_ABUSE_ACTIVITIES_3")}
                    </div>
                  </div>
                </div>
                <label className="tnc-popup-label" htmlFor="tnc-interruptions">
                  {t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE")}
                </label>
                <div id="tnc-interruptions">
                  <div className="tnc-list">
                    {" "}
                    1.
                    <div className="col-12">
                      {t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_1")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.
                    <div className="col-12">
                      {t("HANDLING_ON_SYSTEM_INTERRUPTIONS_ON_FORCE_MAJUERE_2")}
                    </div>
                  </div>
                </div>
                <label
                  className="tnc-popup-label"
                  htmlFor="tnc-administrations"
                >
                  {t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE")}
                </label>
                <div id="tnc-administrations">
                  <div className="tnc-list">
                    {" "}
                    1.
                    <div className="col-12">
                      {t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_1")}
                    </div>
                  </div>
                  <div className="tnc-list">
                    {" "}
                    2.
                    <div className="col-12">
                      {t("ADMINISTRATIONS_OF_EXISTING_TERM_OF_USE_2")}
                    </div>
                  </div>
                </div>
                <label
                  className="tnc-popup-label"
                  htmlFor="tnc-customerService"
                >
                  {t("CUSTOMER_SERVICE")}
                </label>
                <div id="tnc-customerService">
                  <div className="tnc-list">
                    {" "}
                    1.<div className="col-12">{t("CUSTOMER_SERVICE_1")}</div>
                  </div>
                </div>
                <div>
                  <div className="tnc-list" style={{ marginTop: "20px" }}>
                    <div>{t("ADDITIONAL_TNC_1")}</div>
                  </div>
                </div>
              </div>
              <div style={{ paddingRight: "8px" }}>
                <button
                  type="button"
                  onClick={() => setShowTnC(false)}
                  className="btn btn-brand-gradient btn-block"
                >
                  {t("OK")}
                </button>
              </div>
            </div>
          </div>
        )} */}
        {showLicense && (
          <div>
            <div className="license-popup-wrapper"></div>
            <div className="license-popup">
              <div className="license-popup-body">
                <img
                  src="/img/license.jpg"
                  className='img-responsive'
                  style={{height: "100%"}}
                  alt='pagcor'
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setShowLicense(false)}
                  className="btn btn-brand-gradient btn-block"
                >
                  {t("OK")}
                </button>
              </div>
            </div>
          </div>
              )}
              <Modal
                  id="success-modal"
                  fade={true}
                  isOpen={registerPointsEarnedModal}
                  toggle={() => {
                      setRegisterPointsEarnedModal(false);
                  }}
                  centered
              >
                  <ModalBody>
                      <div className="modal-success-container">
                          <img src="/img/version3/successful.png" alt="waving" className="img-responsive" />
                          <h4 className="modal-success-title mt-4">
                              {t("CONGRATULATIONS")}
                          </h4>
                          <p className="modal-success-details mb-0">{t("EARNED_GAME_POINTS", { points: registerGamePoints })} <br />{t("DEPOSIT_NOW_FOR_POINTS", { points: depositGamePoints })}</p>
                          <div className="btn-container">
                              {/*<a className="btn custom-btn mt-4" onClick={() => goToDeposit(registerUsername, registerPassword)}>*/}
                              {/*  {t("DEPOSIT_NOW")}*/}
                              {/*</a>*/}
                              <a className="btn custom-btn mt-4" onClick={() => goToHome(registerUsername, registerPassword)}>
                                  {t("GO_TO_HOME")}
                              </a>
                          </div>
                      </div>
                  </ModalBody>
              </Modal>
      </section>
    </div>
  );
};

export default Register;
