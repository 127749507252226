import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  FixedDepositAmount
} from "../../util/Constant";

import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  numberWithCurrencyFormat2,
  numberWithCommas
}
  from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Alert } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';
import classnames from "classnames";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import moment from "moment";

//THB deposit image
import VaderPayIcon from "../../assets/img/assets/VaderPay.svg";
import VaderPayQRIcon from "../../assets/img/assets/VaderPayQR.svg";
import SPGIcon from "../../assets/img/assets/SPG.svg";
import SPGQRIcon from "../../assets/img/assets/SPGQR.svg";
import FattyPayIcon from "../../assets/img/assets/FattyPay.svg";
import FattyPayQRIcon from "../../assets/img/assets/TruePay.svg";
import JSTPayIcon from "../../assets/img/assets/JST.svg";
import MegaPayQRIcon from "../../assets/img/assets/MegaPay.svg";
import DirePayQRIcon from "../../assets/img/assets/DirePay.jpg";

//THB qr payment 
import TrueMoneyIcon from "../../assets/img/assets/TrueMoney.svg";
import PromptPayIcon from "../../assets/img/assets/PromptPay.svg";
import ThaiQRIcon from "../../assets/img/assets/ThaiQR.svg";

//other image
import TickIcon from "../../assets/img/assets/tick.svg";
import TickIcon1 from "../../assets/img/assets/tick_1.svg";

const InstantDeposit = (props) => {
  const { t } = useTranslation();
  let _dispatch = useDispatch();
  const {
    handleSubmit,
    errors,
    clearErrors,
    setError,
  } = useForm();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const _userData = useSelector(state => state.authState.userData);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [providerIsSelected, setProviderIsSelected] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const fixedAmount = FixedDepositAmount;
  const [instantPaymentList, setInstantPaymentList] = useState([]);
  const [loadingPaymentData, setLoadingPaymentData] = useState(true);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [vaderBankList, setVaderBankList] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [canDisplayBank, setCanDisplayBank] = useState(true);
  var PROMPT_PAY_START_HOUR_LIMIT_IN_UTC0 = 0;
  var PROMPT_PAY_END_HOUR_LIMIT_IN_UTC0 = 23
  const currentHourInUTC0 = moment();


  //useEffect 
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (props.memberTransactionHistory.length > 0 && paymentOptionList.length > 0) {
      initMemberTransactionHistoryData();
    }
  }, [paymentOptionList, props.memberTransactionHistory]);

  //init/call function
  async function init() {
    getPaymentList();
  }


  const getPaymentList = async () => {
    let memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    var responseGetVaderBankJson = await ApiEngine.get(ApiUrl._API_GET_VADERPAY_BANK_OPTION);
    let PromptPayResponseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_PROMPT_PAY_SYSTEM_SETTINGS);

    let providerOptions = [];
    let tempVaderBankList = [];
    let startTimeStr = "";
    let endTimeStr = "";
    let PromptPayStatus = false;
    let openPromptPay = false;

    if (PromptPayResponseJson[ApiKey._API_SUCCESS_KEY] && PromptPayResponseJson[ApiKey._API_DATA_KEY].length > 0) {
      let data = PromptPayResponseJson[ApiKey._API_DATA_KEY];

      data.map((keyValuePair, index) => {
        if (keyValuePair["key"] === "PromptPayStartTime") {
          startTimeStr = keyValuePair["value"];
          //setPromptPayStartTime(keyValuePair["value"]);
          PROMPT_PAY_START_HOUR_LIMIT_IN_UTC0 = moment(
            moment().format("YYYY-MM-DD") + " " + startTimeStr,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (keyValuePair["key"] === "PromptPayEndTime") {
          endTimeStr = keyValuePair["value"];
          //setPromptPayEndTime(keyValuePair["value"]);
          PROMPT_PAY_END_HOUR_LIMIT_IN_UTC0 = moment(
            moment().format("YYYY-MM-DD") + " " + endTimeStr,
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (keyValuePair["key"] === "PromptPayStatus") {
          PromptPayStatus = keyValuePair["value"] === "true";
        }
      });

      if (
        PromptPayStatus &&
        currentHourInUTC0.isAfter(PROMPT_PAY_START_HOUR_LIMIT_IN_UTC0) &&
        currentHourInUTC0.isBefore(PROMPT_PAY_END_HOUR_LIMIT_IN_UTC0)
      ) {
        openPromptPay = true;
      }

      if (responseGetVaderBankJson[ApiKey._API_SUCCESS_KEY]) {
        responseGetVaderBankJson[ApiKey._API_DATA_KEY].map((bank) => {
          if (bank.value !== "VaderPay QR")
            tempVaderBankList.push(bank);
        });
        setVaderBankList(tempVaderBankList);
      }

      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO + "?all=true"
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data1 = [];

        if (memberJson[ApiKey._API_DATA_KEY]["vipId"] === 2)
          data1 = responseJson[ApiKey._API_DATA_KEY];
        else
          data1 = responseJson[ApiKey._API_DATA_KEY].filter((x) => !x.isVIPOnly);

        setInstantPaymentList(responseJson[ApiKey._API_DATA_KEY]);

        if (!openPromptPay) {
          data1 = data1.filter(x => x.brandName !== "PromptPay");
        }

        //instant payment load
        data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString !== "VaderPay" && y.status === true).map((option, index) => {

          option.instantBankSettingModel.map((option2, index2) => {
            let foundIndex = providerOptions.findIndex(x => x.bankId === option2.bankId && x.paymentType === option.paymentType);
            if (foundIndex === -1) {
              let temProvider = [];
              temProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
              providerOptions.push({
                bankId: option2.bankId,
                name: option2.name,
                status: option.status,
                image: option2.bankLogo2,
                paymentType: option.paymentType,
                availableProvider: temProvider,
                bankInstantRank: option2.bankInstantRank
              });
            } else {
              providerOptions[foundIndex].availableProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
            }
          })
        });

        data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString === "VaderPay" && y.status === true).map((option, index) => {
          tempVaderBankList.map((option3, index3) => {
            let foundIndex = providerOptions.findIndex(x => x.bankId === option3.displayBankId && x.paymentType === option.paymentType);
            if (foundIndex === -1) {
              let temProvider = [];
              temProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });

              providerOptions.push({
                bankId: option3.displayBankId,
                name: option3.bankName,
                status: option.status,
                image: option3.bankLogo,
                paymentType: option.paymentType,
                availableProvider: temProvider,
                bankInstantRank: option3.bankInstantRank
              });
            } else {
              providerOptions[foundIndex].availableProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
            }
          })
        });

        //duit now load
        data1.filter(y => y.paymentType === "QR Payment" && y.brandName === "TrueMoney" && y.status === true).map((option, index) => {
          option.instantBankSettingModel.map((option2, index2) => {
            if (providerOptions.filter(x => x.bankId === -100).length === 0) {
              let temProvider = [];
              temProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
              providerOptions.push({
                bankCode: "TrueMoney",
                bankId: -100,
                name: t("TrueMoney"),
                status: true,
                image: TrueMoneyIcon,
                paymentType: option.paymentType,
                availableProvider: temProvider,
                bankInstantRank: 1
              });
            } else {
              providerOptions.filter(x => x.bankId === -100)[0].availableProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
            }
          })
        });

        data1.filter(y => y.paymentType === "QR Payment" && y.brandName === "ThaiQR" && y.status === true).map((option, index) => {
          option.instantBankSettingModel.map((option2, index2) => {
            if (providerOptions.filter(x => x.bankId === -101).length === 0) {
              let temProvider = [];
              temProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
              providerOptions.push({
                bankCode: "ThaiQR",
                bankId: -101,
                name: t("ThaiQR"),
                status: true,
                image: ThaiQRIcon,
                paymentType: option.paymentType,
                availableProvider: temProvider,
                bankInstantRank: 1
              });
            } else {
              providerOptions.filter(x => x.bankId === -101)[0].availableProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "ThaiQr-JSTPay" ? JSTPayIcon
                                    : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                      : null,
              });
            }
          })
        });

        data1.filter(y => y.paymentType === "QR Payment" && y.brandName === "PromptPay" && y.status === true).map((option, index) => {
          option.instantBankSettingModel.map((option2, index2) => {
            if (providerOptions.filter(x => x.bankId === -102).length === 0) {
              let temProvider = [];
              temProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                    : option.instantPayGatewayString == "DirePay QR" ? DirePayQRIcon
                                      : null,
              });
              providerOptions.push({
                bankCode: "ThaiQR",
                bankId: -102,
                name: t("ThaiQR"),
                status: true,
                image: PromptPayIcon,
                paymentType: option.paymentType,
                availableProvider: temProvider,
                bankInstantRank: 1
              });
            } else {
              providerOptions.filter(x => x.bankId === -102)[0].availableProvider.push({
                name: option.instantPayGatewayString,
                image: option.instantPayGatewayString == "SPG" ? SPGIcon
                  : option.instantPayGatewayString == "SPG QR" ? SPGQRIcon
                    : option.instantPayGatewayString == "VaderPay" ? VaderPayIcon
                      : option.instantPayGatewayString == "VaderPay QR" ? VaderPayQRIcon
                        : option.instantPayGatewayString == "FattyPay" ? FattyPayIcon
                          : option.instantPayGatewayString == "OneWallet" ? FattyPayIcon
                            : option.instantPayGatewayString == "OneWallet Qr" ? FattyPayQRIcon
                              : option.instantPayGatewayString == "TrueMoney-JSTPay" ? JSTPayIcon
                                : option.instantPayGatewayString == "PromptPay-JSTPay" ? JSTPayIcon
                                  : option.instantPayGatewayString == "MegaPay QR" ? MegaPayQRIcon
                                    : option.instantPayGatewayString == "DirePay QR" ? DirePayQRIcon
                                      : null,
              });
            }
          })
        });

        setPaymentOptionList(providerOptions.sort((a, b) => a.bankInstantRank - b.bankInstantRank));
      }
      setLoadingPaymentData(false);
    }
  }

  const initMemberTransactionHistoryData = async () => {
    let found = props.memberTransactionHistory.filter(x => x.isInstant && x.transactionTypeId === TransactionType._DEPOSIT);

    if (found.length > 0) {
      if (instantPaymentList.filter((x) => x.instantPayGatewayString === found[0].instantProviderName)[0].paymentType === "QR Payment") {
        if (instantPaymentList.filter((x) => x.instantPayGatewayString === found[0].instantProviderName)[0].brandName === "TrueMoney") {
          setBankIsSelected(true);
          setSelectedBank(paymentOptionList.filter(x => x.bankId === -100)[0]);
          handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === -100)[0]);
        } else if (instantPaymentList.filter((x) => x.instantPayGatewayString === found[0].instantProviderName)[0].brandName === "ThaiQR") {
          setBankIsSelected(true);
          setSelectedBank(paymentOptionList.filter(x => x.bankId === -101)[0]);
          handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === -101)[0]);
        } else if (instantPaymentList.filter((x) => x.instantPayGatewayString === found[0].instantProviderName)[0].brandName === "PromptPay") {
          setBankIsSelected(true);
          setSelectedBank(paymentOptionList.filter(x => x.bankId === -102)[0]);
          handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === -102)[0]);
        }
      } else {
        if (found[0].instantProviderName === "VaderPay") {
          if (paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId).length > 0) {
            setBankIsSelected(true);
            setSelectedBank(paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId)[0]);
            handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId)[0]);
          }
        } else {
          if (paymentOptionList.filter(x => x.bankId === found[0].instantBankId).length > 0) {
            setBankIsSelected(true);
            setSelectedBank(paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
            handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
          }
        }
      }
    }
  }

  //other fucntion
  function handleProviderSelection(providerName, bank) {
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === providerName);
    if (index !== -1) {
      if (instantPaymentList[index].paymentType === "QR Payment") {
        setSelectedBankCode(instantPaymentList[index]["instantBankSettingModel"][0].bankCode);
        setMin(instantPaymentList[index]["instantBankSettingModel"][0].minimumDeposit);
        setMax(instantPaymentList[index]["instantBankSettingModel"][0].maximumDeposit);
        setServiceCharge(instantPaymentList[index]["serviceCharge"]);
        setSelectedProviderName(providerName);
      } else {
        if (providerName === "VaderPay") {
          setSelectedBankCode(vaderBankList.filter(x => x.displayBankId == bank.bankId)[0].bankCode);
          setMin(instantPaymentList[index]["instantBankSettingModel"][0].minimumDeposit);
          setMax(instantPaymentList[index]["instantBankSettingModel"][0].maximumDeposit);
          setServiceCharge(instantPaymentList[index]["serviceCharge"]);
          setSelectedProviderName(providerName);
        } else {
          if (instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId).length > 0) {
            setSelectedBankCode(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].bankCode);
            setMin(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].minimumDeposit);
            setMax(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].maximumDeposit);
            setServiceCharge(instantPaymentList[index]["serviceCharge"]);
            setSelectedProviderName(providerName);
          }
        }
      }
    }
    setAmountValue("");
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleBankSelection = async (bank) => {
    setBankIsSelected(true);
    setSelectedBank(bank);
    if (paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider.length > 0) {
      handleProviderSelection(paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider[0].name, bank);
    }
  }


  function resetFormField() {
    setSelectedProviderName("");
    setBankIsSelected(false);
    setSelectedBank({});
    setSelectedBankCode("");
    setMin(0);
    setMax(0);
    setServiceCharge(0);
    setAmountValue("");
  }

  //const checkValueOnKeyDown = async (e) => {
  //  clearErrors();
  //  let tempAmountValue = amountValue;
  //  if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { //keyCode of 0 to 9, numpad 0 to 9
  //    tempAmountValue = amountValue + "" + e.key;
  //    setAmountValue(tempAmountValue);
  //  }
  //  else if (e.keyCode === 8 || e.keyCode === 46) //keyCode of backSpace and Del
  //  {
  //    tempAmountValue = amountValue.slice(0, -1);
  //    setAmountValue(tempAmountValue);
  //  }
  //  if (parseFloat(tempAmountValue) < min) {
  //    setError("amount", {
  //      message: "VALUE_LESS_THAN_MINIMUM",
  //    });
  //  }
  //  else if (parseFloat(tempAmountValue) > max) {
  //    setError("amount", {
  //      message: "EXCEED_MAXIMUM_VALUE",
  //    });
  //  }
  //}

  const checkAmountForErrors = async (tempAmountValue) => {
    clearErrors();
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  const checkValueOnSelectFixedAmt = async (fixedAmt) => {
    let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue)).toString();
    setAmountValue(
      updatedValue
    );
    clearErrors();
    let tempAmountValue = updatedValue;
    if (parseFloat(tempAmountValue) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(tempAmountValue) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === selectedProviderName);
    if (index !== -1) {
      let bankData;
      let foundProvider = instantPaymentList[index];
      if (selectedProviderName === "VaderPay" || selectedProviderName === "VaderPay QR"
        || selectedProviderName === "SPG QR" || selectedProviderName === "TrueMoney-JSTPay"
        || selectedProviderName === "ThaiQr-JSTPay" || selectedProviderName === "PromptPay-JSTPay"
        || selectedProviderName === "MegaPay QR" || selectedProviderName === "DirePay QR"
      ) {
        bankData = foundProvider["instantBankSettingModel"][0];
      } else {
        bankData = foundProvider["instantBankSettingModel"].filter(
          (option) => option.bankId === selectedBank.bankId
        )[0]
      }

      let params = {
        transactionTypeId: TransactionType._DEPOSIT,
        bankId: bankData.bankId,
        amount: parseFloat(amountValue),
        bankCode: selectedBankCode,
        channel: foundProvider["instantPayGatewayId"],
        panel: PanelType._MEMBERSITE,
        instantPayBankSettingId: bankData.id,
        currency: bankData.currency,
        instantPay: true,
        receiptReference:
        selectedProviderName + "(" + foundProvider["instantPayGatewayString"] + ")",
        bankAccountId: bankData.bankId,
        paymentMethod: foundProvider["instantPayGatewayString"],
      };

      if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
        params["bonusId"] = props.bonusDeposit;
      } else if (props.bonusDeposit == "-1") {
        params["bonusId"] = "";
      }

      let responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_TRANSACTION,
        createMultiPartFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

        e.target.reset();
        props.parentInit();

        if (selectedProviderName === "SPG" || selectedProviderName === "SPG QR" || selectedProviderName === "VaderPay" || selectedProviderName === "VaderPay QR") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY]["redirect_to"],
              })
            );
          } else {
            window.location.href =
              responseJson[ApiKey._API_DATA_KEY]["redirect_to"];

            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        } else if (selectedProviderName === "MegaPay QR") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY].uri,
              })
            );
          } else {
            window.open(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY].uri, "payment");
            window.location.reload();
            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        } else if (selectedProviderName === "DirePay QR") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY]["pay_url"],
              })
            );
          } else {
            //window.location.href =
            //  responseJson[ApiKey._API_DATA_KEY]["pay_url"];

            window.open(responseJson[ApiKey._API_DATA_KEY]["pay_url"], "payment");
            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        } else {
          window.location.href = responseJson[ApiKey._API_DATA_KEY];
          _dispatch(setIdle());
        }

      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    _dispatch(setIdle());
  }

  return (
    <>
      {showCopyAlert && (
        <div style={{ position: scrollPosition < 224 ? "relative" : "sticky", top: scrollPosition < 150 ? "107px" : scrollPosition >= 150 && scrollPosition < 160 ? "-65px" : scrollPosition >= 160 && scrollPosition < 170 ? "-55px" : scrollPosition >= 170 && scrollPosition < 180 ? "-45px" : scrollPosition >= 180 && scrollPosition < 190 ? "-35px" : scrollPosition >= 190 && scrollPosition < 200 ? "-25px" : scrollPosition >= 200 && scrollPosition < 210 ? "-15px" : scrollPosition >= 210 && scrollPosition < 224 ? "-5px" : "160px", left: "-55px", width: "100%", color: "white", zIndex: "99999" }}>
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              width: "200px",
              top: "10px",
              right: "-40px"
            }}
          >
            <Alert color="green" style={{ textAlign: "center" }}>
              {t("COPY_SUCCESS")}
            </Alert>
          </div>
        </div>
      )}
      <div className="normal-deposit-wrapper">
        {!loadingPaymentData ? <form onSubmit={handleSubmit(onSubmit)}>
          {bankIsSelected === false && (
            <>
              <div className="all-normal-banks-container">

              {paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).length > 0 && (
                  <>
                    <div className="normal-bank-item-container">
                      <div className="font14 normal-parent-bank-title">
                      {t("QR_PAY")}
                      </div>
                      <div className="deposit-normal-bank-list-container">
                        {paymentOptionList &&
                        paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).length > 0 &&
                        paymentOptionList.filter(x => x.paymentType === "QR Payment" && x.status === true).map((child, index) => {
                            return (
                            <div
                              key={"qr-deposit-bank" + index}
                              className="deposit-normal-bank-list-items"
                                onClick={() => { handleBankSelection(child) }}
                              >
                                <img
                                  src={child.image}
                                  alt="i8 banks"
                                  className="img-responsive"
                                  loading="lazy"
                                />
                              </div>
                            );
                        })
                        }
                      </div>
                    </div>
                  </>
                )}
              {paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 && (
                  <>
                  <hr className="custom-hr" />
                    <div className="normal-bank-item-container">
                      <div className="font14 normal-parent-bank-title">
                      {t("BANK_INSTANT")}
                      </div>
                    <div className="deposit-normal-bank-list-container mt-3">
                        {paymentOptionList &&
                        paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 &&
                        paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).map((child, index) => {
                            return (
                            <div key={"instant-deposit-bank" + index}
                                className="deposit-normal-bank-list-items"
                                onClick={() => { handleBankSelection(child) }}
                              >
                                <img
                                  src={child.image}
                                alt="i8 banks"
                                  className="img-responsive"
                                  loading="lazy"
                                />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {bankIsSelected && (
            <>
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("SELECTED_BANK_METHOD")}
                </div>
                <div className="second ">
                  <div className="instant-provider-selected-wrapper mt-3" onClick={() => { resetFormField(); }}>
                    <div className="instant-provider active">
                      <img className="tick-image" src={TickIcon} alt="active" />
                      <img className="instant-provider-image" src={selectedBank.image} alt={selectedBank.bankName} />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {!stringIsNullOrEmpty(selectedProviderName) ? t("SELECTED_PROVIDER") : t("PLEASE_SELECT_PROVIDER")}
                </div>
                <div className="second ">
                  <div className="instant-provider-selected-wrapper">
                    {paymentOptionList &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.length > 0 &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.map((child, index) => {
                        return (

                          <div key={"instant-deposit-provider" + index} className={"instant-provider " + classnames({ active: selectedProviderName === child.name, })} onClick={() => { handleProviderSelection(child.name, selectedBank); }}>
                            {selectedProviderName === child.name &&
                              <img className="tick-image" src={TickIcon} alt={child.name} loading="lazy" />
                            }
                            <img className="instant-provider-image" src={child.image} alt={child.title} loading="lazy" />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </>
          )}

          {bankIsSelected && selectedProviderName && (
            <>

              {parseFloat(serviceCharge) > 0 && parseFloat(serviceCharge) !== 0 && (
                <>
                  <hr className="custom-hr" />
                  <div className="normal-bank-per-section-box">
                    <div className="first">
                      {t("SERVICE_CHARGE")}
                    </div>
                    <div className="second">
                      <div className="reminder font-15 text-white font-semi">
                        {t("SERVICE_CHARGE_DISCLAIM") +
                          serviceCharge +
                          "%" +
                          t("SERVICE_CHARGE_DISCLAIM_BACK")}
                      </div>
                    </div>
                  </div>

                </>
              )}
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("AMOUNT")}
                </div>
                <div className="second ">
                  <div className="form-group m-b-15 px-0">
                    <div className="deposit-input-group mb-3">
                      <span className="currency-prefix">THB</span>
                      {<input
                        type="text"
                        className="form-white-input"
                        placeholder={t("PLEASE_ENTER_AMOUNT")}
                        value={numberWithCommas(amountValue)}
                        onChange={(event) => {
                          let tempValue = event.target.value.replace(/[^0-9]/g, '');
                          setAmountValue(tempValue);
                          checkAmountForErrors(tempValue);
                        }}
                        onPaste={(event) => {
                          if (!stringIsNullOrEmpty(window.event.clipboardData.getData('text')) && (/^[1-9]{1,}[0-9]{0,}$/).test(window.event.clipboardData.getData('text'))) {
                            setAmountValue(parseInt(window.event.clipboardData.getData('text')));
                            event.preventDefault();
                          } else {
                            setAmountValue('');
                            event.preventDefault();
                          }
                        }}
                      />}
                      <div className="clear-amount">
                        <Icon
                          path={mdiClose}
                          size={1}
                          color="#FF0808"
                          onClick={() => {
                            setAmountValue("");
                          }}
                        />
                      </div>
                    </div>
                    {errors.amount && (
                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                    )}
                    <div className="font-11 color-white">
                      {parseFloat(amountValue) >= min ?
                        <>
                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                          <span>{numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                          <i className="fas fa-arrow-right" />
                          <span>{" " + numberWithCurrencyFormat2(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue) : 0), 2, true)}</span>
                        </>
                        :
                        <>
                        </>
                      }
                    </div>
                    <div className="instant-provider-reminder">
                      {min >= 0 && max >= 0 && (

                        <div className="font-12 amount-limit-reminder">
                          <span className="reminder mr-2">
                            * {t("MIN")} : THB {numberWithCommas(min)} / {t("MAX")} : THB {numberWithCommas(max)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="amount-row">
                    {fixedAmount.map((amount, index) => {
                      return (
                        <button
                          key={"amount-row" + index}
                          type="button"
                          onClick={() => checkValueOnSelectFixedAmt(amount)}
                          className="btn custom-button"
                        >
                          + {numberWithCommas(amount)}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 col-md-12 px-0">
                <button type="submit" className="custom-btn-style-1">
                  {t("SUBMIT")}
                </button>
              </div>
            </>
          )}
        </form> :
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status" size="sm"></Spinner>
          </div>
        }
      </div>
    </>
  );
};

export default InstantDeposit;
