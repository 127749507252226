import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import {
  filterList,
  newGames,
  bannerList,
  popularGames,
} from "../../config/game-configs";
import GameListCarousel from "components/game-list/carousel";
import GameListGrid from "components/game-list/grid";
import GameListBanner from "components/game-list/banner";
import GameMenu from "components/game-list/game-menu";
import { filter } from "lodash";
import { useRouteMatch } from "react-router-dom";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "util/Constant";
import { useSelector } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showResponseMessage } from "../../redux/AppAction.js";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author: -
/// </summary>
const GameProvider = () => {
  const { params } = useRouteMatch(WebUrl._URL_GAME_PROVIDER);
  const { title } = params;
  const { t, i18n } = useTranslation();

  const [pageMainBanner, setPageMainBanner] = useState([]);
  const [pageSliderBannerList, setPageSliderBannerList] = useState([]);

  const [newGames, setNewGames] = useState({});
  const [newGamesWithoutFilter, setNewGamesWithoutFilter] = useState({});
  const [recommendedGamesWithoutFilter, setRecommendedGamesWithoutFilter] =
    useState({});

  const [recommendedGames, setRecommendedGames] = useState({});
  const [newAndRecommendedGamesId, setNewAndRecommendedGamesId] = useState({});
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  var _dispatch = useDispatch();
  const location = useLocation();

  // ====
  const [isFilterNewDropdownVisible, setIsFilterNewDropdownVisible] =
    useState(false);
  const [isFilterPopularDropdownVisible, setIsFilterPopularDropdownVisible] =
    useState(false);

  const carouselGameList = useSelector((state) =>
    filter(state.gameState.gameProviders, (item) => {
      return item.categoryTitle.toLowerCase() === title;
    })
  );

  const filterByProviderList = carouselGameList.filter(function (item) {
    return !item.isApp && item.hasOwnLobby;
  });

  const { isLoggedIn } = useSelector((state) => state.authState);
  const _userData = useSelector((state) => state.authState.userData);
  const languageId = useSelector((state) => state.languageState.languageId);

  useEffect(() => {
    window.scrollTo(0, 0);

    const newAndRecommendedGamesIdArr = [];

    filterByProviderList.map(function (item) {
      newAndRecommendedGamesIdArr.push(item.id);
    });
    setNewAndRecommendedGamesId(newAndRecommendedGamesIdArr);
    init(newAndRecommendedGamesIdArr);
  }, [location]);

  async function init(newAndRecommendedGamesIdArr) {
    //get provider games
    let apiUrl =
      ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
      "?id=" +
      newAndRecommendedGamesIdArr +
      "&languageId=" +
      languageId +
      "&Count=" +
      14;
    // if (isLoggedIn) {
    //   apiUrl += "&memberId=" + _userData.userId;
    // }

    var responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];

      if (newAndRecommendedGamesIdArr.length > 0) {
        setNewGames(newGamesData);
        setNewGamesWithoutFilter(newGamesData);
      } else {
        setNewGames([]);
        setNewGamesWithoutFilter([]);
      }

      let recommendedGamesData =
        responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
      if (newAndRecommendedGamesIdArr.length > 0) {
        setRecommendedGames(recommendedGamesData);
        setRecommendedGamesWithoutFilter(recommendedGamesData);
      } else {
        setRecommendedGames([]);
        setRecommendedGamesWithoutFilter([]);
      }
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&languageCode=" + 
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en') + "&page=/GameProvider/slots" 
      // + window.location.pathname;
    responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      if (data.filter(x => x.displayDevice === 0).length > 0 && data.filter(x => x.displayDevice === 1).length > 0) { //got 2 places to display banner
        //let tempSliderBannerList = [];
        //data.filter(x => x.displayDevice === 1).map((banner) => {
        //  tempSliderBannerList.push({ image: banner.desktopImage });
        //});
        //setPageSliderBannerList(tempSliderBannerList);
        ////setPageMainBanner(data.filter(x => x.displayDevice === 0)[0].desktopImage);
      }
      else {
        // console.log(data);
        let tempSliderBannerList = [];
        if (data.filter(x => x.displayDevice === 1).length > 0) {
          data.filter(x => x.displayDevice === 1).map((banner) => {
            tempSliderBannerList.push({ image: banner.desktopImage });
          });
          setPageSliderBannerList(tempSliderBannerList);
        }
        else {
          tempSliderBannerList.push({ image: data.filter(x => x.displayDevice === 0)[0].desktopImage });
          setPageMainBanner(tempSliderBannerList);
        }
      }
    }

  }

  //pass filter value
  async function handleFilterByProvider(id, count, type) {
    let apiUrl =
      ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
      "?id=" +
      id +
      "&languageId=" +
      languageId +
      "&Count=" +
      count;
    // if (isLoggedIn) {
    //   apiUrl += "&memberId=" + _userData.userId;
    // }
    var responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      if (type === "newGames") {
        let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];
        setNewGames(newGamesData);
        if (isFilterNewDropdownVisible) {
          setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
        }
      } else if (type === "popularGames") {
        let recommendedGamesData =
          responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
        setRecommendedGames(recommendedGamesData);
        if (isFilterPopularDropdownVisible) {
          setIsFilterPopularDropdownVisible(!isFilterPopularDropdownVisible);
        }
      }
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  return (
    <>
      {displayGameMenu && (
        <>
          <section className="category-section">
            <GameMenu />
          </section>
          <section className="newgames-section section-padding pb-0 mb-5">
            <GameListCarousel
              title={t("GAME_PROVIDER")}
              gameList={carouselGameList}
            />

            {newGamesWithoutFilter && newGamesWithoutFilter.length >= 7 && (
              <GameListGrid
                title={t("NEW_GAMES")}
                isFilterDropdownVisible={isFilterNewDropdownVisible}
                dropdownFilterOnClick={() => {
                  setIsFilterNewDropdownVisible(!isFilterNewDropdownVisible);
                }}
                filterList={filterByProviderList}
                gameList={newGames}
                handleFilterByProvider={handleFilterByProvider}
                type="newGames"
                page="gameProvider"
                providerId={newAndRecommendedGamesId}
              />
            )}
          </section>
          <section className="banner-slider-section">
            <GameListBanner bannerList={pageSliderBannerList.length <= 0 ? pageMainBanner : pageSliderBannerList} categoryTitle={title} />
          </section>
          <section className="popular-section mb-3">
            {recommendedGamesWithoutFilter &&
              recommendedGamesWithoutFilter.length >= 7 && (
                <GameListGrid
                  title={t("POPULAR_GAMES")}
                  isFilterDropdownVisible={isFilterPopularDropdownVisible}
                  dropdownFilterOnClick={() => {
                    setIsFilterPopularDropdownVisible(
                      !isFilterPopularDropdownVisible
                    );
                  }}
                  filterList={filterByProviderList}
                  gameList={recommendedGames}
                  handleFilterByProvider={handleFilterByProvider}
                  type="popularGames"
                  page="gameProvider"
                  providerId={newAndRecommendedGamesId}
                />
              )}
          </section>
        </>
      )}
    </>
  );
};
export default GameProvider;
