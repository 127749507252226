import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ApiKey, ApiUrl, SessionKey, WebUrl, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import Sidebar from "components/sidebar";
import ApiEngine from "../../util/ApiEngine";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { createMultiPartFormBody, isObjectEmpty, stringIsNullOrEmpty } from "../../util/Util";
import { map } from "lodash";
import ContentHeader from "components/content/header";
import PlusMinusMaxButton from "../../components/custom/PlusMinusMaxButton";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import Input from "../../components/custom/Input";
import { AsyncPaginate } from "react-select-async-paginate";

const EditDownlineDetails = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
  localStorage.getItem(SessionKey._LANGUAGE) : 'en' ;

  const [nickname, setNickname] = useState("");
  const [initCheck, setInitCheck] = useState(false);

  const [member, setMember] = useState([]);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    "label": "All",
    "value": "-1"
  });
  const [activeTab, setActiveTab] = useState("");
  const [newDownlineData, setNewDownlineData] = useState({});

  const { handleSubmit, errors, setValue, getValues, control } = useForm();
  const [downlineCount, setDownlineCount] = useState(0);
  //const [latestGame, setLatestGame] = useState({});
  const {
    register: registerInternalTransfer,
    handleSubmit: handleSubmitInternalTransfer,
    errors: errorsInternalTransfer,
    control: controlInternalTransfer,
    setValue: setValueInternalTransfer
  } = useForm();

  const {
    register: registerNickname,
    handleSubmit: handleSubmitNickname,
    errors: errorsNickname,
    setValue: setValueNickname,
    watch: watchNickname,
    control: controlNickname,
    trigger: triggerNickname,
  } = useForm();

  useEffect(() => {
    if (_location["state"]) {
      init();
    } else {
      _history.goBack();
    }
  }, [_location["state"]]);

  const breadcrumb = [
    {
      value: 'DOWNLINE',
      active: false,
      link: WebUrl._URL_DOWNLINE
    },
    {
      value: 'EDIT_DOWNLINE_DETAILS',
      active: true,
      link: WebUrl._URL_EDIT_DOWNLINE
    },
  ];

  const EditDownlineForm = () => {
    if (initCheck) {
      return (
        <div id="edit-downline" className="gold-scroll">
          {products.length > 0 ? (

            <>
              

              {categories.filter((x) => selectedCategory.label == "All" ? x.originalTitle != "All" : x.originalTitle == selectedCategory.label).map((category, index) => {
                if (
                  products.filter(
                    (x) =>
                      x.categoryName === category.originalTitle &&
                      (x.maxBonus > 0 ||
                        x.maxCommission > 0 ||
                        x.maxClubFee > 0)
                  ).length > 0
                ) {
                  return (
                    <table
                      key={"categories_" + index}
                      className="edit-downline-table"
                    >
                      <thead></thead>
                      <tbody>
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxBonus > 0
                        ).length > 0 && (
                          <>
                            {!products.filter(
                              (x) =>
                                x.categoryName === category.originalTitle &&
                                x.maxBonus > 0
                            )[0] && (
                              <tr className="border-bot-black">
                                <td colSpan="4">&nbsp;</td>
                              </tr>
                              )}
                            <tr className={category.originalTitle == "Lottery" ? "data-row category head-category category-lottery-first" : "data-row category head-category" }>
                              <td className="row-data-1 font-16 text-white font-semi">
                                <span>{t(category.originalTitle)}</span>
                              </td>
                              <td className="row-data-2">
                                <div className="font-12 color-a4">
                                  {t("BONUS")}
                                </div>
                                <div className="font-12 color-a4">
                                  {t("MAX") +
                                    " : " +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxBonus.toFixed(2) +
                                    "%"}
                                </div>
                              </td>

                              <td className="row-data-3">
                                <Controller
                                  control={control}
                                  name={"category-bonus-" + category.id}
                                  defaultValue={0}
                                  render={({ onChange, value }) => {
                                    let valueNotLowest =
                                      checkValueForLowest(value);
                                    let valueNotHighest = checkValueForHighest(
                                      value,
                                      category,
                                      "bonus"
                                    );
                                    return (
                                      <PlusMinusMaxButton
                                        buttonSection="plusminus"
                                        styleValueMinus={valueNotLowest}
                                        onClickMinus={(e) => {
                                          if (valueNotLowest === true) {
                                            value -= 0.1;
                                            value *= 100;
                                            value = Math.round(value);
                                            value /= 100;
                                            setValue(
                                              "category-bonus-" + category.id,
                                              value
                                            );
                                            handleCatBtn(
                                              "bonus",
                                              category.originalTitle,
                                              value
                                            );
                                          }
                                        }}
                                        styleValuePlus={valueNotHighest}
                                        onClickPlus={(e) => {
                                          if (valueNotHighest === true) {
                                            value += 0.1;
                                            value *= 100;
                                            value = Math.round(value);
                                            value /= 100;
                                            setValue(
                                              "category-bonus-" + category.id,
                                              value
                                            );
                                            handleCatBtn(
                                              "bonus",
                                              category.originalTitle,
                                              value
                                            );
                                          }
                                        }}
                                        optionValue={valueList[
                                          category.originalTitle
                                        ].bonusValues.map((value, index) => {
                                          return (
                                            <option
                                              key={"bonus_values" + index}
                                              className="bonusComm-option-value"
                                              value={parseFloat(
                                                value.toFixed(2)
                                              )}
                                            >
                                              {value.toFixed(2)}
                                            </option>
                                          );
                                        })}
                                        value={value}
                                        category={category}
                                        handleSelectCatValue={
                                          handleSelectCatValue
                                        }
                                        errors={errors}
                                        type="bonus" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                      />
                                    );
                                  }}
                                />
                              </td>

                              <PlusMinusMaxButton
                                buttonSection="max"
                                text={t("MAX")}
                                styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                category={category}
                                onClickMax={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "bonus"
                                  )
                                }
                              />
                            </tr>
                          </>
                        )}
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxBonus > 0
                        ).length <= 0 && (
                          <tr className="border-bot-black">
                            <td colSpan="4">&nbsp;</td>
                          </tr>
                        )}
                        {products.filter(
                          (x) =>
                            x.categoryName === category.originalTitle &&
                            x.maxCommission > 0
                        ).length > 0 && (
                          <tr
                            className={category.originalTitle == "Lottery" ? "data-row category head-category category-lottery-second" : "data-row category head-category"}
                            style={{
                              marginTop:
                                products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxBonus > 0
                                ).length > 0
                                  ? "0"
                                  : "25px",
                            }}
                          >
                            <td className="row-data-1 font-16 text-white font-semi">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxBonus > 0
                              ).length > 0 ? (
                                ""
                              ) : (
                                <b>{t(category.originalTitle)}</b>
                              )}
                            </td>
                            <td className="row-data-2">
                              <div className="font-12 color-a4">
                                {t("COMMISSION_SHORT")}
                              </div>
                              <div className="font-12 color-a4">
                                {t("MAX") +
                                  " : " +
                                  valueList[
                                    category.originalTitle
                                  ].highestMaxComm.toFixed(2) +
                                  "%"}
                              </div>
                            </td>
                            <td className="row-data-3">
                              {products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxCommission > 0
                              ).length > 0 && (
                                <>
                                  <Controller
                                    control={control}
                                    name={"category-commission-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "commission"
                                        );
                                      return (
                                        <>
                                          <PlusMinusMaxButton
                                            buttonSection="plusminus"
                                            styleValueMinus={valueNotLowest}
                                            onClickMinus={(e) => {
                                              if (valueNotLowest === true) {
                                                value -= 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            styleValuePlus={valueNotHighest}
                                            onClickPlus={(e) => {
                                              if (valueNotHighest === true) {
                                                value += 0.1;
                                                value *= 100;
                                                value = Math.round(value);
                                                value /= 100;
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            optionValue={valueList[
                                              category.originalTitle
                                            ].commValues.map((value, index) => {
                                              return (
                                                <option
                                                  key={"comm_values" + index}
                                                  className="bonusComm-option-value"
                                                  value={parseFloat(
                                                    value.toFixed(2)
                                                  )}
                                                >
                                                  {value.toFixed(2)}
                                                </option>
                                              );
                                            })}
                                            value={value}
                                            category={category}
                                            handleSelectCatValue={
                                              handleSelectCatValue
                                            }
                                            errors={errors}
                                            type="commission" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                          />
                                        </>
                                      );
                                    }}
                                  />
                                </>
                              )}
                            </td>

                            <PlusMinusMaxButton
                              buttonSection="max"
                              text={t("MAX")}
                              styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                              category={category}
                              onClickMax={() =>
                                handleMaxCatBtn(
                                  category.id,
                                  category.originalTitle,
                                  "commission"
                                )
                              }
                            />
                          </tr>
                        )}
                        {category.originalTitle === "Poker" &&
                          products.filter(
                            (x) =>
                              x.categoryName === category.originalTitle &&
                              x.maxClubFee > 0
                          ).length > 0 && (
                            <tr
                              className="data-row category head-category"
                              style={{
                                marginTop:
                                  products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxCommission > 0
                                  ).length > 0
                                    ? "0"
                                    : "25px",
                              }}
                            >
                              <td className="row-data-1 font-16 text-white font-semi">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxCommission > 0
                                ).length > 0 ? (
                                  ""
                                ) : (
                                  <b>{t(category.originalTitle)}</b>
                                )}
                              </td>
                              <td className="row-data-2">
                                <div className="font-12 color-a4">
                                  {t("CLUB_FEE")}
                                </div>
                                <div className="font-12 color-a4">
                                  {t("MAX") +
                                    " : " +
                                    valueList[
                                      category.originalTitle
                                    ].highestMaxClubFee.toFixed(2) +
                                    "%"}
                                </div>
                              </td>
                              <td className="row-data-3">
                                {products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxClubFee > 0
                                ).length > 0 && (
                                  <>
                                    <Controller
                                      control={control}
                                      name={"category-clubFee-" + category.id}
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        let valueNotLowest =
                                          checkValueForLowest(value);
                                        let valueNotHighest =
                                          checkValueForHighest(
                                            value,
                                            category,
                                            "clubFee"
                                          );
                                        return (
                                          <>
                                            <PlusMinusMaxButton
                                              styleValueMinus={valueNotLowest}
                                              onClickMinus={(e) => {
                                                if (valueNotLowest === true) {
                                                  value -= 5.0;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                              styleValuePlus={valueNotHighest}
                                              onClickPlus={(e) => {
                                                if (valueNotHighest === true) {
                                                  value += 5.0;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                              buttonSection="plusminus"
                                              optionValue={valueList[
                                                category.originalTitle
                                              ].clubFeeValues.map(
                                                (value, index) => {
                                                  return (
                                                    <option
                                                      key={
                                                        "club_fee_values" +
                                                        index
                                                      }
                                                      className="bonusComm-option-value"
                                                      value={parseFloat(
                                                        value.toFixed(2)
                                                      )}
                                                    >
                                                      {value.toFixed(2)}
                                                    </option>
                                                  );
                                                }
                                              )}
                                              value={value}
                                              category={category}
                                              handleSelectCatValue={
                                                handleSelectCatValue
                                              }
                                              errors={errors}
                                              type="clubFee" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                            />
                                          </>
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </td>

                              <PlusMinusMaxButton
                                buttonSection="max"
                                text={t("MAX")}
                                styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}   
                                category={category}
                                onClickMax={() =>
                                  handleMaxCatBtn(
                                    category.id,
                                    category.originalTitle,
                                    "clubFee"
                                  )
                                }
                              />
                            </tr>
                          )}

                        {products.filter(
                          (x) => x.categoryName === category.originalTitle
                        ).length > 0 &&
                          products
                            .filter(
                              (product) =>
                                product.categoryName ===
                                  category.originalTitle &&
                                (product.maxBonus > 0 ||
                                  product.maxCommission > 0 ||
                                  product.maxClubFee > 0)
                            )
                            .map((product, i) => {
                              return (
                                <React.Fragment key={"product_item_" + i}>
                                  {product.maxBonus > 0 && (
                                    <tr
                                      key={"products_" + i}
                                      className="data-row category"
                                    >
                                      <td className="row-data-1 font-16 text-white font-semi">
                                        <div className="row-data-title">
                                          {product.productName}
                                        </div>
                                      </td>
                                      <td className="row-data-2">
                                        <div className="font-12 color-a4">
                                          {t("BONUS")}
                                        </div>
                                        <div className="font-12 color-a4">
                                          {t("MAX") +
                                            " : " +
                                            product.maxBonus.toFixed(2) +
                                            "%"}
                                        </div>
                                      </td>
                                      <td className="row-data-3">
                                        <Controller
                                          control={control}
                                          name={"bonus-" + product.productId}
                                          defaultValue={0}
                                          render={({ onChange, value }) => {
                                            return (
                                              <>
                                                <PlusMinusMaxButton
                                                  category={category}
                                                  styleValueMinus={
                                                    product.maxBonus > 0 &&
                                                    parseFloat(
                                                      value.toFixed(2)
                                                    ) > 0
                                                  }
                                                  onClickMinus={(e) => {
                                                    if (
                                                      product.maxBonus > 0 &&
                                                      parseFloat(value) > 0
                                                    ) {
                                                      value -= 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                  styleValuePlus={
                                                    product.maxBonus > 0 &&
                                                    parseFloat(
                                                      value.toFixed(2)
                                                    ) < product.maxBonus
                                                  }
                                                  onClickPlus={(e) => {
                                                    if (
                                                      product.maxBonus > 0 &&
                                                      parseFloat(value) <
                                                        product.maxBonus
                                                    ) {
                                                      value += 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                  buttonSection="plusminus"
                                                  handleSelectCatValue={
                                                    handleSelectCatValue
                                                  }
                                                  optionValue={valueList[
                                                    category.originalTitle
                                                  ][
                                                    product.productName
                                                  ].bonusValues.map(
                                                    (value, index) => {
                                                      return (
                                                        <option
                                                          key={
                                                            "bonus_values_" +
                                                            index
                                                          }
                                                          className="bonusComm-option-value"
                                                          value={parseFloat(
                                                            value.toFixed(2)
                                                          )}
                                                        >
                                                          {value.toFixed(2)}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                  product={product}
                                                  value={value}
                                                  handleSelectValue={
                                                    handleSelectValue
                                                  }
                                                  errors={errors}
                                                  type="maxBonus" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                                />
                                              </>
                                            );
                                          }}
                                        />
                                      </td>
                                      <PlusMinusMaxButton
                                        buttonSection="max"
                                        category={category}
                                        text={t("MAX")}
                                        styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                        onClickMax={() =>
                                          handleMaxBtn(
                                            product.productId,
                                            product.maxBonus,
                                            product.maxCommission,
                                            product.maxClubFee,
                                            "bonus"
                                          )
                                        }
                                      />
                                    </tr>
                                  )}
                                  {product.maxCommission > 0 && (
                                    <tr className="data-row category">
                                      <td className="row-data-1 font-16 text-white font-semi">
                                        {product.maxBonus > 0 ? (
                                          ""
                                        ) : (
                                          <div className="row-data-title">
                                            {product.productName}
                                          </div>
                                        )}
                                      </td>
                                      <td className="row-data-2">
                                        <div className="font-12 color-a4">
                                          {t("COMMISSION_SHORT")}
                                        </div>
                                        <div className="font-12 color-a4">
                                          {t("MAX") +
                                            " : " +
                                            product.maxCommission.toFixed(2) +
                                            "%"}
                                        </div>
                                      </td>
                                      <td className="row-data-3">
                                        {product.maxCommission > 0 && (
                                          <>
                                            <Controller
                                              control={control}
                                              name={
                                                "commission-" +
                                                product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                return (
                                                  <>
                                                    <PlusMinusMaxButton
                                                      category={category}
                                                      styleValueMinus={
                                                        product.maxCommission >
                                                          0 &&
                                                        parseFloat(
                                                          value.toFixed(2)
                                                        ) > 0
                                                      }
                                                      onClickMinus={(e) => {
                                                        if (
                                                          product.maxCommission >
                                                            0 &&
                                                          parseFloat(value) > 0
                                                        ) {
                                                          value -= 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }
                                                      }}
                                                      styleValuePlus={
                                                        product.maxCommission >
                                                          0 &&
                                                        parseFloat(
                                                          value.toFixed(2)
                                                        ) <
                                                          product.maxCommission
                                                      }
                                                      onClickPlus={(e) => {
                                                        if (
                                                          product.maxCommission >
                                                            0 &&
                                                          parseFloat(value) <
                                                            product.maxCommission
                                                        ) {
                                                          value += 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }
                                                      }}
                                                      buttonSection="plusminus"
                                                      handleSelectCatValue={
                                                        handleSelectCatValue
                                                      }
                                                      optionValue={valueList[
                                                        category.originalTitle
                                                      ][
                                                        product.productName
                                                      ].commValues.map(
                                                        (value, index) => {
                                                          return (
                                                            <option
                                                              key={
                                                                "comm_values_" +
                                                                index
                                                              }
                                                              className="bonusComm-option-value"
                                                              value={parseFloat(
                                                                value.toFixed(2)
                                                              )}
                                                            >
                                                              {value.toFixed(2)}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                      product={product}
                                                      value={value}
                                                      handleSelectValue={
                                                        handleSelectValue
                                                      }
                                                      errors={errors}
                                                      type="maxCommission" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                                    />
                                                  </>
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                      </td>
                                      <PlusMinusMaxButton
                                        buttonSection="max"
                                        category={category}
                                        text={t("MAX")}
                                        styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                                        onClickMax={() =>
                                          handleMaxBtn(
                                            product.productId,
                                            product.maxBonus,
                                            product.maxCommission,
                                            product.maxClubFee,
                                            "commission"
                                          )
                                        }
                                      />
                                    </tr>
                                  )}
                                  {product.productName === "D2 Poker" &&
                                    product.maxClubFee > 0 && (
                                      <tr className="data-row category">
                                        <td className="row-data-1 font-16 text-white font-semi">
                                          {product.maxCommission > 0 ? (
                                            ""
                                          ) : (
                                            <div className="row-data-title">
                                              {product.productName}
                                            </div>
                                          )}
                                        </td>
                                        <td className="row-data-2">
                                          <div className="font-12 color-a4">
                                            {t("CLUB_FEE")}
                                          </div>
                                          <div className="font-12 color-a4">
                                            {t("MAX") +
                                              " : " +
                                              product.maxClubFee.toFixed(2) +
                                              "%"}
                                          </div>
                                        </td>
                                        <td className="row-data-3">
                                          {product.maxClubFee > 0 && (
                                            <>
                                              <Controller
                                                control={control}
                                                name={
                                                  "clubFee-" + product.productId
                                                }
                                                defaultValue={0}
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => {
                                                  return (
                                                    <>
                                                      <PlusMinusMaxButton
                                                        category={category}
                                                        styleValueMinus={
                                                          product.maxClubFee >
                                                            0 &&
                                                          parseFloat(
                                                            value.toFixed(2)
                                                          ) > 0
                                                        }
                                                        onClickMinus={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 5.0;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }
                                                        }}
                                                        styleValuePlus={
                                                          product.maxClubFee >
                                                            0 &&
                                                          parseFloat(
                                                            value.toFixed(2)
                                                          ) < product.maxClubFee
                                                        }
                                                        onClickPlus={(e) => {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) <
                                                              product.maxClubFee
                                                          ) {
                                                            value += 5.0;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }
                                                        }}
                                                        buttonSection="plusminus"
                                                        handleSelectCatValue={
                                                          handleSelectCatValue
                                                        }
                                                        optionValue={valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].clubFeeValues.map(
                                                          (value, index) => {
                                                            return (
                                                              <option
                                                                key={
                                                                  "club_fee_values_" +
                                                                  index
                                                                }
                                                                className="bonusComm-option-value"
                                                                value={parseFloat(
                                                                  value.toFixed(
                                                                    2
                                                                  )
                                                                )}
                                                              >
                                                                {value.toFixed(
                                                                  2
                                                                )}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                        product={product}
                                                        value={value}
                                                        handleSelectValue={
                                                          handleSelectValue
                                                        }
                                                        errors={errors}
                                                        type="maxClubFee" // type for bonus/commission/clubfee/maxBonus/maxCommission/maxClubfee
                                                      />
                                                    </>
                                                  );
                                                }}
                                              />
                                            </>
                                          )}
                                              </td>
                                        <PlusMinusMaxButton
                                          buttonSection="max"
                                          category={category}
                                          styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}   
                                          text={t("MAX")}
                                          onClickMax={() =>
                                            handleMaxBtn(
                                              product.productId,
                                              product.maxBonus,
                                              product.maxCommission,
                                              product.maxClubFee,
                                              "clubFee"
                                            )
                                          }
                                        />
                                      </tr>
                                    )}
                                </React.Fragment>
                              );
                            })}
                      </tbody>
                    </table>
                  );
                }
              })}
            </>
          ) : (
            <div className="card">
              <div className="card-body text-center text-white">
                {t("NO_ASSIGNED_PRODUCT")}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  async function eventActiveTab() {
    init();
  } 

  async function init() {
    _dispatch(setBusy());
    try {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_MEMBER_UPLINE_PRODUCT_RATES +
          "?memberId=" +
          _location["state"]["id"]
      );
      let uplineProductRates = [];
      let processedProducts = [];
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }

      let productResponseJson = await ApiEngine.get(
        ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT +
          "?memberId=" +
          _location["state"]["id"]
      );
      if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
        setMember(productResponseJson[ApiKey._API_DATA_KEY].member);
        setNickname(productResponseJson[ApiKey._API_DATA_KEY].member.nickname);
        productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
          let uplineProduct = uplineProductRates.filter(
            (i) => i.accountProductId == product.accountProductId
          )[0];
          processedProducts.push({
            id: product.id,
            productId: product.productId,
            accountProductId: product.accountProductId,
            productName:
              product.productName + (product.useGroupRate ? " *" : ""),
            assigned: product.assigned,
            bonus: product.bonus,
            commission: product.commission,
            clubFee: product.clubFee,
            categoryName: product.categoryName,
            maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
            maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
            maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
            useGroupRate: product.useGroupRate,
          });
        });
      } else {
        throw productResponseJson[ApiKey._API_MESSAGE_KEY];
      }

      setProduct(
        products["length"] == 0
          ? processedProducts[0]
          : processedProducts.find((x) => x["id"] == product["id"])
      );
      setProducts(processedProducts);

      let categoryJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
          "?selectedLanguage=" +
          selectedLanguage
      );
      if (categoryJson[ApiKey._API_SUCCESS_KEY]) {
        let categoryData = categoryJson[ApiKey._API_DATA_KEY];
        setCategories(categoryData);
        let tempObj = {};
        let tempHighestMaxBonus = 0.0;
        let tempHighestMaxComm = 0.0;
        let tempHighestMaxClubFee = 0.0;
        categoryData.map((category, index) => {
          if (!tempObj[category.originalTitle]) {
            tempObj[category.originalTitle] = {
              bonusValues: [],
              commValues: [],
              clubFeeValues: [],
              highestMaxBonus: 0,
              highestMaxComm: 0,
              highestMaxClubFee: 0,
            };
          }
          processedProducts
            .filter((x) => x.categoryName === category.originalTitle)
            .map((product, i) => {
              if (i === 0) {
                tempHighestMaxBonus = product.maxBonus;
                tempHighestMaxComm = product.maxCommission;
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (!tempObj[category.originalTitle][product.productName])
                tempObj[category.originalTitle][product.productName] = {
                  bonusValues: [],
                  commValues: [],
                  clubFeeValues: [],
                };
              if (product.maxBonus > tempHighestMaxBonus) {
                tempHighestMaxBonus = product.maxBonus;
              }
              if (product.maxCommission > tempHighestMaxComm) {
                tempHighestMaxComm = product.maxCommission;
              }
              if (product.maxClubFee > tempHighestMaxClubFee) {
                tempHighestMaxClubFee = product.maxClubFee;
              }
              if (product.maxBonus > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxBonus.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].bonusValues.push(i);
                }
              }
              if (product.maxCommission > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxCommission.toFixed(2));
                  i += 0.1
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].commValues.push(i);
                }
              }
              if (product.maxClubFee > 0) {
                for (
                  var i = 0.0;
                  parseFloat(i.toFixed(2)) <=
                  parseFloat(product.maxClubFee.toFixed(2));
                  i += 5.0
                ) {
                  i = i * 100;
                  i = Math.round(i);
                  i = i / 100;
                  i = parseFloat(i.toFixed(2));

                  tempObj[category.originalTitle][
                    product.productName
                  ].clubFeeValues.push(i);
                }
              }
            });

          tempObj[category.originalTitle]["highestMaxBonus"] =
            tempHighestMaxBonus;
          tempObj[category.originalTitle]["highestMaxComm"] =
            tempHighestMaxComm;
          tempObj[category.originalTitle]["highestMaxClubFee"] =
            tempHighestMaxClubFee;

          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxBonus.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].bonusValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxComm.toFixed(2));
            i += 0.1
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].commValues.push(i);
          }
          for (
            var i = 0.0;
            parseFloat(i.toFixed(2)) <=
            parseFloat(tempHighestMaxClubFee.toFixed(2));
            i += 5.0
          ) {
            i = i * 100;
            i = Math.round(i);
            i = i / 100;
            i = parseFloat(i.toFixed(2));

            tempObj[category.originalTitle].clubFeeValues.push(i);
          }
        });
        setValueList(tempObj);
      }
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
      _history.goBack();
    } finally {
      _dispatch(setIdle());
      setInitCheck(true);
    }
  }

  useEffect(() => {
    products.map((product, index) => {
      setValue("bonus-" + product.productId, product.bonus);
      setValue("commission-" + product.productId, product.commission);
      setValue("clubFee-" + product.productId, product.clubFee);
    });
  }, [initCheck]);

  async function updateNickname(data) {
    if (!stringIsNullOrEmpty(nickname)) {
      _dispatch(setBusy());
      var responseJson = await ApiEngine.post(
        ApiUrl._API_UPDATE_MEMBER_NICKNAME +
          "?memberId=" +
          _location["state"]["id"] +
          "&nickname=" +
          nickname
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
      _dispatch(setIdle());
    }
  }

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let params = {
      id: _location["state"]["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createMultiPartFormBody(params);
    //let tempformBody = createMultiPartFormBody({
    //    accountProductId: product["accountProductId"],
    //    commission: product['commission'],
    //    bonus: product['bonus']
    //});
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_RATE,
      formBody
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      init();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    _dispatch(setIdle());
  }

  function checkValueForLowest(value) {
    if (value === 0) {
      return false;
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (bonusOrComm === "bonus") {
      if (value === valueList[category.originalTitle].highestMaxBonus) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "commission") {
      if (value === valueList[category.originalTitle].highestMaxComm) {
        return false;
      } else {
        return true;
      }
    } else if (bonusOrComm === "clubFee") {
      if (value === valueList[category.originalTitle].highestMaxClubFee) {
        return false;
      } else {
        return true;
      }
    }
  }

  function handleCatBtn(bonusOrComm, categoryTitle, value) {
    value *= 100;
    value = Math.round(value);
    value /= 100;
    if (bonusOrComm === "bonus") {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product["maxBonus"]) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, value);
          }
        });
    } else if (bonusOrComm === "commission") {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product["maxCommission"]) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, value);
          }
        });
    } else if (bonusOrComm === "clubFee") {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product["maxClubFee"]) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, value);
          }
        });
    }
  }

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {
        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {
    if (bonusOrComm === "bonus") {
      setValue("bonus-" + productId, maxBonus);
    } else if (bonusOrComm === "commission") {
      setValue("commission-" + productId, maxComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("clubFee-" + productId, maxClubFee);
    }
  }

  function handleSelectValue(bonusOrComm, productId, value) {
    value *= 100;
    value = Math.round(value);
    value /= 100;

    //let tempValue = parseFloat(value);
    setValue("" + bonusOrComm + "-" + productId, value);
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    value *= 100;
    value = Math.round(value);
    value /= 100;
    //let tempValue = parseFloat(value);
    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, value);
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product.maxBonus) {
            setValue("bonus-" + product.productId, product.maxBonus);
          } else {
            setValue("bonus-" + product.productId, value);
          }
        });
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, value);
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product.maxCommission) {
            setValue("commission-" + product.productId, product.maxCommission);
          } else {
            setValue("commission-" + product.productId, value);
          }
        });
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, value);
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          if (value > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product.maxClubFee);
          } else {
            setValue("clubFee-" + product.productId, value);
          }
        });
    }
  }

  async function getCategoryList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_PRODUCT_CATEGORY_SEARCH}?start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY].data;
      formattedData = data.map((m) => { return { label: m.title, value: m.id } });
      var formattedData;
      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1
        }
      };
    }

  }

  const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
      //padding: '0px',
      //background: 'rgba(0, 0, 32)',
      //color: 'white',
      position: 'absolute !important',
      zIndex: 2,
      background: 'rgba(16, 33, 86, 1) !important',
      fontSize: '12px !important'
    }),
    control: (styles, state) => ({
      ...styles,
      //background: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
      //height: '45px',
      //fontSize: '14px',
      borderRadius: '50px',
      boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
      height: '35px',
      background: 'transparent',
      border: '0px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        //background: 'rgba(0, 0, 32)',
        //color: 'white',
        //boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.3)'
      };
    },
    singleValue: styles => ({
      ...styles,
      //paddingLeft: '1rem',
      //fontSize: '14px',
      //fontWeight: 'bold',
      //color: 'white'
      paddingLeft: '1.2rem',
      fontSize: '12px',
      color: '#3369ff',
      fontWeight: 'bold'
    }),
    placeholder: styles => ({
      ...styles,
      paddingLeft: '1rem',
      fontSize: '14px',
      color: '#FFF'
    }),
    input: styles => ({
      ...styles,
      margin: '2px',
      color: '#fff',
      paddingLeft: '10px'
    })
  }

  useEffect(() => {
    getDownlineGroups();
    //getLatestGame();
    //getTodayDownlineMember();
  }, []);

  const getDownlineGroups = async () => {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_DOWNLINE_GROUPS);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let downlineCount = data["data"].length;
      setDownlineCount(downlineCount);

    }
  };

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  //async function getTodayDownlineMember() {
  //  _dispatch(setBusy());
  //  var responseJson = await ApiEngine.get(
  //    ApiUrl._API_GET_TODAY_NEW_DOWNLINES_MEMBER
  //  );
  //  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
  //    setNewDownlineData(responseJson[ApiKey._API_DATA_KEY]);
  //  }
  //  _dispatch(setIdle());
  //}

  return (
    <>
      <section className="section-padding" id="downline-section">
        <Breadcrumb data={breadcrumb}/>
        <div className="with-information-container">
          <div className="left">
            <h3 className="mb-4">{t("EDIT_DOWNLINE_DETAILS")}</h3>
            <h2 class="title-with-blue-under mb-4">{t("USERNAME")} : {member["username"]}</h2>
            <form onSubmit={handleSubmitNickname(updateNickname) }>
            <div className="">
              <label className="nickname font-12 color-a4 mb-4">
                {t("NICKNAME")}
              </label>
            </div>
            <div id="downline-group-section">
              <div className="template-box mb-4">
                <div className="first">
                  <input
                    className="custom-input-style-1 rounded"
                    name="nickname"
                    placeholder={
                      t("PLEASE_ENTER_NICKNAME") +
                      " (" +
                      t("OPTIONAL") +
                      ")"
                    }
                    value={nickname}
                    onChange={(e) => {
                      setNickname(e.target.value);
                    }}
                  />
                  {errorsNickname.nickname && (
                    <div className="invalid-feedback">
                      {t(errors.nickname.message)}
                    </div>
                  )}
                </div>

                <div className="second">
                  <button
                    type="submit"
                    className="claim-btn"
                    disabled={stringIsNullOrEmpty(nickname)}
                  >
                    {t("SAVE") + " " + t("NICKNAME")}
                  </button>
                </div>
              </div>
            </div>
            </form>
            <div id="downline-search-box-main" className="mt-4 border">
              <Controller
                className="product-category-dropdown"
                control={controlInternalTransfer}
                errors={errorsInternalTransfer}
                name="category"
                render={({ onChange, value }) => {
                  return (
                    <AsyncPaginate
                      styles={_SELECT_STYLES}
                      placeholder={t("SEARCH_BY_CATEGORY")}
                      debounceTimeout={250}
                      loadOptions={getCategoryList}
                      additional={{
                        page: 1,
                      }}
                      value={selectedCategory}
                      onChange={(e) => { eventActiveTab(); setActiveTab(e.value); setSelectedCategory(e); onChange(e.value);}}
                      noOptionsMessage={() => t("NO_OPTIONS")}
                      loadingMessage={() => t("LOADING...")}
                    />
                  )
                }}
                rules={{ required: "SEARCH_BY_CATEGORY" }} />
            </div>
            <div className="p-note mt-2 ml-3">
              {t("CUSTOMIZE_OWN_COMMISSIONS")}
            </div>
            <form id="downlinetableform">
              {initCheck && (
                <>
                  {EditDownlineForm()}
                  <div className="edit-downline-submitbtn">
                    <button
                      type="button"
                      className="custom-btn-style-1"
                      disabled={products.length <= 0}
                      onClick={() => handleSubmit(onSubmit)()}
                    >
                      {t("SUBMIT")}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
          <div className="right">
            <RightInformation
              // info={true}
              // infoTitle="Wallet Info"
              // infoData={walletInformation}
              // recent={true}
              recentTitle="Recent Transaction"
              // recentData={recentTransaction}
              newDownlines={true}
              //newDownlinesData={newDownlineData}
              //newDownlinesTitle="NEW_DOWNLINES_MEMBER"
              buttonTwo={true}
              buttonTwoQuantity={downlineCount}
              buttonTwoText="DOWNLINE_GROUPS"
              games={true}
              //gamesTitle="LATEST_GAME"
              //gamesData={latestGame}
              promotion={true}
            />
          </div>
        </div>



        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"DOWNLINE"} />
            </div>
            <div className="col-9">
              <ContentHeader
                enableBackButton
                title={t("EDIT_DOWNLINE_DETAILS")}
              />
              <hr className="right-title-hr" />
              <div className="template-box" id="downline-edit">
                <div className="downline-edit-container">
                  <div id="downline-details" className="">
                    <form
                      id="downlineform"
                      className="mb-0"
                      onSubmit={handleSubmitNickname(updateNickname)}
                    >
                      <div>
                        <div className="form-group mb-0">
                          <label className="username font-15 text-white font-semi">
                            {t("USERNAME")} : {member["username"]}
                          </label>
                        </div>
                        <div className="form-group mb-0">
                          <label className="nickname font-16 text-white font-semi">
                            {t("NICKNAME")}
                          </label>
                        </div>
                        <div id="downline-group-section">
                          <div className="template-box mb-4">
                            <div className="first">
                              <input
                                className="custom-input-style-1 rounded"
                                name="nickname"
                                placeholder={
                                  t("PLEASE_ENTER_NICKNAME") +
                                  " (" +
                                  t("OPTIONAL") +
                                  ")"
                                }
                                value={nickname}
                                onChange={(e) => {
                                  setNickname(e.target.value);
                                }}
                              />
                              {errorsNickname.nickname && (
                                <div className="invalid-feedback">
                                  {t(errors.nickname.message)}
                                </div>
                              )}
                            </div>

                            <div className="second">
                              <button
                                type="submit"
                                className="claim-btn"
                                disabled={stringIsNullOrEmpty(nickname)}
                              >
                                {t("SAVE") + " " + t("NICKNAME")}
                              </button>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </form>

                    {
                      <form id="downlinetableform">
                        {initCheck && (
                          <>
                            {EditDownlineForm()}
                            <div className="edit-downline-submitbtn">
                              <button
                                type="button"
                                className="custom-btn-style-1"
                                disabled={products.length <= 0}
                                onClick={() => handleSubmit(onSubmit)()}
                              >
                                {t("SUBMIT")}
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default EditDownlineDetails;
