import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { ApiKey, ApiUrl, WebUrl, _MAIN_WALLET_ID } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuRowItem from "components/menu-row/item";
import ContentHeader from "components/content/header";
import { useTranslation } from "react-i18next";
import RightInformation from "../../components/custom/RightInformation";
import ApiEngine from "../../util/ApiEngine.js";
import WalletStatement from "./WalletStatement"; 
import BonusReport from "./BonusReport";
import RolloverStatememnt from "./RolloverStatement";
import GameRecord from "./GameRecord";

const Report = (props) => {
  const { t } = useTranslation();
  const _history = useHistory();
  var { isLoggedIn } = useSelector((state) => state.authState);
  const [reportActive, setReportActive] = useState('WALLET_STATEMENT');
  //const [latestGame, setLatestGame] = useState({});

  useEffect(() => {
    if (!isLoggedIn) {
      _history.replace(WebUrl._URL_MAIN);
    }

    return () => {
      //window.removeEventListener("scroll");
    };
  }, []);

  const reportsMenu = [
    {
      title: 'WALLET_STATEMENT',
      icon: "/img/version3/reports/1.png",
      url: '#'
    },
    {
      title: 'BONUS_STATEMENT',
      icon: "/img/version3/reports/2.png",
      url: '#'
    },
    {
      title: 'ROLLOVER_STATEMENT',
      icon: "/img/version3/reports/3.png",
      url: '#'
    },
    {
      title: 'GAME_RECORD',
      icon: "/img/version3/reports/2.png",
      url: '#'
    },
  ];

  //useEffect(() => {
  //  getLatestGame();
  //}, []);

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  return (
    //<>
    //  <section className="section-padding" id="main-report-section">
    //    <div className="container">
    //      <div className="row">
    //        <div className="col-3" id="account-menu">
    //          <Sidebar activePage={"REPORTS"} />
    //        </div>
    //        <div className="col-9">
    //          <ContentHeader title={t("REPORTS")} />
    //          <hr className="right-title-hr" />
    //          {reportList &&
    //            reportList.length &&
    //            map(reportList, (item, index) => {
    //              return (
    //                  <MenuRowItem
    //                    key={"report_list_" + index}
    //                    item={item}
    //                    iconCustomClass="report-list-icon"
    //                  />

    //              );
    //            })}
    //        </div>
    //      </div>
    //    </div>
    //  </section>
    //</>
    <section className="section-padding reports-page" id="game-record-section">
      <ContentHeader title='Reports' />
      <div className="with-information-container">
        <div className="left">
          <div className="reports-top-menu-container">
            {reportsMenu &&
              reportsMenu.length > 0 &&
              map(reportsMenu, (item, index) => {
                return (
                  <div className={"custom-box-with-icon-container" + (reportActive == item.title ? ' active' : '')} style={{ cursor: "pointer" }} onClick={() => setReportActive(item.title)}>
                    <div className="first">
                      <div className="custom-icon-box">
                        <img src={item.icon} alt={item.title} className="img-responsive" />
                      </div>
                      <div>
                        {t(item.title)}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {reportActive &&
            reportActive == "WALLET_STATEMENT" &&
            <>
              <WalletStatement/>
            </>
          }
          {reportActive &&
            reportActive == "BONUS_STATEMENT" &&
            <>
              <BonusReport />
            </>
          }
          {reportActive &&
            reportActive == "ROLLOVER_STATEMENT" &&
            <>
              <RolloverStatememnt />
            </>
          }
          {reportActive &&
            reportActive == "GAME_RECORD" &&
            <>
              <GameRecord />
            </>
          }
        </div>
        <div className="right">
          <RightInformation
            games={true}
            //gamesTitle="LATEST_GAME"
            //gamesData={latestGame}
            promotion={true}
          />
        </div>
      </div>
    </section>
  );
};
export default Report;
