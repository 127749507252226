import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, SessionKey } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ContentHeader from "components/content/header";
import { map } from "lodash";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState();
  const [toggleModal, setToggleModal] = useState();
  const name = useRef("");
  const email = useRef("");
  const phone = useRef("");
  const message = useRef("");
  const [emailLink, setEmailLink] = useState("");
  const [functionListener, setFunctionListener] = useState("");
  const [displayGameMenu, setDisplayGameMenu] = useState(true);
  const [displayBody, setDisplayBody] = useState(true);
  const [tipsModalVisible, setTipsModalVisible] = useState(false);
  let _dispatch = useDispatch();

  useEffect(() => { 
    if (selectedChannel) {
      if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
        setToggleModal(true);
      }
    }
  }, [selectedChannel]);

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  async function init() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_COMMUNICATION_CHANNELS + "?all=true&languageCode=" + 
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
      // localStorage.getItem(SessionKey._LANGUAGE) 
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setChannelData(responseJson[ApiKey._API_DATA_KEY]);
      setDisplayBody(false);
      setTimeout(() => setDisplayBody(true), 1);
    } 
    // else {
    //   _dispatch(
    //     showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
    //   );
    // }

    _dispatch(setIdle());
  }

  useEffect(() => {
    setDisplayGameMenu(false);
    setTimeout(() => setDisplayGameMenu(true), 1);
  }, [
    // localStorage.getItem(SessionKey._LANGUAGE)
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')
  ]);

  function setChannelData(data) {
    var channels = [];
    data.map((d, index) => {
      var channel = {
        channelName: d.channelName,
        name: d.channelDescription,
        description: "",
        actionUrl: d.cta,
        image: d.channelImage,
        attachment: d.attachment,
      };

      channels.push(channel);
    });
    setChannels(channels);
  }

  useEffect(() => {
    setEmailLink(functionListener);
  }, [functionListener]);

  function setContactDetails() {
    var savedName = name.current.value;
    var savedEmail = email.current.value;
    var savedPhone = phone.current.value;
    var savedMessage = message.current.value;
    // setEmailLink("mailto:johnralph.olayvar@gmail.com?subject=i8 desktop contact form&body=Fullname:" + savedName + "Email:" + savedEmail);
    setFunctionListener(
      "mailto:johnralph.olayvar@gmail.com?subject=i8 desktop contact form&body=Fullname:" +
        savedName +
        "%0d%0aEmail:" +
        savedEmail +
        "%0d%0aPhone:" +
        savedPhone +
        "%0d%0aMessage:" +
        savedMessage
    );
  }

  const tipsOption = [
    {
      label: 'tips for test1',
      value: 'tips for test1'
    },
    {
      label: 'tips for test2',
      value: 'tips for test2'
    },
    {
      label: 'tips for test3',
      value: 'tips for test3'
    },
  ];
  const tipsAmount = [
    {
      value: '100'
    },
    {
      value: '250'
    },
    {
      value: '500'
    },
    {
      value: '750'
    },
    {
      value: '1,000'
    },
  ];

  return (
    <>
      {displayGameMenu && (
        <>
          <section id='contactus' className='contactus-section section-padding'>
            <div>
              <ContentHeader
                title={t("LIVE_CHAT")}
              // escButtonOnClick={() => window.history.go(-1)}
              />
            </div>
            
            <div className='contact-header text-center mb-5'>
              <span className='title'>
                {t("WE_ARE_HERE_TO_HELP")}
              </span>
              <br />
              <span className='details'>
                {t("SEND_US_MESSAGE")}
              </span>
            </div>

            {displayBody && <><div className='contact-body'>
              <div className='row mb-5 text-white'>
                {channels.map((channel, index) => {
                  return (
                    <div className='col-6 col help-centre-margin' key={index}>
                    <div className='d-flex justify-content-between contactus-list cursor-pointer' 
                        onClick={() => { 
                        if (channel.channelName === "Livechat" ) {
                          // window.open(channel.actionUrl, "_blank")
                          window.open(channel.actionUrl, "_blank", 'live-chat')

                        }
                        else if (!stringIsNullOrEmpty(channel.actionUrl)) {
                          window.location.href = channel.actionUrl
                        } 
                        else if (channel.channelName.match("WeChat")) {
                          setSelectedChannel(channel);
                          setToggleModal(true);
                        } else  {
                          setToggleModal(false)
                        } 
                        }}>
                        <div>
                          <div className='channel-content'>
                            <div className="first custom-icon-box">
                              <img
                                className='channel-logo'
                                src={channel.image}
                              />
                            </div>
                            <div>
                              <span className='font-14 text-white'>
                                {channel.name}
                              </span>
                              <br />
                              <span className='font-14 text-white'>
                                {channel.description}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div></>}
            {/*<div className="mt-5">*/}
            {/*  <h4 className="custom-h4 mb-4 contact-us-tips-title">Give us some tips</h4>*/}
            {/*  <div className="row">*/}
            {/*    <div className='col-6 col help-centre-margin'>*/}
            {/*      <div className='d-flex justify-content-between contactus-list cursor-pointer' onClick={() => setTipsModalVisible(true)}>*/}
            {/*        <div className='channel-content'>*/}
            {/*          <div className="first custom-icon-box">*/}
            {/*            <img*/}
            {/*              className='channel-logo'*/}
            {/*              src="/img/version3/tips.svg"*/}
            {/*              alt="tips"*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*          <div>*/}
            {/*            <span className='font-14 text-white'>*/}
            {/*              Give us some Tips */}
            {/*            </span>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*          <img*/}
            {/*            className='channel-logo'*/}
            {/*            src="/img/version3/more.png"*/}
            {/*            alt="more"*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            
          </section>

          {/* <LiveChat license={12685218} /> */}
          {/* <LiveChat license={12685218} onChatLoaded={ref => setLiveChat(ref)} /> */}
          <Modal fade={false} isOpen={toggleModal} toggle={() => { setToggleModal(false) }} centered>
            <ModalHeader toggle={() => { setToggleModal(false) }}>{selectedChannel?.name}</ModalHeader>
            <ModalBody>
                <img src={selectedChannel?.attachment} className="w-100" />
            </ModalBody>
          </Modal>
          <Modal
            id="custom-modal-small"
            fade={true}
            isOpen={tipsModalVisible}
            contentClassName="modal-brand modal-bottom"
            toggle={() => {
              setTipsModalVisible(false);
            }}
            centered
          >
              <ModalBody>
                <div className="tip-body">
                  <div className="text-center">
                    <h6 className="title mb-1">Tips Box</h6>
                    <p>Give a Tips to our friendly cs</p>
                  </div>
                  <div className="normal-select-box mb-3">
                    <select className="normal-select">
                      <option>Tips for...</option>
                      {tipsOption.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <input type="text" placeholder="0.00" className="normal-input"/>
                  </div>
                  <div className="tips-amount-container mt-3">
                    {tipsAmount &&
                      tipsAmount.length &&
                      map(tipsAmount, (item, index) => {
                        return (
                          <div key={index} className={"tips-amout-items "+ (index == 3 ? 'active' : '')}>
                            + {item.value}
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="tips-bottom-box mt-5">
                    <div>
                      Total Tips to Telegram: user 1
                    </div>
                    <div>
                      750 THB
                    </div>
                  </div>
                  <div className="custom-button-two-col mt-5">
                    <div>
                      <button className="btn custom-btn btn-red">Cancel</button>
                    </div>
                    <div>
                      <button className="btn custom-btn">Confirm</button>
                    </div>
                  </div>
                </div>
                
              </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default ContactForm;
