import React, { useState, useEffect } from "react";
import GameMenu from "components/game-list/game-menu";
import { map } from "lodash";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, SessionKey, AlertTypes } from "../../util/Constant";
import { useSelector } from "react-redux";
import {showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Rng = (props) => {
  const [gameData, setGameData] = useState({});
  const [gameId, setGameId] = useState(0);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const _dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=RNG&languageCode=" + 
    (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en'));
    // localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      // console.log(responseJson[ApiKey._API_DATA_KEY]);
      let data = responseJson[ApiKey._API_DATA_KEY][0]; //RNG only got 1 game
      setGameData(data);
    }
  }

  const startGame = () => {
    window.open(ApiUrl._API_START_GAME + "?gameId=" + gameData['id'] + "&isApp=" + (gameData['isApp'] == true ? "1" : "0") + "&device=d");
  }

  return (
    <>
      <section className='category-section'>
        <GameMenu />
      </section>
      <section id='rng-section1'>
        <MouseParallaxContainer
          className='parallax esport-banner'
          containerStyles={{
            width: "100%",
          }}
          resetOnLeave
        >
          <MouseParallaxChild className='esport-banner-img'>
            <img
              src="/img/rng/top-bg.png"
              className='img-100'
              alt='esport-main'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.1}
            factorY={0.03}
            className='rng-dice1'
          >
            <img
              src="/img/rng/dice.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.05}
            factorY={-0.09}
            className='rng-dice2'
          >
            <img
              src="/img/rng/diceblue.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>
          <MouseParallaxChild
            factorX={0.05}
            factorY={0.04}
            className='rng-chip1 opacity-6'
          >
            <img
              src="/img/rng/chip1.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.02}
            factorY={0.06}
            className='rng-robot'
          >
            <img
              src="/img/rng/robot.png"
              className='img-responsive opacity-6'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.06}
            factorY={-0.04}
            className='rng-ball'
          >
            <img
              src="/img/rng/lotteryball.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.01}
            factorY={0.09}
            className='rng-chip2'
          >
            <img
              src="/img/rng/chip2.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.02}
            factorY={-0.06}
            className='rng-robot'
          >
            <img
              src="/img/rng/robot.png"
              className='img-responsive'
              alt='rng-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild className=''>
            <div className='rng-text'>
              <img
                src="/img/rng/RNG.png"
                className='img-responsive mb-2'
                alt='rng-icon'
              />
              <br />
              <a className='rng-playnow' onClick={() => {
                  if(isLoggedIn) {
                    startGame();
                    }
                  else {
                    _dispatch(
                      showMessage({
                        type: AlertTypes._INFO,
                        content: t("PLEASE_LOGIN_FIRST"),
                        onConfirm: () => {
                          console.log("hi");
                        },
                      })
                    );
                  }
                }
                }>
                <img
                  src="/img/rng/playnow.png"
                  className={'img-responsive rng-pl-1' + (gameData['gameUm'] === true ? ' game-um' : '')}
                  alt='rng-icon'
                />
                <img
                  src="/img/rng/Hover_playnow.png"
                  className={'img-responsive rng-pl-2' + (gameData['gameUm'] === true ? ' game-um' : '')}
                  alt='rng-icon'
                />
              </a>
            </div>
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </section>
      <section id='rng-section2'>
        <img
          src="/img/providers/Backdrop_RNG.png"
          className='img-100'
          alt='rng-icon'
        />
        {/* Note: Temporary static url link until BO ready */}
        <a
          onClick={() => {
            if(isLoggedIn) {
              startGame();
            }
            else {
              _dispatch(
                showMessage({
                  type: AlertTypes._INFO,
                  content: t("PLEASE_LOGIN_FIRST"),
                  onConfirm: () => {
                    console.log("hi");
                  },
                })
              );
            }
          }
        }
          className={'rng-girl' + (gameData['gameUm'] === true ? ' game-um' : '')}
        >
          <img
            src="/img/rng/girl.png"
            className='img-responsive rng-girl-before-hover'
            alt='rng-icon'
          />
          {isLoggedIn && <div className="um-icon">
            <img
              src="/img/maintenance.png"
              className='img-responsive'
              alt='poker-icon'
            />
          </div>}
          <img
            src="/img/rng/girl-hover.png"
            className='img-responsive rng-girl-hover'
            alt='rng-icon'
          />
        </a>
      </section>
    </>
  );
};
export default Rng;
