import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import { mdiEyeOff, mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "components/button";
import ContentHeader from "components/content/header";
import moment from "moment";
import DateRangeFilter from "../../components/custom/DateRangeFilter"; 
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useHistory, Link } from "react-router-dom";
import {
  setBusy,
  setIdle,
  showResponseMessage,
  setDownlineSearchText,
  setDownlineQuery,
  setPageSize,
  setY,
} from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody } from "reactstrap";
import classnames from "classnames";
import {
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  stringIsNullOrEmpty,
  createFormBody,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Input from "../../components/custom/Input";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateUserData } from "../../redux/AuthAction";
import Accordion from "react-bootstrap/Accordion";
import PinInput from "react-pin-input";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";

const VipEvent = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [isShowEditForm, setIsShowEditForm] = useState(false);
  const [shareholderIndex, setShareholderIndex] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showShareholderConfirmation, setShowShareholderConfirmation] =
    useState(false);
  const { handleSubmit } = useForm();
  const {
    handleSubmit: handleSubmitShareholder,
    register: registerShareholder,
    errors: errorsShareholder,
    setValue: setValueShareholder,
    getValues: getValuesShareholder,
    watch: watchShareholder,
  } = useForm();

  //const [startDate, setStartDate] = useState("2021-08-01"); // for quicker debug purpose
  //const [endDate, setEndDate] = useState("2021-08-01"); // for quicker debug purpose
  const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment().subtract(1, "days"));
  const [allData, setAllData] = useState({});
  const [memberDepthData, setMemberDepthData] = useState({});

  const [processedData, setProcessedData] = useState({});
  const [processedUserTotal, setProcessedUserTotal] = useState({});
  const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
  const [processedSubTotal, setProcessedSubTotal] = useState({});
  const [processedGrandTotal, setProcessedGrandTotal] = useState({});
  const [processedUsernameRows, setProcessedUsernameRows] = useState({});
  const [companySummaryData, setCompanySummaryData] = useState({});
  const [companyCategoryTotal, setCompanyCategoryTotal] = useState({});
  const [companyGrandTotal, setCompanyGrandTotal] = useState({});

  const [claimData, setClaimData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");

  const [member, setMember] = useState([]);
  const [tempData, setTempData] = useState({});
  const [showDynamicTable, setShowDynamicTable] = useState(true);

  const [share, setShare] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const hideSummary = params.get("GAzobE73Vse4nr8WpufA");

  const [activeOptionsList, setActiveOptionsList] = useState([]);
  const [showCreateShareholderPin, setShowCreateShareholderPin] = useState(false);

  const [totalShareholder, setTotalShareholder] = useState(0);
  const [totalNonShareholder, setTotalNonShareholder] = useState(0);
  const [haveShareholder, setHaveShareholder] = useState(true);
  //const [latestGame, setLatestGame] = useState({});
  const [totalUnClaimable, setTotalUnClaimable] = useState("");
  const [invalidPin, setInvalidPin] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const elePin = useRef(null);
  const eleConfirm = useRef(null);

  var { isLoggedIn } = useSelector((state) => state.authState);
  let isShareholder = useSelector((state) => state.appState.isShareholder);
  var _userData = useSelector((state) => state.authState.userData);
  var activeTheme = _userData.selectedTheme;
  let _savedData = useSelector((state) => state.appState.savedText);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);

  const companyToggle = (category) => {
    let tempObj = companyCategoryTotal;
    if (category !== "null") {
      tempObj[category].show = !tempObj[category].show;
    } else {
      tempObj.show = !tempObj.show;
    }
    setCompanyCategoryTotal({ ...tempObj });
  };

  const settlementToggle = (username) => {
    let tempObj = processedUserTotal;
    tempObj[username].showset = !tempObj[username].showset;
    setProcessedUserTotal({ ...tempObj });
  };

  const settlementCatToggle = (username, category) => {
    let tempObj = processedCategoryTotal;
    tempObj[username][category].showset = !tempObj[username][category].showset;
    setProcessedCategoryTotal({ ...tempObj });
  };

  const usernameToggle = (username) => {
    let tempObj = processedUserTotal;
    tempObj[username].show = !tempObj[username].show;
    setProcessedUserTotal({ ...tempObj });
  };
  const categoryToggle = (username, category) => {
    let tempObj = processedCategoryTotal;
    tempObj[username][category].show = !tempObj[username][category].show;
    setProcessedCategoryTotal({ ...tempObj });
  };

  useEffect(() => {
    let temp = localStorage.getItem("6FAZhVi1QvBGsKaUl7WPhVTwRPf1HUhF");
    if (temp !== "wdAvBAPKCPB1TvXPsfPSda8PUUdEGaHg") {
      _history.push(WebUrl._URL_MAIN);
    }
    if (hideSummary === true) {
      _history.push(tabHeadings[0].link);
      //setActiveTab(1);
    }

    return () => {
      // window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    init(startDate, endDate);
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    if (tab === 2) {
      claimInit();
    }
  };

  function getText(e) {
    _dispatch(setDownlineSearchText(e.target.value));
    _dispatch(
      setDownlineQuery(
        ApiUrl._API_GET_USER_MEMBER_LIST +
        "?keyword=" +
        e.target.value +
        "&directUserOnly=true"
      )
    );
    _dispatch(setPageSize(10));
    _dispatch(setY(0));
  }

  async function init(sDate, eDate) {
    _dispatch(setBusy());
    setStartDate(sDate);
    setEndDate(eDate);

    let params = {};
    if (isShareholder) {
      var responseJson2 = await ApiEngine.post(
        ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );

      if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
        var theInterval = setInterval(() => {
          if (elePin["current"] == undefined) return;

          if (
            elePin["current"]["values"][0] == "" ||
            elePin["current"]["values"][0] == undefined
          ) {
            elePin["current"]["elements"][0].focus();
          } else if (
            elePin["current"]["values"][1] == "" ||
            elePin["current"]["values"][1] == undefined
          ) {
            elePin["current"]["elements"][1].focus();
          } else if (
            elePin["current"]["values"][2] == "" ||
            elePin["current"]["values"][2] == undefined
          ) {
            elePin["current"]["elements"][2].focus();
          } else if (
            elePin["current"]["values"][3] == "" ||
            elePin["current"]["values"][3] == undefined
          ) {
            elePin["current"]["elements"][3].focus();
          } else if (
            eleConfirm["current"]["values"][0] == "" ||
            eleConfirm["current"]["values"][0] == undefined
          ) {
            eleConfirm["current"]["elements"][0].focus();
          } else if (
            eleConfirm["current"]["values"][1] == "" ||
            eleConfirm["current"]["values"][1] == undefined
          ) {
            eleConfirm["current"]["elements"][1].focus();
          } else if (
            eleConfirm["current"]["values"][2] == "" ||
            eleConfirm["current"]["values"][2] == undefined
          ) {
            eleConfirm["current"]["elements"][2].focus();
          } else if (
            eleConfirm["current"]["values"][3] == "" ||
            eleConfirm["current"]["values"][3] == undefined
          ) {
            eleConfirm["current"]["elements"][3].focus();
          } else {
            var currpin =
              elePin["current"]["values"][0] +
              elePin["current"]["values"][1] +
              elePin["current"]["values"][2] +
              elePin["current"]["values"][3];
            var currconfpin =
              eleConfirm["current"]["values"][0] +
              eleConfirm["current"]["values"][1] +
              eleConfirm["current"]["values"][2] +
              eleConfirm["current"]["values"][3];
            if (currpin != currconfpin) {
              setInvalidPin(true);
              elePin["current"].clear();
              eleConfirm["current"].clear();
              elePin["current"].focus();
            } else {
              setInvalidPin(false);
              clearInterval(theInterval);
              submitPin(currpin, currconfpin);
            }
          }
        }, 50);
        setShowCreateShareholderPin(true);
      }
    }

    var responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_HIDE_SUMMARY + "?hideSummary=" + hideSummary
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${moment(
          sDate
        ).format("YYYY-MM-DD")}&enddate=${moment(eDate).format("YYYY-MM-DD")}`
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];
        setAllData(data);
        let memberData = responseJson[ApiKey._API_DATA_KEY]["depthData"];
        setMemberDepthData(memberData);
        userMemberList(memberData);
        let summaryRows = {};
        let summaryCategoryTotal = { show: false };
        let summaryGrandTotal = {
          sales: 0,
          vsales: 0,
          payout: 0,
          netProfit: 0,
        };
        if (data != null) {
          data["summaryData"].map((summary, index) => {
            if (!summaryRows[summary.categoryName]) {
              summaryRows[summary.categoryName] = {};
              summaryCategoryTotal[summary.categoryName] = {
                grossSales: 0,
                validTurnover: 0,
                payout: 0,
                netProfit: 0,
              };
            }

            if (!summaryRows[summary.categoryName][summary.productName]) {
              summaryRows[summary.categoryName][summary.productName] = {
                grossSales: summary["grossSales"],
                validTurnover: summary["validTurnover"],
                payout: summary["payout"],
                netProfit: summary["netProfit"],
              };
            }

            summaryCategoryTotal[summary.categoryName].show = false;
            summaryCategoryTotal[summary.categoryName].grossSales +=
              summary["grossSales"];
            summaryCategoryTotal[summary.categoryName].validTurnover +=
              summary["validTurnover"];
            summaryCategoryTotal[summary.categoryName].payout +=
              summary["payout"];
            summaryCategoryTotal[summary.categoryName].netProfit +=
              summary["netProfit"];

            if (summary.categoryName !== null) {
              summaryGrandTotal.sales += summary["grossSales"];
              summaryGrandTotal.vsales += summary["validTurnover"];
              summaryGrandTotal.payout += summary["payout"];
              summaryGrandTotal.netProfit += summary["netProfit"];
            } else {
              summaryGrandTotal.netProfit += summary["netProfit"];
            }
          });
          setCompanySummaryData(summaryRows);
          setCompanyCategoryTotal(summaryCategoryTotal);
          setCompanyGrandTotal(summaryGrandTotal);

          let tempRows = {};
          let dataRows = {};
          let userTotal = {};
          let categoryTotal = {};
          let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
          let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

          data["downlineData"].map((e, i) => {
            if (!dataRows[e.username]) {
              dataRows[e.username] = {};
              tempRows[e.username] = {
                memberGuid: e.memberId,
                hasDownline: e.usernameHasDownlineData,
              };
              userTotal[e.username] = {
                grossSales: 0,
                validTurnover: 0,
                netProfit: 0,
                profit: 0,
                settlement: 0,
                share: 0,
              };
              categoryTotal[e.username] = {};
            }

            if (!dataRows[e.username][e.categoryName]) {
              dataRows[e.username][e.categoryName] = {};
              categoryTotal[e.username][e.categoryName] = {
                grossSales: 0,
                validTurnover: 0,
                netProfit: 0,
                profit: 0,
                settlement: 0,
                share: 0,
              };
            }

            if (!dataRows[e.username][e.categoryName][e.productName]) {
              dataRows[e.username][e.categoryName][e.productName] = {
                grossSales: e.grossSales,
                validTurnover: e.validTurnover,
                netProfit: e.netProfit,
                profit: e.profit,
                settlement: e.settlement,
                share: e.share,
              };
            }

            userTotal[e.username].show = false;
            userTotal[e.username].showset = false;
            userTotal[e.username].grossSales += e.grossSales;
            userTotal[e.username].validTurnover += e.validTurnover;
            userTotal[e.username].netProfit += e.netProfit;
            userTotal[e.username].profit += e.profit;
            userTotal[e.username].settlement += e.settlement;
            userTotal[e.username].share = e.share;

            categoryTotal[e.username][e.categoryName].show = false;
            categoryTotal[e.username][e.categoryName].showset = false;
            categoryTotal[e.username][e.categoryName].grossSales +=
              e.grossSales;
            categoryTotal[e.username][e.categoryName].validTurnover +=
              e.validTurnover;
            categoryTotal[e.username][e.categoryName].netProfit += e.netProfit;
            categoryTotal[e.username][e.categoryName].profit += e.profit;
            categoryTotal[e.username][e.categoryName].settlement +=
              e.settlement;
            categoryTotal[e.username][e.categoryName].share = e.share;

            subTotal.sales += e.grossSales;
            subTotal.vsales += e.validTurnover;
            subTotal.netProfit += e.profit;

            grandTotal.sales += e.grossSales;
            grandTotal.vsales += e.validTurnover;
            grandTotal.netProfit += e.profit;
            grandTotal.netProfit += e.settlement;
          });
          setProcessedUserTotal(userTotal);
          setProcessedCategoryTotal(categoryTotal);
          setProcessedData(dataRows);
          setProcessedUsernameRows(tempRows);
          setProcessedSubTotal(subTotal);
          setProcessedGrandTotal(grandTotal);
        }
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
      _history.push(WebUrl._URL_MAIN);
    }
    _dispatch(setIdle());
  }

  const getDownlineSales = async (username) => {
    _history.push(
      WebUrl._URL_SUMMARY_DOWNLINE +
      "?startDate=" +
      moment(startDate).format("YYYY-MM-DD") +
      "&endDate=" +
      moment(endDate).format("YYYY-MM-DD") +
      "&username=" +
      username +
      "&GAzobE73Vse4nr8WpufA=" +
      hideSummary
    );
    //responseJson = await ApiEngine.get(`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).format("YYYY-MM-DD")}&memberId=${memberId}`);
    //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    //}
  };

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        claimData.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  async function claimInit() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setClaimData(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    _dispatch(setIdle());
  }

  function preSubmit() {
    setShowConfirmation(true);
  }

  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_SHAREHOLDER,
      createMultiPartFormBody({})
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(updateUserData());
      claimInit();
    }

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  }

  var tabHeadings = [
    // {
    //   title: "SUMMARY",
    //   tab_id: 0,
    // },
    {
      title: "SHAREHOLDERS",
      tab_id: 1,
      link: WebUrl._URL_SHAREHOLDER +
        "?startDate=" +
        moment(startDate).format("YYYY-MM-DD") +
        "&endDate=" +
        moment(endDate).format("YYYY-MM-DD") +
        "&GAzobE73Vse4nr8WpufA=" +
        hideSummary
    },
    {
      title: "CLAIM",
      tab_id: 2,
      link: WebUrl._URL_VIP_CLAIM
    },
  ];
  if (hideSummary) {
    let tempTabHeadings = tabHeadings;
    tabHeadings = tempTabHeadings.filter((x) => x.title !== "SUMMARY");
  }

  const getShareholderDetails = async (index, downlineId) => {
    try {
      var responseJson = await ApiEngine.get(
        `${ApiUrl._API_GET_MEMBER_DETAILS_BY_ID}?memberId=${downlineId}&shareholder=true`
      );

      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setShare(responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] == 1
        ? responseJson[ApiKey._API_DATA_KEY]['sharePercent']
        : responseJson[ApiKey._API_DATA_KEY]['maxShare']);
      // setIsShowEditForm(true);
      setShareholderIndex(index);
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    } finally {
      _dispatch(setIdle());
    }
  };

  async function preShareholderSubmit(data, e) {
    e.preventDefault();
    setTempData(data);
    if (member["isShareHolder"] != 1) {
      setShowShareholderConfirmation(true);
    } else {
      await submitShareholderForm(data);
    }
  }

  async function submitShareholderForm(data) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_SHAREHOLDER,
      createMultiPartFormBody({
        memberId: member["id"],
        share: share,
        hideSummary: hideSummary,
      })
    );
    setIsShowEditForm(false);
    _dispatch(setY(0));
    setShowDynamicTable(false);
    setTimeout(() => setShowDynamicTable(true), 1);
    _dispatch(setIdle());

    //_dispatch(
    //  showMessage({
    //    type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
    //    content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
    //    onConfirm: () => {
    //      _dispatch(setY(0));
    //      setShowDynamicTable(false);
    //      setTimeout(() => setShowDynamicTable(true), 1);
    //      _dispatch(setIdle());
    //    },
    //  })
    //);
  }

  async function handleEditShareholderBtn(index, downline, e) {
    _dispatch(setBusy());
    e.preventDefault();
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID + "?memberId=" + downline['id'] + "&shareholder=true");
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];
      let tempOptionsList = [];
      for (var i = editingDownlineData["minShare"]; i <= editingDownlineData["maxShare"]; i += 5) {
        tempOptionsList.push(i.toFixed(2));
      }
      setActiveOptionsList(tempOptionsList);
      // setMember(editingDownlineData);
      // setHideSummary(editingDownlineData["hideSummary"]);
      // setShare(editingDownlineData["sharePercent"]);

      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setShare(responseJson[ApiKey._API_DATA_KEY]['isShareHolder'] == 1
        ? responseJson[ApiKey._API_DATA_KEY]['sharePercent']
        : responseJson[ApiKey._API_DATA_KEY]['maxShare']);

      setShareholderIndex(index);
      setIsShowEditForm(true);
    }
    _dispatch(setIdle());
  }

  function onCreatePinKeyPress(value) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (value !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        // elePin["current"]["elements"][currentIndex].state.value = value;
        pinStr = value;
        setEnteredPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        // eleConfirm["current"]["elements"][currentIndex].state.value = value;
        pinConfirmStr = value;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex < 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          // setTimeout(function () {
          //   submitPin(pinNumber, pinConfirmStr);
          // }, 1000);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  async function submitPin(pin, conf) {
    try {
      let params = {
        pinNumber: pin,
        confirmPinNumber: conf,
      };
      let responseJson = await ApiEngine.post(
        ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setShowCreateShareholderPin(false);
        setInvalidPin(false);
        _history.push(WebUrl._URL_VIP);
      }
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  async function userMemberList(memberData) {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_MEMBER_LIST + "?directUserOnly=true" + "&count=" + memberData["totalDirectDownlines"])
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setHaveShareholder(responseJson[ApiKey._API_DATA_KEY]["data"].length > 0 ? true : false)
      setTotalShareholder(responseJson[ApiKey._API_DATA_KEY]["data"].filter((x) => x.isShareHolder == true).length);
      setTotalNonShareholder(responseJson[ApiKey._API_DATA_KEY]["data"].filter((x) => x.isShareHolder == false).length);
    }

  }

  //useEffect(() => {
  //  getLatestGame();
  //  getUnClaimed();
  //}, []);

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  //const getUnClaimed = async () => {
  //  var unClimedJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

  //  if (unClimedJson[ApiKey._API_SUCCESS_KEY]) {
  //    setTotalUnClaimable(unClimedJson[ApiKey._API_DATA_KEY]["totalclaimable"]);
  //  }
  //}

  return (
    <>
      <section className="section-padding" id="vip-section">
        <div>
          <ContentHeader title={t("VIP_EVENT")} />
          <div className="with-information-container">
            <div className="left">
              <div class="vip-main-nav">
                {tabHeadings &&
                  tabHeadings.length &&
                  map(tabHeadings, (item, index) => {
                    return (
                      <div onClick={() => _history.push(item.link)}>
                        {t(item.title)}
                      </div>
                    );
                  })}
              </div>
              {hideSummary === true ?
                <></>
                :
                <div className="vip-details-container mt-4">
                  <DateRangeFilter
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    onSave={(start, end) => {
                      init(moment(start), moment(end));
                      // todo : for api funtion, can refer WalletStatament page
                      // getWalletStatement(start, end);
                    }}
                    options={true}
                  />
                  {Object.keys(companySummaryData) &&
                    Object.keys(companySummaryData).length > 0 && (
                      <>
                        <h4 className="vip-event-company-summary-title">
                          {t("COMPANY_SUMMARY")}
                        </h4>
                        <div className="company-summary card card-package">
                          <div>
                            <div className="card-table table-responsive">
                              <table className="table table-kpi-summary">
                                <thead
                                // style={{
                                //   borderBottom: "2px solid",
                                // }}
                                >
                                  <tr>
                                    <th
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {t("CATEGORY")}
                                    </th>
                                    <th
                                      style={{ textAlign: "right" }}
                                    >
                                      {t("VTURNOVER")}
                                    </th>
                                    <th
                                      style={{ textAlign: "right" }}
                                    >
                                      {t("PAYOUT")}
                                    </th>
                                    <th
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      {t("NET_PROFIT")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(companySummaryData)
                                    .length > 0 &&
                                    Object.keys(
                                      companyCategoryTotal
                                    ).length > 0 &&
                                    Object.keys(
                                      companySummaryData
                                    ).map((category, i) => {
                                      if (
                                        typeof companyCategoryTotal[
                                        category
                                        ] !== "undefined" &&
                                        (category === "null" ||
                                          companyCategoryTotal.show)
                                      ) {
                                        return (
                                          <>
                                            <tr key={i}>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  paddingLeft:
                                                    "20px",
                                                }}
                                                onClick={() =>
                                                  companyToggle(
                                                    category
                                                  )
                                                }
                                              >
                                                {category === "null"
                                                  ? t("SETTLEMENT")
                                                  : t(category)}
                                                {category !==
                                                  "null" && (
                                                    <i
                                                      className={
                                                        companyCategoryTotal[
                                                          category
                                                        ].show
                                                          ? "shareholder-dropdown fas fa-caret-up"
                                                          : "shareholder-dropdown fas fa-caret-down"
                                                      }
                                                    />
                                                  )}
                                                {category ===
                                                  "null" && (
                                                    <i
                                                      className={
                                                        companyCategoryTotal.show
                                                          ? "shareholder-dropdown fas fa-caret-up"
                                                          : "shareholder-dropdown fas fa-caret-down"
                                                      }
                                                    />
                                                  )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign:
                                                    "right",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    companyCategoryTotal[
                                                    category
                                                    ][
                                                    "validTurnover"
                                                    ]
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign:
                                                    "right",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    companyCategoryTotal[
                                                    category
                                                    ]["payout"]
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                              <td
                                                className={
                                                  companyCategoryTotal[
                                                    category
                                                  ]["netProfit"] < 0
                                                    ? "text-red"
                                                    : ""
                                                }
                                                style={{
                                                  textAlign:
                                                    "right",
                                                  paddingRight:
                                                    "20px",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    companyCategoryTotal[
                                                    category
                                                    ]["netProfit"]
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            {companyCategoryTotal[
                                              category
                                            ].show &&
                                              Object.keys(
                                                companySummaryData[
                                                category
                                                ]
                                              ).map(
                                                (
                                                  product,
                                                  index
                                                ) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="child-row"
                                                    >
                                                      <td
                                                        className="category-product"
                                                        style={{
                                                          textAlign:
                                                            "left",
                                                          paddingLeft:
                                                            "20px"
                                                        }}
                                                      >
                                                        - {product}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign:
                                                            "right",
                                                        }}
                                                      >
                                                        {numberWithCurrencyFormat(
                                                          parseFloat(
                                                            companySummaryData[
                                                            category
                                                            ][
                                                            product
                                                            ][
                                                            "validTurnover"
                                                            ]
                                                          ),
                                                          3,
                                                          true
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign:
                                                            "right",
                                                        }}
                                                      >
                                                        {numberWithCurrencyFormat(
                                                          parseFloat(
                                                            companySummaryData[
                                                            category
                                                            ][
                                                            product
                                                            ][
                                                            "payout"
                                                            ]
                                                          ),
                                                          3,
                                                          true
                                                        )}
                                                      </td>
                                                      <td
                                                        className={
                                                          companySummaryData[
                                                            category
                                                          ][
                                                            product
                                                          ][
                                                            "netProfit"
                                                          ] < 0
                                                            ? "text-red"
                                                            : ""
                                                        }
                                                        style={{
                                                          textAlign:
                                                            "right",
                                                          paddingRight:
                                                            "20px",
                                                        }}
                                                      >
                                                        {numberWithCurrencyFormat(
                                                          parseFloat(
                                                            companySummaryData[
                                                            category
                                                            ][
                                                            product
                                                            ][
                                                            "netProfit"
                                                            ]
                                                          ),
                                                          3,
                                                          true
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    })}
                                  <tr
                                    className="grandtotal"
                                    key={"companygrandtotal"}
                                  >
                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {t("GRAND_TOTAL")}
                                    </td>
                                    <td
                                      style={{ textAlign: "right" }}
                                    >
                                      {numberWithCurrencyFormat(
                                        parseFloat(
                                          companyGrandTotal.vsales
                                        ),
                                        3,
                                        true
                                      )}
                                    </td>
                                    <td
                                      style={{ textAlign: "right" }}
                                    >
                                      {numberWithCurrencyFormat(
                                        parseFloat(
                                          companyGrandTotal.payout
                                        ),
                                        3,
                                        true
                                      )}
                                    </td>
                                    <td
                                      className={
                                        companyGrandTotal.netProfit <
                                          0
                                          ? "text-red"
                                          : ""
                                      }
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      {numberWithCurrencyFormat(
                                        parseFloat(
                                          companyGrandTotal.netProfit
                                        ),
                                        3,
                                        true
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  {Object.keys(companySummaryData).length === 0 && (
                    <>
                      <h4 className="vip-event-company-summary-title">
                        {t("COMPANY_SUMMARY")}
                      </h4>
                      <div className="vip-event-table">
                        <div className="">
                          <div className="card-table table-responsive">
                            <table className="table table-kpi-summary">
                              <thead
                              // style={{
                              //   borderBottom: "2px solid",
                              // }}
                              >
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    {t("CATEGORY")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("VTURNOVER")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("PAYOUT")}
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    {t("NET_PROFIT")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan="4">
                                    <div style={{ paddingLeft: '10px' }}>{t("NO_REPORT_FOR_THE_DATE")}</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {Object.keys(processedData).length > 0 && (
                    <>
                      <h4 className="vip-event-company-summary-title mt-4">
                        {t("MY_INCOME")}
                      </h4>
                      <div className="shareholder-my-income">
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("DIRECT_DOWNLINE")}
                            </div>
                            <div className="content-value">
                              {
                                memberDepthData[
                                "totalDirectDownlines"
                                ]
                              }
                            </div>
                          </div>
                        </div>
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("NET_PROFIT")}
                            </div>
                            <div
                              className={
                                "content-value" +
                                (processedGrandTotal.netProfit < 0
                                  ? " text-red"
                                  : "")
                              }
                            >
                              {isNaN(processedGrandTotal.netProfit)
                                ? ""
                                : numberWithCurrencyFormat(
                                  parseFloat(
                                    processedGrandTotal.netProfit
                                  ),
                                  3,
                                  true
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("VALID_TURNOVER")}
                            </div>
                            <div
                              className={
                                "content-value" +
                                (processedGrandTotal.vsales < 0
                                  ? " text-red"
                                  : "")
                              }
                            >
                              {isNaN(processedGrandTotal.vsales)
                                ? ""
                                : numberWithCurrencyFormat(
                                  parseFloat(
                                    processedGrandTotal.vsales
                                  ),
                                  3,
                                  true
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="income-summary card card-package">
                        <div>
                          <div className="card-table table-responsive">
                            <table className="table table-kpi-summary">
                              <thead
                              // style={{
                              //   borderBottom: "2px solid",
                              // }}
                              >
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    {t("USERNAME")}
                                  </th>
                                  <th style={{ textAlign: "left" }}>
                                    {t("CATEGORY")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("VTURNOVER")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("%")}
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    {t("NET_PROFIT")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(processedData).length >
                                  0 &&
                                  Object.keys(processedData).map(
                                    (_username, i) => {
                                      if (
                                        typeof processedUserTotal[
                                        _username
                                        ] !== "undefined"
                                      ) {
                                        return (
                                          <>
                                            <tr
                                              key={
                                                "username_" +
                                                _username
                                              }
                                            >
                                              <td
                                                className={
                                                  "has-downline" +
                                                  (Object.keys(
                                                    processedUsernameRows
                                                  ).length > 0 &&
                                                    processedUsernameRows[
                                                    _username
                                                    ] &&
                                                    processedUsernameRows[
                                                    _username
                                                    ]["hasDownline"]
                                                    ? ""
                                                    : "-false")
                                                }
                                                style={{
                                                  textAlign: "left",
                                                  paddingLeft:
                                                    "20px",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    Object.keys(
                                                      processedUsernameRows
                                                    ).length > 0 &&
                                                    processedUsernameRows[
                                                    _username
                                                    ] &&
                                                    processedUsernameRows[
                                                    _username
                                                    ]["hasDownline"]
                                                  ) {
                                                    getDownlineSales(
                                                      _username
                                                    );
                                                  }
                                                }}
                                              >
                                                {_username}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                }}
                                                onClick={() =>
                                                  usernameToggle(
                                                    _username
                                                  )
                                                }
                                              >
                                                {t("ALL")}{" "}
                                                <i
                                                  className={
                                                    processedUserTotal[
                                                      _username
                                                    ].show
                                                      ? "shareholder-dropdown fas fa-caret-up"
                                                      : "shareholder-dropdown fas fa-caret-down"
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  textAlign:
                                                    "right",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    processedUserTotal[
                                                      _username
                                                    ].validTurnover
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign:
                                                    "right",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    processedUserTotal[
                                                      _username
                                                    ].share
                                                  ),
                                                  0,
                                                  true
                                                )}
                                                /130
                                              </td>
                                              <td
                                                className={
                                                  processedUserTotal[
                                                    _username
                                                  ].profit < 0
                                                    ? "text-red"
                                                    : ""
                                                }
                                                style={{
                                                  textAlign:
                                                    "right",
                                                  paddingRight:
                                                    "20px",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    processedUserTotal[
                                                      _username
                                                    ].profit
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            {processedUserTotal[
                                              _username
                                            ].show &&
                                              Object.keys(
                                                processedData[
                                                _username
                                                ]
                                              ).map(
                                                (_category, j) => {
                                                  return (
                                                    <>
                                                      <tr
                                                        key={
                                                          "category_" +
                                                          _username +
                                                          "_" +
                                                          _category
                                                        }
                                                        className="child-row"
                                                      >
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "left",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "left",
                                                          }}
                                                          className="cat-prod"
                                                          onClick={() =>
                                                            categoryToggle(
                                                              _username,
                                                              _category
                                                            )
                                                          }
                                                        >
                                                          -{" "}
                                                          {
                                                            t(_category)
                                                          }{" "}
                                                          <i
                                                            className={
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ].show
                                                                ? "shareholder-dropdown fas fa-caret-up"
                                                                : "shareholder-dropdown fas fa-caret-down"
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                          }}
                                                        >
                                                          {numberWithCurrencyFormat(
                                                            parseFloat(
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ]
                                                                .validTurnover
                                                            ),
                                                            3,
                                                            true
                                                          )}
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                          }}
                                                        >
                                                          {numberWithCurrencyFormat(
                                                            parseFloat(
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ]
                                                                .share
                                                            ),
                                                            0,
                                                            true
                                                          )}
                                                          /130
                                                        </td>
                                                        <td
                                                          className={
                                                            processedCategoryTotal[
                                                              _username
                                                            ][
                                                              _category
                                                            ]
                                                              .profit <
                                                              0
                                                              ? "text-red"
                                                              : ""
                                                          }
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                            paddingRight:
                                                              "20px",
                                                          }}
                                                        >
                                                          {numberWithCurrencyFormat(
                                                            parseFloat(
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ]
                                                                .profit
                                                            ),
                                                            3,
                                                            true
                                                          )}
                                                        </td>
                                                      </tr>
                                                      {processedCategoryTotal[
                                                        _username
                                                      ][_category]
                                                        .show &&
                                                        Object.keys(
                                                          processedData[
                                                          _username
                                                          ][
                                                          _category
                                                          ]
                                                        ).map(
                                                          (
                                                            _product,
                                                            k
                                                          ) => {
                                                            return (
                                                              <>
                                                                <tr
                                                                  key={
                                                                    "product_" +
                                                                    _username +
                                                                    "_" +
                                                                    _category +
                                                                    "_" +
                                                                    _product
                                                                  }
                                                                  className="child-row"
                                                                >
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  ></td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                    className="cat-prod"
                                                                  >
                                                                    --{" "}
                                                                    {
                                                                      _product
                                                                    }
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {numberWithCurrencyFormat(
                                                                      parseFloat(
                                                                        processedData[
                                                                          _username
                                                                        ][
                                                                          _category
                                                                        ][
                                                                          _product
                                                                        ]
                                                                          .validTurnover
                                                                      ),
                                                                      3,
                                                                      true
                                                                    )}
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {numberWithCurrencyFormat(
                                                                      parseFloat(
                                                                        processedData[
                                                                          _username
                                                                        ][
                                                                          _category
                                                                        ][
                                                                          _product
                                                                        ]
                                                                          .share
                                                                      ),
                                                                      0,
                                                                      true
                                                                    )}
                                                                    /130
                                                                  </td>
                                                                  <td
                                                                    className={
                                                                      processedData[
                                                                        _username
                                                                      ][
                                                                        _category
                                                                      ][
                                                                        _product
                                                                      ]
                                                                        .profit <
                                                                        0
                                                                        ? "text-red"
                                                                        : ""
                                                                    }
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                      paddingRight:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    {numberWithCurrencyFormat(
                                                                      parseFloat(
                                                                        processedData[
                                                                          _username
                                                                        ][
                                                                          _category
                                                                        ][
                                                                          _product
                                                                        ]
                                                                          .profit
                                                                      ),
                                                                      3,
                                                                      true
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    }
                                  )}

                                <tr
                                  className="subtotal"
                                  key={"subtotal"}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "20px",
                                    }}
                                    colSpan="2"
                                  >
                                    {t("SUB_TOTAL")}
                                  </td>
                                  <td
                                    style={{ textAlign: "right" }}
                                  >
                                    {numberWithCurrencyFormat(
                                      parseFloat(
                                        processedSubTotal.vsales
                                      ),
                                      3,
                                      true
                                    )}
                                  </td>
                                  <td
                                    style={{ textAlign: "right" }}
                                  ></td>
                                  <td
                                    className={
                                      processedSubTotal.netProfit <
                                        0
                                        ? "text-red"
                                        : ""
                                    }
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    {numberWithCurrencyFormat(
                                      parseFloat(
                                        processedSubTotal.netProfit
                                      ),
                                      3,
                                      true
                                    )}
                                  </td>
                                </tr>

                                {Object.keys(processedData).length >
                                  0 &&
                                  Object.keys(processedData).map(
                                    (_username, i) => {
                                      if (
                                        typeof processedUserTotal[
                                        _username
                                        ] !== "undefined"
                                      ) {
                                        return (
                                          <>
                                            <tr
                                              key={
                                                "settlement_username_" +
                                                _username
                                              }
                                            >
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  paddingLeft:
                                                    "20px",
                                                }}
                                              >
                                                {_username}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                }}
                                                colSpan="2"
                                                onClick={() =>
                                                  settlementToggle(
                                                    _username
                                                  )
                                                }
                                              >
                                                {t("SETTLEMENT")}{" "}
                                                <i
                                                  className={
                                                    processedUserTotal[
                                                      _username
                                                    ].showset
                                                      ? "shareholder-dropdown fas fa-caret-up"
                                                      : "shareholder-dropdown fas fa-caret-down"
                                                  }
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  textAlign:
                                                    "right",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    processedUserTotal[
                                                      _username
                                                    ].share
                                                  ),
                                                  0,
                                                  true
                                                )}
                                                /130
                                              </td>
                                              <td
                                                className={
                                                  processedUserTotal[
                                                    _username
                                                  ].settlement < 0
                                                    ? "text-red"
                                                    : ""
                                                }
                                                style={{
                                                  textAlign:
                                                    "right",
                                                  paddingRight:
                                                    "20px",
                                                }}
                                              >
                                                {numberWithCurrencyFormat(
                                                  parseFloat(
                                                    processedUserTotal[
                                                      _username
                                                    ].settlement
                                                  ),
                                                  3,
                                                  true
                                                )}
                                              </td>
                                            </tr>
                                            {processedUserTotal[
                                              _username
                                            ].showset &&
                                              Object.keys(
                                                processedData[
                                                _username
                                                ]
                                              ).map(
                                                (_category, j) => {
                                                  return (
                                                    <>
                                                      <tr
                                                        key={
                                                          "category_" +
                                                          _username +
                                                          "_" +
                                                          _category
                                                        }
                                                        className="child-row"
                                                      >
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "left",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "left",
                                                          }}
                                                          className="cat-prod"
                                                          onClick={() =>
                                                            settlementCatToggle(
                                                              _username,
                                                              _category
                                                            )
                                                          }
                                                        >
                                                          -
                                                          {
                                                            t(_category)
                                                          }{" "}
                                                          <i
                                                            className={
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ].show
                                                                ? "shareholder-dropdown fas fa-caret-up"
                                                                : "shareholder-dropdown fas fa-caret-down"
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                          }}
                                                        ></td>
                                                        <td
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                          }}
                                                        >
                                                          {numberWithCurrencyFormat(
                                                            parseFloat(
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ]
                                                                .share
                                                            ),
                                                            0,
                                                            true
                                                          )}
                                                          /130
                                                        </td>
                                                        <td
                                                          className={
                                                            processedCategoryTotal[
                                                              _username
                                                            ][
                                                              _category
                                                            ]
                                                              .settlement <
                                                              0
                                                              ? "text-red"
                                                              : ""
                                                          }
                                                          style={{
                                                            textAlign:
                                                              "right",
                                                            paddingRight:
                                                              "20px",
                                                          }}
                                                        >
                                                          {numberWithCurrencyFormat(
                                                            parseFloat(
                                                              processedCategoryTotal[
                                                                _username
                                                              ][
                                                                _category
                                                              ]
                                                                .settlement
                                                            ),
                                                            3,
                                                            true
                                                          )}
                                                        </td>
                                                      </tr>
                                                      {processedCategoryTotal[
                                                        _username
                                                      ][_category]
                                                        .showset &&
                                                        Object.keys(
                                                          processedData[
                                                          _username
                                                          ][
                                                          _category
                                                          ]
                                                        ).map(
                                                          (
                                                            _product,
                                                            k
                                                          ) => {
                                                            return (
                                                              <>
                                                                <tr
                                                                  key={
                                                                    "product_" +
                                                                    _username +
                                                                    "_" +
                                                                    _category +
                                                                    "_" +
                                                                    _product
                                                                  }
                                                                  className="child-row"
                                                                >
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  ></td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                    className="cat-prod"
                                                                  >
                                                                    --
                                                                    {
                                                                      _product
                                                                    }
                                                                  </td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  ></td>
                                                                  <td
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    {numberWithCurrencyFormat(
                                                                      parseFloat(
                                                                        processedData[
                                                                          _username
                                                                        ][
                                                                          _category
                                                                        ][
                                                                          _product
                                                                        ]
                                                                          .share
                                                                      ),
                                                                      0,
                                                                      true
                                                                    )}
                                                                    /130
                                                                  </td>
                                                                  <td
                                                                    className={
                                                                      processedData[
                                                                        _username
                                                                      ][
                                                                        _category
                                                                      ][
                                                                        _product
                                                                      ]
                                                                        .settlement <
                                                                        0
                                                                        ? "text-red"
                                                                        : ""
                                                                    }
                                                                    style={{
                                                                      textAlign:
                                                                        "right",
                                                                      paddingRight:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    {numberWithCurrencyFormat(
                                                                      parseFloat(
                                                                        processedData[
                                                                          _username
                                                                        ][
                                                                          _category
                                                                        ][
                                                                          _product
                                                                        ]
                                                                          .settlement
                                                                      ),
                                                                      3,
                                                                      true
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    }
                                  )}

                                <tr
                                  className="grandtotal"
                                  key={"grandtotal"}
                                >
                                  <td
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "20px",
                                    }}
                                    colSpan="2"
                                  >
                                    {t("GRAND_TOTAL")}
                                  </td>
                                  <td
                                    style={{ textAlign: "right" }}
                                  ></td>
                                  <td
                                    style={{ textAlign: "right" }}
                                  ></td>
                                  <td
                                    className={
                                      processedGrandTotal.netProfit <
                                      0 && "text-red"
                                    }
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    {numberWithCurrencyFormat(
                                      parseFloat(
                                        processedGrandTotal.netProfit
                                      ),
                                      3,
                                      true
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {Object.keys(processedData).length == 0 && (
                    <>
                      <h4 className="vip-event-company-summary-title mt-4">
                        {t("MY_INCOME")}
                      </h4>
                      <div className="shareholder-my-income">
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("DIRECT_DOWNLINE")}
                            </div>
                            <div className="content-value">
                              {
                                memberDepthData[
                                "totalDirectDownlines"
                                ]
                              }
                            </div>
                          </div>
                        </div>
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("NET_PROFIT")}
                            </div>
                            <div
                              className={
                                "content-value" +
                                (processedGrandTotal.netProfit < 0
                                  ? " text-red"
                                  : "")
                              }
                            >
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  processedGrandTotal.netProfit
                                ),
                                3,
                                true
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card my-income-card">
                          <div className="my-income-card-content">
                            <div className="content-title">
                              {t("VALID_TURNOVER")}
                            </div>
                            <div
                              className={
                                "content-value" +
                                (processedGrandTotal.vsales < 0
                                  ? " text-red"
                                  : "")
                              }
                            >
                              {numberWithCurrencyFormat(
                                parseFloat(
                                  processedGrandTotal.vsales
                                ),
                                3,
                                true
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="vip-event-table">
                        <div className="">
                          <div className="card-table table-responsive">
                            <table className="table table-kpi-summary">
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    {t("USERNAME")}
                                  </th>
                                  <th style={{ textAlign: "left" }}>
                                    {t("CATEGORY")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("VTURNOVER")}
                                  </th>
                                  <th
                                    style={{ textAlign: "right" }}
                                  >
                                    {t("%")}
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    {t("NET_PROFIT")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan="5">
                                    <div style={{ paddingLeft: '10px' }}>{t("NO_REPORT_FOR_THE_DATE")}</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>}
            </div>
            <div className="right">
              <RightInformation
                shareHolder={haveShareholder}
                shareHolderMembers={totalShareholder}
                nonShareHolderMembers={totalNonShareholder}
                shareHolderTotalUnclaimed={true}
                //shareHolderTotalUnclaimedTitle="TOTAL_UNCLAIMED"
                //shareHolderTotalUnclaimedDetails={totalUnClaimable}
                games={true}
                //gamesTitle="LATEST_GAME"
                //gamesData={latestGame}
                promotion={true}
              />
            </div>
          </div>
        </div>
      </section>
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      {showShareholderConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t(
            "CONFIRM_APPOINT_AS_SHAREHOLDER", { username: member["username"] }
          )}
          onConfirm={() => {
            setShowShareholderConfirmation(false);
            submitShareholderForm(tempData);
          }}
          onCancel={() => {
            setShowShareholderConfirmation(false);
          }}
        ></SweetAlert>
      )}

      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={isLoggedIn && showCreateShareholderPin}
        centered
      >
        <ModalBody>
          <form>
            <div
              className="display-wrapper"
              style={{ textAlign: "center" }}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  onCreatePinKeyPress("{bksp}");
                }
              }}
            >
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "20px" }}
              >
                {t("NO_SHAREHOLDER_PIN_FOUND")}
              </strong>
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                }}
              >
                {t("ENTER_YOUR_PIN")}
              </strong>
              <PinInput
                ref={elePin}
                length={4}
                initialValue=""
                secret={true}
                focus={true}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberFocused(index);
                  onCreatePinKeyPress(value);
                }}
                onComplete={(value) => adjustFocus()}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                  marginTop: "30px",
                }}
              >
                {t("CONFIRM_YOUR_PIN")}
              </strong>
              <PinInput
                ref={eleConfirm}
                length={4}
                initialValue=""
                secret={true}
                focus={false}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberConfirmFocused(index);
                  onCreatePinKeyPress(value);
                }}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
              {/* <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              /> */}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default VipEvent;
