import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import { map } from "lodash";
import { ApiKey, ApiUrl, WebUrl, PhoneNumberPrefix } from "../../util/Constant";
import {
  createFormBody,
  createMultiPartFormBody,
  stringIsNullOrEmpty,
} from "../../util/Util";
import {
  setBusy,
  setIdle,
  showResponseMessage,
} from "../../redux/AppAction";
import { updateUserData } from "../../redux/AuthAction";
import { useSelector, useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Modal,
  ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import PinInput from "react-pin-input";
import Button from "components/button";
import ContentHeader from "components/content/header";
import DateTime from 'react-datetime';
import moment from 'moment';
import DatePicker from "react-datepicker";
import RightInformation from "../../components/custom/RightInformation";
import closedEye from "../../assets/img/version3/register-login/hide.png";
import openEye from "../../assets/img/version3/register-login/eye-blue.png";
import Shareholder from "../vip-event/Shareholder";

const Settings = (props) => {
  const _history = useHistory();
  const _ALERT_TIMEOUT = 3000;

  const { t } = useTranslation();
  const _dispatch = useDispatch();
  const [member, setMember] = useState();
  const [memberFullName, setMemberFullName] = useState("");
  // const [memberEmail, setMemberEmail] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [showFormattedPhone, setshowFormattedPhone] = useState("");
  const [TACphoneNumber, setTACphoneNumber] = useState("");
  const [showThemeAlert, setShowThemeAlert] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [checkPinExist, setCheckPinExist] = useState(true);
  const [checkVipPinExist, setCheckVipPinExist] = useState(true);
  const [oldPinNumber, setOldPinNumber] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");

  const [oldVipPinNumber, setOldVipPinNumber] = useState("");
  const [vipPinNumber, setVipPinNumber] = useState("");
  const [vipPinNumberConfirm, setVipPinNumberConfirm] = useState("");

  const [tempPinNumber, setTempPinNumber] = useState("");

  const [oldPinNumberFocused, setOldPinNumberFocused] = useState(0);
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);

  const [oldVipPinNumberFocused, setOldVipPinNumberFocused] = useState(0);
  const [vipPinNumberFocused, setVipPinNumberFocused] = useState(0);
  const [vipPinNumberConfirmFocused, setVipPinNumberConfirmFocused] =
    useState(0);

  const [tempPinNumberFocused, setTempPinNumberFocused] = useState(0);
  const [showPinEntry, setShowPinEntry] = useState(true);
  const [showVipPinEntry, setShowVipPinEntry] = useState(false);
  const [allowPinClick, setAllowPinClick] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isShareholder, setIsShareholder] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);
  const [showChangePasswordDropdown, setShowChangePasswordDropdown] = useState(false);
  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState( 
    ""
    // PhoneNumberPrefix[0].value
  );
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [sentCode, setSentCode] = useState(false);
  const [enteredCode, setEnteredCode] = useState("");
  const _COUNTDOWN_PERIOD = 60;
  const _SECONDS_IN_MS = 1000;
  const _MIN_PASSWORD_LENGTH = 6;
  const [activeTab, setActiveTab] = useState(0);
  var _userData = useSelector((state) => state.authState.userData);

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [birthModal, setBirthModal] = useState(false);
  const [birthdayValue, setBirthdayValue] = useState("");
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [formattedEmail, setFormattedEmail] = useState("");
  const [TACemail, setTACemail] = useState("");
  const [countdownPeriodEmail, setCountdownPeriodEmail] = useState(0);
  const [sentCodeEmail, setSentCodeEmail] = useState(false);
  const [enteredCodeEmail, setEnteredCodeEmail] = useState("");
  const [disabledDate, setDisabledDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [birthdayString, setBirthdayString] = useState("");

  const [isHiddenCurrentPassword, setIsHiddenCurrentPassword] = useState(closedEye);
  const [isHiddenRegisterPassword, setIsHiddenRegisterPassword] = useState(closedEye);
  const [isHiddenRegisterConfirmPassword, setIsHiddenRegisterConfirmPassword] = useState(closedEye);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newRegisterPasswordVisible, setNewRegisterPasswordVisible] = useState(false);
  const [confirmRegisterPasswordVisible, setConfirmRegisterPasswordVisible] = useState(false);

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [pinCurrentData, setPinCurrentData] = useState(0);
  //const [latestGame, setLatestGame] = useState({});
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [emailActiveStep, setEmailActiveStep] = useState(1);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgetPinActiveStep, setForgetPinActiveStep] = useState(1);
  const [countdownPeriodForgetPassword, setCountdownPeriodForgetPassword] = useState(0);
  const [countdownPeriodForgetPin, setCountdownPeriodForgetPin] = useState(0);
  const [countdownPeriodShareholderForgetPin, setCountdownShareholderPeriodForgetPin] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [forgetPin, setForgetPin] = useState(false);


  const [currentPinRef, setCurrentPinRef] = useState(0);
  const pin_1 = useRef(null);
  const pin_2 = useRef(null);
  const pin_3 = useRef(null);
  const pin_vip_1 = useRef(null);
  const pin_vip_2 = useRef(null);
  const pin_vip_3 = useRef(null);
  const pin_temp = useRef(null);
  const pin_vip_temp = useRef(null);

  const {
    register: profileRegister,
    errors: profileErrors,
    handleSubmit: handleProfileSubmit,
    setValue: setProfileValue,
    getValues: getProfileValues,
    clearErrors: clearProfileErrors,
    watch: profileWatch,
  } = useForm();

  const {
    errors: resetPasswordErrors,
    handleSubmit: handleResetPasswordSubmit,
    watch: resetPasswordWatch,
    setValue: setResetPasswordValue,
    setError: setResetPasswordError,
    clearErrors: clearResetPasswordErrors,
    register: resetPasswordRegister,
  } = useForm();

  const {
    register: codeRegister,
    errors: codeErrors,
    getValues: getCodeValues,
    handleSubmit: handleCodeSubmit
  } = useForm();
    const {
        handleSubmit, register, errors
    } = useForm();

  const { handleSubmit: handlePinSubmit } = useForm();

  const tabPinHeadings = [
    {
      title: "WITHDRAWAL",
      tab_id: 0,
    },
    {
      title: "SHAREHOLDER",
      tab_id: 1,
    },
  ];


  useEffect(() => {
    init();

    return () => {
      setIsContentLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    } else {
      setSentCode(false);
    }
  }, [countdownPeriod]);


    useEffect(() => {
            if (countdownPeriodEmail > 0) {
                setTimeout(() => {
                    let tempPeriod = countdownPeriodEmail - 1;
                    setCountdownPeriodEmail(tempPeriod);
                }, _SECONDS_IN_MS);
            } else {
                setSentCodeEmail(false);
            }
  }, [countdownPeriodEmail]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    };

  async function init() {
    let params = {};
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_MEMBER_PIN_NUMBER,
      createFormBody(params)
    );
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      setCheckPinExist(false);
      setPinCurrentData(1);
    } else {
      setPinCurrentData(0);
    }
    responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            
      if (responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] === true) {
        let responseJson2 = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );

        if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
          setCheckVipPinExist(false);
        }
      }

      setMember(responseJson[ApiKey._API_DATA_KEY]);
      setIsShareholder(responseJson[ApiKey._API_DATA_KEY]["isShareHolder"]);
      setSelectedTheme(responseJson[ApiKey._API_DATA_KEY]["selectedTheme"]);
        setIsPhoneVerified(responseJson[ApiKey._API_DATA_KEY]["isPhoneVerify"]);
        setIsEmailVerified(responseJson[ApiKey._API_DATA_KEY]["isEmailVerify"]);
      setIsContentLoaded(true);
      setProfileValue(
        "username",
        responseJson[ApiKey._API_DATA_KEY]["username"]
      );
      setProfileValue(
        "phoneNumber",
        "+" + responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]
      );
      setFormattedPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
      setMemberFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
      // setMemberEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
      setFormattedEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
      setMemberId(responseJson[ApiKey._API_DATA_KEY]['id']);
      setBirthdayValue(responseJson[ApiKey._API_DATA_KEY]["birthDateString"]);

      var tempPhoneNumber = responseJson[ApiKey._API_DATA_KEY]["phoneNumber"];
      setSelectedPhonePrefix(
        PhoneNumberPrefix.filter((prefix) =>
          tempPhoneNumber.includes(prefix.value)
        ).length > 0
          ? PhoneNumberPrefix.filter((prefix) =>
              tempPhoneNumber.includes(prefix.value)
            )[0].value
          : PhoneNumberPrefix[0].value
      );
      
      setshowFormattedPhone(
        responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].substring(
          //responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].indexOf(
          //    selectedPhonePrefix
          //) + 2,
          PhoneNumberPrefix.filter((x) => tempPhoneNumber.includes(x.value))[0].value.length,
          responseJson[ApiKey._API_DATA_KEY]["phoneNumber"].length
        )
        );
        checkENB(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }
  console.log("12", isShareholder);
  const handleToggleTheme = async (isModernTheme) => {
    let themeId = isModernTheme ? 1 : 0;
    let params = {
      selectedTheme: themeId,
    };
    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_THEME,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(setBusy());
      setSelectedTheme(themeId);
      await _dispatch(updateUserData());
      setShowThemeAlert(true);
      _dispatch(setIdle());
      setTimeout(() => setShowThemeAlert(false), 3000);
    }
  };

  const sendVerificationCode = async () => {
    await ApiEngine.post(
      ApiUrl._API_SEND_VERIFICATION_CODE,
      createMultiPartFormBody({ phoneNumber: formattedPhone })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        let tempTACphoneNumber = response[ApiKey._API_DATA_KEY];
        setTACphoneNumber(tempTACphoneNumber);
        setSentCode(true);
        setCountdownPeriod(_COUNTDOWN_PERIOD);
      }
    });
  };

  const sendVerificationCodeEmail = async () => {
    _dispatch(setBusy());
    await ApiEngine.post(
      ApiUrl._API_SEND_EMAIL_VERIFICATION_CODE,
      createMultiPartFormBody({ email: formattedEmail })
    ).then((response) => {
      if (response[ApiKey._API_SUCCESS_KEY]) {
        setSentCodeEmail(true);
        setCountdownPeriodEmail(_COUNTDOWN_PERIOD);
        setEmailActiveStep(3);
      } else {
        _dispatch(showResponseMessage(response[ApiKey._API_SUCCESS_KEY], response[ApiKey._API_MESSAGE_KEY]));
      }
      _dispatch(setIdle());
    });
  };

  const submitProfileForm = async (data, e) => {
   
    _dispatch(setBusy());
    if (!isPhoneVerified) {
      {
        let verifyJson = await ApiEngine.post(
          ApiUrl._API_VERIFY_PHONE_NUMBER,
          createMultiPartFormBody({
            verificationCode: data.profileVerifyCode,
          })
        );
        if (verifyJson[ApiKey._API_SUCCESS_KEY]) {
          if (!member.userFullName) {
            submitFullName(data);
          }
          submitPhoneNumber();

          setShowVerifyPhoneModal(false);
          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        } else {
          _dispatch(
            showResponseMessage(
              verifyJson[ApiKey._API_SUCCESS_KEY],
              verifyJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      }
    } else {
      if (!member.userFullName) {
        submitFullName(data);
      }
    }
    setSentCode(false);
    _dispatch(setIdle());
  };

  async function submitFullName(data) {
    let params = {
      userFullName: data.profileFullName,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    init();
  }

  async function submitPhoneNumber() {
    // let params = {
    //   phoneNumber: formattedPhone
    // };

    let data = [];

    data["phoneNumber"] = selectedPhonePrefix + showFormattedPhone;
    data["isMemberOperate"] = true;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_MEMBER_PHONE_NUMBER,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    init();
  }

  const submitResetPasswordForm = async (data) => {
    data["memberId"] = _userData.userId;

    var responseJson = await ApiEngine.post(
      ApiUrl._API_MEMBER_RESET_PASSWORD,
      createMultiPartFormBody(data)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setResetPasswordValue("currentPassword", "");
      setResetPasswordValue("password", "");
      setResetPasswordValue("confirmPassword", "");
      clearResetPasswordErrors();
    }
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  };

  const changePin = async () => {
    try {
      let params = {
        oldPinExist: checkPinExist,
        oldPinNumber: oldPinNumber,
        pinNumber: pinNumber,
        confirmPinNumber: pinNumberConfirm,
      };

      //console.log(params);

      var responseJson = await ApiEngine.post(
        ApiUrl._API_SET_MEMBER_PIN_NUMBER,
        createFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCheckPinExist(true);
      }

      setOldPinNumber("");
      setPinNumber("");
      setPinNumberConfirm("");
      setPinCurrentData(0);


      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t(responseJson[ApiKey._API_MESSAGE_KEY])
        )
      );
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  };

  const changeShareholderPin = async () => {
    try {
      let params = {
        oldPinNumber: oldVipPinNumber,
        pinNumber: vipPinNumber,
        confirmPinNumber: vipPinNumberConfirm,
      };

      console.log(params);

      var responseJson = await ApiEngine.post(
        ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCheckVipPinExist(true);
      }
      setPinCurrentData(0);

      setOldVipPinNumber("");
      setVipPinNumber("");
      setVipPinNumberConfirm("");
     

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  };

  function onKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyPressed = '';

    if (currentPinRef === 1) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_1["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setOldPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        }
        else {
          setOldPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_1["current"])) pin_1["current"].clear();
        pin_temp["current"].clear();
        setOldPinNumber("");
        setTempPinNumber("");
        setOldPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else if (currentPinRef === 2) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_2["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else if(pinStr.length < 4) {
          setPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        } else {
          setPinNumber(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_2["current"])) 
        pin_2["current"].clear();
        pin_temp["current"].clear();
        setPinNumber("");
        setTempPinNumber("");
        setPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_temp["current"]["elements"][currentIndex].state.value;
        pin_3["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3 && pinStr.length != 4) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } else if(pinStr.length < 4) {
          setPinNumberConfirm("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowPinEntry(true);
        } else {
          setPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_3["current"])) 
        pin_3["current"].clear();
        pin_temp["current"].clear();
        setPinNumberConfirm("");
        setTempPinNumber("");
        setPinNumberConfirmFocused(0);
        setTempPinNumberFocused(0);
      }
    }
  }

  function onVipKeyPress(key) {
    setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyPressed = '';

    if (currentPinRef === 1) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_1["current"]["elements"][currentIndex].state.value = key.slice(-1);;
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setOldVipPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setOldVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_1["current"]))
        pin_vip_1["current"].clear();
        pin_vip_temp["current"].clear();
        setOldVipPinNumber("");
        setTempPinNumber("");
        setOldVipPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else if (currentPinRef === 2) {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_2["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setVipPinNumber("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setVipPinNumber(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_2["current"]))
          pin_vip_2["current"].clear();
        pin_vip_temp["current"].clear();
        setVipPinNumber("");
        setTempPinNumber("");
        setVipPinNumberFocused(0);
        setTempPinNumberFocused(0);
      }
    } else {
      if (key !== "{bksp}") {
        currentIndex = tempPinNumberFocused;
        // pin_vip_temp["current"]["elements"][currentIndex].focus();
        keyPressed = pin_vip_temp["current"]["elements"][currentIndex].state.value;
        pin_vip_3["current"]["elements"][currentIndex].state.value = key.slice(-1);
        pin_vip_temp["current"]["elements"][currentIndex].state.value = keyPressed;
        pinStr = key;
        setTempPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setTempPinNumberFocused(currentIndex);
        } 
        else if(pinStr.length < 4) {
          setVipPinNumberConfirm("");
          setTempPinNumber("");
          setTempPinNumberFocused(0);
          setShowVipPinEntry(true);
        }
        else {
          setVipPinNumberConfirm(pinStr);
          setTempPinNumber("");
          pin_vip_temp["current"].clear();
          setTempPinNumberFocused(0);
          setShowVipPinEntry(false);
          setTimeout(function () {
            setAllowPinClick(true);
          }, 1000);
        }
      } else {
        if (!stringIsNullOrEmpty(pin_vip_3["current"]))
        pin_vip_3["current"].clear();
        pin_vip_temp["current"].clear();
        setVipPinNumberConfirm("");
        setTempPinNumber("");
        setVipPinNumberConfirmFocused(0);
        setTempPinNumberFocused(0);
      }
    }
  }

  const validatePhoneNumber = (e) => {
    var regexp = /^\d+$/;
    const { value } = e.target;

    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      // setFormattedPhone(e.target.value);

      if (value.startsWith(selectedPhonePrefix)) {
        const repValue = value.replace(selectedPhonePrefix, ""); //replace to space
        const newValue = repValue.replace(/ /g, ""); //replace white space
        setshowFormattedPhone(newValue);

        setFormattedPhone(selectedPhonePrefix + newValue); //177277883
      }
    }
  };

  
    /// <summary>
    /// Author:
    /// </summary>
  async function checkENB(data) {
        if (!data.isEmailVerify || stringIsNullOrEmpty(data["birthDateString"])) {
            setShowVerifyEmailModal(true);
        }
    }

    /// <summary>
    /// Author:
    /// </summary>
  const confirmMessageClick = async () => {
    var a = startDate.toString();
    let parts = a.split(" ");
    let months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12"
    };
    let date = (parts[3] + "-" + months[parts[1]] + "-" + parts[2]);
    setBirthdayString(date);
    if (stringIsNullOrEmpty(formattedEmail)) {
      let success = false
      let message = 'PLS_VERIFIFY_THE_CODE_FIRST'
      _dispatch(showResponseMessage(success, message));
    } else {
      setEmailActiveStep(2);

    }
  }

    const closeForm = async () => {
        setConfirmMessage(false);
        setSentCodeEmail(true);
    }
    /// <summary>
    /// Author: 
    /// </summary>
    const verifyEmail = async () => {
        let returnBool = [];
        await ApiEngine.post(ApiUrl._API_VERIFY_EMAIL, createMultiPartFormBody({ verificationCode: enteredCodeEmail }))
            .then(async (response) => {
                if (response[ApiKey._API_SUCCESS_KEY]) {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                } else {
                    returnBool = [
                        {
                            success: response[ApiKey._API_SUCCESS_KEY],
                            message: response[ApiKey._API_MESSAGE_KEY]
                        }]
                }
            })
        return returnBool;
    }

    const submitForm = async () => {
      _dispatch(setBusy());
        let success = '';
        let message = '';
        if (countdownPeriodEmail > 0) {
            let verifyIsSuccess = await verifyEmail();

            verifyIsSuccess.map((option) => {
                success = option["success"]
                message = option["message"]
            });

            if (success) {
                var event = new Date(startDate);
                let date = JSON.stringify(event);
                date = date.slice(1, 11);
                let params = {
                    "email": formattedEmail,
                    "birthDate": birthdayString
                }
                //if (!stringIsNullOrEmpty(memberId)) {
                //    params["id"] = memberId;
                //}
                let formBody = createFormBody(params);
                _dispatch(setBusy());

                fetch(ApiUrl._API_UPDATE_MEMBER_EMAIL_AND_BIRTH_DATE, {
                    method: ApiKey._API_POST,
                    headers: {
                        'Content-Type': ApiKey._API_FORM_URLENCODED,
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                    },
                    body: formBody
                }).then((response) => response.json()).then((responseJson) => {
                    _dispatch(setIdle());
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                      setShowVerifyEmailModal(false);
                      setEmailActiveStep(1);
                      init();
                    }
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));

                });
            } else {
                _dispatch(showResponseMessage(success, message));

            }
        } else {
            success = false
            message = 'Verification Code Expire Already!'
            _dispatch(showResponseMessage(success, message));
        }
      _dispatch(setIdle());
    }
  const securityListData = [
    {
      id: 1,
      title: 'CHANGE_PASSWORD'
    },
    {
      id: 2,
      title: isShareholder ? 'CHANGE_PIN' : 'CHANGE_WITHDRAWAL_PIN'
    },
  ];

  const tabHeadings = [
    {
      title: "WITHDRAWAL_PIN",
      tab_id: 0,
    },
    {
      title: "CHANGE_SHAREHOLDER_PIN",
      tab_id: 1,
    },
    //{
    //  title: "DOWNLINE_GAMES",
    //  tab_id: 2,
    //},
  ];

  //useEffect(() => {
  //  getLatestGame();
  //}, []);

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  const toggleHiddenCurrentPassword = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
    if (isHiddenCurrentPassword === closedEye) {
      setIsHiddenCurrentPassword(openEye);
    } else {
      setIsHiddenCurrentPassword(closedEye);
    }
  };

  const toggleHiddenRegisterPassword = () => {
    setNewRegisterPasswordVisible(!newRegisterPasswordVisible);
    if (isHiddenRegisterPassword === closedEye) {
      setIsHiddenRegisterPassword(openEye);
    } else {
      setIsHiddenRegisterPassword(closedEye);
    }
  };
  const toggleHiddenRegisterConfirmPassword = () => {
    setConfirmRegisterPasswordVisible(!confirmRegisterPasswordVisible);
    if (isHiddenRegisterConfirmPassword === closedEye) {
      setIsHiddenRegisterConfirmPassword(openEye);
    } else {
      setIsHiddenRegisterConfirmPassword(closedEye);
    }
  };

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "false",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        }
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  //function onVerifyPinKeyPress(key) {
  //  let currentIndex = 0;

  //  let pinStr = enteredPinNumberFinal;
  //  if (key !== "{bksp}") {
  //    if (pinNumberFinal === "") {
  //      currentIndex = pinNumberFinalFocused;
  //      pinStr = key;
  //      setEnteredPinNumberFinal(pinStr);
  //      if (currentIndex !== 3 && pinStr.length != 4) {
  //        currentIndex++;
  //        setPinNumberFinalFocused(currentIndex);
  //        eleFinalPin["current"]["elements"][currentIndex].focus();
  //      }
  //      else if (pinStr.length < 4) {
  //        setPinNumberFinal("");
  //        setPinEntered(false);
  //        setShowPinEntry(true);
  //        setEnteredPinNumberFinal("");
  //        setPinNumberFinalFocused(0);
  //      }
  //      else {
  //        setPinNumberFinal(pinStr);
  //        setPinEntered(true);
  //        setShowPinEntry(false);
  //        verifyPin(pinStr);
  //        eleFinalPin["current"] && eleFinalPin["current"].clear();
  //        setEnteredPinNumberFinal("");
  //        setPinNumberFinalFocused(0);
  //        eleFinalPin["current"] &&
  //          eleFinalPin["current"]["elements"][0].focus();
  //      }
  //    }
  //  } else {
  //    eleFinalPin["current"].clear();
  //    setPinNumberFinal("");
  //    setEnteredPinNumberFinal("");
  //    setPinNumberFinalFocused(currentIndex);
  //    eleFinalPin["current"]["elements"][currentIndex].focus();
  //  }
  //}



  return (
    <div id="settings">
      <section className="section-padding" id="settings-section">
        <ContentHeader title={t("SETTINGS")} />
        <div className="with-information-container">
          <div className="left">
            <div className="template-box-style-1 settings-user">
              <div className="first">
                <div>
                  <img
                    src="/img/version3/user.png"
                    className="img-responsive"
                    alt="edit"
                  />
                </div>
                <div>
                  <div className="title">
                    {_userData && !stringIsNullOrEmpty(_userData.username) ? _userData.username : ""}
                  </div>
                  <div className="details">
                    {/*{!stringIsNullOrEmpty(phoneNumber) ? ("+" + selectedPhonePrefix + phoneNumber) : (member && !stringIsNullOrEmpty(member.phoneNumber)) ? "+" + member.phoneNumber : ""}*/}
                    {!stringIsNullOrEmpty(selectedPhonePrefix) && !stringIsNullOrEmpty(showFormattedPhone) ? ("+" + selectedPhonePrefix + showFormattedPhone) : (member && !stringIsNullOrEmpty(member.phoneNumber)) ? "+" + member.phoneNumber : ""}
                  </div>
                </div>
              </div>
              <div className="second cursor-pointer" onClick={() => _history.push(WebUrl._URL_EDIT_PROFILE)}>
                <img
                  src="/img/version3/edit.png"
                  className="img-responsive"
                  alt="edit"
                />
              </div>
            </div>
            {!isPhoneVerified ? (
              <div className="reminder-box mt-4">
                <div>
                  <img
                    src="/img/version3/i.png"
                    className="img-responsive"
                    alt="icon"
                  />
                </div>
                <div className="second">
                  <div className="title">
                    {t("YOUR_PHONE_IS_NOT_VERIFIED")}
                  </div>
                  <div className="link" onClick={() => { setShowVerifyPhoneModal(true); sendVerificationCode(); }}>
                    {t("SEND_VERIFICATION_CODE")}
                  </div>
                </div>
              </div>
            ) : (
                <>
                </>
            )}
            
            <div className="hr-left-blue mt-4 mb-4"></div>
            <h3 className="mb-4">{t("SECURITY")} & { t("PIN")}</h3>
            {securityListData &&
              securityListData.length &&
              map(securityListData, (item, index) => {
                return (
                  <>
                    <div key={item.id} className="template-box settings-accordion-item mb-4">
                      <div
                        className="settings-acc-title"
                        data-toggle="collapse"
                        data-target={"#security" + index}
                      >
                        <div>
                          {t(item.title)} 
                        </div>
                        <div className="acc-arrows">
                          <img
                            src="/img/version3/next-gray.png"
                            className="img-responsive img1"
                            alt="accordion"
                          />
                          <img
                              src="/img/version3/select-arrow-blue-up.png"
                              className="img-responsive img2"
                              alt="icon"
                            />
                        </div>
                      </div>

                      {item.id==1 && (
                        <>
                          <div
                            id={"security" + index}
                            className="collapse settings-acc-details settings-form"
                          >
                            <form onSubmit={handleResetPasswordSubmit(submitResetPasswordForm)}>
                            <div>
                              <label className="mt-4">{t("CURRENT_PASSWORD")}</label>
                              <div className="input-with-icon-container">
                                <input
                                  type={currentPasswordVisible ? 'text' : 'password'}
                                  className="custom-input"
                                  placeholder={t("CURRENT_PASSWORD")}
                                  name={"currentPassword"}
                                  autoComplete={"off"}
                                  ref={resetPasswordRegister({
                                    required: 'REQUIRED_OLD_PASSWORD'
                                  })}
                                  value={resetPasswordWatch('currentPassword') || ""}
                                  onInput={(e) => {
                                    if (e.target.value === resetPasswordWatch("password")) {
                                      setResetPasswordError("password", { message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD" });
                                    }
                                    else {
                                      clearResetPasswordErrors("password");
                                    }
                                    setResetPasswordValue('currentPassword', e.target.value);
                                  }}
                                  errors={resetPasswordErrors}
                                />
                                <div className="input-icon">
                                  <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                </div>
                                <div className="input-hidden-icon" onClick={toggleHiddenCurrentPassword}>
                                  <img src={isHiddenCurrentPassword} alt="password" className="img-responsive" />
                                </div>
                              </div>
                              <label className="mt-4">{t("NEW_PASSWORD")}</label>
                              <div className="input-with-icon-container">
                                <input
                                  type={newRegisterPasswordVisible ? 'text' : 'password'}
                                  className="custom-input"
                                  placeholder={t("ENTER_NEW_PASSWORD")}
                                  name={"password"}
                                  ref={resetPasswordRegister({
                                    required: 'REQUIRED_NEW_PASSWORD'
                                  })}
                                  value={resetPasswordWatch('password') || ""}
                                  onInput={(e) => {
                                    if (e.target.value === resetPasswordWatch("currentPassword")) {
                                      setResetPasswordError("password", { message: "NEW_PASSWORD_SAME_AS_OLD_PASSWORD" });
                                    }
                                    else {
                                      clearResetPasswordErrors("password");
                                    }
                                    setResetPasswordValue('password', e.target.value);
                                  }}
                                  errors={resetPasswordErrors}
                                />
                                <div className="input-icon">
                                  <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                </div>
                                <div className="input-hidden-icon" onClick={toggleHiddenRegisterPassword}>
                                  <img src={isHiddenRegisterPassword} alt="password" className="img-responsive" />
                                </div>
                              </div>
                              <label className="mt-4">{t("CONFIRM_NEW_PASSWORD")}</label>
                              <div className="input-with-icon-container">
                                <input
                                  type={confirmRegisterPasswordVisible ? 'text' : 'password'}
                                  className="custom-input"
                                  placeholder={t("ENTER_CONFIRM_NEW_PASSWORD")}
                                  name={"confirmPassword"}
                                  ref={resetPasswordRegister({
                                    required: 'REQUIRED_CONFIRM_NEW_PASSWORD',
                                    validate: (value) => value == resetPasswordWatch("password") || "PASSWORD_MISMATCH"
                                  })}
                                  value={resetPasswordWatch('confirmPassword') || ""}
                                  onInput={(e) => { setResetPasswordValue('confirmPassword', e.target.value); }}
                                  errors={resetPasswordErrors}
                                />
                                <div className="input-icon">
                                  <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                </div>
                                <div className="input-hidden-icon" onClick={toggleHiddenRegisterConfirmPassword}>
                                  <img src={isHiddenRegisterConfirmPassword} alt="confirm password" className="img-responsive" />
                                </div>
                              </div>
                              {/*  <div class="modal-small-p mt-4 text-center" onClick={() => { setForgetPassword(true);  }}><a>{t("FORGOT_PASSWORD_QUES")}</a></div>*/}
                              <div className="mt-4">
                                <button type="submit" className="btn custom-btn">{t("SAVE")}</button>
                              </div>
                            </div>
                            </form>
                          </div>
                        </>
                      )}

                      {item.id==2 && (
                        <>
                          <div
                            id={"security" + index}
                            className="collapse settings-acc-details settings-form"
                          >
                            {isShareholder && <div className="custom-two-header-tab-container mt-4">
                              {tabHeadings &&
                                tabHeadings.length &&
                                tabHeadings
                                  .map((item, index) => {
                                    return (
                                      <div
                                        key={"downline-tab-" + index}
                                        onClick={() => setCurrentActiveTab(item.tab_id)}
                                        className={currentActiveTab == item.tab_id ? 'active' : ''}
                                      >
                                        {t(item.title)}
                                      </div>
                                    );
                                  })
                              }
                            </div>}
                            
                            {currentActiveTab == 0 && (
                              <>
                              <form onSubmit={handlePinSubmit(changePin)}>
                                { pinCurrentData == 0 && (
                                  <>
                                    <div>
                                        <label className="mt-4">{t("CURRENT_WITHDRAWAL_PIN")}</label>
                                      <div className="input-with-icon-container">
                                        <input
                                            type={currentPasswordVisible ? 'text' : 'password'}
                                            className="custom-input"
                                            placeholder={t("CURRENT_WITHDRAWAL_PIN")}
                                            autoComplete={"off"}
                                            onChange={(e) => setOldPinNumber(e.target.value)}
                                            value={oldPinNumber}
                                        />
                                        <div className="input-icon">
                                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                        </div>
                                        <div className="input-hidden-icon" onClick={toggleHiddenCurrentPassword}>
                                          <img src={isHiddenCurrentPassword} alt="password" className="img-responsive" />
                                        </div>
                                      </div>
                                        <div className="mt-4">
                                          <button className="btn custom-btn" disabled={oldPinNumber.length != 4} onClick={() => setPinCurrentData(1)}>Next</button>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {pinCurrentData == 1 && (
                                  <>
                                    <div>
                                        <label className="mt-4">{t("SET_NEW_PIN")}</label>
                                      <div className="input-with-icon-container">
                                        <input
                                          type={newRegisterPasswordVisible ? 'text' : 'password'}
                                          className="custom-input"
                                            placeholder="new password"
                                            onChange={(e) => setPinNumber(e.target.value)}
                                            value={pinNumber}
                                        />
                                        <div className="input-icon">
                                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                        </div>
                                        <div className="input-hidden-icon" onClick={toggleHiddenRegisterPassword}>
                                          <img src={isHiddenRegisterPassword} alt="password" className="img-responsive" />
                                        </div>
                                      </div>

                                        <label className="mt-4">{t("CONFIRM_NEW_PIN")}</label>
                                      <div className="input-with-icon-container">
                                        <input
                                            type={confirmRegisterPasswordVisible ? 'text' : 'password'}
                                            className="custom-input"
                                            placeholder="confirm new password"
                                            onChange={(e) => setPinNumberConfirm(e.target.value)}
                                            value={pinNumberConfirm}
                                        />
                                        <div className="input-icon">
                                          <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                        </div>
                                        <div className="input-hidden-icon" onClick={toggleHiddenRegisterConfirmPassword}>
                                          <img src={isHiddenRegisterConfirmPassword} alt="confirm password" className="img-responsive" />
                                        </div>
                                      </div>
                                        <div className="mt-4">
                                          <button className="btn custom-btn" disabled={pinNumber.length != 4 && pinNumberConfirm.length != 4} type="submit">{t("SAVE")}</button>
                                      </div>
                                    </div>
                                  </>
                                )}
                                </form>
                              </>
                            )}

                            {isShareholder && currentActiveTab == 1 && (
                              <>
                                <form onSubmit={handlePinSubmit(changeShareholderPin)}>
                                  {pinCurrentData == 0 && (
                                    <>
                                      <div>
                                        <label className="mt-4">{t("CURRENT_SHAREHOLDER_PIN")}</label>
                                        <div className="input-with-icon-container">
                                          <input
                                            type={currentPasswordVisible ? 'text' : 'password'}
                                            className="custom-input"
                                            placeholder={t("CURRENT_SHAREHOLDER_PIN")}
                                            autoComplete={"off"}
                                            onChange={(e) => setOldVipPinNumber(e.target.value)}
                                            value={oldVipPinNumber}
                                          />
                                          <div className="input-icon">
                                            <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                          </div>
                                          <div className="input-hidden-icon" onClick={toggleHiddenCurrentPassword}>
                                            <img src={isHiddenCurrentPassword} alt="password" className="img-responsive" />
                                          </div>
                                        </div>

                                        <div class="modal-small-p mt-4 text-center">
                                          {forgetPin && countdownPeriod > 0 ? (
                                            <p
                                              className="text-brand"
                                              style={{
                                                textAlign: "center",
                                                marginTop: "5px",
                                              }}
                                            >
                                              {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "} {t("SECOND")}
                                            </p>
                                          ) : (
                                            <a
                                              className="text-brand display-value"
                                              style={{ cursor: "pointer", paddingTop: '30px' }}
                                              onClick={() => {
                                                setForgetPin(true);
                                                sendPinCode();
                                              }}
                                            >
                                              {t("RESET_PIN")}
                                            </a>
                                          )}
                                        </div>
                                        <div className="mt-4">
                                          <button className="btn custom-btn" disabled={oldVipPinNumber.length != 4} onClick={() => setPinCurrentData(1)}>Next</button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {pinCurrentData == 1 && (
                                    <>
                                      <div>
                                        <label className="mt-4">{t("SET_SHAREHOLDER_NEW_PIN")}</label>
                                        <div className="input-with-icon-container">
                                          <input
                                            type={newRegisterPasswordVisible ? 'text' : 'password'}
                                            className="custom-input"
                                            placeholder={t("SET_SHAREHOLDER_NEW_PIN")}
                                            onChange={(e) => setVipPinNumber(e.target.value)}
                                            value={vipPinNumber}
                                          />
                                          <div className="input-icon">
                                            <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                          </div>
                                          <div className="input-hidden-icon" onClick={toggleHiddenRegisterPassword}>
                                            <img src={isHiddenRegisterPassword} alt="password" className="img-responsive" />
                                          </div>
                                        </div>

                                        <label className="mt-4">{t("CONFIRM_SHAREHOLDER_NEW_PIN")}</label>
                                        <div className="input-with-icon-container">
                                          <input
                                            type={confirmRegisterPasswordVisible ? 'text' : 'password'}
                                            className="custom-input"
                                            placeholder={t("CONFIRM_SHAREHOLDER_NEW_PIN")}
                                            onChange={(e) => setVipPinNumberConfirm(e.target.value)}
                                            value={vipPinNumberConfirm}
                                          />
                                          <div className="input-icon">
                                            <img src="/img/version3/register-login/key.png" alt="username" className="img-responsive" />
                                          </div>
                                          <div className="input-hidden-icon" onClick={toggleHiddenRegisterConfirmPassword}>
                                            <img src={isHiddenRegisterConfirmPassword} alt="confirm password" className="img-responsive" />
                                          </div>
                                        </div>
                                        <div className="mt-4">
                                          <button className="btn custom-btn" disabled={vipPinNumber.length != 4 || vipPinNumberConfirm.length != 4} type="submit">{t("SAVE")}</button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </form>
                              </>
                            )}
                            
                          </div>
                        </>
                      )}
                      
                    </div>
                  </>
                );
              })
            }

            <h3 className="mb-4">{t("HELP_AND_SUPPORT")}</h3>
            <div className="template-box mb-4 settings-item-box" onClick={() => _history.push(WebUrl._URL_HELP_CENTRE)}>
              <div>
                {t("HELP_CENTRE") }
              </div>
              <div>
                <img
                  src="/img/version3/next-gray.png"
                  className="img-responsive"
                  alt="accordion"
                />
              </div>
            </div>

            {/*<><h3 className="mb-4">{t("LANGUAGE_AND_REGION")}</h3>
            <div className="template-box mb-4 settings-item-box">
              <div className="color-main">
                Malaysia  l  English
              </div>
            </div></>*/}
          </div>
          <div className="right">
            <RightInformation
              button={true}
              buttonText="WALLET_INFO"
              games={true}
              //gamesTitle="LATEST_GAME"
              //gamesData={latestGame}
              promotion={true}
            />
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"SETTINGS"} />
            </div>
            <div className="col-9">
              <ContentHeader title={t("SETTINGS")} />
              <hr className="right-title-hr" />

              {settingList &&
                settingList.length &&
                map(settingList, (item, index) => {
                  return <MenuRowItem key={"settings_" + index} item={item} />;
                })}
            </div>
          </div>
        </div> */}
      </section>

      <Modal
        isOpen={showVerifyPhoneModal}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowVerifyPhoneModal(false);
        }}
        centered
      >
        <ModalBody>
          <form onSubmit={handleCodeSubmit(submitProfileForm)}>
          <div className="text-center">
            <div className="phone-number-verified-div">
              <div style={{ fontSize: '20px' }} >{t("PHONE_NUMBER_VERIFIED")}</div>
              <div className="phone-number-text">{t("CODE_SENT_TO_NUMBER", { phoneNumber: "+" + selectedPhonePrefix + showFormattedPhone })}</div>
              <div>
                <input
                  type="password"
                  className="custom-input"
                  placeholder={t("ENTER_VERIFICATION_CODE")}
                  autoComplete={"off"}
                  style={{ padding:'0px 20px 0px 25px' }}
                  name="profileVerifyCode"
                  ref={codeRegister({
                    required: 'FIELD_REQUIRED',
                    pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                  })}
                />
              </div>
              {sentCode && countdownPeriod != 0 ? (
                <div className="phone-number-text">{t("RESET_CODE_IN_SECONDS", { value: countdownPeriod })}</div>
              ) : (
                  <div className="phone-number-text" onClick={() => sendVerificationCode() }>{t("SEND_VERIFICATION_CODE")}</div>
              )
              }
              <div><button type="submit" className="btn custom-btn">{t("SEND")}</button></div>
            </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showVerifyEmailModal}
        id="modal-small-custom"
        fade={true}
        toggle={() => {
          setShowVerifyEmailModal(false);
        }}
        centered
      >
        <ModalBody>
          {emailActiveStep === 1 && (
            <>
              <div className="text-center">
                <div className="phone-number-verified-div">
                  <div style={{ width: '300px', alignSelf: 'center' }}>
                    <div style={{ fontSize: '20px' }} >{t("UPDATE_EMAIL_AND_BIRTH_DATE")}</div>
                    <div className="phone-number-text">{t("UPDATE_EMAIL_AND_BIRTH_DATE_TEXT")}</div>
                  </div>
                  <div style={{ textAlign: 'left' }}>{t("ENTER_BIRTH_DATE")}</div>
                  <div style={{ marginTop: '-15px' }}>
                    <DatePicker
                      type="date"
                      className="birthdate-custom-input"
                      placeholderText={t("SELECT_BIRTH_DATE")}
                      selected={startDate}
                      maxDate={moment().subtract(18, 'years')._d}
                      onChange={date => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <div style={{ textAlign: 'left' }}>{t("EMAIL")}</div>
                  <div style={{ marginTop: '-15px' }}>
                    <input
                      type='text'
                      className="custom-input"
                      style={{ padding: '0px 20px 0px 25px' }}
                      placeholder={t("EMAIL")}
                      readOnly={isEmailVerified}
                      value={formattedEmail}
                      autoComplete={"off"}
                      ref={profileRegister({
                        required: "PLEASE_ENTER_EMAIL",
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "INVALID_EMAIL"
                        }
                      })}
                      errors={profileErrors}
                      onChange={(e) => {
                        setFormattedEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div onClick={() => confirmMessageClick()}><button type="submit" disabled={stringIsNullOrEmpty(formattedEmail) || stringIsNullOrEmpty(startDate)} className="btn custom-btn">{t("CONTINUE")} </button></div>
                </div>
              </div>
            </>
          )}

          {emailActiveStep === 2 && (
            <>
              <div className="text-center">
                <div className="phone-number-verified-div">
                  {t("ARE_YOU_CONFIRM_TO_SELECT_AS_YOUR_BIRTHDAY", { value: birthdayString })}
                  <br />
                  {t("CANNOT_BE_CHANGED_AFTER_CONFIRMATION")}
                </div>
                <div className="confirm-btn-div" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                  <div>
                    <button type="submit" style={{ background: 'red' }} className="btn custom-btn" onClick={() => setEmailActiveStep(1)}>{t("CLOSE")} </button>
                  </div>
                  <div onClick={() => sendVerificationCodeEmail()}>
                    <button type="submit" className="btn custom-btn">{t("CONFIRM")} </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {emailActiveStep === 3 && (
            <>
              <div className="text-center">
                <div className="phone-number-verified-div">
                  <div style={{ fontSize: '20px' }} >{t("EMAIL_VERIFICATION_CODE")}</div>
                  <div className="phone-number-text">{t("CODE_SENT_TO_NUMBER", { phoneNumber: formattedEmail })}</div>
                  <div>
                    <input
                      type="password"
                      className="custom-input"
                      placeholder={t("ENTER_VERIFICATION_CODE")}
                      autoComplete={"off"}
                      style={{ padding: '0px 20px 0px 25px' }}
                      name="profileVerifyCode"
                      onChange={(e) => setEnteredCodeEmail(e.target.value)}
                      ref={profileRegister({
                        required: 'FIELD_REQUIRED',
                        pattern: { value: /^[0-9]+$/, message: "INVALID_VERIFICATION_CODE" }
                      })}
                    />
                  </div>
                  {sentCodeEmail && countdownPeriodEmail != 0 ? (
                    <div className="phone-number-text">{t("RESET_CODE_IN_SECONDS", { value: countdownPeriodEmail })}</div>
                  ) : (
                    <div className="phone-number-text" onClick={() => sendVerificationCodeEmail()}>{t("SEND_VERIFICATION_CODE")}</div>
                  )}

                  <div onClick={() => submitForm()}><button type="submit" className="btn custom-btn" disabled={stringIsNullOrEmpty(formattedEmail) || stringIsNullOrEmpty(enteredCodeEmail) || enteredCodeEmail.length < 6 || enteredCodeEmail.length > 6}>{t("VERIFY")}</button></div>
                </div>
              </div>
            </>
          )}

        </ModalBody>
      </Modal>

      <Modal
        fade={false}
        contentClassName="transaction-pin-modal"
        isOpen={forgetPassword}
        toggle={() => {
          setForgetPassword(false);
        }}
        centered
      >
        <ModalBody>
          {forgetPinActiveStep == 1 && ( //forgetMemberPassword
            <>
              <div className="text-center">
                <div className="transaction-pin">
                  <div className="title">
                    {t("ENTER_YOUR_PIN")}
                  </div>
                  <div className="custom-pin-container mt-4">
                    <PinInput
                      length={6}
                      autocomplete={"off"}
                      initialValue=""
                      secret={true}
                      autoSelect={true}
                      disabled={false}
                      type="numeric"
                      inputMode="number"
                      regexCriteria={/^[ 0-9_@./#&+-]*$/}
                      
                      inputFocusStyle={{
                        borderBottom: "2px solid #FFC159",
                      }}
                    />
                  </div>
                  {sentCodeEmail && countdownPeriodEmail != 0 ? (
                    <div className="send-verification-code">{t("RESET_CODE_IN_SECONDS", { value: countdownPeriodEmail })}</div>
                  ) : (
                      <div className="send-verification-code" onClick={() => sendVerificationCodeEmail()}>{t("SEND_VERIFICATION_CODE")}</div>
                  )}
       
                    <button type="submit" className="btn custom-btn mt-4">{t("VERIFY")}</button>
              
                </div>
              </div>
            </>
          )}


        </ModalBody>



      </Modal>
    </div>
  );
};
export default Settings;
