import React, { useState, useEffect, useCallback } from "react";
import "react-simple-keyboard/build/css/index.css";
import { debounce, find } from "lodash";
import ApiEngine from "../../util/ApiEngine.js";
import { useHistory, useParams } from "react-router-dom";
import {
  AlertTypes,
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
  LanguageOption,
  SessionKey
} from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { setGameQuery, setGameSearchText } from "../../redux/GameAction.js";
import { showMessage } from "../../redux/AppAction.js";
import { stringIsNullOrEmpty } from "../../util/Util.js";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";
import ContentHeader from "components/content/header";

const GameList = (props) => {
  const { t } = useTranslation();
  const { provider } = useParams();
  var _dispatch = useDispatch();

  const [lobbyName, setLobbyName] = useState("PlayTech%20Slots");
  const [masterProductId, setMasterProductId] = useState(38);
  ///from mobile app
  const [games, setGames] = useState([]);
  const [initCheck, setInitCheck] = useState(false);
  const [providerLogo, setProviderLogo] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [iconImage, setIconImage] = useState("");
  const [gameList, setGameList] = useState([]);
  let _savedQuery = useSelector((state) => state.gameState.gameQuery);
  let _savedData = useSelector((state) => state.appState.gameSavedText);
  const [keyword, setKeyword] = useState("");
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [productCategory, setProductCategory] = useState([]);
  const [apiQuery, setApiQuery] = useState("");
  const api = ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_PROVIDER_V3_DESKTOP;



  let _history = useHistory();


  //const selectedGameProvider = useSelector((state) =>
  //  find(state.gameState.gameProviders, (item) => item.gameName === provider)
  //);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const _userData = useSelector((state) => state.authState.userData);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    getProductGameList();
  }, [provider,keyword, localStorage.getItem(SessionKey._LANGUAGE)]);

  async function getProductGameList() {

    if (!stringIsNullOrEmpty(provider)) {
      let apiUrl = `${api}?providerName=${provider}`;

      if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
        if (LanguageOption.find(
          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
          apiUrl += "&languageId=" + LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).languageId;
        }
        else {
          apiUrl += "&languageId=1";
        }
      } else {
        apiUrl += "&languageId=1";
      }

      if (!stringIsNullOrEmpty(keyword)) {
        apiUrl += "&keyword=" + keyword;
      }

      if (isLoggedIn) {
        apiUrl += "&memberId=" + _userData.userId;
      }

      setApiQuery(apiUrl);
    }
  }

  async function startGame(gameId, isApp, categoryTitle) {
    if (isSpecialRollover) {
      let productCategoryListArr = await getBonusDepositProductCategory();
      if (productCategoryListArr.includes(categoryTitle)) {
        window.open(
          `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
          }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
        );
      }
      else {
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("ROLLOVER_EXIST"),
            onConfirm: () => {
            },
          })
        );
      }
    }
    else {
      window.open(
        `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
        }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
      );
    }
  }

  const searchOnChange = useCallback(
    debounce(async (keyword) => {
      _dispatch(setGameSearchText(keyword.target.value));
      setKeyword(keyword.target.value);
    }, 500),
    []
  );

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      [...productCategory].forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.label);
        }
      })

      return tempArr;
    }
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title });
        }
      });
      setProductCategory(tempArr);
    }
  }

  return (
    <>
      <section className="text-white" id="sort-section">
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="sort-search-box mt-4">
                <div>
                  <ContentHeader title={provider} />
                </div>
                <div className="position-relative">
                  <input
                    type="text"
                    name="search"
                    className="form-white-input"
                    placeholder={t("SEARCH")}
                    onChange={searchOnChange}
                  />
                  <div className="video-search-icon">
                    <img
                      src="/img/search.png"
                      className="img-100"
                      alt="banner-main"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sort-games-section section-padding pb-0 mb-3 text-white">
        <div className="product-games-list-container mt-4" >
          <DynamicLoadTable
            pageSize={24}
            apiQuery={apiQuery}
            searchEnabled={true}
            autoscrollEnabled={true}
            render={(game, i) => {
              return (
                <div className="product-games-list-items">
                  <div className={"product-game-box desktop " + (isLoggedIn ? game["gameUm"] === true ? "game-um" : "cursor-pointer" : "")} onClick={() => {
                    if (isLoggedIn && game["gameUm"] !== true) {
                      startGame(game["id"], game["isApp"], game["categoryTitle"]);
                    }
                    else if (!isLoggedIn) {
                      _dispatch(
                        showMessage({
                          type: AlertTypes._INFO,
                          content: t("PLEASE_LOGIN_FIRST"),
                          onConfirm: () => {
                          },
                        })
                      );
                    }
                  }}>
                    {game["isNewGame"] || game["isRecommended"] ? (
                      <div className="tag">
                        <img
                          src={
                            game["isNewGame"]
                              ? "/img/assets/new.svg"
                              : "/img/assets/hot.svg"
                          }
                          className="img-responsive"
                          alt={game["isNewGame"] ? "new" : "hot"}
                          loading="lazy"
                        />
                      </div>
                    ) : null}

                    <div
                      className="product-game-image"
                      style={{
                        backgroundImage:
                          (isLoggedIn
                            ? game["gameUm"] == true
                              ? "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), "
                              : " "
                            : " ") /*+ `url(${encodeURI(game["image"])})`*/,
                        position: "relative",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "16px",
                      }}
                    >
                      {isLoggedIn && game["gameUm"] == true && (
                        <>
                          <div
                            style={{
                              backgroundImage: `url(${UMIcon})`,
                              zIndex: 1
                            }}
                            className="v2-um-tag-icon"
                          ></div>
                        </>
                      )}
                      <img
                        src={game["image"]}
                        alt="Game provider"
                        style={{
                          position: "relative",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "16px",
                          opacity: game["gameUm"] == true ? "0.7" : "1"
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="product-game-name">
                      {game["gameName"]}
                    </div>
                  </div>
                </div>
              );
            }}
          ></DynamicLoadTable>
        </div>
      </section>
    </>
  );
};
export default GameList;
