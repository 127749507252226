import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import ContentHeader from "components/content/header";
import Breadcrumb from "components/custom/Breadcrumb";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { map } from "lodash";

const TipsHistory = () => {
    const breadcrumb = [
        {
          value: 'Live Chat',
          active: false,
        },
        {
          value: 'Give us some tips',
          active: false,
        },
        {
          value: 'Tips History',
          active: true,
        },
      ];
      const tipsData = [
        {
          title: 'Tips to Telegram: CS 41',
          amount: '100 THB',
          date: '04112023  l  12:55:38PM'
        },
        {
          title: 'Tips to Whatsap: demo',
          amount: '1,000 THB',
          date: '04112023  l  12:55:38PM'
        },
        {
          title: 'Tips to Telegram: CS1.2',
          amount: '500 THB',
          date: '04112023  l  12:55:38PM'
        },
      ];
  return (
    <>
        <section className="section-padding">
            <Breadcrumb
                data={breadcrumb}
                // escButtonOnClick={() => window.history.go(-1)}
            />
            <div>
              <DateRangeFilter
                options={true}
              />
            </div>
            <div className="custom-box-list mt-5" id="tips-report-list">
                {tipsData &&
                    tipsData.length &&
                    map(tipsData, (item, index) => {
                    return (
                        <div className="custom-item-box mb-4">
                            <div className="first">
                                <div className="custom-icon-box">
                                    <img
                                        src="/img/version3/tips-dollar.png"
                                        alt="tips"
                                    />
                                </div>
                                <div>
                                    <div className="title">
                                        {item.title}
                                    </div>
                                    <div className="date">
                                        {item.title}
                                    </div>
                                </div>
                            </div>
                            <div className="second">
                                <div className="title">
                                    {item.amount}
                                </div>
                            </div>
                        </div>
                    );
                    })
                }
            </div>
        </section>
        
    </>
  );
};
export default TipsHistory;
