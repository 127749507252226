import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ContentHeader from "components/content/header";
import RightInformation from "../../components/custom/RightInformation";
import { walletInformation, recentTransaction, latestGames } from "../../config/game-configs";
import { map } from "lodash";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";

const Withdrawal = (props) => {
  const { t } = useTranslation();
  var _dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    getBonusData();
  }, []);

  async function getBonusData() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setTransactionData(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }
  const reportsMenu = [
    {
      title: 'Wallet Statement',
      icon: "/img/version3/reports/1.png",
      url: '#'
    },
    {
      title: 'Bonus Report',
      icon: "/img/version3/reports/2.png",
      url: '#'
    },
    {
      title: 'Rollover Statement',
      icon: "/img/version3/reports/3.png",
      url: '#'
    },
    {
      title: 'Game Record',
      icon: "/img/version3/reports/2.png",
      url: '#'
    },
  ];
  return (
    <>
            <h2 class="title-with-blue-under mb-4 mt-4">
              {t("ROLLOVER_STATEMENT")}
            </h2>
            <div>
              {transactionData && transactionData.length === 0 ? (
                <div className="text-white">{t("NO_DATA_FOUND")}</div>
              ) : (
                transactionData.map((item, i) => {
                  var statusHtml = "";
                  statusHtml =
                    item["isLatestRecord"] == 1 ? (
                      <span
                        className={
                          item["RolloverProgress"] == item["RolloverTarget"]
                            ? "badge badge-secondary badge-green"
                            : "badge badge-secondary badge-danger"
                        }
                      >
                        {t(item["status"])}
                      </span>
                    ) : (
                      <span className="badge badge-secondary badge-danger">
                        {t(item["status"])}
                      </span>
                    );
                  return (
                    <div
                      key={"rollover_statement_" + i}
                      className="template-box"
                      id="rollover-statement-box"
                    >
                      <div className="text-white rollover-box">
                        <div>
                          <span className="date">{item["date"]}</span>
                        </div>
                        <div>{statusHtml}</div>
                      </div>
                      <div className="text-white rollover-box">
                        <div>
                          <span className="title">
                            {t("AMOUNT")}
                          </span>
                        </div>
                        <div>
                          <span className="details">
                            {item["amount"]}
                          </span>
                        </div>
                      </div>
                      <div className="text-white rollover-box">
                        <div>
                          <span className="title">
                            {t("ROLLOVER_PROGRESS")}
                          </span>
                        </div>
                        <div>
                          <span className="details">
                            {item["isLatestRecord"] == 1
                              ? item["rolloverProgress"]
                              : item["rolloverTarget"]}{" "}
                            / {item["rolloverTarget"]}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
    </>
  );
};
export default Withdrawal;
