import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { isNull, map } from "lodash";
import ApiCms from "util/ApiCms";
import { useHistory, useRouteMatch } from "react-router-dom";
import { WebUrl, SessionKey, LanguageOption } from "../../util/Constant";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";

const HelpCentre = (props) => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch(WebUrl._URL_HELP_CENTRE_SUB_CATEGORY);
  const { category: Parent_Category } = params;
  const { sub_category: Sub_Category } = params;

  const [categoryID, setCategoryID] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const [scrollTop, setScrollTop] = useState(0);
  const [isShowHelpCentreCategory, setIsShowHelpCentreCategory] =
    useState(false);
  const [breadcrumbs, updateBreadcrumbs] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  var _dispatch = useDispatch();
  // const [lang, setLang] = useState("");
  let _history = useHistory();

  const lang = (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
  (LanguageOption.find(
    (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).code : 
      LanguageOption[0].code
    ): 
    LanguageOption[0].code;

  const onClick = (breadcrumbItems) => {
    updateBreadcrumbs((arr) => [...arr, breadcrumbItems]);
  };

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener("scroll", (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      });
    };
    scrollEvent();

    return () => {
      // window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    fetchData();

    // setLang(
    //   LanguageOption.find(
    //     (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    //   ).code
    // );
  }, [
    // (lang)
    (localStorage.getItem(SessionKey._LANGUAGE)&& localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ) ? 
  (LanguageOption.find(
    (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined? 
      LanguageOption.find(
      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
      ).code : 
      LanguageOption[0].code
    ): 
    LanguageOption[0].code
    
    // LanguageOption.find(
    //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
    // ).code,
  ]);

  async function fetchData() {
    _dispatch(setBusy());

    const { data } = await ApiCms.get(
      "/sub-categories" +
        "?locale=" + 
        (lang)
        // LanguageOption.find(
        //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
        // ).code
         +
        "&populate=*"
    );
    const { data: categoriesList } = data;
    setSubCategories(categoriesList);

    {
      categoriesList.length &&
        categoriesList.map((item, index) => {
          if (item.attributes.slug === Sub_Category)
            setSubCategoryID(item.attributes.sub_category_id);
        });
    }

    _dispatch(setIdle());
  }

  useEffect(() => {
    const fetchParent = async () => {
      const { data } = await ApiCms.get(
        "/categories" +
          "?locale=" +
          (lang)
          // LanguageOption.find(
          //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          // ).code 
          +
          "&populate=*"
      );
      const { data: parentCategoriesList } = data;
      setParentCategories(parentCategoriesList);

      {
        parentCategoriesList.length &&
          parentCategoriesList.map((item, index) => {
            if (item.attributes.slug === Parent_Category)
              setCategoryID(item.attributes.category_id);
          });
      }
    };
    fetchParent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    setSubCategory(subCategoryID);
    setParentCategory(categoryID);
  }, [params]);

  async function GoToCategory(slug, category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", slug),
        state: {
          category_id: category_id,
        },
      });
    }
  }

  async function GoToSubCategory(slug, sub_slug, category_id, sub_category_id) {
    if (slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
          .replace(":category", slug)
          .replace(":sub_category", sub_slug),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
        },
      });
    }
  }

  async function GoToFaq(
    slug,
    sub_slug,
    faq,
    category_id,
    sub_category_id,
    faq_id
  ) {
    if (sub_slug) {
      _history.push({
        pathname: WebUrl._URL_HELP_CENTRE_FAQ
          .replace(":category", slug)
          .replace(":sub_category", sub_slug)
          .replace(":faq", faq),
        state: {
          category_id: category_id,
          sub_category_id: sub_category_id,
          faq_id: faq_id,
        },
      });
    }
  }

  const breadcrumb = [
    {
      value: 'SETTINGS',
      active: false,
      link: WebUrl._URL_SETTINGS
    },
    {
      value: 'HELP_CENTRE',
      active: false,
      link: WebUrl._URL_HELP_CENTRE
    }
  ];

  const breadcrumb2 = [
    {
      value: 'Getting Started',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "getting-started"
    },
    {
      value: 'General',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "general"
    },
    {
      value: 'Welcome to i8 Live!',
      active: false,
      link: "",
      slug: "welcome-to-i8-live"
    },
    {
      value: 'Why choose i8?',
      active: false,
      link: "",
      slug: "why-choose-i8"
    },
    {
      value: 'Game',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "game"
    },
    {
      value: 'How to Guide',
      active: true,
      link: "",
      slug: "how-to-guide"
    },
    {
      value: 'General Betting Rules',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "general-betting-rules"
    },
    {
      value: 'Rules for games',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "rules-for-games"
    },
    {
      value: 'Slots',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "slots"
    },
    {
      value: 'Other',
      active: false,
      link: WebUrl._URL_HELP_CENTRE,
      slug: "other"
    },
    {
      value: 'Terms & Conditions',
      active: true,
      link: "",
      slug: "terms-and-conditions"
    },
  ]

  return (
    <>
      <section className="help-centre-section">
        <div style={{ display: "flex" }}>
          <Breadcrumb
            data={breadcrumb}
          />
          <div class="text-right-arrow-new"> {t(">")} </div>
          {params &&
            < Breadcrumb
              data={breadcrumb2.filter((x) => x.slug == params.category || x.slug == params.sub_category || x.slug == params.faq)}
            />

          }
        </div>
        <div id="help-centre-banner">
          {/*{subCategory && (*/}
          {/*  <ul className="help-centre-breadcrumbs breadcrumbs list-unstyled ">*/}
          {/*    <li>*/}
          {/*      <a*/}
          {/*        href={WebUrl._URL_HELP_CENTRE}*/}
          {/*        className="text-white font-semi"*/}
          {/*      >*/}
          {/*        {t("HELP_CENTRE")}*/}
          {/*      </a>*/}
          {/*    </li>*/}

          {/*    {parentCategories.length &&*/}
          {/*      parentCategories.map((item, index) => {*/}
          {/*        return (*/}
          {/*          !isNull(item.attributes.publishedAt) &&*/}
          {/*          item.attributes.category_id === parentCategory && (*/}
          {/*            <>*/}
          {/*              <li>*/}
          {/*                <a*/}
          {/*                  onClick={() =>*/}
          {/*                    GoToCategory(*/}
          {/*                      item.attributes.slug,*/}
          {/*                      item.attributes.category_id*/}
          {/*                    )*/}
          {/*                  }*/}
          {/*                  className="text-white font-semi"*/}
          {/*                >*/}
          {/*                  {item.attributes.title}*/}
          {/*                </a>*/}
          {/*              </li>*/}
          {/*              <li>*/}
          {/*                {item.attributes.sub_categories.data.length &&*/}
          {/*                  item.attributes.sub_categories.data.map((item1) => {*/}
          {/*                    return (*/}
          {/*                      !isNull(item1.attributes.publishedAt) &&*/}
          {/*                      item1.attributes.sub_category_id ===*/}
          {/*                        subCategory && (*/}
          {/*                        <a className="text-white font-semi">*/}
          {/*                          {item1.attributes.Title}*/}
          {/*                        </a>*/}
          {/*                      )*/}
          {/*                    );*/}
          {/*                  })}*/}
          {/*              </li>*/}
          {/*            </>*/}
          {/*          )*/}
          {/*        );*/}
          {/*      })}*/}
          {/*  </ul>*/}
          {/*)}*/}
          <div>
            <div className="with-information-container">
              <div className="left">
                {subCategories &&
                  subCategories.length &&
                  subCategories.map((item, index) => {
                    return (
                        !isNull(item.attributes.publishedAt) && ((!item.slug.includes("_")) || item.slug.match("_myr")) && (
                        <>
                          {/* {item.attributes.sub_category_id === subCategory &&
                            item.attributes.faqs.data.length &&
                            item.attributes.faqs.data.map((item1, index) => {
                              return (
                                !isNull(item1.attributes.publishedAt) && (
                                  <div className="sub-category-faq-titles">
                                    <div
                                      className="d-flex justify-content-between"
                                      onClick={() =>
                                        GoToFaq(
                                          item.attributes.category.data
                                            .attributes.slug,
                                          item.attributes.slug,
                                          item1.attributes.slug,
                                          item.attributes.category.data
                                            .attributes.category_id,
                                          item.attributes.sub_category_id,
                                          item1.attributes.faq_id
                                        )
                                      }
                                    >
                                      <div className="first kpi-accordion-title font-15 text-white font-semi">
                                        {item1.attributes.Title}
                                      </div>

                                      <div className="second">
                                        <img
                                          src="/img/assets/next-page.png"
                                          className="img-responsive"
                                          alt="next-page"
                                        />
                                      </div>
                                    </div>
                                    {index > 1 && (
                                      <div className="tag">
                                        <div className="border-bot-black"></div>
                                      </div>
                                    )}
                                  </div>
                                )
                              );
                            })}*/}
                          {item.attributes.sub_category_id === subCategory &&
                            item.attributes.faqs.data.length === 0 && (
                              <>
                                <h4 className="font-20">
                                  {item.attributes.Title}
                                </h4>
                                <div
                                  className="text-white mt-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item.attributes.Content,
                                  }}
                                ></div>
                              </>
                            )}
                        </>
                      )
                    );
                  })}
              </div>
              <div className="right">
                <RightInformation
                  promotion={true}
                  helpCentre={true}
                  helpCentreList={true}
                  helpCentreSubCategory={Sub_Category}
                  helpCentreFaqs={Sub_Category}
                />
              </div>
              {/*<div className="col-md-4 col-lg-4 col help-centre-margin">*/}
              {/*  <div className="d-flex justify-content-center">*/}
              {/*    <div className="help-centre-side-list list-group">*/}
              {/*      {parentCategory &&*/}
              {/*        parentCategories.length &&*/}
              {/*        parentCategories.map((item, index) => {*/}
              {/*          return (*/}
              {/*            !isNull(item.attributes.publishedAt) && (*/}
              {/*              <>*/}
              {/*                <a*/}
              {/*                  href={WebUrl._URL_HELP_CENTRE_CATEGORY.replace(*/}
              {/*                    ":category",*/}
              {/*                    item.attributes.slug*/}
              {/*                  )}*/}
              {/*                  className={*/}
              {/*                    item.attributes.category_id === parentCategory*/}
              {/*                      ? "list-group-item list-group-item-action active font-semi text-white font-16 cursor-pointer"*/}
              {/*                      : "list-group-item list-group-item-action font-semi text-white font-16 cursor-pointer"*/}
              {/*                  }*/}
              {/*                >*/}
              {/*                  {item.attributes.title}*/}
              {/*                </a>*/}

              {/*                <div className="list-group">*/}
              {/*                  {item.attributes.category_id ===*/}
              {/*                    parentCategory &&*/}
              {/*                    item.attributes.sub_categories.data.length &&*/}
              {/*                    item.attributes.sub_categories.data.map(*/}
              {/*                      (item1) => {*/}
              {/*                        return (*/}
              {/*                          !isNull(*/}
              {/*                            item1.attributes.publishedAt*/}
              {/*                          ) && (*/}
              {/*                            <a*/}
              {/*                              href={WebUrl._URL_HELP_CENTRE_SUB_CATEGORY*/}
              {/*                                .replace(*/}
              {/*                                  ":category",*/}
              {/*                                  item.attributes.slug*/}
              {/*                                )*/}
              {/*                                .replace(*/}
              {/*                                  ":sub_category",*/}
              {/*                                  item1.attributes.slug*/}
              {/*                                )}*/}
              {/*                              className={*/}
              {/*                                item.attributes.category_id ===*/}
              {/*                                  parentCategory &&*/}
              {/*                                item1.attributes*/}
              {/*                                  .sub_category_id === subCategory*/}
              {/*                                  ? "list-group-item active font-12"*/}
              {/*                                  : "list-group-item font-12"*/}
              {/*                              }*/}
              {/*                            >*/}
              {/*                              {item1.attributes.Title}*/}
              {/*                            </a>*/}
              {/*                          )*/}
              {/*                        );*/}
              {/*                      }*/}
              {/*                    )}*/}
              {/*                </div>*/}
              {/*              </>*/}
              {/*            )*/}
              {/*          );*/}
              {/*        })}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HelpCentre;
