import React, { useState, useEffect, useRef } from 'react';
import { gsap, Bounce } from 'gsap';
import { ApiUrl } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';

const ProgressiveJackpot = () => {
  const ballRefs = useRef([]);
  const timeline = useRef(null);
  const timeOutRef = useRef(null);
  const containerRef = useRef(null);

  const [counter, setCounter] = useState("");
  const [randomNumber, setRandomNumber] = useState('000');
  const [displayNumber, setDisplayNumber] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    getMegatotoJackpot();

    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight);
      }
    };

    // ResizeObserver to monitor container height changes
    const resizeObserver = new ResizeObserver(updateHeight);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateHeight(); // Initial height update
    }

    return () => {
      if (resizeObserver && containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      // Clear timeout on unmount to avoid memory leaks
      clearTimeout(timeOutRef.current);
    };
  }, []);

  const formatNumber = (input) => {
    if (input === null || input === undefined || isNaN(Number(input))) return '';

    // Convert input to a number, in case it's a string
    const number = parseFloat(input).toFixed(4); // Ensure there are exactly 4 decimal places

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = number.split('.');

    // Add thousand separators to the integer part and return the formatted number
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;
  };

  const generateRandomNumber = () => {
    const randomNum = Math.floor(Math.random() * 1000);
    const formattedNum = randomNum.toString().padStart(3, '0');
    setRandomNumber(formattedNum);
    setDisplayNumber(true);

    // Initialize the animation
    animateBalls();

    clearTimeout(timeOutRef.current);
    // Hide number after 5 seconds
    timeOutRef.current = setTimeout(() => setDisplayNumber(false), 5000);
  };

  const animateBalls = () => {
    // Ensure previous animation is stopped
    if (timeline.current) {
      timeline.current.kill();
    }

    // Revert balls to initial position
    gsap.set(ballRefs.current, {
      y: ((containerHeight / 2) - (150 / 2)) * -1,
      scaleX: 1,
      scaleY: 1,
    });

    // Start the bounce animation
    timeline.current = gsap.timeline({ repeat: 0, repeatDelay: 1 })
      .staggerTo(ballRefs.current, 1, {
        y: (containerHeight / 2) - (150 / 2),
        ease: Bounce.easeOut
      }, 0.04);
  };

  const getMegatotoJackpot = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEGATOTO_JACKPOT);

    setCounter(formatNumber(responseJson))
  }

  return (
    <div className="home-progressive-container">
      <div className="jackpot-gif-base" ref={containerRef}>
        <div className="jackpot-first-layer">
          <div className="jackpot-title"></div>
          <div className="jackpot-counter"></div>
          <div className="jackpot-text">
            <div>USDT </div><div>{counter ? counter : <span className="loading-text">Loading</span>}</div>
          </div>
        </div>
        <div className="jackpot-second-layer">
          <div className="crown"></div>
          <div className="coin"></div>
          <div className="lucky-draw" onClick={generateRandomNumber}></div>
          <div className={`lucky-draw-container ${displayNumber ? "show-number" : ""}`}>
            {randomNumber.split('').map((digit, index) => (
              <div key={index} className={`lucky-number-${index + 1}`} ref={el => ballRefs.current[index] = el}>{digit}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressiveJackpot;