import React, { useState, useEffect, useRef, useMemo } from "react";
import "react-simple-keyboard/build/css/index.css";
import { filter, map, isEmpty, find, forEach, create } from "lodash";
//import { bannerList } from "../../config/game-configs";
import Slider from "react-slick";
import Input from "components/custom/Input";
import {
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import closedEye from "../../assets/img/version3/register-login/hide.png";
import openEye from "../../assets/img/version3/register-login/eye-blue.png";
import PinInput from "react-pin-input";
import { useSelector, useDispatch } from "react-redux";
import { ApiKey, ApiUrl, SessionKey, AlertTypes, PhoneNumberPrefix, WebUrl, TransactionType, BankingChannel, PanelType, LanguageOption } from "../../util/Constant";
import { setGameProviders, setGameCategories } from "redux/GameAction";
import {
  setDisplayLogin,
  setDisplayRegister,
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
  setBannerPopupImage,
  setBannerPopupModal
} from "../../redux/AppAction";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { performLogin } from "../../redux/AuthAction.js";
import {
  createFormBody,
  createMultiPartFormBody,
  numberWithCurrencyFormat,
  numberWithCurrencyFormat2,
  stringIsNullOrEmpty,
} from "./../../util/Util.js";
import { } from "../../redux/AuthAction.js";
import SecureLS from "secure-ls";
import ApiEngine from "./../../util/ApiEngine.js";
import { useHistory } from "react-router-dom";
import useCountDown from 'react-countdown-hook';
import moment from "moment";
import parse from "html-react-parser";
import PromoBanner from "components/promo-banner/PromoBanner";
import GameListRecommendMatch from "components/game-list/recommend-match";
import LiveChatModal from 'components/custom/LiveChatModal';
import CustomMiniGame from "components/custom/CustomMiniGame";
import DownloadApp from 'components/custom/DownloadApp';
import { walletInfoFunction, latestGameFunction, newDownlineMemberFunction, promotionFuntion, unclaimedFunction, downlineSaleFunction, rewardInfoFunction, redemptionHistoryFunction } from "../../redux/InformationAction.js";
import GameListGrid from "components/game-list/grid";
import GameSectionV3 from "../../components/game-list/game-section-v3";
import BannerPopupModal from '../../components/custom/BannerPopupModal';
import ProgressiveJackpot from "components/custom/ProgressiveJackpot";
 
const Home = (props) => {
  const _history = useHistory();
  const { t, i18n } = useTranslation();
  const _MIN_PASSWORD_LENGTH = 6;
  const _TAC_VALID_TIMER = 300000;
  const _SECONDS_IN_MS = 1000;
  const _TAC_TIMEOUT_IN_MINUTES = 5;
  const initialTime = 120 * 1000;
  const interval = 1000;
  const PROVIDERS_PER_ROW = 7;
  const TOTAL_PROVIDERS_ROW = 6;
  var _userData = useSelector((state) => state.authState.userData);
  const languageId = useSelector((state) => state.languageState.languageId);
  const dispatch = useDispatch();
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';

  const elePin = useRef(null);
  const eleConfirm = useRef(null);
  const rolloverFlagInterval = useRef(null);
  const rolloverInterval = useRef(null);
  const tempInterval = useRef(null);
  const [invalidPin, setInvalidPin] = useState(false);
  const [theInterval, setTheInterval] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const [countdownTAC, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const _dispatch = useDispatch();
  const [categoryActive, setCategoryActive] = useState("");
  const popularSlider = useRef();
  const gameForYouSlider = useRef();
  const { isLoggedIn } = useSelector((state) => state.authState);
  const [memberData, setMemberData] = useState({});
  const [showCreateShareholderPin, setShowCreateShareholderPin] = useState(false);

  const [unclaimComm, setUnclaimComm] = useState("");
  const [grandTotalRewards, setGrandTotalRewards] = useState("");
  const [content, setContent] = useState("");
  const [activeLanguageId, setActiveLanguageId] = useState(5);
  const [activeProvider, setActiveProvider] = useState(0);
  const [activeProviderName, setActiveProviderName] = useState("");
  const [allAvailableProvidersList, setAllAvailableProvidersList] = useState([]);
  const [masterProviderList, setMasterProviderList] = useState({});

  const [gameAppUrl, setGameAppUrl] = useState("");
  const [gameAppDeepLink, setGameAppDeepLink] = useState("");
  const [mpData, setMpData] = useState([]);
  const [gameAppPackageNameKeyword, setGameAppPackageNameKeyword] = useState("");
  const [isRecommendedMobileGame, setIsRecommendedMobileGame] = useState({});
  const [isGameForYouMobileGame, setIsGameForYouMobileGame] = useState({});
  const [isGettingGames, setIsGettingGames] = useState(true);
  const [newGames, setNewGames] = useState({});
  const [newGamesIsLoading, setNewGamesIsLoading] = useState(false);
  const [recommendedGames, setRecommendedGames] = useState({});
  const [games93Connect, setGames93Connect] = useState({});
  const [providerListRowNum, setProviderListRowNum] = useState(1);
  const [activeSoloGame, setActiveSoloGame] = useState({});
  const [providerWalletData, setProviderWalletData] = useState({});
  const [gameIsApp, setGameIsApp] = useState(false);
  const [gameHasLobby, setGameHasLobby] = useState(false);
  const [masterProductId, setMasterProductId] = useState(0);
  const [walletModalVisible, setWalletModalVisible] = useState(false);
  const [mainBannerData, setMainBannerData] = useState([]);
  const [sliderBannerList, setSliderBannerList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [promoBannerList, setPromoBannerList] = useState([]);
  const [recommendedMatches, setRecommendedMatches] = useState([]);
  const [gettingNewAndPopularGames, setGettingNewAndPopularGames] = useState(true);

  const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment().subtract(1, "days"));


  const [loadingMemberFlag, setLoadingMemberFlag] = useState(true);
  const [uplineDetail, setUplineDetail] = useState({});

  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [currentRolloverBalance, setCurrentRolloverBalance] = useState(_userData.rolloverBalance);
  const [rolloverTarget, setRolloverTarget] = useState(0);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [productCategory, setProductCategory] = useState([]);

  const verifyState = {
    button: "1"
  };
  const popularNext = () => {
    popularSlider.current.slickNext();
  };
  const popularPrev = () => {
    popularSlider.current.slickPrev();
  };

  const gameForYouNext = () => {
    gameForYouSlider.current.slickNext();
  };
  const gameForYouPrev = () => {
    gameForYouSlider.current.slickPrev();
  };
  const bannerSlick = {
    slidesToShow: 1,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
  };


  const gameForYouSlick = {
    slidesToShow: 5,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  //use effect region -------------------------------------------------------------------

  //init 
  useEffect(() => {
    /*    getDashboardGames();*/
    //getIsRecommandedGame();
    get568RecommendedMatches();
    setInterval(get568RecommendedMatches, 30000);
  }, []);


  //trigger logged in function
  useEffect(() => {
    if (isLoggedIn) {
      getMemberInit();
      getUnclaimCommHistory();
      getRewardData();
    } else {
      setShowCreateShareholderPin(false);
    }
    /*    getNewAndPopularGames();*/

  }, [isLoggedIn]);


  //trigger banner language marquee tag content follow the local storage 
  useEffect(() => {
    getBannerLanguage();
    getMarqueeTagContent();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  useEffect(() => {
    if (!isLoggedIn) {
      clearInterval(rolloverFlagInterval.current);
      clearInterval(rolloverInterval.current);
      clearTimeout(tempInterval.current);
      setPlayableCategory([]);
      setRolloverTarget(0);
    }

    if (isLoggedIn) {
      getProductCategory();
      getUserBalance(_userData.walletBalance)
    }

  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      getMemberSpecialRollover();
      getMemberCurrentRolloverBalance();
      rolloverFlagInterval.current = setInterval(getMemberSpecialRollover, 30000);
      rolloverInterval.current = setInterval(getMemberCurrentRolloverBalance, 30000);
    }
    else {
      // do nothing
    }
  }, [currentWalletBalance])

  useEffect(() => {
    if (isLoggedIn && isSpecialRollover) {
      getRolloverData();
      getBonusDepositProductCategory();
    }
    else {
      clearTimeout(tempInterval.current);
    }
  }, [isSpecialRollover, productCategory])

  useEffect(() => {
    if (isLoggedIn && isSpecialRollover) {
      getRolloverData();
    }
    else {
    }
  }, [currentRolloverBalance])

  //useEffect(() => {
  //  const fetchData = async () => {
  //    // Get game categories
  //    const { data: gameCategories } = await ApiEngine.get(
  //      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
  //      "?selectedLanguage=" +
  //      selectedLanguage
  //    );

  //    dispatch(
  //      setGameCategories({
  //        gameCategories,
  //      })
  //    );

  //    // Get game providers
  //    let apiUrl = ApiUrl._API_GET_CATEGORY_GROUPED_GAME_DASHBOARD_DESKTOP;

  //    if (isLoggedIn) {
  //      if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
  //        if (LanguageOption.find(
  //          (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
  //          apiUrl += "?languageId=" + LanguageOption.find(
  //            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
  //          ).languageId;
  //        }
  //        else {
  //          apiUrl += "?languageId=1";
  //        }
  //      } else {
  //        apiUrl += "?languageId=1";
  //      }

  //      // LanguageOption.find(
  //      //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
  //      // ).languageId;
  //    }

  //    var { data: gameProviders } = await ApiEngine.get(apiUrl);

  //    dispatch(
  //      setGameProviders({
  //        gameProviders,
  //      })
  //    );
  //  };

  //  fetchData();
  //}, [isLoggedIn]);

  //use effect region end -------------------------------------------------------------------


  //api function region ---------------------------------------------------------------------

  //check member if shareholder need to create shareholder pin
  async function getMemberInit() {
    let member = [];
    try {
      let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
      // setLoadingMemberFlag(false);
      if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
      setMemberData(responseJson[ApiKey._API_DATA_KEY]);
      member = responseJson[ApiKey._API_DATA_KEY];

      setLoadingMemberFlag(true);
      let params = {};
      if (member["isShareHolder"] === true) {
        responseJson = await ApiEngine.post(
          ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
          createFormBody(params)
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
          var theInterval = setInterval(() => {
            if (elePin["current"] == undefined) return;

            if (
              elePin["current"]["values"][0] == "" ||
              elePin["current"]["values"][0] == undefined
            ) {
              elePin["current"]["elements"][0].focus();
            } else if (
              elePin["current"]["values"][1] == "" ||
              elePin["current"]["values"][1] == undefined
            ) {
              elePin["current"]["elements"][1].focus();
            } else if (
              elePin["current"]["values"][2] == "" ||
              elePin["current"]["values"][2] == undefined
            ) {
              elePin["current"]["elements"][2].focus();
            } else if (
              elePin["current"]["values"][3] == "" ||
              elePin["current"]["values"][3] == undefined
            ) {
              elePin["current"]["elements"][3].focus();
            } else if (
              eleConfirm["current"]["values"][0] == "" ||
              eleConfirm["current"]["values"][0] == undefined
            ) {
              eleConfirm["current"]["elements"][0].focus();
            } else if (
              eleConfirm["current"]["values"][1] == "" ||
              eleConfirm["current"]["values"][1] == undefined
            ) {
              eleConfirm["current"]["elements"][1].focus();
            } else if (
              eleConfirm["current"]["values"][2] == "" ||
              eleConfirm["current"]["values"][2] == undefined
            ) {
              eleConfirm["current"]["elements"][2].focus();
            } else if (
              eleConfirm["current"]["values"][3] == "" ||
              eleConfirm["current"]["values"][3] == undefined
            ) {
              eleConfirm["current"]["elements"][3].focus();
            } else {
              var currpin =
                elePin["current"]["values"][0] +
                elePin["current"]["values"][1] +
                elePin["current"]["values"][2] +
                elePin["current"]["values"][3];
              var currconfpin =
                eleConfirm["current"]["values"][0] +
                eleConfirm["current"]["values"][1] +
                eleConfirm["current"]["values"][2] +
                eleConfirm["current"]["values"][3];
              if (currpin != currconfpin) {
                setInvalidPin(true);
                elePin["current"].clear();
                eleConfirm["current"].clear();
                elePin["current"].focus();
              } else {
                setInvalidPin(false);
                clearInterval(theInterval);
                submitPin(currpin, currconfpin);
              }
            }
          }, 50);
          setTheInterval(theInterval);
          setShowCreateShareholderPin(true);
        }
      }

      // await _dispatch(checkIsLoggedIn());
    } catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    } finally {
      _dispatch(setIdle());
    }
  }

  //submit the create pin
  async function submitPin(pin, conf) {
    try {
      let params = {
        pinNumber: pin,
        confirmPinNumber: conf,
      };
      let responseJson = await ApiEngine.post(
        ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setShowCreateShareholderPin(false);
        setInvalidPin(false);
      }
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  //get unclaim comm hisyory
  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  //get reward data
  const getRewardData = async () => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      //let temp = Object.keys(tempObj).sort();
      //setSelectedDate(moment(temp[temp.length - 1]));
      //setRewardData(tempObj);
      setGrandTotalRewards(grandTotalReward);
      //setGameCategoryData(categoryTotal);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  //get banner language
  const getBannerLanguage = async () => {
    let apiUrl = ApiUrl._API_GET_USER_BANNER + "?isDesktop=true&isPopup=false&languageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'id');

    // localStorage.getItem(SessionKey._LANGUAGE);
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data1 = responseJson[ApiKey._API_DATA_KEY]["banners"];
      if (data1/*.filter(x => x.displayDevice === 0)*/.length > 0) {
        let tempMainBannerData = data1;//.filter(x => x.displayDevice === 0);
        if (tempMainBannerData.length > 0) {
          setMainBannerData(tempMainBannerData);
        }
        else {
          setMainBannerData();
        }
      }
      else {
        setMainBannerData();
      }
      let tempSliderBannerList = [];
      if (data1.filter(x => x.displayDevice === 1).length > 0) {
        data1.filter(x => x.displayDevice === 1).map((banner) => {
          tempSliderBannerList.push({ image: banner.image, url: banner.url });
        });
      }
      setSliderBannerList(tempSliderBannerList);
      let data2 = responseJson[ApiKey._API_DATA_KEY]["promoBanners"];
      setPromoBannerList(data2);
      let data3 = responseJson[ApiKey._API_DATA_KEY]["popupBanners"];
      if (data3 != null) {
        _dispatch(setBannerPopupImage(data3));
        _dispatch(setBannerPopupModal(true));
      } else {
        _dispatch(setBannerPopupImage(""));
        _dispatch(setBannerPopupModal(false));
      }
      setIsLoaded(true);
    }
  }

  //get marquee tag content
  async function getMarqueeTagContent() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MARQUEE_TAG_CONTENT
      + "?MarqueeTagId=1"
      + "&LanguageCode=" +
      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].map((option) => {
        setContent(option["content"]);

      });
    }
  }

  //get dashboard games
  const getDashboardGames = async () => {
    setIsGettingGames(true);
    try {
      setNewGamesIsLoading(true);
      let apiUrl =
        ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
        "?languageId=" +
        activeLanguageId +
        "&Count=" +
        15;
      var newResponseJson = await ApiEngine.get(apiUrl);

      if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
        let newGamesData = newResponseJson[ApiKey._API_DATA_KEY]["isNewGame"];

        setNewGames(newGamesData);
        newGamesData.map((game) => {
          let image = new Image();
          image.src = game["image"];

          window[game["image"]] = image;
        });
        setTimeout(() => setNewGamesIsLoading(false), 1000);
        let availableProductList = newResponseJson[ApiKey._API_DATA_KEY]["availableMasterProductList"].filter(x => !stringIsNullOrEmpty(x.productImage));
        setAllAvailableProvidersList(availableProductList);
        let listIndex = 1;
        let tempMasterProductList = {};
        availableProductList.map((product) => {
          if (!tempMasterProductList["productList" + listIndex]) {
            tempMasterProductList["productList" + listIndex] = [];
          }
          if (tempMasterProductList["productList" + listIndex].length < PROVIDERS_PER_ROW) {
            tempMasterProductList["productList" + listIndex].push(product);
          }
          else {
            listIndex++;
            tempMasterProductList["productList" + listIndex] = [];
            tempMasterProductList["productList" + listIndex].push(product);
          }

          if (product.categoryName == "Connect") {
            setGames93Connect(product.game);
          }
        });
        setMasterProviderList(tempMasterProductList);
      } else {
        throw newResponseJson[ApiKey._API_MESSAGE_KEY];
      }
    }
    catch (errorMessage) {
      _dispatch(showResponseMessage(false, errorMessage));
    }
    setTimeout(() => setIsGettingGames(false), 1);
  }

  // click start game
  const startGame = async (productGame) => {
    if (isLoggedIn) {
      if (!isEmpty(productGame)) {
        const { id, isApp, isNewWindow, isSeamless, gameName, hasOwnLobby, categoryTitle } = productGame;

        let productCategoryListArr = [...playableCategory];

        if (!isSpecialRollover || (isSpecialRollover && productCategoryListArr.includes(categoryTitle))) {
          if (hasOwnLobby !== true) {
            if (isSeamless == false) {
              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
            } else {
              if (!window.ReactNativeWebView) {
                window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
              }
              else {
                if (isApp) {
                  if (gameName !== "LionKing") {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        action: "deeplink",
                        url: gameAppUrl,
                        deeplink: gameAppDeepLink
                          .replace("[username]", mpData["username"])
                          .replace("[password]", mpData["password"]),
                        packageKeyword: gameAppPackageNameKeyword
                      })
                    );
                  }
                }
                if (isNewWindow) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      url:
                        window.location.origin +
                        ApiUrl._API_START_GAME +
                        "?gameId=" +
                        id
                    })
                  );
                } else {
                  if (gameName === "LionKing" || gameName === "918Kiss") {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        packageKeyword: gameAppPackageNameKeyword
                      })
                    );
                  }
                  else {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        url:
                          window.location.origin +
                          WebUrl._URL_GAME_PAGE +
                          "?gameId=" +
                          id
                      })
                    );
                  }
                }
              }
            }
          }
          else {
            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      }
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  // start game for provider
  const startGameProvider = async (productGame) => {
    if (isLoggedIn) {
      if (!isEmpty(productGame)) {
        const { id, isApp, isNewWindow, isSeamless, gameName, hasOwnLobby, productCategory } = productGame;

        let productCategoryListArr = [...playableCategory];

        if (!isSpecialRollover || (isSpecialRollover && productCategoryListArr.includes(productCategory.originalTitle))) {
          if (hasOwnLobby !== true) {
            if (isSeamless == false) {
              window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
            } else {
              if (!window.ReactNativeWebView) {
                window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
              }
              else {
                if (isApp) {
                  if (gameName !== "LionKing") {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        action: "deeplink",
                        url: gameAppUrl,
                        deeplink: gameAppDeepLink
                          .replace("[username]", mpData["username"])
                          .replace("[password]", mpData["password"]),
                        packageKeyword: gameAppPackageNameKeyword
                      })
                    );
                  }
                }
                if (isNewWindow) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      url:
                        window.location.origin +
                        ApiUrl._API_START_GAME +
                        "?gameId=" +
                        id
                    })
                  );
                } else {
                  if (gameName === "LionKing" || gameName === "918Kiss") {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        packageKeyword: gameAppPackageNameKeyword
                      })
                    );
                  }
                  else {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        url:
                          window.location.origin +
                          WebUrl._URL_GAME_PAGE +
                          "?gameId=" +
                          id
                      })
                    );
                  }
                }
              }
            }
          }
          else {
            window.open(ApiUrl._API_START_GAME + "?gameId=" + id + "&isApp=" + (isApp ? 1 : 0) + "&device=m");
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      }
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  //click provider
  const handleProviderClick = async (listIndex, index, provider) => {
    if (!provider.isUM) {
      setProviderListRowNum(listIndex + 1);
      setActiveProvider(index);
      if (provider != null) {
        let tempProviderName = provider.productName;
        setActiveProviderName(tempProviderName);
      }
      if (!provider.hasOwnLobby) { setActiveSoloGame(provider.game); }
      if (!provider.isSeamless) {
        if (isLoggedIn) {
          _dispatch(setBusy());
          let walletJson = await ApiEngine.get(ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE + "?masterProductId=" + provider.productId);
          if (walletJson[ApiKey._API_SUCCESS_KEY]) {
            let walletData = walletJson[ApiKey._API_DATA_KEY];
            let tempProviderWalletData = walletData.filter(x => x.productId === provider.productId)[0];
            setProviderWalletData(tempProviderWalletData);

            let mpJson = await ApiEngine(ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS + "?masterProductId=" + provider.productId);
            if (mpJson[ApiKey._API_SUCCESS_KEY]) {
              let data = mpJson[ApiKey._API_DATA_KEY];
              setMpData(data);

              if (provider.productName !== "LionKing") {
                if (window.ReactNativeWebView && provider.isApp) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      action: "deeplink",
                      url: provider.game.appUrl,
                      deeplink: provider.game.appDeepLink
                        .replace("[username]", data["username"])
                        .replace("[password]", data["password"]),
                      packageKeyword: provider.game.packageNameKeyword,
                    })
                  );
                }
              }
              else {
                const gameArr = [
                  {
                    masterProductId: provider.productId,
                    gameId: provider.game?.id,
                    appDeepLink: provider.game?.appDeepLink,
                    appUrl: provider.game?.appUrl,
                    packageKeyword: provider.game?.packageNameKeyword
                  }
                ];
                if (window.ReactNativeWebView && provider.isApp) {
                  //startGameLionKing(gameArr);
                }
              }
            }
          }
          _dispatch(setIdle());
          setGameIsApp(provider.isApp);
          setGameHasLobby(provider.hasOwnLobby);
          setGameAppUrl(provider.game?.appUrl)
          setGameAppDeepLink(provider.game?.appDeepLink);
          setGameAppPackageNameKeyword(provider.game?.packageNameKeyword);
          setMasterProductId(provider.productId);
          setWalletModalVisible(true);
        }
        else {
          if (!provider.hasOwnLobby) {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
          else {
            _history.push({

              pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
            });
          }
        }
      }
      else {
        if (!provider.hasOwnLobby) {
          if (isLoggedIn) {
            startGameProvider(provider.game);
          }
          else {
            _dispatch(
              showMessage({
                type: AlertTypes._INFO,
                content: t("PLEASE_LOGIN_FIRST"),
                onConfirm: () => {
                },
              })
            );
          }
        }
        else {
          _history.push({
            pathname: WebUrl._URL_GAME_LIST.replace(":provider", provider.productName),
          });
        }
      }
    }
  }

  const get568RecommendedMatches = async () => {
    try {
      let apiUrl = ApiUrl._API_GET_568_RECOMMENDED_MATCHES + "?languageId=" + (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en');
      var responseJson = await ApiEngine.get(apiUrl);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        data = data.sort(function (a, b) {
          var dateA = new Date(a.kickOffTimeGMT);
          var dateB = new Date(b.kickOffTimeGMT);
          return dateA - dateB;
        });
        setRecommendedMatches(data);
      } else {
        setRecommendedMatches([]);
      }
    } catch {
      setRecommendedMatches([]);
    }
  }

  async function startGame568SportsBook(matchId) {
    if (isLoggedIn) {
      _dispatch(setBusy());

      if (isSpecialRollover) {
        let productCategoryListArr = [...playableCategory];
        if (productCategoryListArr.includes("Sports")) {
          const result = await ApiEngine(
            ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
            "?matchId=" + matchId + "&device=d"
          );

          if (result[ApiKey._API_SUCCESS_KEY]) {
            const data = result[ApiKey._API_DATA_KEY];


            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  url: data,
                })
              );
            }
            window.open(data);
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      }
      else {
        const result = await ApiEngine(
          ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS_SPORTS_BOOK +
          "?matchId=" + matchId + "&device=d"
        );

        if (result[ApiKey._API_SUCCESS_KEY]) {
          const data = result[ApiKey._API_DATA_KEY];


          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                url: data,
              })
            );
          }
          window.open(data);
        }
      }

      _dispatch(setIdle());
    }
    else {
      // eslint-disable-next-line no-undef
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content: t("PLEASE_LOGIN_FIRST"),
          onConfirm: () => {
          },
        })
      );
    }
  }

  function onCreatePinKeyPress(value) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (value !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        // elePin["current"]["elements"][currentIndex].state.value = value;
        pinStr = value;
        setEnteredPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        // eleConfirm["current"]["elements"][currentIndex].state.value = value;
        pinConfirmStr = value;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex < 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          // setTimeout(function () {
          //   submitPin(pinNumber, pinConfirmStr);
          // }, 1000);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  const getNewAndPopularGames = async () => {
    setGettingNewAndPopularGames(true);
    //get all new game and recommended game
    let apiUrl =
      ApiUrl._API_GET_IS_NEW_MASTER_PRODUCT_GROUPED_GAME +
      "?languageId=" +
      languageId +
      "&Count=" +
      14;
    // if (isLoggedIn) {
    //   apiUrl += "&memberId=" + _userData.userId;
    // }
    var responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {

      let newGamesData = responseJson[ApiKey._API_DATA_KEY]["isNewGame"];
      setNewGames(newGamesData);

      let recommendedGamesData =
        responseJson[ApiKey._API_DATA_KEY]["isReccommendedGame"];
      setRecommendedGames(recommendedGamesData);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    setTimeout(() => setGettingNewAndPopularGames(false), 1);
  }

  const getMemberSpecialRollover = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
    let data = "";

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      data = responseJson[ApiKey._API_DATA_KEY];
      setIsSpecialRollover(data);

      if (data === false) {
        clearInterval(rolloverFlagInterval.current);
        clearInterval(rolloverInterval.current);
        setPlayableCategory([]);
        setRolloverTarget(0);
      }
    }
  }

  const getBonusDepositProductCategory = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let productCategoryArr = [];

      if (data.includes(",")) {
        productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
      }
      else {
        productCategoryArr.push(data);
      }

      let tempArr = [];
      productCategory.forEach((x) => {
        if (productCategoryArr.includes(x.value)) {
          tempArr.push(x.originalTitle);
        }
      })

      setPlayableCategory(tempArr);
    }
  }

  const getMemberCurrentRolloverBalance = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_CURRENT_ROLLOVER_BALANCE);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setCurrentRolloverBalance(data);
    }
  }

  async function getRolloverData() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setRolloverTarget(data[0].rolloverTarget);
    }
  }

  const getUserBalance = async () => {
    let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
    if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
      let data = walletResponse[ApiKey._API_DATA_KEY];
      setCurrentWalletBalance(data["primaryCredit"]);
      if (data["count"] == false) {
        setBalanceTimeout(30000);
      }
      else {
        setBalanceTimeout(5000);
        clearInterval(rolloverFlagInterval.current);
        clearInterval(rolloverInterval.current);
      }
    }
  }

  function setBalanceTimeout(mmTimer) {
    clearTimeout(tempInterval.current);
    tempInterval.current = setTimeout(() => {
      getUserBalance();
    }, mmTimer);
  }

  const getProductCategory = async () => {
    let tempArr = [];
    var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
    if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
      let data = productCategoryJson[ApiKey._API_DATA_KEY];
      data.forEach(x => {
        if (x.id > 0) {
          tempArr.push({ value: x.id.toString(), label: x.title, originalTitle: x.originalTitle });
        }
      });
      setProductCategory(tempArr);
    }
  }

  //api function region end ---------------------------------------------------------------------



  //normal function region ---------------------------------------------------------------------
  const handleCategoryClick = (categoryName) => {
    let tempCatName = categoryName;
    if (categoryActive === categoryName) {
      tempCatName = "";
    }
    setActiveProvider(0);
    setActiveProviderName("");
    setCategoryActive(tempCatName);
    sortProviders(tempCatName);
    if (categoryName === "93Connect") {
      let connectDetails = allAvailableProvidersList.filter((x) => {
        if (x.productName === "93Connect") return x.game;
      })
      let gameDetails = connectDetails.map((item) => item.game);
      startGame(gameDetails[0]);
    }
  }


  const sortProviders = (categoryName) => {
    let tempAvailableProvidersList = allAvailableProvidersList;
    if (categoryName !== "") {
      tempAvailableProvidersList = allAvailableProvidersList.filter(x => (categoryName.startsWith("Slots") ? x.categoryName.startsWith(categoryName) : x.categoryName === categoryName) && !stringIsNullOrEmpty(x.productImage));
    }
    let listIndex = 1;
    let tempMasterProductList = {};
    let checkrow
    tempAvailableProvidersList.map((product) => {
      if (!tempMasterProductList["productList" + listIndex]) {
        tempMasterProductList["productList" + listIndex] = [];
      }
      if (tempMasterProductList["productList" + listIndex].length < PROVIDERS_PER_ROW) {
        tempMasterProductList["productList" + listIndex].push(product);
      }
      else {
        listIndex++;
        tempMasterProductList["productList" + listIndex] = [];
        tempMasterProductList["productList" + listIndex].push(product);
      }
    });
    setMasterProviderList(tempMasterProductList);
  }

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  //normal function region end ---------------------------------------------------------------------

  useEffect(() => {
    rightInformation();
  }, [isLoggedIn]);

  useEffect(() => {
    _dispatch(promotionFuntion());
  }, []);

  async function rightInformation() {
    if (isLoggedIn) {
      var url_string = window.location;
      var url = new URL(url_string);

      _dispatch(walletInfoFunction());
      _dispatch(latestGameFunction());
      _dispatch(newDownlineMemberFunction());
      _dispatch(rewardInfoFunction(selectedLanguage));
      _dispatch(redemptionHistoryFunction());

      if (_userData["isVip"] === true) {
        _dispatch(unclaimedFunction());
        _dispatch(downlineSaleFunction(_userData["username"], url, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')));
      }
    }
  }

  const handleBannerOnClick = async (bannerUrl) => {
    if (bannerUrl.startsWith("Game/StartGame?gameId=")) {
      if (isLoggedIn) {
        let game = bannerUrl.match("gameId=(.*)&isApp=");
        let gameId = game[1];
        var newResponseJson = await ApiEngine.get(
          ApiUrl._API_GET_MASTER_PRODUCT_GROUP_GAME_BY_GAME_ID + "?id=" + gameId
        );

        if (newResponseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = newResponseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY].filter(x => x.id == gameId)
          startGame(data[0]);
        }
      }
      else {
        // eslint-disable-next-line no-undef
        _dispatch(
          showMessage({
            type: AlertTypes._INFO,
            content: t("PLEASE_LOGIN_FIRST"),
            onConfirm: () => {
            },
          })
        );
      }
    } else {
      const urlSplit = bannerUrl.split("/");
      const productName = urlSplit[urlSplit.length - 1]
      _history.push({
        pathname: WebUrl._URL_GAME_LIST.replace(":provider", productName),
      });
    }
  }

  return (
    <>
      <div id="home-page">
        <div className="">
          <Slider {...bannerSlick} className="banner-slick">
            {mainBannerData &&
              mainBannerData.length > 0 &&
              map(mainBannerData, (item, index) => {
                return (
                  <div key={index} className="banner-items cursor-global">
                    <img src={item.image} alt="banner" className="banner-items-image" onClick={() => handleBannerOnClick(item.url)} />
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="home-announcement mt-4">
          <div>
            <img src="/img/version3/announce.png" alt="shout" />
          </div>
          <marquee direction="left">
            {parse(content)}
          </marquee>
        </div>
        <GameSectionV3 playableCategory={playableCategory} isSpecialRollover={isSpecialRollover} />

        <section id="home-progressive" className="mt-4">
          <ProgressiveJackpot />
        </section>

        {promoBannerList && promoBannerList.length > 0 &&
          <section>
            <PromoBanner promoBannerList={promoBannerList} />
          </section>}
        {/* ////if login/// */}
        {/* //end if login// */}

        {isSpecialRollover && productCategory && rolloverTarget !== 0 && currentRolloverBalance !== 0 ?
          <section>
            <div className="title-themed mt-5">
              <h4>{t("REMAINING_ROLLOVER")}</h4>
            </div>
            <div className="special-rollover-container mt-5">
              <div className="text-n-icon">
                <div className="icon-container">
                  {productCategory.map((item, index) => {
                    return (
                      <div className={`inner-container ${[...playableCategory].includes(item.originalTitle) ? "playable" : ""}`}>
                        <img
                          src={'/img/assets/game-category/svg/' + item.originalTitle + '.svg'}
                          className="img-responsive"
                          alt={item.label + " image"}
                          width="30"
                          height="30"
                        />
                        <span style={{ color: "#FFD928", fontSize: "13px", fontWeight: "700", letterSpacing: "1px" }}>{item.label}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="progress-bar-container">
                <div className="progress-bar-outline">
                  <span style={{ position: "absolute", textShadow: "2px 2px 5px black" }}>{numberWithCurrencyFormat((Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100, 2, true)}%</span>
                  <div className="progress-bar-fill"
                    style={{
                      width: `${(Math.abs(rolloverTarget - currentRolloverBalance) / (currentRolloverBalance + (rolloverTarget - currentRolloverBalance))) * 100}%`
                    }}
                  >
                  </div>
                </div>
              </div>
              <div className="rollover-text">
                {t("AMOUNT")}: {"\u0E3F "} {numberWithCurrencyFormat(Math.abs(rolloverTarget - currentRolloverBalance), 2, true) + " / " + numberWithCurrencyFormat((currentRolloverBalance + (rolloverTarget - currentRolloverBalance)), 2, true)} <span style={{ color: "#FFD928" }}></span>
              </div>
            </div>
            <hr className="hr-left" />
          </section>
          :
          <></>
        }

        {recommendedMatches && recommendedMatches.length > 0 && <>
          <div className="home-title-with-arrows mt-5">
            <div className="title-themed">
              <h4>{t("UPCOMING_LIVE_MATCH")}</h4>
              <p>{t("ENJOY_UPCOMING_MATCH")}</p>
            </div>
          </div>
          <GameListRecommendMatch recommendedMatches={recommendedMatches} startGame568SportsBook={startGame568SportsBook} />
          <hr className="hr-right" />
        </>}
        {/*<div className="title-themed mt-5">*/}
        {/*  <h4>{t("GAMES_CATEGORIES")}</h4>*/}
        {/*  <p>{t("BROWSE_GAMES_FROM_CATEGORIES")}</p>*/}
        {/*</div>*/}
        {/*<div className="mt-5 home-categories">*/}
        {/*  <div className="home-top-categories">*/}
        {/*    <div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "Slots" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("Slots")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive === "Slots"*/}
        {/*              ? "1px solid #00f8ff"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/slot.png"*/}
        {/*          className="w-100" alt="homepage-category-slots-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-left">{t("SLOTS")}</div>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "Poker" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("Poker")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive == "Poker"*/}
        {/*              ? "1px solid #7743ff"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/poker.png"*/}
        {/*          className="w-100" alt="homepage-category-poker-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-left">{t("Poker")}</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <div*/}
        {/*        className="category-active"*/}
        {/*        onClick={() => handleCategoryClick("93Connect")}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/93connect.gif"*/}
        {/*          className="w-100" alt="homepage-category-connect-img"*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "Casino" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("Casino")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive == "Casino"*/}
        {/*              ? "1px solid #d57e01"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/casino.png"*/}
        {/*          className="w-100" alt="homepage-category-casino-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-right">{t("CASINO")}</div>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "eSports" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("eSports")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive == "eSports"*/}
        {/*              ? "1px solid #ff003d"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/esport.png"*/}
        {/*          className="w-100" alt="homepage-category-esports-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-right">{t("eSports")}</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="home-top-categories after-home-category">*/}
        {/*    <div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "Sports" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("Sports")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive == "Sports"*/}
        {/*              ? "1px solid #1400ff"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/sport.png"*/}
        {/*          className="w-100" alt="homepage-category-sport-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-left">{t("SPORTS")}</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <div*/}
        {/*        className={categoryActive == "Lottery" ? "category-active" : ""}*/}
        {/*        onClick={() => handleCategoryClick("Lottery")}*/}
        {/*        style={{*/}
        {/*          outline:*/}
        {/*            categoryActive == "Lottery"*/}
        {/*              ? "1px solid #c8ff00"*/}
        {/*              : "1px solid transparent",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img*/}
        {/*          src="/img/version3/categories/lottery.png"*/}
        {/*          className="w-100" alt="homepage-category-lottery-img"*/}
        {/*        />*/}
        {/*        <div className="home-game-category-label-right">{t("LOTTERY")}</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*</div>*/}
        {/*<hr className="hr-left" />*/}
        {/*{Object.keys(masterProviderList).length > 0 &&*/}
        {/*  <>{<>*/}
        {/*    {isGettingGames ? <div className="provider-spinner"><Spinner animation="border" role="status"></Spinner></div> : <div id="provider-section">*/}
        {/*      <div className="home-provider-container mt-5">*/}
        {/*        {Object.keys(masterProviderList).map((list, index) => {*/}
        {/*          return (*/}
        {/*            <div key={index} className="home-provider-parent mt-4">*/}
        {/*              {*/}
        {/*                masterProviderList[list].map((provider, i) => {*/}
        {/*                  return (*/}
        {/*                    <div key={i} className="home-provider-items" onClick={() => handleProviderClick(index, i, provider)}>*/}
        {/*                      <img src={provider.productImage} alt="provider" className="w-100" onClick={() => handleProviderClick(index, i, provider)} />*/}
        {/*                    </div>)*/}
        {/*                })*/}
        {/*              }*/}
        {/*            </div>*/}
        {/*          )*/}
        {/*        })*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*    </div>}*/}
        {/*    <hr className="hr-right" />*/}
        {/*  </>}</>*/}
        {/*}*/}
        {/*{isRecommendedMobileGame && isRecommendedMobileGame.length > 0 &&*/}
        {/*  <>*/}
        {/*      <h3>{t("POPULAR_GAMES")}</h3>*/}
        {/*      <p className="under-title-text">*/}
        {/*        {t("BROWSE_GAMES_FROM_CATEGORIES")}*/}
        {/*  </p>*/}
        {/*  <PopularMatch isRecommendedMobileGame={isRecommendedMobileGame} startGame={startGame} />*/}
        {/*  <hr className="custom-hr" />*/}
        {/*  </>*/}
        {/*}*/}





        {/*{!gettingNewAndPopularGames ? <>{newGames.length > 0 ? <GameListGrid*/}
        {/*  title={t("NEW_GAMES")}*/}
        {/*  description={t("ENJOY_THE_NEWEST_GAMES")}*/}
        {/*  gameList={newGames}*/}
        {/*  type="newGames"*/}
        {/*  page="home"*/}
        {/*/> : <></>}</> : <div className="text-center"><Spinner animation="border" role="status" /></div>}*/}




        {/*{newGames &&*/}
        {/*  newGames.length > 0 &&*/}
        {/*  <>*/}
        {/*    <div className="title-themed mt-5">*/}
        {/*      <h4>{t("NEW_GAMES")}</h4>*/}
        {/*      <p>{t("ENJOY_THE_NEWEST_GAMES")}</p>*/}
        {/*    </div>*/}
        {/*    <div className="home-games-grid-container mt-5">*/}
        {/*    {map(newGames, (item, index) => {*/}
        {/*      return (*/}
        {/*        <div key={index} className="home-games-grid-items">*/}
        {/*          <img src={item.image} alt={item.gameName} className="w-100" />*/}
        {/*          <div className="home-games-grid-title mb-2 mt-3">*/}
        {/*            {item.gameName}*/}
        {/*          </div>*/}
        {/*          <div className="home-games-grid-provider">*/}
        {/*            - {item.providerName}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*    </div>*/}
        {/*  </>}*/}
        {/*{isGameForYouMobileGame &&*/}
        {/*  isGameForYouMobileGame.length > 0 &&*/}
        {/*  <>*/}
        {/*  <div className="home-title-with-arrows mt-5">*/}
        {/*    <div className="title-themed">*/}
        {/*      <h4>{t("GAMES_FOR_YOU")}</h4>*/}
        {/*      <p>{t("CURATED_GAME_LIST")}</p>*/}
        {/*    </div>*/}
        {/*    <div className="slick-custom-arrows">*/}
        {/*      <div onClick={gameForYouPrev}>*/}
        {/*        <img src="/img/version3/arrow-left.png" alt="left arrow" />*/}
        {/*      </div>*/}
        {/*      <div onClick={gameForYouNext}>*/}
        {/*        <img src="/img/version3/arrow-right.png" alt="right arrow" />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*    <Slider*/}
        {/*  {...gameForYouSlick}*/}
        {/*  ref={(c) => (gameForYouSlider.current = c)}*/}
        {/*  className="gameforyou-slick"*/}
        {/*>*/}
        {/*  {map(isGameForYouMobileGame, (item, index) => {*/}
        {/*    return (*/}
        {/*      <div key={index} className="home-games-grid-items">*/}
        {/*        <img src={item.image} alt={item.gameName} className="w-100" />*/}
        {/*        <div className="home-games-grid-title mb-2 mt-3">*/}
        {/*          {item.gameName}*/}
        {/*        </div>*/}
        {/*        <div className="home-games-grid-provider">*/}
        {/*          - {item.product.productName}*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*  </Slider>*/}
        {/*  </>*/}
        {/*}*/}

        <div className="home-title-with-arrows mt-5">
          <div className="title-themed">
            <h4>{t("APP_DOWNLOAD")}</h4>
            <p>{t("LETS_PLAY_TOGETHER")}</p>
          </div>
        </div>
        <DownloadApp />
        {/*<div className="home-subscribe-button mt-5">*/}
        {/*  <h2 className="mb-3">{t("STAY_IN_I8GLOBAL")}</h2>*/}
        {/*  <p className="mb-4">*/}
        {/*    {t("SUBSCRIBE_SENTENCES") }.{" "}*/}
        {/*    <br />*/}
        {/*    {t("SUBSCRIBE_SENTENCES_2")}{" "}*/}
        {/*  </p>*/}
        {/*  <div className="subscribe-input-box">*/}
        {/*    <div className="first">*/}
        {/*      <Input name="email" placeholder={t("ENTER_EMAIL_ADRRESS")} />*/}
        {/*    </div>*/}
        {/*    <div className="second">*/}
        {/*      <button className="btn btn-subscribe">{t("CONTINUE") }</button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <LiveChatModal />
      <CustomMiniGame parentGames93Connect={games93Connect} startGame={startGame} />


      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={isLoggedIn && showCreateShareholderPin}
        centered
      >
        <ModalBody>
          <form>
            <div className="display-wrapper" style={{ textAlign: "center" }}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  onCreatePinKeyPress("{bksp}");
                }
              }}
            >
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "20px" }}
              >
                {t("NO_SHAREHOLDER_PIN_FOUND")}
              </strong>
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                }}
              >
                {t("ENTER_YOUR_PIN")}
              </strong>
              <PinInput
                ref={elePin}
                length={4}
                initialValue=""
                secret={true}
                focus={true}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberFocused(index);
                  onCreatePinKeyPress(value);
                }}
                onComplete={(value) => adjustFocus()}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                  marginTop: "30px",
                }}
              >
                {t("CONFIRM_YOUR_PIN")}
              </strong>
              <PinInput
                ref={eleConfirm}
                length={4}
                initialValue=""
                secret={true}
                focus={false}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberConfirmFocused(index);
                  onCreatePinKeyPress(value);
                }}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
              {/* <Keyboard
                layout={{
                  default: ["1 2 3 4 5 6 7 8 9 . 0 {bksp}"],
                }}
                display={{
                  "{bksp}": "<i class='fas fa-backspace'></i>",
                }}
                theme={`keyboard keyboard-numpad`}
                keyboardRef={(r) => (keyboard.current = r)}
                onKeyPress={onCreatePinKeyPress}
                disableButtonHold
                disableCaretPositioning
              /> */}
            </div>
          </form>
        </ModalBody>
      </Modal>
      <BannerPopupModal />
    </>
  );
};
export default Home;