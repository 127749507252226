import React, { useState, useEffect } from "react"; 
import { find, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  LanguageOption,
  ApiUrl,
  WebUrl,
  SessionKey
} from "./../../util/Constant.js";
import { setBusy, setIdle } from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "./../../util/ApiEngine.js";
import { createMultiPartFormBody } from "./../../util/Util.js";
import { saveLanguageId } from "../../redux/LanguageAction.js";     

const Footer = () => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const selectedLanguageId = useSelector(
    (state) => state.languageState.languageId
  );

  const isLoggedIn = useSelector((state) => state.authState.isLoggedIn);
  const _userData = useSelector((state) => state.authState.userData);

  useEffect(() => {
    if (selectedLanguageId) {
      const selectedItem = find(
        LanguageOption,
        (item) => item.code === selectedLanguageId
      );

      setSelectedLanguage(selectedItem.prefix);
    }
  }, [selectedLanguageId]);

  const aboutMenu = [
    {
      link: WebUrl._URL_ABOUT.replace(":category", "about-us"),
      text: t("ABOUT_US"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE,
      text: t("HELP_CENTRE"),
    },
    {
      link: WebUrl._URL_ABOUT.replace(":category", "terms-and-conditions"),
      text: t("TERMS_AND_CONDITIONS"),
    },
    {
      link: WebUrl._URL_ABOUT.replace(":category", "privacy-policy"),
      text: t("PRIVACY_POLICY"),
    },
    {
      link: WebUrl._URL_ABOUT.replace(":category", "affiliate-partner-program"),
      text: t("AFFILIATE_PARTNER_PROGRAM"),
    },
    {
      link: WebUrl._URL_ABOUT.replace(":category", "responsible-gambling"),
      text: t("RESPONSIBLE_GAMBLING"),
    },
  ];

  const supportMenu = [
    /* TEMPORARILY HIDE PAYMENT OPTIONS UNTIL WE HAVE CONTENT */
    // {
    //   link: WebUrl._URL_HELP_CENTRE_CATEGORY.replace(":category", "Payment"),
    //   text: t("PAYMENT_OPTION"),
    // },
    {
      link: WebUrl._URL_HELP_CENTRE,
      text: t("HELP_CENTRE"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
        .replace(":category", "Game")
        .replace(":sub_category", "How to Guide"),
      text: t("HOW_TO_GUIDE"),
    },
    {
      link: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY
        .replace(":category", "Game")
        .replace(":sub_category", "General Betting Rules"),
      text: t("GENERAL_BETTING_RULES"),
    },
  ];

  const paymentMethods = [
    {
      className: "img-payment",
      alt: "spg",
      image: "/img/version3/footer/SPG.svg",
    },
    {
      className: "img-payment",
      alt: "PayTrust",
      image: "/img/version3/footer/Paytrust.svg",
    },
    {
      className: "img-payment",
      alt: "touch n go",
      image: "/img/version3/footer/TNGo.svg",
    },
    {
      className: "img-payment-fatty-pay",
      alt: "FattyPay",
      image: "/img/version3/footer/Fattypay.svg",
    },
    {
      className: "img-payment",
      alt: "VaderPay",
      image: "/img/version3/footer/Vaderpay.svg",
    },
  ];

  const security = [
    {
      alt: "Iovation",
      image: "/img/version3/footer/iovation.svg",
    },
    {
      alt: "ThreatMetrix",
      image: "/img/version3/footer/threatmetrix.svg",
    },
  ];
  const browser = [
    {
      alt: "Crome",
      image: "/img/version3/footer/crome.png",
    },
    {
      alt: "Firefox",
      image: "/img/version3/footer/firefox.png",
    },
  ];

  const socialMedias = [
    {
      alt: "facebook",
      image: "/img/version3/footer/fb.png",
      link: "https://www.facebook.com/i8OfficialTeam",
    },
    {
      alt: "instagram",
      image: "/img/version3/footer/ig.png",
      link: "https://instagram.com/i8.live",
    },
    {
      alt: "youtube",
      image: "/img/version3/footer/yt.png",
      link: "https://www.youtube.com/channel/UCxrKFaJx8jENt4hjrnKtYiQ",
    },
    //{
    //  alt: "twitter",
    //  image: require("../../assets/img/social-media/tw.png"),
    //  link: "https://twitter.com/?lang=en",
    //},

  ];

  const _LANG_NAME = {
    EN: "English",
    中文: "Chinese",
    BM: "Bahasa Melayu",
    BD: "Bangladesh",
    TH: "Thai",
    VI: "Vietnamese",
    ID: "Indonesia",
  };

  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    setSelectedLanguage(
      LanguageOption.find((x) => x.code === selectedLanguage).prefix
    );
    i18n.changeLanguage(selectedLanguage);
    if (!isLoggedIn) {
      _dispatch(saveLanguageId(selectedLanguage, true));
    } else {
      _dispatch(saveLanguageId(selectedLanguage));
      let data = {};
      data["id"] = _userData.userId;
      data["selectedTheme"] = _userData.selectedTheme;
      data["languageSetting"] = selectedLanguage;
      data["isMemberOperate"] = true;

      // Silently update user's language settings
      await ApiEngine.post(
        ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        createMultiPartFormBody(data)
      );

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }
    }
    _dispatch(setIdle());
  };

  useEffect(() => {
    // const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
    if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
        setSelectedLanguage(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).prefix
        );
      }
      else {
        setSelectedLanguage("EN");
        localStorage.setItem(SessionKey._LANGUAGE, "en");
      }
    } else {
      setSelectedLanguage("EN");
      localStorage.setItem(SessionKey._LANGUAGE, "en");
    }
  }, []);

  return (
    <>
      <footer id='footer'>
        <div className='container container-theme-width'>
          <div className='row footer-first-row'>
            <div className="col-12">
              <div className="footer-top-container">
                <div className="first">
                  <h4>{t("GAMING_LICENCE")}</h4>
                  <img
                    src="/img/version3/footer/Pagcor.svg"
                    className='img-responsive footer-pagcore'
                    alt='pagcor'
                  />
                  <p className="footer-gld mt-3">
                    {t("GAMING_LICENCE_DETAILS")}
                  </p>
                </div>
                <div>
                  <h4>{t("CERTIFICATION")}</h4>
                  <img
                    src="/img/version3/footer/Certification.svg"
                    className='img-responsive'
                    alt='certificate'
                  />
                </div>
                <div>
                  <h4>{t("SECURITY")}</h4>
                  <ul className='list-unstyled footer-payment'>
                    {security &&
                      security.length &&
                      map(security, (item, index) => {
                        return (
                          <li key={index}>
                            <img
                              src={item.image}
                              className='img-responsive'
                              alt={item.alt}
                            />
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
                <div>
                  <h4>{t("PAYMENT_METHOD")}</h4>
                  <ul className='list-unstyled payment-method'>
                    {paymentMethods &&
                      paymentMethods.length &&
                      map(paymentMethods, (item, index) => {
                        return (
                          <li key={index}>
                            <img
                              src={item.image}
                              className={item.className}
                              alt={item.alt}
                            />
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <hr className="footer-hr"/>
          </div>
          <div className='row footer-second-row'>
            <div className='col-4 footer-social-media'>
              <h4>{t("FOLLOW_US")}</h4>
              <ul className='list-unstyled'>
                {socialMedias &&
                  socialMedias.length &&
                  map(socialMedias, (item, index) => {
                    return (
                      <li key={index} className="cursor-pointer">
                        <a onClick={() => window.open(item.link)}>
                          <img
                            src={item.image}
                            className='img-responsive'
                            alt={item.alt}
                          />
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className='col-3'>
              <h4>{t("SUGGESTED_BROWSER")}</h4>
              <ul className='list-unstyled footer-payment'>
                {browser &&
                  browser.length &&
                  map(browser, (item, index) => {
                    return (
                      <li key={index}>
                        <img
                          src={item.image}
                          className='img-responsive'
                          alt={item.alt}
                        />
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
          <div className="row">
            <hr className="footer-hr"/>
          </div> */}
          <div className="row mt-5">
            <div className="col-12">
              <ul className='list-unstyled f-links'>
                {aboutMenu &&
                  aboutMenu.length &&
                  map(aboutMenu, (item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.link}>{item.text}</a>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>

        </div>
        <hr className="footer-hr" />
        <div className="container container-theme-width">
          <div className='row copy-right'>
            <div className='col-12' style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="copy-right-body">
                <h4 className='font-14'>
                  {t("COPYRIGHT")}
                  <img
                    src="/img/footer/18plus.svg"
                    className='img-responsive'
                    alt="18+"
                  />
                </h4>
                <div className="footer-social-media-box">
                  <div className="media-icons">
                    <ul className='list-unstyled social-media'>
                      {socialMedias &&
                        socialMedias.length &&
                        map(socialMedias, (item, index) => {
                          return (
                            <li key={index} className="cursor-pointer">
                              <a onClick={() => window.open(item.link)}>
                                <img
                                  src={item.image}
                                  className='img-responsive'
                                  alt={item.alt}
                                />
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="social-divider"></div>
                    <ul className='list-unstyled social-media'>
                      {browser &&
                        browser.length &&
                        map(browser, (item, index) => {
                          return (
                            <li key={index} className="cursor-pointer">
                              <a onClick={() => window.open(item.link)}>
                                <img
                                  src={item.image}
                                  className='img-responsive'
                                  alt={item.alt}
                                />
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="version-number">{"Ver. " + process.env.REACT_APP_VERSION}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
