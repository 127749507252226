import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { map } from "lodash";
import {
  ApiUrl,
  ApiKey,
  AlertTypes,
  SessionKey,
  WebUrl,
  DEFAULT_PAGE_SIZE,
  PanelType
} from "../../util/Constant";
import { useHistory, useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import ApiEngine from "../../util/ApiEngine";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage,
} from "../../redux/AppAction";
import {
  createFormBody,
  isObjectEmpty,
  stringIsNullOrEmpty,
} from "../../util/Util";
import ContentHeader from "components/content/header";
import PlusMinusMaxButton from "../../components/custom/PlusMinusMaxButton";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import Input from "../../components/custom/Input";
import { AsyncPaginate } from "react-select-async-paginate";

const ReferralEdit = (props) => {
  const { t, i18n } = useTranslation();
  const _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const [referralCodeId, setReferralCodeId] = useState();
  const [remark, setRemark] = useState("");
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [valueList, setValueList] = useState([]);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showCategoryKeyboard, setShowCategoryKeyboard] = useState(false);
  const [initCheck, setInitCheck] = useState(false);
  const [selectedValue, setSelectedValue] = useState("0000");
  const [currentIndexOfValue, setCurrentIndexOfValue] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(-1);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");
  const [selectedBonusOrComm, setSelectedBonusOrComm] = useState("");
  const [selectedProductsIndex, setSelectedProductsIndex] = useState(0);
  const { control, handleSubmit, setValue, errors, getValues } = useForm();
  const [contentTitle, setContentTitle] = useState("");
  const [referralCodeCount, setReferralCodeCount] = useState(0);
  //const [latestGame, setLatestGame] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? 
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';
  
  const {
    register: registerInternalTransfer,
    handleSubmit: handleSubmitInternalTransfer,
    errors: errorsInternalTransfer,
    control: controlInternalTransfer,
    setValue: setValueInternalTransfer
  } = useForm();

  useEffect(() => {
    init(true);
  }, [_location.state]);

  useEffect(() => {
    if (!_location.state) {
      setContentTitle("NEW_REFERRAL_DETAIL");
      products.map((product, index) => {
        setValue("bonus-" + product.productId, 0.0);
        setValue("commission-" + product.productId, 0.0);
        setValue("clubFee-" + product.productId, 0.0);
      });
      categories.map((category, index) => {
        setValue("category-bonus-" + category.id, 0.0);
        setValue("category-commission-" + category.id, 0.0);
        setValue("category-clubFee-" + category.id, 0.0);
      });
    } else {
      setContentTitle("EDIT_REFERRAL_DETAIL");
      setReferralCodeId(_location.state.id);
      if (!isObjectEmpty(_location.state.referralCodeDetail)) {
        _location.state.referralCodeDetail.memberReferralInfos.map((info) => {
          setValue("bonus-" + info.productId, info.bonus);
          setValue("commission-" + info.productId, info.commission);
          setValue("clubFee-" + info.productId, info.clubFee);
        });
        setRemark(_location.state.referralCodeDetail.remark);
      }
    }
  }, [initCheck]);

  useEffect(() => {
    init(false);
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
  localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    getReferralGroups();
    //getLatestGame();
  }, []);

  const getReferralGroups = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_REFERRAL_CODES
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setReferralCodeCount((responseJson[ApiKey._API_DATA_KEY]).length);
    }
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  const init = async (init) => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MEMBER_PRODUCT_RATES
    );
    let uplineProductRates = [];
    let processedProducts = [];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      uplineProductRates = responseJson[ApiKey._API_DATA_KEY];
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }

    var productResponseJson = await ApiEngine.get(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT
    );
    if (productResponseJson[ApiKey._API_SUCCESS_KEY]) {
      productResponseJson[ApiKey._API_DATA_KEY].products.map((product) => {
        let uplineProduct = uplineProductRates.filter(
          (i) => i.accountProductId == product.accountProductId
        )[0];
        processedProducts.push({
          id: product.id,
          productId: product.productId,
          accountProductId: product.accountProductId,
          productName: product.productName + (product.useGroupRate ? " *" : ""),
          assigned: product.assigned,
          bonus: product.bonus,
          commission: product.commission,
          clubFee: product.clubFee,
          categoryName: product.categoryName,
          maxBonus: uplineProduct != null ? uplineProduct.bonus : 0,
          maxCommission: uplineProduct != null ? uplineProduct.commission : 0,
          maxClubFee: uplineProduct != null ? uplineProduct.clubFee : 0,
          useGroupRate: product.useGroupRate,
        });
      });
    } else {
      throw productResponseJson[ApiKey._API_MESSAGE_KEY];
    }

    setProduct(
      products["length"] == 0
        ? processedProducts[0]
        : processedProducts.find((x) => x["id"] == product["id"])
    );
    setProducts(processedProducts);

    let tempCategoryData = [];
    let categoryJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
        "?selectedLanguage=" +
        selectedLanguage
    );
    if (categoryJson[ApiKey._API_DATA_KEY]) {
      tempCategoryData = categoryJson[ApiKey._API_DATA_KEY];
    }
    setActiveTab(tempCategoryData[0]["id"]);
    let tempObj = {};
    let tempHighestMaxBonus = 0.0;
    let tempHighestMaxComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    let tempNonEmptyCategories = [];
    tempCategoryData.map((category, index) => {
      if (
        processedProducts.filter(
          (x) =>
            x.categoryName === category.originalTitle &&
            (x.maxBonus > 0 || x.maxCommission > 0 || x.maxClubFee > 0)
        ).length > 0 ||
        category.originalTitle === "All"
      ) {
        tempNonEmptyCategories.push(category);
      }
      if (!tempObj[category.originalTitle]) {
        tempObj[category.originalTitle] = {
          bonusValues: [],
          commValues: [],
          clubFeeValues: [],
          highestMaxBonus: 0,
          highestMaxComm: 0,
          highestMaxClubFee: 0,
        };
      }
      processedProducts
        .filter((x) => x.categoryName === category.originalTitle)
        .map((product, i) => {
          if (i === 0) {
            tempHighestMaxBonus = product.maxBonus;
            tempHighestMaxComm = product.maxCommission;
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (!tempObj[category.originalTitle][product.productName])
            tempObj[category.originalTitle][product.productName] = {
              bonusValues: [],
              commValues: [],
              clubFeeValues: [],
            };
          if (product.maxBonus > tempHighestMaxBonus) {
            tempHighestMaxBonus = product.maxBonus;
          }
          if (product.maxCommission > tempHighestMaxComm) {
            tempHighestMaxComm = product.maxCommission;
          }
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (product.maxBonus > 0) {
            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(product.maxBonus.toFixed(2));
              i += 0.1
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));

              if (i === 0.0) {
                tempObj[category.originalTitle][
                  product.productName
                ].bonusValues.push("-");
              }

              tempObj[category.originalTitle][
                product.productName
              ].bonusValues.push(i);
            }
          }
          if (product.maxCommission > 0) {
            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(product.maxCommission.toFixed(2));
              i += 0.1
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));

              if (i === 0.0) {
                tempObj[category.originalTitle][
                  product.productName
                ].commValues.push("-");
              }

              tempObj[category.originalTitle][
                product.productName
              ].commValues.push(i);
            }
          }
          if (product.maxClubFee > 0) {
            for (
              var i = 0.0;
              parseFloat(i.toFixed(2)) <=
              parseFloat(product.maxClubFee.toFixed(2));
              i += 5.0
            ) {
              i = i * 100;
              i = Math.round(i);
              i = i / 100;
              i = parseFloat(i.toFixed(2));

              if (i === 0.0) {
                tempObj[category.originalTitle][
                  product.productName
                ].clubFeeValues.push("-");
              }

              tempObj[category.originalTitle][
                product.productName
              ].clubFeeValues.push(i);
            }
          }
        });

      tempObj[category.originalTitle]["highestMaxBonus"] =
        tempHighestMaxBonus;
      tempObj[category.originalTitle]["highestMaxComm"] = tempHighestMaxComm;
      tempObj[category.originalTitle]["highestMaxClubFee"] =
        tempHighestMaxClubFee;

      for (
        var i = 0.0;
        parseFloat(i.toFixed(2)) <=
        parseFloat(tempHighestMaxBonus.toFixed(2));
        i += 0.1
      ) {
        i = i * 100;
        i = Math.round(i);
        i = i / 100;
        i = parseFloat(i.toFixed(2));

        if (i === 0.0) {
          tempObj[category.originalTitle].bonusValues.push("-");
        }
        tempObj[category.originalTitle].bonusValues.push(i);
      }
      for (
        var i = 0.0;
        parseFloat(i.toFixed(2)) <= parseFloat(tempHighestMaxComm.toFixed(2));
        i += 0.1
      ) {
        i = i * 100;
        i = Math.round(i);
        i = i / 100;
        i = parseFloat(i.toFixed(2));

        if (i === 0.0) {
          tempObj[category.originalTitle].commValues.push("-");
        }
        tempObj[category.originalTitle].commValues.push(i);
      }
      for (
        var i = 0.0;
        parseFloat(i.toFixed(2)) <=
        parseFloat(tempHighestMaxClubFee.toFixed(2));
        i += 5.0
      ) {
        i = i * 100;
        i = Math.round(i);
        i = i / 100;
        i = parseFloat(i.toFixed(2));

        if (i === 0.0) {
          tempObj[category.originalTitle].clubFeeValues.push("-");
        }
        tempObj[category.originalTitle].clubFeeValues.push(i);
      }
    });
    setActiveTab(tempNonEmptyCategories[0]["id"]);

    setValueList(tempObj);
    setCategories(tempNonEmptyCategories);
    setInitCheck(true);
  };

  const submitForm = async (data) => {
    _dispatch(setBusy());
    //if (expiryDate == '' || expiryDate == null) {
    //    _dispatch(showResponseMessage(false, t("EXPIRY_DATE_INVALID")));
    //}
    let params = {
      //expiryDate: expiryDate,
      remark: remark,
    };

    if (!stringIsNullOrEmpty(referralCodeId)) {
      params["id"] = referralCodeId;
    }

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_GENERATE_MEMBER_REFERRAL_CODE,
      formBody
    );
    _dispatch(
      showMessage({
        type: responseJson[ApiKey._API_SUCCESS_KEY]
          ? AlertTypes._SUCCESS
          : AlertTypes._ERROR,
        content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        onConfirm: () => {
          _history.replace({
            pathname: WebUrl._URL_REFERRAL_CODE,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        },
      })
    );
    _dispatch(setIdle());
  };

  function onEditReferralKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    } else {
      tempSlicedValueStr = valueStr.substring(
        tempCurrentIndex + 1,
        valueStr.length
      );
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      } else {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          } else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              } else {
                valueStr = "" + (parseInt(valueStr) + 1);
              }
            } else {
              valueStr =
                valueStr.substring(0, 2) +
                (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
                "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(
            valueStr.substring(0, 3) +
              "." +
              valueStr.substring(3, valueStr.length)
          );
        } else {
          tempValue = parseFloat(
            valueStr.substring(0, 2) +
              "." +
              valueStr.substring(2, valueStr.length)
          );
        }
        if (selectedBonusOrComm === "bonus") {
          if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
            tempValue = products[selectedProductsIndex]["maxBonus"];
          }
        } else if (selectedBonusOrComm === "commission") {
          if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
            tempValue = products[selectedProductsIndex]["maxCommission"];
          }
        } else if (selectedBonusOrComm === "clubFee") {
          if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
            tempValue = products[selectedProductsIndex]["maxClubFee"];
          }
        } else {
          //error
        }
        setValue("" + selectedBonusOrComm + "-" + selectedProductId, tempValue);
        setTimeout(() => setShowKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    } else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        } else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            } else {
              valueStr = "" + (parseInt(valueStr) + 1);
            }
          } else {
            valueStr =
              valueStr.substring(0, 2) +
              (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
              "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(
          valueStr.substring(0, 3) +
            "." +
            valueStr.substring(3, valueStr.length)
        );
      } else {
        tempValue = parseFloat(
          valueStr.substring(0, 2) +
            "." +
            valueStr.substring(2, valueStr.length)
        );
      }
      if (selectedBonusOrComm === "bonus") {
        if (tempValue > products[selectedProductsIndex]["maxBonus"]) {
          tempValue = products[selectedProductsIndex]["maxBonus"];
        }
      } else if (selectedBonusOrComm === "commission") {
        if (tempValue > products[selectedProductsIndex]["maxCommission"]) {
          tempValue = products[selectedProductsIndex]["maxCommission"];
        }
      } else if (selectedBonusOrComm === "clubFee") {
        if (tempValue > products[selectedProductsIndex]["maxClubFee"]) {
          tempValue = products[selectedProductsIndex]["maxClubFee"];
        }
      } else {
        //error
      }
      setValue("" + selectedBonusOrComm + "-" + selectedProductId, tempValue);
      setTimeout(() => setShowKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    } else {
      setSelectedValue("0000");
    }
  }

  function onCategoryKeyPress(key) {
    let valueStr = selectedValue;
    let tempCurrentIndex = currentIndexOfValue;
    let tempSlicedValueStr = "";
    let tempValue = 0;
    if (valueStr === "0000") {
      tempCurrentIndex = valueStr.length - 1;
      setCurrentIndexOfValue(tempCurrentIndex);
    } else {
      tempSlicedValueStr = valueStr.substring(
        tempCurrentIndex + 1,
        valueStr.length
      );
    }
    if (key !== "{bksp}" && key !== "{enter}") {
      if (tempCurrentIndex > 0) {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        setCurrentIndexOfValue(tempCurrentIndex - 1);
        setSelectedValue(valueStr);
      } else {
        valueStr =
          valueStr.substring(0, tempCurrentIndex) + tempSlicedValueStr + key;
        if (valueStr.charAt(valueStr.length - 1) !== "0") {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
            valueStr = valueStr.substring(0, 3) + "0";
          } else {
            if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
              if (valueStr === "9999") {
                valueStr = "10000";
              } else {
                valueStr = "" + (parseInt(valueStr) + 1);
              }
            } else {
              valueStr =
                valueStr.substring(0, 2) +
                (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
                "0";
            }
          }
        }
        setSelectedValue(valueStr);
        if (valueStr.length === 5) {
          tempValue = parseFloat(
            valueStr.substring(0, 3) +
              "." +
              valueStr.substring(3, valueStr.length)
          );
        } else {
          tempValue = parseFloat(
            valueStr.substring(0, 2) +
              "." +
              valueStr.substring(2, valueStr.length)
          );
        }
        if (selectedBonusOrComm === "bonus") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxBonus"]) {
                setValue("bonus-" + product.productId, product["maxBonus"]);
              } else {
                setValue("bonus-" + product.productId, tempValue);
              }
            });
        } else if (selectedBonusOrComm === "commission") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxCommission"]) {
                setValue(
                  "commission-" + product.productId,
                  product["maxCommission"]
                );
              } else {
                setValue("commission-" + product.productId, tempValue);
              }
            });
        } else if (selectedBonusOrComm === "clubFee") {
          products
            .filter((x) => x.categoryName === selectedCategoryTitle)
            .map((product, index) => {
              if (tempValue > product["maxClubFee"]) {
                setValue("clubFee-" + product.productId, product["maxClubFee"]);
              } else {
                setValue("clubFee-" + product.productId, tempValue);
              }
            });
        } else {
          //error
        }
        setValue(
          "category-" + selectedBonusOrComm + "-" + selectedCategoryId,
          tempValue
        );
        setTimeout(() => setShowCategoryKeyboard(false), 500);
        setTimeout(() => setSelectedValue("0000"), 600);
      }
    } else if (key === "{enter}") {
      if (valueStr.charAt(valueStr.length - 1) !== "0") {
        if (parseInt(valueStr.charAt(valueStr.length - 1)) < 5) {
          valueStr = valueStr.substring(0, 3) + "0";
        } else {
          if (parseInt(valueStr.charAt(valueStr.length - 1)) === 9) {
            if (valueStr === "9999") {
              valueStr = "10000";
            } else {
              valueStr = "" + (parseInt(valueStr) + 1);
            }
          } else {
            valueStr =
              valueStr.substring(0, 2) +
              (parseInt(valueStr.charAt(valueStr.length - 2)) + 1) +
              "0";
          }
        }
      }
      if (valueStr.length === 5) {
        tempValue = parseFloat(
          valueStr.substring(0, 3) +
            "." +
            valueStr.substring(3, valueStr.length)
        );
      } else {
        tempValue = parseFloat(
          valueStr.substring(0, 2) +
            "." +
            valueStr.substring(2, valueStr.length)
        );
      }
      if (selectedBonusOrComm === "bonus") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxBonus"]) {
              setValue("bonus-" + product.productId, product["maxBonus"]);
            } else {
              setValue("bonus-" + product.productId, tempValue);
            }
          });
      } else if (selectedBonusOrComm === "commission") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxCommission"]) {
              setValue(
                "commission-" + product.productId,
                product["maxCommission"]
              );
            } else {
              setValue("commission-" + product.productId, tempValue);
            }
          });
      } else if (selectedBonusOrComm === "clubFee") {
        products
          .filter((x) => x.categoryName === selectedCategoryTitle)
          .map((product, index) => {
            if (tempValue > product["maxClubFee"]) {
              setValue("clubFee-" + product.productId, product["maxClubFee"]);
            } else {
              setValue("clubFee-" + product.productId, tempValue);
            }
          });
      } else {
        //error
      }
      setValue(
        "category-" + selectedBonusOrComm + "-" + selectedCategoryId,
        tempValue
      );
      setTimeout(() => setShowCategoryKeyboard(false), 500);
      setTimeout(() => setSelectedValue("0000"), 600);
    } else {
      setSelectedValue("0000");
    }
  }

  function handleSelectCatValue(bonusOrComm, categoryId, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );
          } else {
            setValue(bonusOrComm + "-" + product.productId, value);
          }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          setValue(bonusOrComm + "-" + product.productId, "-");
        });
    }
    setValue("category-" + bonusOrComm + "-" + categoryId, value);
  }

  function handleSelectValue(bonusOrComm, productId, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;
    }
    setValue("" + bonusOrComm + "-" + productId, value);
  }

  function handleCatBtn(bonusOrComm, categoryTitle, value) {
    if (value !== "-") {
      value *= 100;
      value = Math.round(value);
      value /= 100;

      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          let tempBonusOrComm =
            bonusOrComm.substring(0, 1).toUpperCase() +
            bonusOrComm.substring(1, bonusOrComm.length);
          if (value > product["max" + tempBonusOrComm]) {
            setValue(
              bonusOrComm + "-" + product.productId,
              product["max" + tempBonusOrComm]
            );
          } else {
            setValue(bonusOrComm + "-" + product.productId, value);
          }
        });
    } else {
      products
        .filter((x) => x.categoryName === categoryTitle)
        .map((product, index) => {
          setValue(bonusOrComm + "-" + product.productId, "-");
        });
    }
  }

  function handleMaxBtn(productId, maxBonus, maxComm, maxClubFee, bonusOrComm) {
    if (bonusOrComm === "bonus") {
      setValue("bonus-" + productId, maxBonus);
    } else if (bonusOrComm === "commission") {
      setValue("commission-" + productId, maxComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("clubFee-" + productId, maxClubFee);
    }
  }

  function handleMaxCatBtn(categoryId, categoryTitle, bonusOrComm) {
    let tempHighestBonus = 0.0;
    let tempHighestComm = 0.0;
    let tempHighestMaxClubFee = 0.0;
    products
      .filter((x) => x.categoryName === categoryTitle)
      .map((product, index) => {
        if (bonusOrComm === "bonus") {
          if (product.maxBonus > tempHighestBonus) {
            tempHighestBonus = product.maxBonus;
          }
          if (100.0 > product.maxBonus) {
            setValue("bonus-" + product.productId, product["maxBonus"]);
          } else {
            setValue("bonus-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "commission") {
          if (product.maxCommission > tempHighestComm) {
            tempHighestComm = product.maxCommission;
          }
          if (100.0 > product.maxCommission) {
            setValue(
              "commission-" + product.productId,
              product["maxCommission"]
            );
          } else {
            setValue("commission-" + product.productId, 100.0);
          }
        } else if (bonusOrComm === "clubFee") {
          if (product.maxClubFee > tempHighestMaxClubFee) {
            tempHighestMaxClubFee = product.maxClubFee;
          }
          if (100.0 > product.maxClubFee) {
            setValue("clubFee-" + product.productId, product["maxClubFee"]);
          } else {
            setValue("clubFee-" + product.productId, 100.0);
          }
        }
      });

    if (bonusOrComm === "bonus") {
      setValue("category-bonus-" + categoryId, tempHighestBonus);
    } else if (bonusOrComm === "commission") {
      setValue("category-commission-" + categoryId, tempHighestComm);
    } else if (bonusOrComm === "clubFee") {
      setValue("category-clubFee-" + categoryId, tempHighestMaxClubFee);
    }
  }

  function checkValueForLowest(value) {
    if (value !== "-") {
      if (value === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function checkValueForHighest(value, category, bonusOrComm) {
    if (value !== "-") {
      if (bonusOrComm === "bonus") {
        if (value === valueList[category.originalTitle].highestMaxBonus) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "commission") {
        if (value === valueList[category.originalTitle].highestMaxComm) {
          return false;
        } else {
          return true;
        }
      } else if (bonusOrComm === "clubFee") {
        if (value === valueList[category.originalTitle].highestMaxClubFee) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  async function getCategoryList(search, loadOptions, { page }) {
    var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_PRODUCT_CATEGORY_SEARCH}?start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&languageId=${selectedLanguage}&keyword=${search}`);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY].data;
      formattedData = data.map((m) => { return { label: m.title, value: m.id } });
      var formattedData;
      return {
        options: formattedData,
        hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
        additional: {
          page: page + 1
        }
      };
    }

  }

   const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
      //padding: '0px',
      //background: 'rgba(0, 0, 32)',
      //color: 'white',
      position: 'absolute !important',
      zIndex: 2,
      background: 'rgba(16, 33, 86, 1) !important',
      fontSize: '12px !important'
    }),
    control: (styles, state) => ({
      ...styles,
      //background: 'linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%)',
      //height: '45px',
      //fontSize: '14px',
      borderRadius: '50px',
      boxShadow: '0px 3px 17px 0px rgba(0,0,0,.1)',
      height: '35px',
      background: 'transparent',
      border: '0px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isFocused ? 'black' : 'white',
        //background: 'rgba(0, 0, 32)',
        //color: 'white',
        //boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.3)'
      };
     },
    singleValue: styles => ({
      ...styles,
      //paddingLeft: '1rem',
      //fontSize: '14px',
      //fontWeight: 'bold',
      //color: 'white'
      paddingLeft: '1.2rem',
      fontSize: '12px',
      color: '#3369ff',
      fontWeight: 'bold'
    }),
    placeholder: styles => ({
      ...styles,
      paddingLeft: '1rem',
      fontSize: '14px',
      color: '#FFF'
    }),
    input: styles => ({
      ...styles,
      margin: '2px',
      color: '#fff',
      paddingLeft: '10px'
    })
  }

  function eventActiveTab(data) {
    setActiveTab(data);
    setInitCheck(true);
  } 
  
  const ReferralCodeDetailForm = () => {
    return (
      <>
        <div className="template-box" id="create-referral-form">
          <div className="first">
            
              <input
                type="text"
                name="amount"
                className="custom-input-style-1 rounded"
                placeholder={t("REMARK")}
                defaultValue={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            
          </div>
        </div>
        <div id="downline-search-box-main" className="mt-4">
          {/*<Input*/}
          {/*  placeholder={t("SEARCH_BY_CATEGORY")}*/}
          {/*  inputClassName="downline-search-input"*/}
          {/*  inputGroupType="append"*/}
            
          {/*  inputGroupContent={*/}
          {/*    <i*/}
          {/*      className="fas fa-search"*/}
          {/*      style={{*/}
          {/*        color: "#898F97",*/}
          {/*        fontSize: "14px",*/}
          {/*      }}*/}
          {/*    ></i>*/}
          {/*  }*/}
          {/*/>*/}
          <Controller
            className="product-category-dropdown"
            control={controlInternalTransfer}
            errors={errorsInternalTransfer}
            name="category"
            render={({ onChange, value }) => {
              return (
                <AsyncPaginate
                  styles={_SELECT_STYLES}
                  placeholder={t("SEARCH_BY_CATEGORY")}
                  debounceTimeout={250}
                  loadOptions={getCategoryList}
                  additional={{
                    page: 1,
                  }}
                  value={selectedCategory}
                  onChange={(e) => { eventActiveTab(e.value); onChange(e.value); setSelectedCategory(e); }}
                  noOptionsMessage={() => t("NO_OPTIONS")}
                  loadingMessage={() => t("LOADING...")}
                />
              )
            }}
            rules={{ required: "SEARCH_BY_CATEGORY" }} />
        </div>
        {/* <Nav className="nav nav-tabs mt-4" id="my-tabs">
          {categories &&
            categories.length > 0 &&
            categories.filter((x) => x.originalTitle !== "All").length > 1 &&
            map(categories, (category, index) => {
              if (
                products.filter(
                  (x) => x.categoryName === category.originalTitle
                ).length > 0 ||
                category.originalTitle === "All"
              ) {
                return (
                  <NavItem
                    Key={index}
                    className={classnames({
                      active: activeTab === category.id,
                    })}
                  >
                    <NavLink
                      className={
                        "font-14 " +
                        classnames({
                          active: activeTab === category.id,
                        })
                      }
                      onClick={() => {
                        toggleTab(category.id);
                      }}
                    >
                      {t(category.originalTitle)}
                    </NavLink>
                  </NavItem>
                );
              }
            })}
        </Nav> */}
        
        {initCheck ? (
          <form onSubmit={handleSubmit(submitForm)} id="referral-form">
            <TabContent activeTab={activeTab}>
              {categories &&
                categories.length > 0 &&
                activeTab !==
                  categories.find((x) => x.originalTitle === "All").id &&
                map(categories, (category, index) => {
                  if (
                    products.filter(
                      (x) =>
                        x.categoryName === category.originalTitle &&
                        (x.maxBonus > 0 ||
                          x.maxCommission > 0 ||
                          x.maxClubFee > 0)
                    ).length > 0
                  ) {
                    return (
                      <TabPane tabId={category.id}>
                        <div className="text-white mt-4">
                          <div className="referral-edit-box head-category font-14 mt-2">
                            <div>
                              <h6 className="row-data-1 font-16 text-white font-semi">
                                {t(category.originalTitle)}
                              </h6>
                            </div>

                            <div className="font-14 referral-edit-details-width">
                              {(products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxBonus > 0
                              ).length > 0 ||
                                category.originalTitle === "All") && (
                                <div className="referral-edit-details">
                                  <Controller
                                    control={control}
                                    name={"category-bonus-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "bonus"
                                        );

                                      return (
                                        <>
                                          <div className="first font-12 color-a4">
                                            {t("BONUS")}
                                            <br />
                                            {t("MAX") + " : "}
                                            {valueList &&
                                            valueList[category.originalTitle]
                                              ? valueList[
                                                  category.originalTitle
                                                ].highestMaxBonus.toFixed(2)
                                              : "0"}
                                            %
                                          </div>

                                          <PlusMinusMaxButton
                                            buttonSection="plusminus"
                                            styleValueMinus={valueNotLowest}
                                            onClickMinus={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== "-") {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-bonus-" +
                                                    category.id,
                                                  value
                                                );
                                                handleCatBtn(
                                                  "bonus",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            styleValuePlus={valueNotHighest}
                                            onClickPlus={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== "-") {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-bonus-" +
                                                    category.id,
                                                  value
                                                );
                                                handleCatBtn(
                                                  "bonus",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            optionValue={
                                              valueList &&
                                              valueList[
                                                category.originalTitle
                                              ] &&
                                              valueList[
                                                category.originalTitle
                                              ].bonusValues.map(
                                                (bonusValue, index) => {
                                                  return (
                                                    <option
                                                      className="bonusComm-option-value"
                                                      value={
                                                        bonusValue !== "-"
                                                          ? parseFloat(
                                                              bonusValue.toFixed(
                                                                2
                                                              )
                                                            )
                                                          : "-"
                                                      }
                                                    >
                                                      {bonusValue !== "-"
                                                        ? bonusValue.toFixed(2)
                                                        : "-"}
                                                    </option>
                                                  );
                                                }
                                              )
                                            }
                                            value={value}
                                            category={category}
                                            handleSelectCatValue={
                                              handleSelectCatValue
                                            }
                                            errors={errors}
                                            type="bonus" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                            section="referral"
                                          />

                                          <PlusMinusMaxButton
                                            buttonSection="max"
                                            text={t("MAX")}
                                            styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                            category={category}
                                            onClickMax={() =>
                                              handleMaxCatBtn(
                                                category.id,
                                                category.originalTitle,
                                                "bonus"
                                              )
                                            }
                                            section="referral"
                                          />
                                        </>
                                      );
                                    }}
                                  />
                                </div>
                              )}
                              {(products.filter(
                                (x) =>
                                  x.categoryName === category.originalTitle &&
                                  x.maxCommission > 0
                              ).length > 0 ||
                                category.originalTitle === "All") && (
                                <div className="referral-edit-details">
                                  <Controller
                                    control={control}
                                    name={"category-commission-" + category.id}
                                    defaultValue={0}
                                    render={({ onChange, value }) => {
                                      let valueNotLowest =
                                        checkValueForLowest(value);
                                      let valueNotHighest =
                                        checkValueForHighest(
                                          value,
                                          category,
                                          "commission"
                                        );
                                      return (
                                        <>
                                          <div className="first font-12 color-a4 referral-edit-details-comm">
                                            {t("COMMISSION_SHORT")}
                                            <br />
                                            {t("MAX") + " : "}
                                            {valueList &&
                                            valueList[category.originalTitle]
                                              ? valueList[
                                                  category.originalTitle
                                                ].highestMaxComm.toFixed(2)
                                              : "0"}
                                            %
                                          </div>

                                          <PlusMinusMaxButton
                                            buttonSection="plusminus"
                                            styleValueMinus={valueNotLowest}
                                            onClickMinus={(e) => {
                                              if (valueNotLowest === true) {
                                                if (value !== "-") {
                                                  value -= 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            styleValuePlus={valueNotHighest}
                                            onClickPlus={(e) => {
                                              if (valueNotHighest === true) {
                                                if (value !== "-") {
                                                  value += 0.1;
                                                  value *= 100;
                                                  value = Math.round(value);
                                                  value /= 100;
                                                } else {
                                                  value = 0.0;
                                                }
                                                setValue(
                                                  "category-commission-" +
                                                    category.id,
                                                  parseFloat(value.toFixed(2))
                                                );
                                                handleCatBtn(
                                                  "commission",
                                                  category.originalTitle,
                                                  value
                                                );
                                              }
                                            }}
                                            optionValue={
                                              valueList &&
                                              valueList[
                                                category.originalTitle
                                              ] &&
                                              valueList[
                                                category.originalTitle
                                              ].commValues.map(
                                                (commValue, index) => {
                                                  return (
                                                    <option
                                                      className="bonusComm-option-value"
                                                      value={
                                                        commValue !== "-"
                                                          ? parseFloat(
                                                              commValue.toFixed(
                                                                2
                                                              )
                                                            )
                                                          : "-"
                                                      }
                                                    >
                                                      {commValue !== "-"
                                                        ? commValue.toFixed(2)
                                                        : "-"}
                                                    </option>
                                                  );
                                                }
                                              )
                                            }
                                            value={value}
                                            category={category}
                                            handleSelectCatValue={
                                              handleSelectCatValue
                                            }
                                            errors={errors}
                                            type="commission" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                            section="referral"
                                          />

                                          <PlusMinusMaxButton
                                            buttonSection="max"
                                            text={t("MAX")}
                                            styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                                            category={category}
                                            onClickMax={() =>
                                              handleMaxCatBtn(
                                                category.id,
                                                category.originalTitle,
                                                "commission"
                                              )
                                            }
                                            section="referral"
                                          />
                                        </>
                                      );
                                    }}
                                  />
                                </div>
                              )}
                              {category.originalTitle === "Poker" &&
                                (products.filter(
                                  (x) =>
                                    x.categoryName === category.originalTitle &&
                                    x.maxClubFee > 0
                                ).length > 0 ||
                                  category.originalTitle === "All") && (
                                  <div className="referral-edit-details">
                                    <Controller
                                      control={control}
                                      name={"category-clubFee-" + category.id}
                                      defaultValue={0}
                                      render={({ onChange, value }) => {
                                        let valueNotLowest =
                                          checkValueForLowest(value);
                                        let valueNotHighest =
                                          checkValueForHighest(
                                            value,
                                            category,
                                            "clubFee"
                                          );
                                        return (
                                          <>
                                            <div className="first font-12 color-a4">
                                              {t("CLUB_FEE")}
                                              <br />
                                              {t("MAX") + " : "}
                                              {valueList &&
                                              valueList[category.originalTitle]
                                                ? valueList[
                                                    category.originalTitle
                                                  ].highestMaxClubFee.toFixed(2)
                                                : "0"}
                                              %
                                            </div>

                                            <PlusMinusMaxButton
                                              buttonSection="plusminus"
                                              styleValueMinus={valueNotLowest}
                                              onClickMinus={(e) => {
                                                if (valueNotLowest === true) {
                                                  if (value !== "-") {
                                                    value -= 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                              styleValuePlus={valueNotHighest}
                                              onClickPlus={(e) => {
                                                if (valueNotHighest === true) {
                                                  if (value !== "-") {
                                                    value += 5.0;
                                                    value *= 100;
                                                    value = Math.round(value);
                                                    value /= 100;
                                                  } else {
                                                    value = 0.0;
                                                  }
                                                  setValue(
                                                    "category-clubFee-" +
                                                      category.id,
                                                    parseFloat(value.toFixed(2))
                                                  );
                                                  handleCatBtn(
                                                    "clubFee",
                                                    category.originalTitle,
                                                    value
                                                  );
                                                }
                                              }}
                                              optionValue={
                                                valueList &&
                                                valueList[
                                                  category.originalTitle
                                                ] &&
                                                valueList[
                                                  category.originalTitle
                                                ].clubFeeValues.map(
                                                  (clubFeeValue, index) => {
                                                    return (
                                                      <option
                                                        className="bonusComm-option-value"
                                                        value={
                                                          clubFeeValue !== "-"
                                                            ? parseFloat(
                                                                clubFeeValue.toFixed(
                                                                  2
                                                                )
                                                              )
                                                            : "-"
                                                        }
                                                      >
                                                        {clubFeeValue !== "-"
                                                          ? clubFeeValue.toFixed(
                                                              2
                                                            )
                                                          : "-"}
                                                      </option>
                                                    );
                                                  }
                                                )
                                              }
                                              value={value}
                                              category={category}
                                              handleSelectCatValue={
                                                handleSelectCatValue
                                              }
                                              errors={errors}
                                              type="clubFee" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                              section="referral"
                                            />

                                            <PlusMinusMaxButton
                                              buttonSection="max"
                                              text={t("MAX")}
                                              styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}  
                                              category={category}
                                              onClickMax={() =>
                                                handleMaxCatBtn(
                                                  category.id,
                                                  category.originalTitle,
                                                  "clubFee"
                                                )
                                              }
                                              section="referral"
                                            />
                                          </>
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>

                          {/*foreach product in category*/}

                          {products.filter(
                            (x) => x.categoryName === category.originalTitle
                          ).length > 0 &&
                            products
                              .filter(
                                (product) =>
                                  product.categoryName ===
                                    category.originalTitle &&
                                  (product.maxBonus > 0 ||
                                    product.maxCommission > 0 ||
                                    product.maxClubFee > 0)
                              )
                              .map((product, i) => {
                                return (
                                  <div className="referral-edit-box font-14 mt-2">
                                    <div>
                                      <h6>{product.productName}</h6>
                                    </div>
                                    <div className="font-14 referral-edit-details-width">
                                      {product.maxBonus > 0 && (
                                        <div className="referral-edit-details">
                                          <Controller
                                            control={control}
                                            name={"bonus-" + product.productId}
                                            defaultValue={0}
                                            render={({ onChange, value }) => {
                                              return (
                                                <>
                                                  <div className="first font-12 color-a4">
                                                    {t("BONUS")}
                                                    <br />
                                                    {t("MAX") + " : "}
                                                    {product.maxBonus.toFixed(
                                                      2
                                                    )}
                                                    %
                                                  </div>

                                                  <PlusMinusMaxButton
                                                    buttonSection="plusminus"
                                                    styleValueMinus={
                                                      product.maxBonus > 0 &&
                                                      (value !== "-"
                                                        ? parseFloat(
                                                            value.toFixed(2)
                                                          ) > 0
                                                        : true)
                                                    }
                                                    onClickMinus={(e) => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxBonus >
                                                            0 &&
                                                          parseFloat(value) > 0
                                                        ) {
                                                          value -= 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                    styleValuePlus={
                                                      product.maxBonus > 0 &&
                                                      (value !== "-"
                                                        ? parseFloat(
                                                            value.toFixed(2)
                                                          ) < product.maxBonus
                                                        : true)
                                                    }
                                                    onClickPlus={(e) => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxBonus >
                                                            0 &&
                                                          parseFloat(value) <
                                                            product.maxBonus
                                                        ) {
                                                          value += 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "bonus-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                    optionValue={
                                                      valueList &&
                                                      valueList[
                                                        category.originalTitle
                                                      ] &&
                                                      valueList[
                                                        category.originalTitle
                                                      ][product.productName] &&
                                                      valueList[
                                                        category.originalTitle
                                                      ][
                                                        product.productName
                                                      ].bonusValues.map(
                                                        (bonusValue, index) => {
                                                          return (
                                                            <option
                                                              className="bonusComm-option-value"
                                                              value={
                                                                bonusValue !==
                                                                "-"
                                                                  ? parseFloat(
                                                                      bonusValue.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  : "-"
                                                              }
                                                            >
                                                              {bonusValue !==
                                                              "-"
                                                                ? bonusValue.toFixed(
                                                                    2
                                                                  )
                                                                : "-"}
                                                            </option>
                                                          );
                                                        }
                                                      )
                                                    }
                                                    value={value}
                                                    category={category}
                                                    product={product}
                                                    handleSelectCatValue={
                                                      handleSelectCatValue
                                                    }
                                                    handleSelectValue={
                                                      handleSelectValue
                                                    }
                                                    errors={errors}
                                                    type="maxBonus" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                    section="referral"
                                                      />
                                                     
                                                  <PlusMinusMaxButton
                                                    buttonSection="max"
                                                    text={t("MAX")}
                                                    styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                                    category={category}
                                                    onClickMax={() =>
                                                      handleMaxBtn(
                                                        product.productId,
                                                        product.maxBonus,
                                                        product.maxCommission,
                                                        product.maxClubFee,
                                                        "bonus"
                                                      )
                                                    }
                                                    section="referral"
                                                  />
                                                </>
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                      {product.maxCommission > 0 && (
                                        <div className="referral-edit-details">
                                          <Controller
                                            control={control}
                                            name={
                                              "commission-" + product.productId
                                            }
                                            defaultValue={0}
                                            render={({ onChange, value }) => {
                                              return (
                                                <>
                                                  <div className="first font-12 color-a4 referral-edit-details-comm">
                                                    {t("COMMISSION_SHORT")}
                                                    <br />
                                                    {t("MAX") + " : "}
                                                    {product.maxCommission.toFixed(
                                                      2
                                                    )}
                                                    %
                                                  </div>

                                                  <PlusMinusMaxButton
                                                    buttonSection="plusminus"
                                                    styleValueMinus={
                                                      product.maxCommission >
                                                        0 &&
                                                      (value !== "-"
                                                        ? parseFloat(
                                                            value.toFixed(2)
                                                          ) > 0
                                                        : true)
                                                    }
                                                    onClickMinus={(e) => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxCommission >
                                                            0 &&
                                                          parseFloat(value) > 0
                                                        ) {
                                                          value -= 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "commission-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                    styleValuePlus={
                                                      product.maxCommission >
                                                        0 &&
                                                      (value !== "-"
                                                        ? parseFloat(
                                                            value.toFixed(2)
                                                          ) <
                                                          product.maxCommission
                                                        : true)
                                                    }
                                                    onClickPlus={(e) => {
                                                      if (value !== "-") {
                                                        if (
                                                          product.maxCommission >
                                                            0 &&
                                                          parseFloat(value) <
                                                            product.maxCommission
                                                        ) {
                                                          value += 0.1;
                                                          value *= 100;
                                                          value =
                                                            Math.round(value);
                                                          value /= 100;
                                                        }
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "commission-" +
                                                          product.productId,
                                                        value
                                                      );
                                                    }}
                                                    optionValue={
                                                      valueList &&
                                                      valueList[
                                                        category.originalTitle
                                                      ] &&
                                                      valueList[
                                                        category.originalTitle
                                                      ][product.productName] &&
                                                      valueList[
                                                        category.originalTitle
                                                      ][
                                                        product.productName
                                                      ].commValues.map(
                                                        (commValue, index) => {
                                                          return (
                                                            <option
                                                              className="bonusComm-option-value"
                                                              value={
                                                                commValue !==
                                                                "-"
                                                                  ? parseFloat(
                                                                      commValue.toFixed(
                                                                        2
                                                                      )
                                                                    )
                                                                  : "-"
                                                              }
                                                            >
                                                              {commValue !== "-"
                                                                ? commValue.toFixed(
                                                                    2
                                                                  )
                                                                : "-"}
                                                            </option>
                                                          );
                                                        }
                                                      )
                                                    }
                                                    value={value}
                                                    category={category}
                                                    product={product}
                                                    handleSelectCatValue={
                                                      handleSelectCatValue
                                                    }
                                                    handleSelectValue={
                                                      handleSelectValue
                                                    }
                                                    errors={errors}
                                                    type="maxCommission" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                    section="referral"
                                                  />
                                                     
                                                  <PlusMinusMaxButton
                                                    buttonSection="max"
                                                    styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                                                    text={t("MAX")}
                                                    category={category}
                                                    onClickMax={() =>
                                                      handleMaxBtn(
                                                        product.productId,
                                                        product.maxBonus,
                                                        product.maxCommission,
                                                        product.maxClubFee,
                                                        "commission"
                                                      )
                                                    }
                                                    section="referral"
                                                  />
                                                </>
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                      {product.productName === "D2 Poker" &&
                                        product.maxClubFee > 0 && (
                                          <div className="referral-edit-details">
                                            <Controller
                                              control={control}
                                              name={
                                                "clubFee-" + product.productId
                                              }
                                              defaultValue={0}
                                              render={({ onChange, value }) => {
                                                return (
                                                  <>
                                                    <div className="first font-12 color-a4">
                                                      {t("CLUB_FEE")}
                                                      <br />
                                                      {t("MAX") + " : "}
                                                      {product.maxClubFee.toFixed(
                                                        2
                                                      )}
                                                      %
                                                    </div>

                                                    <PlusMinusMaxButton
                                                      buttonSection="plusminus"
                                                      styleValueMinus={
                                                        product.maxClubFee >
                                                          0 &&
                                                        (value !== "-"
                                                          ? parseFloat(
                                                              value.toFixed(2)
                                                            ) > 0
                                                          : true)
                                                      }
                                                      onClickMinus={(e) => {
                                                        if (value !== "-") {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) >
                                                              0
                                                          ) {
                                                            value -= 5.0;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;
                                                          }
                                                        } else {
                                                          value = 0.0;
                                                        }
                                                        setValue(
                                                          "clubFee-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                      styleValuePlus={
                                                        product.maxClubFee >
                                                          0 &&
                                                        (value !== "-"
                                                          ? parseFloat(
                                                              value.toFixed(2)
                                                            ) <
                                                            product.maxClubFee
                                                          : true)
                                                      }
                                                      onClickPlus={(e) => {
                                                        if (value !== "-") {
                                                          if (
                                                            product.maxClubFee >
                                                              0 &&
                                                            parseFloat(value) <
                                                              product.maxClubFee
                                                          ) {
                                                            value += 5.0;
                                                            value *= 100;
                                                            value =
                                                              Math.round(value);
                                                            value /= 100;
                                                          }
                                                        } else {
                                                          value = 0.0;
                                                        }
                                                        setValue(
                                                          "clubFee-" +
                                                            product.productId,
                                                          value
                                                        );
                                                      }}
                                                      optionValue={
                                                        valueList &&
                                                        valueList[
                                                          category.originalTitle
                                                        ] &&
                                                        valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ] &&
                                                        valueList[
                                                          category.originalTitle
                                                        ][
                                                          product.productName
                                                        ].clubFeeValues.map(
                                                          (
                                                            clubFeeValue,
                                                            index
                                                          ) => {
                                                            return (
                                                              <option
                                                                className="bonusComm-option-value"
                                                                value={
                                                                  clubFeeValue !==
                                                                  "-"
                                                                    ? parseFloat(
                                                                        clubFeeValue.toFixed(
                                                                          2
                                                                        )
                                                                      )
                                                                    : "-"
                                                                }
                                                              >
                                                                {clubFeeValue !==
                                                                "-"
                                                                  ? clubFeeValue.toFixed(
                                                                      2
                                                                    )
                                                                  : "-"}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      }
                                                      value={value}
                                                      category={category}
                                                      product={product}
                                                      handleSelectCatValue={
                                                        handleSelectCatValue
                                                      }
                                                      handleSelectValue={
                                                        handleSelectValue
                                                      }
                                                      errors={errors}
                                                      type="maxClubFee" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                      section="referral"
                                                    />

                                                    <PlusMinusMaxButton
                                                      buttonSection="max"
                                                      text={t("MAX")}
                                                      styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}  
                                                      category={category}
                                                      onClickMax={() =>
                                                        handleMaxBtn(
                                                          product.productId,
                                                          product.maxBonus,
                                                          product.maxCommission,
                                                          product.maxClubFee,
                                                          "clubFee"
                                                        )
                                                      }
                                                      section="referral"
                                                    />
                                                  </>
                                                );
                                              }}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                      </TabPane>
                    );
                  }
                })}

              {/* this loop is to handle when active category is "All" */}
              {categories &&
                categories.length > 0 &&
                activeTab ===
                  categories.find((x) => x.originalTitle === "All").id && (
                  <TabPane
                    tabId={categories.find((x) => x.originalTitle === "All").id}
                  >
                    {map(
                      categories.filter((x) => x.originalTitle !== "All"),
                      (category, index) => {
                        return (
                          <>
                            <div
                              className={
                                "text-white " 
                              }
                            >
                              <div className="hr-referral-category"></div>
                              <div className="referral-edit-box head-category font-14 mt-2">
                                <div>
                                  <h6 className="row-data-1 font-16 text-white font-semi">
                                    {t(category.originalTitle)}
                                  </h6>
                                </div>
                                <div className="font-14 referral-edit-details-width">
                                  {(products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle && x.maxBonus > 0
                                  ).length > 0 ||
                                    category.originalTitle === "All") && (
                                    <div className="referral-edit-details">
                                      <Controller
                                        control={control}
                                        name={"category-bonus-" + category.id}
                                        defaultValue={0}
                                        render={({ onChange, value }) => {
                                          let valueNotLowest =
                                            checkValueForLowest(value);
                                          let valueNotHighest =
                                            checkValueForHighest(
                                              value,
                                              category,
                                              "bonus"
                                            );
                                          return (
                                            <>
                                              <div className="first font-12 color-a4">
                                                {t("BONUS")}
                                                <br />
                                                {t("MAX") + " : "}
                                                {valueList &&
                                                valueList[
                                                  category.originalTitle
                                                ]
                                                  ? valueList[
                                                      category.originalTitle
                                                    ].highestMaxBonus.toFixed(2)
                                                  : "0"}
                                                %
                                              </div>

                                              <PlusMinusMaxButton
                                                buttonSection="plusminus"
                                                styleValueMinus={valueNotLowest}
                                                onClickMinus={(e) => {
                                                  if (valueNotLowest === true) {
                                                    if (value !== "-") {
                                                      value -= 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    } else {
                                                      value = 0.0;
                                                    }
                                                    setValue(
                                                      "category-bonus-" +
                                                        category.id,
                                                      value
                                                    );
                                                    handleCatBtn(
                                                      "bonus",
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                                styleValuePlus={valueNotHighest}
                                                onClickPlus={(e) => {
                                                  if (
                                                    valueNotHighest === true
                                                  ) {
                                                    if (value !== "-") {
                                                      value += 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    } else {
                                                      value = 0.0;
                                                    }
                                                    setValue(
                                                      "category-bonus-" +
                                                        category.id,
                                                      value
                                                    );
                                                    handleCatBtn(
                                                      "bonus",
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                                optionValue={
                                                  valueList &&
                                                  valueList[
                                                    category.originalTitle
                                                  ] &&
                                                  valueList[
                                                    category.originalTitle
                                                  ].bonusValues.map(
                                                    (bonusValue, index) => {
                                                      return (
                                                        <option
                                                          className="bonusComm-option-value"
                                                          value={
                                                            bonusValue !== "-"
                                                              ? parseFloat(
                                                                  bonusValue.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                              : "-"
                                                          }
                                                        >
                                                          {bonusValue !== "-"
                                                            ? bonusValue.toFixed(
                                                                2
                                                              )
                                                            : "-"}
                                                        </option>
                                                      );
                                                    }
                                                  )
                                                }
                                                value={value}
                                                category={category}
                                                handleSelectCatValue={
                                                  handleSelectCatValue
                                                }
                                                errors={errors}
                                                type="bonus" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                section="referral"
                                              />

                                              <PlusMinusMaxButton
                                                buttonSection="max"
                                                text={t("MAX")}
                                                styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                                category={category}
                                                onClickMax={() =>
                                                  handleMaxCatBtn(
                                                    category.id,
                                                    category.originalTitle,
                                                    "bonus"
                                                  )
                                                }
                                                section="referral"
                                              />
                                            </>
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                  {(products.filter(
                                    (x) =>
                                      x.categoryName ===
                                        category.originalTitle &&
                                      x.maxCommission > 0
                                  ).length > 0 ||
                                    category.originalTitle === "All") && (
                                    <div className="referral-edit-details">
                                      <Controller
                                        control={control}
                                        name={
                                          "category-commission-" + category.id
                                        }
                                        defaultValue={0}
                                        render={({ onChange, value }) => {
                                          let valueNotLowest =
                                            checkValueForLowest(value);
                                          let valueNotHighest =
                                            checkValueForHighest(
                                              value,
                                              category,
                                              "commission"
                                            );
                                          return (
                                            <>
                                              <div className="first font-12 color-a4 referral-edit-details-comm">
                                                {t("COMMISSION_SHORT")}
                                                <br />
                                                {t("MAX") + " : "}
                                                {valueList &&
                                                valueList[
                                                  category.originalTitle
                                                ]
                                                  ? valueList[
                                                      category.originalTitle
                                                    ].highestMaxComm.toFixed(2)
                                                  : "0"}
                                                %
                                              </div>

                                              <PlusMinusMaxButton
                                                buttonSection="plusminus"
                                                styleValueMinus={valueNotLowest}
                                                onClickMinus={(e) => {
                                                  if (valueNotLowest === true) {
                                                    if (value !== "-") {
                                                      value -= 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    } else {
                                                      value = 0.0;
                                                    }
                                                    setValue(
                                                      "category-commission-" +
                                                        category.id,
                                                      parseFloat(
                                                        value.toFixed(2)
                                                      )
                                                    );
                                                    handleCatBtn(
                                                      "commission",
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                                styleValuePlus={valueNotHighest}
                                                onClickPlus={(e) => {
                                                  if (
                                                    valueNotHighest === true
                                                  ) {
                                                    if (value !== "-") {
                                                      value += 0.1;
                                                      value *= 100;
                                                      value = Math.round(value);
                                                      value /= 100;
                                                    } else {
                                                      value = 0.0;
                                                    }
                                                    setValue(
                                                      "category-commission-" +
                                                        category.id,
                                                      parseFloat(
                                                        value.toFixed(2)
                                                      )
                                                    );
                                                    handleCatBtn(
                                                      "commission",
                                                      category.originalTitle,
                                                      value
                                                    );
                                                  }
                                                }}
                                                optionValue={
                                                  valueList &&
                                                  valueList[
                                                    category.originalTitle
                                                  ] &&
                                                  valueList[
                                                    category.originalTitle
                                                  ].commValues.map(
                                                    (commValue, index) => {
                                                      return (
                                                        <option
                                                          className="bonusComm-option-value"
                                                          value={
                                                            commValue !== "-"
                                                              ? parseFloat(
                                                                  commValue.toFixed(
                                                                    2
                                                                  )
                                                                )
                                                              : "-"
                                                          }
                                                        >
                                                          {commValue !== "-"
                                                            ? commValue.toFixed(
                                                                2
                                                              )
                                                            : "-"}
                                                        </option>
                                                      );
                                                    }
                                                  )
                                                }
                                                value={value}
                                                category={category}
                                                handleSelectCatValue={
                                                  handleSelectCatValue
                                                }
                                                errors={errors}
                                                type="commission" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                section="referral"
                                              />

                                              <PlusMinusMaxButton
                                                buttonSection="max"
                                                text={t("MAX")}
                                                styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                                                category={category}
                                                onClickMax={() =>
                                                  handleMaxCatBtn(
                                                    category.id,
                                                    category.originalTitle,
                                                    "commission"
                                                  )
                                                }
                                                section="referral"
                                              />
                                            </>
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                                  {category.originalTitle === "Poker" &&
                                    (products.filter(
                                      (x) =>
                                        x.categoryName ===
                                          category.originalTitle &&
                                        x.maxClubFee > 0
                                    ).length > 0 ||
                                      category.originalTitle === "All") && (
                                      <div className="referral-edit-details">
                                        <Controller
                                          control={control}
                                          name={
                                            "category-clubFee-" + category.id
                                          }
                                          defaultValue={0}
                                          render={({ onChange, value }) => {
                                            let valueNotLowest =
                                              checkValueForLowest(value);
                                            let valueNotHighest =
                                              checkValueForHighest(
                                                value,
                                                category,
                                                "clubFee"
                                              );
                                            return (
                                              <>
                                                <div className="first font-12 color-a4">
                                                  {t("CLUB_FEE")}
                                                  <br />
                                                  {t("MAX") + " : "}
                                                  {valueList &&
                                                  valueList[
                                                    category.originalTitle
                                                  ]
                                                    ? valueList[
                                                        category.originalTitle
                                                      ].highestMaxClubFee.toFixed(
                                                        2
                                                      )
                                                    : "0"}
                                                  %
                                                </div>

                                                <PlusMinusMaxButton
                                                  buttonSection="plusminus"
                                                  styleValueMinus={
                                                    valueNotLowest
                                                  }
                                                  onClickMinus={(e) => {
                                                    if (
                                                      valueNotLowest === true
                                                    ) {
                                                      if (value !== "-") {
                                                        value -= 5.0;
                                                        value *= 100;
                                                        value =
                                                          Math.round(value);
                                                        value /= 100;
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "category-clubFee-" +
                                                          category.id,
                                                        parseFloat(
                                                          value.toFixed(2)
                                                        )
                                                      );
                                                      handleCatBtn(
                                                        "clubFee",
                                                        category.originalTitle,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                  styleValuePlus={
                                                    valueNotHighest
                                                  }
                                                  onClickPlus={(e) => {
                                                    if (
                                                      valueNotHighest === true
                                                    ) {
                                                      if (value !== "-") {
                                                        value += 5.0;
                                                        value *= 100;
                                                        value =
                                                          Math.round(value);
                                                        value /= 100;
                                                      } else {
                                                        value = 0.0;
                                                      }
                                                      setValue(
                                                        "category-clubFee-" +
                                                          category.id,
                                                        parseFloat(
                                                          value.toFixed(2)
                                                        )
                                                      );
                                                      handleCatBtn(
                                                        "clubFee",
                                                        category.originalTitle,
                                                        value
                                                      );
                                                    }
                                                  }}
                                                  optionValue={
                                                    valueList &&
                                                    valueList[
                                                      category.originalTitle
                                                    ] &&
                                                    valueList[
                                                      category.originalTitle
                                                    ].clubFeeValues.map(
                                                      (clubFeeValue, index) => {
                                                        return (
                                                          <option
                                                            className="bonusComm-option-value"
                                                            value={
                                                              clubFeeValue !==
                                                              "-"
                                                                ? parseFloat(
                                                                    clubFeeValue.toFixed(
                                                                      2
                                                                    )
                                                                  )
                                                                : "-"
                                                            }
                                                          >
                                                            {clubFeeValue !==
                                                            "-"
                                                              ? clubFeeValue.toFixed(
                                                                  2
                                                                )
                                                              : "-"}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                  }
                                                  value={value}
                                                  category={category}
                                                  handleSelectCatValue={
                                                    handleSelectCatValue
                                                  }
                                                  errors={errors}
                                                  type="clubfee" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                  section="referral"
                                                />

                                                <PlusMinusMaxButton
                                                  buttonSection="max"
                                                  text={t("MAX")}
                                                  styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}  
                                                  category={category}
                                                  onClickMax={() =>
                                                    handleMaxCatBtn(
                                                      category.id,
                                                      category.originalTitle,
                                                      "clubFee"
                                                    )
                                                  }
                                                  section="referral"
                                                />
                                              </>
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>

                              {products.filter(
                                (x) => x.categoryName === category.originalTitle
                              ).length > 0 &&
                                products
                                  .filter(
                                    (product) =>
                                      product.categoryName ===
                                        category.originalTitle &&
                                      (product.maxBonus > 0 ||
                                        product.maxCommission > 0 ||
                                        product.maxClubFee > 0)
                                  )
                                  .map((product, i) => {
                                    return (
                                      <div className="referral-edit-box font-14 mt-2">
                                        <div>
                                          <h6>{product.productName}</h6>
                                        </div>
                                        <div className="font-14 referral-edit-details-width">
                                          {product.maxBonus > 0 && (
                                            <div className="referral-edit-details">
                                              <Controller
                                                control={control}
                                                name={
                                                  "bonus-" + product.productId
                                                }
                                                defaultValue={0}
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => {
                                                  return (
                                                    <>
                                                      <div className="first font-12 color-a4">
                                                        {t("BONUS")}
                                                        <br />
                                                        {t("MAX") + " : "}
                                                        {product.maxBonus.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </div>

                                                      <PlusMinusMaxButton
                                                        buttonSection="plusminus"
                                                        styleValueMinus={
                                                          product.maxBonus >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) > 0
                                                            : true)
                                                        }
                                                        onClickMinus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxBonus >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) > 0
                                                            ) {
                                                              value -= 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                          }
                                                          setValue(
                                                            "bonus-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        styleValuePlus={
                                                          product.maxBonus >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) <
                                                              product.maxBonus
                                                            : true)
                                                        }
                                                        onClickPlus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxBonus >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) <
                                                                product.maxBonus
                                                            ) {
                                                              value += 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                          }
                                                          setValue(
                                                            "bonus-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        optionValue={
                                                          valueList &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ] &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ][
                                                            product.productName
                                                          ] &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ][
                                                            product.productName
                                                          ].bonusValues.map(
                                                            (
                                                              bonusValue,
                                                              index
                                                            ) => {
                                                              return (
                                                                <option
                                                                  className="bonusComm-option-value"
                                                                  value={
                                                                    bonusValue !==
                                                                    "-"
                                                                      ? parseFloat(
                                                                          bonusValue.toFixed(
                                                                            2
                                                                          )
                                                                        )
                                                                      : "-"
                                                                  }
                                                                >
                                                                  {bonusValue !==
                                                                  "-"
                                                                    ? bonusValue.toFixed(
                                                                        2
                                                                      )
                                                                    : "-"}
                                                                </option>
                                                              );
                                                            }
                                                          )
                                                        }
                                                        value={value}
                                                        category={category}
                                                        product={product}
                                                        handleSelectCatValue={
                                                          handleSelectCatValue
                                                        }
                                                        handleSelectValue={
                                                          handleSelectValue
                                                        }
                                                        errors={errors}
                                                        type="maxBonus" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                        section="referral"
                                                      />

                                                      <PlusMinusMaxButton
                                                        buttonSection="max"
                                                        styleValueButton={getValues('bonus-' + product.productId) == product.maxBonus}
                                                        text={t("MAX")}
                                                        category={category}
                                                        onClickMax={() =>
                                                          handleMaxBtn(
                                                            product.productId,
                                                            product.maxBonus,
                                                            product.maxCommission,
                                                            product.maxClubFee,
                                                            "bonus"
                                                          )
                                                        }
                                                        section="referral"
                                                      />
                                                    </>
                                                  );
                                                }}
                                              />
                                            </div>
                                          )}
                                          {product.maxCommission > 0 && (
                                            <div className="referral-edit-details">
                                              <Controller
                                                control={control}
                                                name={
                                                  "commission-" +
                                                  product.productId
                                                }
                                                defaultValue={0}
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => {
                                                  return (
                                                    <>
                                                      <div className="first font-12 color-a4 referral-edit-details-comm">
                                                        {t("COMMISSION_SHORT")}
                                                        <br />
                                                        {t("MAX") + " : "}
                                                        {product.maxCommission.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </div>

                                                      <PlusMinusMaxButton
                                                        buttonSection="plusminus"
                                                        styleValueMinus={
                                                          product.maxCommission >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) > 0
                                                            : true)
                                                        }
                                                        onClickMinus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxCommission >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) > 0
                                                            ) {
                                                              value -= 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                          }
                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        styleValuePlus={
                                                          product.maxCommission >
                                                            0 &&
                                                          (value !== "-"
                                                            ? parseFloat(
                                                                value.toFixed(2)
                                                              ) <
                                                              product.maxCommission
                                                            : true)
                                                        }
                                                        onClickPlus={(e) => {
                                                          if (value !== "-") {
                                                            if (
                                                              product.maxCommission >
                                                                0 &&
                                                              parseFloat(
                                                                value
                                                              ) <
                                                                product.maxCommission
                                                            ) {
                                                              value += 0.1;
                                                              value *= 100;
                                                              value =
                                                                Math.round(
                                                                  value
                                                                );
                                                              value /= 100;
                                                            }
                                                          } else {
                                                            value = 0.0;
                                                          }
                                                          setValue(
                                                            "commission-" +
                                                              product.productId,
                                                            value
                                                          );
                                                        }}
                                                        optionValue={
                                                          valueList &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ] &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ][
                                                            product.productName
                                                          ] &&
                                                          valueList[
                                                            category
                                                              .originalTitle
                                                          ][
                                                            product.productName
                                                          ].commValues.map(
                                                            (
                                                              commValue,
                                                              index
                                                            ) => {
                                                              return (
                                                                <option
                                                                  className="bonusComm-option-value"
                                                                  value={
                                                                    commValue !==
                                                                    "-"
                                                                      ? parseFloat(
                                                                          commValue.toFixed(
                                                                            2
                                                                          )
                                                                        )
                                                                      : "-"
                                                                  }
                                                                >
                                                                  {commValue !==
                                                                  "-"
                                                                    ? commValue.toFixed(
                                                                        2
                                                                      )
                                                                    : "-"}
                                                                </option>
                                                              );
                                                            }
                                                          )
                                                        }
                                                        value={value}
                                                        category={category}
                                                        product={product}
                                                        handleSelectCatValue={
                                                          handleSelectCatValue
                                                        }
                                                        handleSelectValue={
                                                          handleSelectValue
                                                        }
                                                        errors={errors}
                                                        type="maxCommission" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                        section="referral"
                                                      />

                                                      <PlusMinusMaxButton
                                                        buttonSection="max"
                                                        text={t("MAX")}
                                                        category={category}
                                                        styleValueButton={getValues('commission-' + product.productId) == product.maxCommission}
                                                        onClickMax={() =>
                                                          handleMaxBtn(
                                                            product.productId,
                                                            product.maxBonus,
                                                            product.maxCommission,
                                                            product.maxClubFee,
                                                            "commission"
                                                          )
                                                        }
                                                        section="referral"
                                                      />
                                                    </>
                                                  );
                                                }}
                                              />
                                            </div>
                                          )}
                                          {product.productName === "D2 Poker" &&
                                            product.maxClubFee > 0 && (
                                              <div className="referral-edit-details">
                                                <Controller
                                                  control={control}
                                                  name={
                                                    "clubFee-" +
                                                    product.productId
                                                  }
                                                  defaultValue={0}
                                                  render={({
                                                    onChange,
                                                    value,
                                                  }) => {
                                                    return (
                                                      <>
                                                        <div className="first font-12 color-a4">
                                                          {t("CLUB_FEE")}
                                                          <br />
                                                          {t("MAX") + " : "}
                                                          {product.maxClubFee.toFixed(
                                                            2
                                                          )}
                                                          %
                                                        </div>

                                                        <PlusMinusMaxButton
                                                          buttonSection="plusminus"
                                                          styleValueMinus={
                                                            product.maxClubFee >
                                                              0 &&
                                                            (value !== "-"
                                                              ? parseFloat(
                                                                  value.toFixed(
                                                                    2
                                                                  )
                                                                ) > 0
                                                              : true)
                                                          }
                                                          onClickMinus={(e) => {
                                                            if (value !== "-") {
                                                              if (
                                                                product.maxClubFee >
                                                                  0 &&
                                                                parseFloat(
                                                                  value
                                                                ) > 0
                                                              ) {
                                                                value -= 5.0;
                                                                value *= 100;
                                                                value =
                                                                  Math.round(
                                                                    value
                                                                  );
                                                                value /= 100;
                                                              }
                                                            } else {
                                                              value = 0.0;
                                                            }
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }}
                                                          styleValuePlus={
                                                            product.maxClubFee >
                                                              0 &&
                                                            (value !== "-"
                                                              ? parseFloat(
                                                                  value.toFixed(
                                                                    2
                                                                  )
                                                                ) <
                                                                product.maxClubFee
                                                              : true)
                                                          }
                                                          onClickPlus={(e) => {
                                                            if (value !== "-") {
                                                              if (
                                                                product.maxClubFee >
                                                                  0 &&
                                                                parseFloat(
                                                                  value
                                                                ) <
                                                                  product.maxClubFee
                                                              ) {
                                                                value += 5.0;
                                                                value *= 100;
                                                                value =
                                                                  Math.round(
                                                                    value
                                                                  );
                                                                value /= 100;
                                                              }
                                                            } else {
                                                              value = 0.0;
                                                            }
                                                            setValue(
                                                              "clubFee-" +
                                                                product.productId,
                                                              value
                                                            );
                                                          }}
                                                          optionValue={
                                                            valueList &&
                                                            valueList[
                                                              category
                                                                .originalTitle
                                                            ] &&
                                                            valueList[
                                                              category
                                                                .originalTitle
                                                            ][
                                                              product
                                                                .productName
                                                            ] &&
                                                            valueList[
                                                              category
                                                                .originalTitle
                                                            ][
                                                              product
                                                                .productName
                                                            ].clubFeeValues.map(
                                                              (
                                                                clubFeeValue,
                                                                index
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    className="bonusComm-option-value"
                                                                    value={
                                                                      clubFeeValue !==
                                                                      "-"
                                                                        ? parseFloat(
                                                                            clubFeeValue.toFixed(
                                                                              2
                                                                            )
                                                                          )
                                                                        : "-"
                                                                    }
                                                                  >
                                                                    {clubFeeValue !==
                                                                    "-"
                                                                      ? clubFeeValue.toFixed(
                                                                          2
                                                                        )
                                                                      : "-"}
                                                                  </option>
                                                                );
                                                              }
                                                            )
                                                          }
                                                          value={value}
                                                          category={category}
                                                          product={product}
                                                          handleSelectCatValue={
                                                            handleSelectCatValue
                                                          }
                                                          handleSelectValue={
                                                            handleSelectValue
                                                          }
                                                          errors={errors}
                                                          type="maxClubFee" // type for bonus/commission/clubFee/maxBonus/maxCommission/maxClubfee
                                                          section="referral"
                                                        />

                                                        <PlusMinusMaxButton
                                                          buttonSection="max"
                                                          text={t("MAX")}
                                                          category={category}
                                                          styleValueButton={getValues('clubFee-' + product.productId) == product.maxClubFee}  
                                                          onClickMax={() =>
                                                            handleMaxBtn(
                                                              product.productId,
                                                              product.maxBonus,
                                                              product.maxCommission,
                                                              product.maxClubFee,
                                                              "clubFee"
                                                            )
                                                          }
                                                          section="referral"
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                            </div>
                          </>
                        );
                      }
                    )}
                  </TabPane>
                )}
            </TabContent>
            <button className="custom-btn-style-1">{t("SUBMIT")}</button>
          </form>
        ) : (
          <div className="loading-spinner-wrapper">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      
      </>
    );
  };

  const breadcrumb = [
    {
      value: 'REFERRAL',
      active: false,
      link: WebUrl._URL_REFERRAL
    },
    {
      value: !_location.state ? "NEW_REFERRAL_DETAIL" : "EDIT_REFERRAL_DETAIL",
      active: true,
    },
  ];

  return (
    <>
      <section className="section-padding" id="referral-edit-section">
        <Breadcrumb data={breadcrumb}/>
        <div className="with-information-container">
          <div className="left">
            <h2 class="title-with-blue-under mb-4">{!_location.state ? t("NEW_REFERRAL_DETAIL") : t("EDIT_REFERRAL_DETAIL")}</h2>
            <label className="group-name-label mb-3">{t("REMARK")}</label>
            <div id="downline-group-section">
              {initCheck ? ReferralCodeDetailForm() : <div className="loading-spinner-wrapper">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>}
            </div>
          </div>
          <div className="right">
            <RightInformation
              buttonTwo={true}
              buttonTwoQuantity={referralCodeCount}
              buttonTwoText="REFERRAL_GROUPS"
              games={true}
              //gamesTitle="LATEST_GAME"
              //gamesData={latestGame}
              promotion={true}
            />
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-3" id="account-menu">
              <Sidebar activePage={"REFERRAL"} />
            </div>
            <div className="col-9">
              <ContentHeader enableBackButton title={t(contentTitle)} />
              <hr className="right-title-hr" />
              
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};
export default ReferralEdit;
