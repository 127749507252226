import React from 'react';
import Select from 'react-select';

/// <summary>
/// Author : -
/// </summary>
const StyledSelect = props => {
  const { options, placeholder = "", value, onChange, ...otherProps } = props;
  const _SELECT_STYLES = {
    menu: (styles, state) => ({
      ...styles,
      position: 'absolute !important',
      zIndex: 2,
      background: '#0e1a3c !important;',
      fontSize: '12px !important',
      cursor: 'pointer'
    }),
    control: (styles, state) => ({
      ...styles,
      borderRadius: '8px',
      background: 'radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%)',
      height: '40px',
      border: 'none !important',
      position: 'relative',
      boxShadow: 'none !important',
      cursor: 'pointer'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      if (isSelected) {
        return {
          ...styles,
          background: "#3369FF",
          color: '#fff',
          cursor: 'pointer'
        };
      } else if (isFocused) {
        return {
          ...styles,
          background: "#FFF",
          color: '#000',
          cursor: 'pointer'
        };
      } else {
        return {
          ...styles,
          color: '#A4A4A4',
          cursor: 'pointer'
        };
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#fff',
      padding: "2px 0px"
    }),
    valueContainer: styles => ({
      ...styles,
      color: '#fff',
      padding: "2px 10px"
    }),
    indicatorContainer: styles => ({
      ...styles,
      padding: "10px"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      width: "0px"
    }),
    placeholder: styles => ({
      ...styles,
      color: '#A4A4A4'
    }),
  }

  return (
    <div className='custom-style-selection'>
      <Select
        options={options} isSearchable={false}
        styles={_SELECT_STYLES}
        placeholder={(options.filter(option => option.value == value)[0] !== undefined) ? (options.filter(option => option.value == value)[0].label) : placeholder}
        value={options.filter(option => option.value == value)}
        onChange={(e) => {
          onChange(e);
        }}
        {...otherProps}
      />
    </div>
  )
}

export default StyledSelect;