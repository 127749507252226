import { map } from 'lodash';
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
/// <summary>
/// Author : -
/// </summary>
const Breadcrumb = props => {
    const { data  } = props;
    const _history = useHistory();
    const { t, i18n } = useTranslation();

    return (
        <ul className='custom-breadcrumb mb-5'>
            {data &&
                data.length &&
                map(data, (item, index) => {
                  return (
                    <>
                      {item.active ?
                        <li key={index} className={item.active ? 'active' : ''} >
                          {t(item.value)}
                        </li>
                        :
                        <li key={index} className={item.active ? 'active' : ''} onClick={() => _history.push(item.link)} style={{ cursor: "pointer" }}>
                          {t(item.value)}
                        </li>
                      }
                      
                    </>
                   
                );
                })
            }
        </ul>
    )
}

export default Breadcrumb;