import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { SessionKey } from "../../util/Constant";

const DownloadApp = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState('en');
  const links = [{
    icon: "/img/downloadapp/desktop_apple.png",
    qr: "/img/downloadapp/qr_apple.png",
    link: "https://apps.apple.com/us/app/m4d/id6451379122",
    word: "IOS"
  }, {
    icon: "/img/downloadapp/desktop_android.png",
    qr: "/img/downloadapp/qr_android.png",
    link: "https://play.google.com/store/apps/details?id=com.m4dresult.app",
    word: "ANDROID"
  }]

  useEffect(() => {
    if (localStorage.getItem(SessionKey._LANGUAGE) !== 'undefined') {
      setLang(localStorage.getItem(SessionKey._LANGUAGE))
    } else {
      setLang(localStorage.getItem(SessionKey._LANGUAGE))
    }
  }, [localStorage.getItem(SessionKey._LANGUAGE)]);

  const handleLinkOnClick = async (link) => {
    window.open(link, "_blank", "download-app")
  }


  return (
    <div className="download-app-container mt-5">
      <div className="download-app-container-left">
        <div className="download-app-header">
          {t("DOWNLOAD_APP_HEADER")}
        </div>
        <div className="download-app-description">
          {t("DOWNLOAD_APP_DESC")}
        </div>
        <div className="download-app-link">
          {links.map((item, index) => {
            return (
              <div className="download-app-qr-section" key={"download-app-" + index}>
                <div className="download-app-qr">
                  <QRCode value={item.link} />
                </div>
                <div className="download-app-button" key={"download-app-" + index} onClick={() => {
                  handleLinkOnClick(item.link);
                }}>
                  <img src={item.icon} alt="download app button" />
                  <div className="download-text-wrapper">
                    {t(item.word)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="download-app-container-right">
        <img src={"/img/downloadapp/instruction_" + lang + ".svg"} alt="instruction" />
      </div>
    </div>
  )
}

export default DownloadApp;