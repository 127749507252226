import { WebUrl } from "./../util/Constant.js";

const gameCategory = [
  {
    text: "E-Sport",
    image: "/img/assets/game-category/eSports.png",
    link: WebUrl._URL_GAME_PROVIDER,
  },
  {
    text: "Casino",
    image: "/img/assets/game-category/Casino.png",
    link: "#",
  },
  {
    text: "Slots",
    image: "/img/assets/game-category/Slots.png",
    link: "#",
  },
  {
    text: "RNG",
    image: "/img/assets/game-category/RNG.png",
    link: "#",
  },
  {
    text: "Sports",
    image: "/img/assets/game-category/Sports.png",
    link: "#",
  },
  {
    text: "Lottery",
    image: "/img/assets/game-category/Lottery.png",
    link: "#",
  },
  {
    text: "Fishing",
    image: "/img/assets/game-category/Fish Games.png",
    link: "#",
  },
  {
    text: "Poker",
    image: "/img/assets/game-category/Poker.png",
    link: "#",
  },
];

const gameProvider = [
  {
    image: "/img/game-banner/provider1.png",
    link: "#",
  },
  {
    image: "/img/game-banner/provider2.png",
    link: "#",
  },
  {
    image: "/img/game-banner/provider3.png",
    link: "#",
  },
  {
    image: "/img/game-banner/provider4.png",
    link: "#",
  },
  {
    image: "/img/game-banner/provider3.png",
    link: "#",
  },
];
const filterList = [
  {
    text: "Pussy888",
  },
  {
    text: "Jclub777",
  },
  {
    text: "Playtech ",
  },
  {
    text: "Mario Club",
  },
  {
    text: "Red Tiger",
  },
  {
    text: "Funky Game",
  },
  {
    text: "Pragmatic Play",
  },
  {
    text: "Yggdrasil",
  },
  {
    text: "Microgaming",
  },
  {
    text: "Gamatron",
  },
  {
    text: "Joker Gaming",
  },
  {
    text: "SBO Slot",
  },
  {
    text: "918 Kiss",
  },
  {
    text: "V Power",
  },
  {
    text: "XE88",
  },
];

const newGames = [
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Lucky New Year.png",
    link: "#",
    text: "Lucky New Year Tiger Treasures",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Heart of the Frontier.png",
    link: "#",
    text: "Heart of the Frontier Treasures",
  },
  {
    tag: "",
    image: "/img/game/Legacy of the Tiger.png",
    link: "#",
    text: "Legacy of the Tiger",
  },
  {
    tag: "",
    image: "/img/game/5DragonLegend_EN.png",
    link: "#",
    text: "5 Dragons Legend",
  },
  {
    tag: "",
    image: "/img/game/Fire Blaze Golden_ Amazing Factory.png",
    link: "#",
    text: "Fire Blaze Golden: Amazing Factory",
  },
  {
    tag: "",
    image: "/img/game/Age of the Gods Norse Book of Dwarves.png",
    link: "#",
    text: "Book of Dwarves",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Caishen2.png",
    link: "#",
    text: "Emperor Caishen",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Age of Gods_ Book of Oracle.png",
    link: "#",
    text: "Book of the Oracle",
  },
  {
    tag: "",
    image: "/img/game/Nian Nian You Yu Asia.png",
    link: "#",
    text: "Nian Nian You Yu",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/GoldenDynasty.png",
    link: "#",
    text: "Golden Dynasty",
  },
  {
    tag: "",
    image: "/img/game/03_Cashanova-Cashpotsen.png",
    link: "#",
    text: "Cashanova Cashpots",
  },
  {
    tag: "",
    image: "/img/game/15_Fire-and-Golden.png",
    link: "#",
    text: "Fire And Gold",
  },
  {
    tag: "",
    image: "/img/game/Fat Choy Choy Sun.png",
    link: "#",
    text: "Fat Choy Choy Sun",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Olympus2.png",
    link: "#",
    text: "Gate of Olympus",
  },
];

const bannerList = [
  {
    image: "/img/home/home-slider-banner-1.png",
  },
  {
    image: "/img/home/home-slider-banner-2.png",
  },
  {
    image: "/img/home/home-slider-banner-3.png",
  },
  {
    image: "/img/home/home-slider-banner-4.png",
  },
  {
    image: "/img/home/home-slider-banner-5.png",
  },
  {
    image: "/img/home/home-slider-banner-6.png",
  },
  {
    image: "/img/home/home-slider-banner-7.png",
  },
  {
    image: "/img/home/home-slider-banner-8.png",
  },
];

const popularGames = [
  {
    tag: "/img/assets/new.png",
    image: "/img/game/Heart of Ocean.png",
    link: "#",
    text: "Heart of Ocean",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/MonkeyKing_EN.png",
    link: "#",
    text: "Monkey King",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/Eros_EN.png",
    link: "#",
    text: "Eros Sexy",
  },
  {
    tag: "",
    image: "/img/game/LegendOfEgypt_EN.png",
    link: "#",
    text: "Legend Of Egypt",
  },
  {
    tag: "",
    image: "/img/game/fanTan_mobileCasino_gameicon_174x174_en.png",
    link: "#",
    text: "Fan Tan",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/mobile_game_game_virtualRoulette_en.png",
    link: "#",
    text: "Virtual Roulette",
  },
  {
    tag: "",
    image: "/img/game/pking.png",
    link: "#",
    text: "Pyramid King",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/JiJiJi_EN.png",
    link: "#",
    text: "Ji Ji Ji",
  },
  {
    tag: "",
    image: "/img/game/basketballStrike.png",
    link: "#",
    text: "Basketball Strike",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/Long Long Long III_game.png",
    link: "#",
    text: "Long Long Long",
  },
  {
    tag: "",
    image: "/img/game/Olympus2.png",
    link: "#",
    text: "Gates of Olympus",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/CaribbeanSaga.png",
    link: "#",
    text: "Caribbean Saga",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Quest West.png",
    link: "#",
    text: "Quest West",
  },
  {
    tag: "",
    image: "/img/game/FortuneofGiza.png",
    link: "#",
    text: "Fortune of Giza",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Age of the Gods Norse_ Gods and Giants.png",
    link: "#",
    text: "God and Giants",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/CaptainsTreasure_EN.png",
    link: "#",
    text: "Captains's Teasure",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/Queen of Wands.png",
    link: "#",
    text: "Queen Of Wands",
  },
  {
    tag: "/img/assets/new.png",
    image: "/img/game/CaiShensFortune.png",
    link: "#",
    text: "Caisheng's Fortune",
  },
  {
    tag: "",
    image: "/img/game/Fire Blaze Golden_ Buccaneer Bells.png",
    link: "#",
    text: "Bucchneer Bells",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Adventure Trail.png",
    link: "#",
    text: "Adventure Trail",
  },
  {
    tag: "/img/assets/hot.png",
    image: "/img/game/Buffalo King.png",
    link: "#",
    text: "Buffalo King",
  },
];
const sortList = [
  {
    text: "Popularity",
  },
  {
    text: "New",
  },
  {
    text: "A - Z",
  },
];
const sideMenu = [
  {
    image: "/img/assets/side-menu/WALLET.svg",
    activeImage: "/img/assets/side-menu/WALLET_active.svg",
    text: "WALLET",
    link: WebUrl._URL_MAIN_WALLET
  },
  {
    image: require("../assets/img/assets/side-menu/CHECKIN.svg"),
    activeImage: require("../assets/img/assets/side-menu/CHECKIN_active.svg"),
    text: "DAILY_CHECK_IN",
    link: WebUrl._URL_DAILY_CHECK_IN,
  },
  {
    image: require("../assets/img/assets/side-menu/NOTIFICATION.svg"),
    activeImage: require("../assets/img/assets/side-menu/NOTIFICATION_active.svg"),
    text: "NOTIFICATION",
    link: WebUrl._URL_NOTIFICATION,
  },
  {
    image: "/img/assets/side-menu/PROMOTION.svg",
    activeImage: "/img/assets/side-menu/PROMOTION_active.svg",
    text: "PROMOTION",
    link: WebUrl._URL_REWARDS,
  },
  {
    image: "/img/assets/side-menu/PROMOTION.svg",
    activeImage: "/img/assets/side-menu/PROMOTION_active.svg",
    text: "GAME_POINTS",
    link: WebUrl._URL_LOYALTY_REDEMPTION,
  },
  {
    image: "/img/assets/side-menu/REPORTS.svg",
    activeImage: "/img/assets/side-menu/REPORTS_active.svg",
    text: "REPORTS",
    link: WebUrl._URL_REPORT,
  },
  {
    image: "/img/assets/side-menu/VIP_EVENT.svg",
    activeImage: "/img/assets/side-menu/VIP_EVENT_active.svg",
    text: "VIP_EVENT",
    link: WebUrl._URL_VIP,
  },
  {
    image: "/img/assets/side-menu/DOWNLINE.svg",
    activeImage: "/img/assets/side-menu/DOWNLINE_active.svg",
    text: "DOWNLINE",
    link: WebUrl._URL_DOWNLINE,
  },
  {
    image: "/img/assets/side-menu/REFERRAL.svg",
    activeImage: "/img/assets/side-menu/REFERRAL_active.svg",
    text: "REFERRAL",
    link: WebUrl._URL_REFERRAL,
  },
  {
    image: "/img/assets/side-menu/SETTINGS.svg",
    activeImage: "/img/assets/side-menu/SETTINGS_active.svg",
    text: "SETTINGS",
    link: WebUrl._URL_SETTINGS,
  },
];
const historyFilter = [
  {
    text: "Previous Day",
  },
  {
    text: "Today",
  },
  {
    text: "Yesterday",
  },
  {
    text: "This Week",
  },
  {
    text: "Last Week",
  },
  {
    text: "This Month",
  },
  {
    text: "Last Month",
  },
  {
    text: "Next Day",
  },
];

const rewardsFilter = [
  {
    text: "Last Week",
  },
  {
    text: "Sunday",
  },
  {
    text: "Monday",
  },
  {
    text: "Tuesday",
  },
  {
    text: "Wednesday",
  },
  {
    text: "Thursday",
  },
  {
    text: "Friday",
  },
  {
    text: "Saturday",
  },
];

const tabReferralHeadings = [
  {
    title: "Casino",
    link: "casino",
  },
  {
    title: "Slots",
    link: "slots",
  },
  {
    title: "RNG",
    link: "rng",
  },
  {
    title: "Sports",
    link: "sports",
  },
  {
    title: "Lottery",
    link: "lottery",
  },
];

//4D result//
const _POOL_NAME = {
  mg: "Magnum",
  kd: "Da Ma Cai 1 + 3D",
  tt: "Sport TOTO",
  sg: "Singapore Pool ",
  sr: "Sarawak 88 ",
  sn: "SANDAKAN",
  sb: "Sabah 88",
  gd: "GRAND DRAGON",
};

const _TITLE_COLOR = {
  mg: "#000000",
  kd: "#FFFFFF",
  tt: "#FFFFFF",
  sg: "#FFFFFF",
  sr: "#FFFFFF",
  sn: "#000000",
  sb: "#FFFFFF",
  gd: "#FFFFFF",
};

const _COLOR = {
  mg: "#FFC100",
  kd: "#000080",
  tt: "#AD0006", //4D AND 5D AND 6D
  sg: "#0378BB",
  sr: "#008000",
  sn: "#F8C100",
  sb: "#3333FF", // currently no color
  gd: "#A1030E", //4D AND 5D AND 6D
};

const _POOL_IMAGE = {
  mg: "/img/4dresult-icon/magnum_logo.svg",
  kd: "/img/4dresult-icon/damacai_logo.svg",
  tt: "/img/4dresult-icon/toto_logo.svg",
  sg: "/img/4dresult-icon/singapore_logo.svg",
  sr: "/img/4dresult-icon/bigcash_logo.svg",
  sn: "/img/4dresult-icon/stc_logo.svg",
  sb: "/img/4dresult-icon/sabah_logo.svg",
  gd: "/img/4dresult-icon/gd_logo.svg",
};

const _SECTION_TYPE = {
  mg: "section-3",
  kd: "section-3",
  tt: "section-3", //4D AND 5D AND 6D
  sg: "section-3",
  sr: "section-3",
  sn: "section-3",
  sb: "section-3", // currently no color
  gd: "section-3", //4D AND 5D AND 6D
};

const _IS_5D_6D = {
  tt: "section-2",
  gd: "section-1",
};

//End 4D result//

const walletInformation = [
  {
    title: 'JClub777',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'XE88',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'VPower',
    details: '',
    amount: '1000.11'
  },
  {
    title: '918Kiss',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'PlayTech Casino',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'PlayTech Slot',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'LionKing',
    details: '',
    amount: '1000.11'
  },
  {
    title: 'Mega888',
    details: '',
    amount: '1000.11'
  },
];

const recentTransaction = [
  {
    status: 2,
    date: '04052023  l  13:35:59 PM',
    amount: '500.00'
  },
  {
    status: 1,
    date: '04052023  l  13:35:59 PM',
    amount: '500.00'
  },
  {
    status: 2,
    date: '04052023  l  13:35:59 PM',
    amount: '500.00'
  },
];

const latestGames = [
  {
    title: 'Knight Hot Spotz',
    provider: 'Pragmatic Play Slots',
    image: "/img/version3/main-wallet/game1.png"
  },
  {
    title: 'Zeus vs Hades',
    provider: 'Pragmatic Play Slots',
    image: "/img/version3/main-wallet/game2.png"
  },
  {
    title: 'Diamonds of Egypt',
    provider: 'Pragmatic Play Slots',
    image: "/img/version3/main-wallet/game3.png"
  },
  {
    title: 'Wild Bison Charge',
    provider: 'Pragmatic Play Slots',
    image: "/img/version3/main-wallet/game4.png"
  },
];

const grandTotalData = [
  {
    label:'Total Earned',
    value: 'THB 12,256.90',
  },
  {
    label:'Total Claimable',
    value: 'THB 156.90',
  },
];

const newDownlineData = [
  {
    name:'PXd8K9mi',
    id: '60145999958',
  },
  {
    name:'PXd8K9mi',
    id: '60145999958',
  },
  {
    name:'PXd8K9mi',
    id: '60145999958',
  },
  {
    name:'PXd8K9mi',
    id: '60145999958',
  },
];

const vipClaimHistory = [
  {
    date:'04052023',
    amount: '0.1458',
  },
  {
    date:'04052023',
    amount: '-0.140',
  },
  {
    date:'04052023',
    amount: '0.1458',
  },
];

const redemptionHistory = [
  {
    icon: "/img/version3/main-wallet/redeem.png",
    date:'04052023  l  13:35:59 PM',
    amount: '5,000',
    redeemed: true,
  },
  {
    icon: "/img/version3/main-wallet/redeem.png",
    date:'04052023  l  13:35:59 PM',
    amount: '5,000',
    redeemed: true,
  },
  {
    icon: "/img/version3/main-wallet/redeem.png",
    date:'04052023  l  13:35:59 PM',
    amount: '5,000',
    redeemed: true,
  },
  {
    icon: "/img/version3/main-wallet/redeem.png",
    date:'04052023  l  13:35:59 PM',
    amount: '5,000',
    redeemed: true,
  },
];


export {
  gameCategory,
  gameProvider,
  filterList,
  newGames,
  bannerList,
  popularGames,
  sortList,
  sideMenu,
  historyFilter,
  rewardsFilter,
  tabReferralHeadings,
  _POOL_NAME,
  _TITLE_COLOR,
  _COLOR,
  _POOL_IMAGE,
  _SECTION_TYPE,
  _IS_5D_6D,
  walletInformation,
  recentTransaction,
  latestGames,
  grandTotalData,
  newDownlineData,
  vipClaimHistory,
  redemptionHistory
};
