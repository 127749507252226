import React, { useEffect, useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  DEFAULT_PAGE_SIZE,
  SessionKey,
} from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import ContentHeader from "components/content/header";
import { mdiPin } from '@mdi/js';
import Icon from '@mdi/react';
import { map } from "lodash";

const Notification = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  const [apiQuery, setApiQuery] = useState(
    ApiUrl._API_GET_NOTIFICATION_LIST +
      "?languageCode=" +

      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en')

      // localStorage.getItem(SessionKey._LANGUAGE)
  );
  const [showDynamicTable, setShowDynamicTable] = useState(true);
  const [isReadNotification, setIsReadNotification] = useState(false);
  const [notificationLength, setNotificationLength] = useState(false);
  const [tabActive, setTabActive] = useState(1);

  const tabList = [
    {
      id: 1,
      title: t('ALL_MESSAGES'),
    },
    {
      id: 2,
      title: t('UNREAD_MESSAGES'),
    },
  ];

  const _SELECTIONS = {
    "Deposit SMS": "DEPOSIT",
    "Withdrawal SMS": "WITHDRAWAL",
    "Reset Password SMS": "RESET_PASSWORD",
    "Phone Verfication SMS": "PHONE_VERIFICATION",
    "PIN Reset": "RESET_PIN",
  };

  async function readAllAnnouncement() {
    _dispatch(setBusy());
    var responseJson = await ApiEngine.post(
      `${ApiUrl._API_MARK_ANNOUNCEMENT_AS_READ}`
    );

    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setShowDynamicTable(false);
      setTimeout(() => setShowDynamicTable(true), 1);
      setIsReadNotification(true);
      localStorage.setItem('isReadAllNotification', true);
    }
    _dispatch(setIdle());
    }

    useEffect(() => {
    setApiQuery(
      ApiUrl._API_GET_NOTIFICATION_LIST +
      "?languageCode=" +

      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
      localStorage.getItem(SessionKey._LANGUAGE) : 'en'));

      // localStorage.getItem(SessionKey._LANGUAGE));
    setShowDynamicTable(false);
    setTimeout(() => setShowDynamicTable(true), 1);
    getDataList();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

    async function getDataList() {
        let responseJson = await ApiEngine.get(apiQuery);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            
            if (responseJson[ApiKey._API_DATA_KEY].totalCount > 0) {
                setNotificationLength(true);
            }
        }
    }
  return (
    <>
      <section className="section-padding" id="notification-section">
        <ContentHeader
          title={t("NOTIFICATION")}
          // escButtonOnClick={() => window.history.go(-1)}
        />
        {/* {notificationLength && 
          <div className="text-right mb-3">
              <a
                  href="#"
                  onClick={() => readAllAnnouncement()}
                  className="font-14 text-gold font-semi"
              >
                  {t("MARK_ALL_READ")}
              </a>
          </div>
        } */}

        <div className="custom-tab-container notification-tab">
            {tabList &&
              tabList.length &&
              map(tabList, (item, index) => {
                return (
                  <div key={index} className={item.id == tabActive ? "custom-tab-list-item active" : "custom-tab-list-item"} onClick={() => setTabActive(item.id)}>
                    {item.title}
                  </div>
                );
              })
            }
          </div>

        {showDynamicTable && (
          <>
            {tabActive == 1 && (
              <div className="ul-news-list mt-4">
                <DynamicLoadTable
                  pageSize={DEFAULT_PAGE_SIZE}
                  apiQuery={apiQuery}
                  searchEnabled={true}
                  loadingColor={"white"}
                  render={(announcement, index) => {
                    var localLocale = moment(announcement["date"]);
                    console.log(announcement)
                    return (
                      <div
                        key={index}
                        className="template-box mb-4"
                        id="notif-box"
                        >
                        <Link
                          to={{
                            pathname: WebUrl._URL_NOTIFICATION_DETAIL,
                            state: { data: JSON.stringify(announcement) },
                          }}
                        >
                          {!announcement["isRead"] ? (
                            <div className="text-white position-relative notification-box">
                            <div className="notification-container">
                              <div className="first">
                                <div className="custom-icon-box">
                                  <img
                                    src="/img/version3/icon-email-message.png" alt="email message"
                                  />
                                </div>
                                <div>
                                  <h4 className="mb-2">
                                    <span>{announcement["title"] == "Deposit SMS" ||
                                    announcement["title"] == "Withdrawal SMS"
                                      ? t(_SELECTIONS[announcement["title"]])
                                      : announcement["title"]}</span>{/*<div className="ml-3">{announcement["pinOnTop"] && <Icon path={mdiPin} size={0.8} color="white" />}</div>*/}
                                  </h4>
                                  <p
                                    className="brief-annoucement"
                                    dangerouslySetInnerHTML={{
                                      __html: announcement["description"],
                                    }}
                                  ></p>
                                </div>
                              </div>
                              <div className="second">
                                <p className="font-12 mb-2 notification-date">
                                  {localLocale.format("LLL")}
                                </p>
                                <span className="circle-orange"></span>
                              </div>
                            </div>
                          </div>
                          ) : (
                            <div className="text-white position-relative notification-box">
                              <div className="notification-container">
                                <div className="first">
                                  <div className="custom-icon-box">
                                    <img
                                      src="/img/version3/email.png" alt="email"
                                    />
                                  </div>
                                  <div>
                                    <h4 className="mb-2">
                                      <span>{announcement["title"] == "Deposit SMS" ||
                                      announcement["title"] == "Withdrawal SMS"
                                        ? t(_SELECTIONS[announcement["title"]])
                                        : announcement["title"]}</span>{/*<div className="ml-3">{announcement["pinOnTop"] && <Icon path={mdiPin} size={0.8} color="white" />}</div>*/}
                                    </h4>
                                    <p
                                      className="brief-annoucement"
                                      dangerouslySetInnerHTML={{
                                        __html: announcement["description"],
                                      }}
                                    ></p>
                                  </div>
                                </div>
                                <div className="second">
                                  <p className="font-12 mb-2 notification-date">
                                    {localLocale.format("LLL")}
                                  </p>
                                  <span className="circle-green"></span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            )}
            {tabActive == 2 && (
              <div className="ul-news-list mt-4">
                <DynamicLoadTable
                  pageSize={DEFAULT_PAGE_SIZE}
                  apiQuery={apiQuery}
                  searchEnabled={true}
                  loadingColor={"white"}
                  render={(announcement, index) => {
                    var localLocale = moment(announcement["date"]);
                    console.log(announcement)
                    if(announcement["isRead"] == false){
                      return (
                        <div
                          key={index}
                          className="template-box mb-4"
                          id="notif-box"
                          >
                          <Link
                            to={{
                              pathname: WebUrl._URL_NOTIFICATION_DETAIL,
                              state: { data: JSON.stringify(announcement) },
                            }}
                          >
                            <div className="text-white position-relative notification-box">
                              <div className="notification-container">
                                <div className="first">
                                  <div className="custom-icon-box">
                                    <img
                                      src="/img/version3/icon-email-message.png" alt="email message"
                                    />
                                  </div>
                                  <div>
                                    <h4 className="mb-2">
                                      <span>{announcement["title"] == "Deposit SMS" ||
                                      announcement["title"] == "Withdrawal SMS"
                                        ? t(_SELECTIONS[announcement["title"]])
                                        : announcement["title"]}</span>{/*<div className="ml-3">{announcement["pinOnTop"] && <Icon path={mdiPin} size={0.8} color="white" />}</div>*/}
                                    </h4>
                                    <p
                                      className="brief-annoucement"
                                      dangerouslySetInnerHTML={{
                                        __html: announcement["description"],
                                      }}
                                    ></p>
                                  </div>
                                </div>
                                <div className="second">
                                  <p className="font-12 mb-2 notification-date">
                                    {localLocale.format("LLL")}
                                  </p>
                                  <span className="circle-orange"></span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    }
                  }}
                />
              </div>
            )}
          </>
          
        )}
      </section>
    </>
  );
};
export default Notification;
