import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { map } from "lodash";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch} from "react-redux";

const GameListRecommendMatch = ({ recommendedMatches, startGame568SportsBook }) => {
  const { t } = useTranslation();

  return (
    <div className="mt-5">
      {recommendedMatches.length > 0 &&
        <div className="recommended-match-container">
          {recommendedMatches &&
            recommendedMatches.length &&
            map(recommendedMatches, (item, index) => {
              return (
                <div key={"recommended-match-" + index}>
                  <div className="recommended-match-details">
                    <div className="topbar">
                      <div className="vendor-logo">
                        <img src="/img/version3/selected_recommended_match_vendor.svg" alt="vendor-logo" />
                      </div>
                      <div className="status">
                        {!stringIsNullOrEmpty(item.gameTime) ? <div className="live">
                          <span className="dot"></span>{t("LIVE") + " (" + item.gameTime + ")"}
                        </div> : <div className="live">
                          {t("COMING_SOON_2")}
                        </div>}
                      </div>
                      <div className="date-time">
                        {moment(item.kickOffTimeGMT).format("DD/MM/YY HH:mm") + " GMT+8"}
                      </div>
                    </div>
                    <div className="match">
                      <div className="team-logo">
                        <img src={item.homeTeamIconUrl} alt="homeLogo" />
                        <div className="team-name">{item.homeName}</div>
                        {!stringIsNullOrEmpty(item.gameTime) && <div className="team-score">{item.homeScore}</div>}
                      </div>
                      <div className="versus">{t("VS")}</div>
                      <div className="team-logo">
                        <img src={item.awayTeamIconUrl} alt="awayLogo" />
                        <div className="team-name">{item.awayName}</div>
                        {!stringIsNullOrEmpty(item.gameTime) && <div className="team-score">{item.awayScore}</div>}
                      </div>
                    </div>
                  </div>
                  <button className="recommended-match-button" onClick={() => { startGame568SportsBook(item.matchId); }}><span>{t("BET_NOW")}</span></button>
                </div>
              );
            })}
        </div>}
    </div>
  );
};


GameListRecommendMatch.propTypes = {
  recommendedMatches: PropTypes.array,
  startGame568SportsBook: PropTypes.func
};

GameListRecommendMatch.defaultProps = {
  recommendedMatches: [],
}

export default GameListRecommendMatch;
