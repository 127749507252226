import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce,filter, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input from "../custom/Input";
import {
  LanguageOption,
  ApiUrl,
  ApiKey,
  SessionKey,
  WebUrl,
  _MAIN_WALLET_ID,
  AlertTypes,
  Language,
} from "../../util/Constant.js";
import { setDisplayLogin, setDisplayRegister, showResponseMessage, setBusy, setIdle, setActiveTab, showMessage } from "../../redux/AppAction";
import ApiEngine from "./../../util/ApiEngine.js";
import { useTranslation } from "react-i18next";
import { saveLanguageId } from "../../redux/LanguageAction.js";
import {
  createMultiPartFormBody,
  numberWithCurrencyFormat2,
  stringIsNullOrEmpty, 
} from "./../../util/Util.js";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import CustomSpinWheelContainer from "../../components/custom/CustomSpinWheelContainer";
import CustomHeaderDropDown from "../../components/custom/CustomHeaderDropDown";
import CustomHeaderDropDown2 from "../../components/custom/CustomHeaderDropDown2";
import Auth from "../../components/auth/Auth";   

const Header = () => {
  const { t, i18n } = useTranslation();
  var _userData = useSelector((state) => state.authState.userData);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("TH");
  const _dispatch = useDispatch();
  const [notificationUnread, setNotificationUnread] = useState(false);
  var { isLoggedIn } = useSelector((state) => state.authState);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState('th');
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var isReadAllNotification = JSON.parse(localStorage.getItem('isReadAllNotification'));
  var unReadNotification = localStorage.getItem('unreadNotificationCount'); 
  const activeTab = useSelector((state) => state.appState.activeTab);
  const _history = useHistory();
  let tempInterval;
  const [walletInterval, setWalletInterval] = useState();
  const [unclaimComm, setUnclaimComm] = useState("");
  const [grandTotalRewards, setGrandTotalRewards] = useState("");
  const [gamePoints, setGamePoints] = useState("");
  const [loadCommission, setLoadCommission] = useState(false);
  const [loadReward, setLoadReward] = useState(false);

  //search bar
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loadingSearchData, setLoadingSearchData] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const headerSearchBar = useRef(null);
  const headerSearchResult = useRef(null);
  const gameCategories = useSelector((state) => {
    return filter(state.gameState.gameCategories, (item) => {
      return item.id > 0 && item.originalTitle != 'Virtual-Sports' && item.originalTitle != 'V.Sports';
    });
  });
  const selectedGameProvider = useSelector((state) => (state.gameState.gameProviders));

  const countryList = [
    {
      name: "Thailand",
      iso: "TH",
      icon: "/img/version3/th.svg",
      icon_circle: "/img/version3/th-circle.svg",
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://th-staging-d.i8global.com' : (window.location.href).includes("preproduction") ? 'https://th-preproduction-d.huat8th.com/' : 'https://i8th.live/',
      child_languages: [
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Malaysia",
      iso: "MYR",
      icon: "/img/version3/my.svg",
      icon_circle: "/img/version3/my-circle.svg",
      url: window.location.origin, //((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://my-staging-d.i8global.com' : (window.location.href).includes("preproduction") ? 'https://my-preproduction-d.i8global.com/' : 'https://my-preproduction-d.i8global.com/',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
    {
      name: "Indonesia",
      iso: "ID",
      icon: "/img/version3/id.svg",
      icon_circle: "/img/version3/id-circle.svg",
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://id-staging-d.huat8id.com/' : (window.location.href).includes("preproduction") ? 'https://id-preproduction-d.huat8id.com/' : 'https://i8id.live/',
      child_languages: [
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        },
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        //{
        //  name: "中文",
        //  languageCode: Language._CHINESE,
        //  languageId: 2
        //},
        //{
        //  name: "Malay",
        //  languageCode: Language._BAHASA_MELAYU,
        //  languageId: 3
        //},
        //{
        //  name: "ไทย",
        //  languageCode: Language._THAI,
        //  languageId: 5
        //},
        //{
        //  name: "Tiếng Việt",
        //  languageCode: Language._VIETNAMESE,
        //  languageId: 6
        //},
      ],
    },
    {
      name: "Australia",
      iso: "AU",
      icon: "/img/version3/aud.svg",
      icon_circle: "/img/version3/aud-circle.svg",
      url: ((window.location.href).includes("staging") || (window.location.href).includes("localhost")) ? 'https://au-staging-d.huat8au.com' : (window.location.href).includes("preproduction") ? 'https://au-preproduction-d.huat8au.com' : 'https://i8au.live',
      child_languages: [
        {
          name: "English",
          languageCode: Language._ENGLISH,
          languageId: 1
        },
        {
          name: "中文",
          languageCode: Language._CHINESE,
          languageId: 2
        },
        {
          name: "Malay",
          languageCode: Language._BAHASA_MELAYU,
          languageId: 3
        },
        {
          name: "ไทย",
          languageCode: Language._THAI,
          languageId: 5
        },
        {
          name: "Tiếng Việt",
          languageCode: Language._VIETNAMESE,
          languageId: 6
        },
        {
          name: "Indo",
          languageCode: Language._INDON,
          languageId: 7
        }
      ],
    },
  ];

  const itemList = [
    {
      title: "COMMISSION",
      image: "/img/version3/commission.svg",
      url: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
      value: numberWithCurrencyFormat2(parseFloat(unclaimComm.totalClaimable), 2, true)
    },
    {
      title: "REWARDS",
      image: "/img/version3/rewards.svg",
      url: WebUrl._URL_REWARDS,
      value: numberWithCurrencyFormat2(parseFloat(grandTotalRewards), 2, true)
    },
    {
      title: "GAME_POINTS",
      image: "/img/version3/points.svg",
      url: WebUrl._URL_LOYALTY_REDEMPTION,
      value: numberWithCurrencyFormat2(parseFloat(_userData['loyaltyPoints']), 2, true)
    }
  ]

  const options = [
    {
      title: "DEPOSIT",
      image: "/img/version3/deposit-gold.svg",
      url: WebUrl._URL_DEPOSIT,
    },
    {
      title: "WITHDRAWAL",
      image: "/img/version3/withdraw-gold.svg",
      url: WebUrl._URL_WITHDRAWAL,
    },
    {
      title: "TRANSFER",
      image: "/img/version3/transfer-gold.svg",
      url: WebUrl._URL_TRANSFER,
    }
  ]

  const handleCountryOnClick = async (countryItem, selectedLanguage) => {
    if (window.location.href.startsWith(countryItem.url) || (window.location.href.includes("localhost") && countryItem.iso == "TH")) {
      if (typeof (selectedLanguage) !== "undefined") {
        onChangeLanguage(selectedLanguage);
      }
    }
    else {
      let redirectUrl = countryItem.url + "?languageCode=" + (typeof (selectedLanguage) !== "undefined" ? selectedLanguage : "th");
      window.location.assign(redirectUrl);
    }
    setShowLanguageDropdown(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NOTIFICATION_LIST + "?languageCode=" +
          (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
            localStorage.getItem(SessionKey._LANGUAGE) : 'th')
          // localStorage.getItem(SessionKey._LANGUAGE)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var allData =
            responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

          allData.forEach(function (obj) {
            if (obj.isReadFlag == false) {
              setNotificationUnread(true);
              return true;
            }
            setNotificationUnread(false);
            return false;
          });
        } else {
          _dispatch(
            showResponseMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              responseJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }
      }
      setCurrentWalletBalance(_userData['walletBalance']);
    };
    fetchData();
    if (isLoggedIn) {
      onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'th'));
      let tempInterval = setInterval(() => tempUpdateUserData(), 30000);
      setWalletInterval(tempInterval);
      return () => clearInterval(tempInterval);

    }
  }, [isLoggedIn]);

  const onChangeLanguage = async (selectedLanguage) => {
    _dispatch(setBusy());
    setSelectedLanguage(
      LanguageOption.find((x) => x.code === selectedLanguage).prefix
    );
    i18n.changeLanguage(selectedLanguage);
    if (!isLoggedIn) {
      _dispatch(saveLanguageId(selectedLanguage, true));
    } else {
      _dispatch(saveLanguageId(selectedLanguage));
      let data = {};
      // data["id"] = _userData.userId;
      // data["selectedTheme"] = _userData.selectedTheme;
      data["languageSetting"] = selectedLanguage;
      // data["isMemberOperate"] = true;

      // Silently update user's language settings
      await ApiEngine.post(
        // ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
        ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
        createMultiPartFormBody(data)
      );

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: "language",
            language: selectedLanguage,
          })
        );
      }
    }
    _dispatch(setIdle());
  };

  useEffect(() => {
    if (params.get("languageCode") != null) {
      onChangeLanguage(params.get("languageCode"));
      localStorage.setItem(SessionKey._LANGUAGE, params.get("languageCode"));
    }
    else if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
      if (LanguageOption.find(
        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
        setSelectedLanguage(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).prefix
        );
        setSelectedLanguageCode(
          LanguageOption.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).code
        );
        i18n.changeLanguage(localStorage.getItem(SessionKey._LANGUAGE));
      }
      else {
        i18n.changeLanguage("th");
        setSelectedLanguage("TH");
        setSelectedLanguageCode("TH");

        localStorage.setItem(SessionKey._LANGUAGE, "th");
      }
    } else {
      i18n.changeLanguage("th");
      setSelectedLanguage("TH");
      setSelectedLanguageCode("TH");

      localStorage.setItem(SessionKey._LANGUAGE, "th");
    }
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'th')]);

  const tempUpdateUserData = async () => {
    let walletResponse = await ApiEngine.get(ApiUrl._API_GET_MEMBER_WALLET_BALANCE);
    if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
      let data = walletResponse[ApiKey._API_DATA_KEY];
      setCurrentWalletBalance(data["primaryCredit"]);
      if (data["count"] == false) {
        setBalanceTimeout(30000);
      }
      else {
        setBalanceTimeout(5000);
      }
    }
  }

  function setBalanceTimeout(mmTimer) {
    clearTimeout(tempInterval);
    tempInterval = setTimeout(() => {
      tempUpdateUserData();
    }, mmTimer);
  }

  useEffect(() => {
    if (isLoggedIn) {
      getRewardData();
      getUnclaimCommHistory();
      let tempInterval = setInterval(() => {
        tempUpdateUserData();
      }, 30000);
      return () => clearInterval(tempInterval);
    }
  }, [isLoggedIn]);


  useEffect(() => {
    const checkIfClickedOutsideSearch = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isSearchInputVisible &&
        headerSearchBar.current &&
        !headerSearchBar.current.contains(e.target)
        // ||
        // (headerSearchResult.current &&
        //   !headerSearchResult.current.contains(e.target))
      ) {
        setIsSearchInputVisible(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideSearch);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutsideSearch);
    };
  }, [isSearchInputVisible]);

  const onChangeKeyword = useCallback(
    debounce(async (keyword) => {
      setIsSearchInputVisible(true);
      setLoadingSearchData(true);
      let responseJson = await ApiEngine.get(
        ApiUrl._API_GET_PRODUCT_GAME_LIST +
        "?keyword=" +
        keyword +
        (_userData && !stringIsNullOrEmpty(_userData.userId) ? "&memberId=" + _userData.userId : "")
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setSearchData(responseJson[ApiKey._API_DATA_KEY]);
      }
      setLoadingSearchData(false);
    }, 1000),
    []
  );

  const onClickGameHandler = (data) => {
    const getGameNameUm = selectedGameProvider.find(i => {
      return i.gameName === data.gameName && i.gameUm == true;
    });

    if (getGameNameUm === undefined) {
      window.open(
        `${process.env
          .REACT_APP_ENDPOINT_URL ||
        ""
        }${ApiUrl._API_START_GAME
        }?gameId=${data.gameId
        }&isApp=${data.isApp
          ? 1
          : 0
        }&device=d`
      )
    }
    else {
      _dispatch(
        showMessage({
          type: AlertTypes._INFO,
          content:
            t(
              "GAME_UM"
            ),
          onConfirm:
            () => {
            },
        })
      );
    }
  }

  //get unclaim comm hisyory
  const getUnclaimCommHistory = async () => {
    setLoadCommission(false);
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
    setLoadCommission(true);
  };

  //get reward data
  const getRewardData = async () => {
    setLoadReward(true);
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      //let temp = Object.keys(tempObj).sort();
      //setSelectedDate(moment(temp[temp.length - 1]));
      //setRewardData(tempObj);
      setGrandTotalRewards(grandTotalReward);
      //setGameCategoryData(categoryTotal);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
    setLoadReward(true);
  }

  return (
    <>
      <div id="header-v3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="theme-container">
                <div className="first-container">
                  <div className="logo">
                    <img
                      src="/img/version3/i8.live_2024.svg"
                      className="img-responsive"
                      alt="logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => (_history.push(WebUrl._URL_MAIN), _dispatch(setActiveTab(0)))}
                    />
                  </div>
                </div>
                <div className="second-container">
                  <div className="header-right">
                    <div className="first">
                      <div className="header-search-box" ref={headerSearchBar}>
                        <Input
                          id="header-search"
                          name="header-search"
                          placeholder={t("SEARCHING")}
                          inputGroupType="append"
                          inputGroupContent={<div className="header-search-icon" onClick={() => { onChangeKeyword(searchKeyword) }}> <img
                            src="/img/version3/search.png"
                            alt="search"
                          /> </div>}
                          onInput={(e) => {
                            setSearchKeyword(e.target.value);
                            onChangeKeyword(e.target.value);
                          }}
                        />
                        {isSearchInputVisible && searchKeyword !== "" && (
                          <div
                            className={
                              "header-search-dropdown text-white" +
                              (loadingSearchData ? " text-center" : "")
                            }
                            ref={headerSearchResult}
                          >
                            {!loadingSearchData ? (
                              <>
                                {gameCategories.map((category, index) => {
                                  if (
                                    searchData.filter((x) =>
                                      category.originalTitle !== "All"
                                        ? x.categoryName ===
                                        category.originalTitle
                                        : true
                                    ).length > 0
                                  ) {
                                    return (
                                      <div key={index}>
                                        <span className="font-14 text-gold"
                                          key={index}>
                                          {t(category.originalTitle)}
                                        </span>
                                        {searchData.length > 0 &&
                                          searchData.filter((x) =>
                                            category.originalTitle !== "All"
                                              ? x.categoryName ===
                                              category.originalTitle
                                              : true
                                          ).length > 0 &&
                                          searchData
                                            .filter((x) =>
                                              category.originalTitle !==
                                                "All"
                                                ? x.categoryName ===
                                                category.originalTitle
                                                : true
                                            )
                                            .map((data, index) => {
                                              return (
                                                <div key={index}>
                                                  <ul className="list-inline"  >
                                                    <li>
                                                      <div
                                                        onClick={() => {
                                                          if (isLoggedIn) {
                                                            onClickGameHandler(data);
                                                            //         window.open(
                                                            //             `${process.env
                                                            //               .REACT_APP_ENDPOINT_URL ||
                                                            //             ""
                                                            //             }${ApiUrl._API_START_GAME
                                                            //             }?gameId=${data.gameId
                                                            //             }&isApp=${data.isApp
                                                            //               ? 1
                                                            //               : 0
                                                            //             }&device=d`
                                                            //           )
                                                            //     }

                                                          } else {
                                                            _dispatch(
                                                              showMessage({
                                                                type: AlertTypes._INFO,
                                                                content:
                                                                  t(
                                                                    "PLEASE_LOGIN_FIRST"
                                                                  ),
                                                                onConfirm:
                                                                  () => {
                                                                    console.log(
                                                                      "hi"
                                                                    );
                                                                  },
                                                              })
                                                            );
                                                          }
                                                        }}
                                                        className="font-14"
                                                      >
                                                        {data.gameName} - {data.provider}
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              );
                                            })}
                                      </div>
                                    );
                                  }
                                })}
                                <div className="text-gold font-14 mt-1">
                                  {t("END_OF_RESULT")}
                                </div>
                              </>
                            ) : (
                              <Spinner animation="border" role="status">
                        {/*         <span className="sr-only">Loading...</span> */}
                              </Spinner>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="second">
                      {isLoggedIn ? (
                        <>
                          <div className="header-wallet-container">
                            <div className="header-balance" style={{ cursor: "pointer" }} onClick={() => _history.push(WebUrl._URL_MAIN_WALLET)}>
                              <div className="first">
                                <img src="/img/version3/wallet.svg" alt="balance" />
                              </div>
                              <div className="second">
                                <span>|</span>
                              </div>
                              <div className="third">
                                <span>{t("BALANCE")}</span>
                                <span>
                                  {isNaN(currentWalletBalance) ? "0.00" : numberWithCurrencyFormat2(currentWalletBalance, 2, true)}
                                </span>
                              </div>
                            </div>
                            <div className="" style={{ cursor: "pointer" }} onClick={() => tempUpdateUserData()}>
                              <img src="/img/version3/refresh.svg" alt="refresh" />
                            </div>
                          </div>
                          {loadCommission && loadReward && <CustomHeaderDropDown options={itemList} />}
                          <CustomHeaderDropDown2 options={_userData.isBlockWithdraw ? options.filter(x => x.title !== "WITHDRAWAL") : options} />
                          <div className="custom-icon-box header-notification-icon" style={{ cursor: "pointer" }} onClick={() => _history.push(WebUrl._URL_NOTIFICATION)}>

                            {(unReadNotification === null ? notificationUnread : unReadNotification > 0 && !isReadAllNotification) ? (
                              <div>
                                <img src="/img/version3/notification.png" alt="notification" />
                                <div className="header-notification-dot"></div>
                              </div>
                            ) : (
                              <div>
                                <img src="/img/version3/notification.png" alt="notification" />
                              </div>
                            )
                            }
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div onClick={() => _dispatch(setDisplayLogin(true))} className="btn login-btn">{t('LOGIN')}</div>
                          </div>
                          <div>
                            <div onClick={() => _dispatch(setDisplayRegister(true))} className="btn register-btn">{t('REGISTER')}</div>
                          </div>
                        </>
                      )}

                      <div>
                        <div onClick={() => setShowLanguageDropdown(!showLanguageDropdown)}>
                          <div className="lang-toggle">
                            <div className="lang-country-flag"><img src="/img/version3/th-circle.svg" alt="language" /></div>
                            <div className="lang-name-short">{selectedLanguage}</div>
                          </div>
                          {showLanguageDropdown && <div className="lang-dropdown">
                            {(isLoggedIn ? countryList.filter(x => x.iso === "TH") : countryList).map((country, index) => {
                              return (
                                <div className="header-dropdown-box" key={index}>
                                  <div className="display-flex">
                                    <div className="lang-square-flag">
                                      <img
                                        src={country.icon_circle}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div className="lang-country-name">
                                      {country.name}
                                    </div>
                                    <div className="display-flex child-lang">
                                      {country.child_languages.map((lang, i) => {
                                        return (
                                          <div onClick={() => handleCountryOnClick(country, lang.languageCode)} className={"dropdown-lang" + (selectedLanguageCode.toLowerCase() === lang.languageCode ? " active" : "")} key={i}>
                                            {lang.name}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Auth/>
      <CustomSpinWheelContainer />
    </>
  );
}

export default Header;