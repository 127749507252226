import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import { map } from "lodash";
import { WebUrl, _MAIN_WALLET_ID, TransactionType } from "../../util/Constant";
import Breadcrumb from "components/custom/Breadcrumb";
import Slider from "react-slick";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { ApiKey, ApiUrl, TransactionStatus } from "../../util/Constant";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { numberWithCurrencyFormat2, stringIsNullOrEmpty } from "../../util/Util";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiDotsHorizontalCircle,
} from "@mdi/js";
import RightInformation from "../../components/custom/RightInformation";
import ApiEngine from "../../util/ApiEngine.js";
import { useSelector, useDispatch } from "react-redux";
import {
  setDisplayLogin,
  setDisplayRegister,
  setActiveTab,
  setBusy,
  setIdle,
  showMessage,
  showResponseMessage
} from "../../redux/AppAction";
import { Link, useHistory } from "react-router-dom";


const MainWallet = (props) => {
  const _history = useHistory();
  var _userData = useSelector((state) => state.authState.userData);
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [transactionActive, setTransactionActive] = useState('RELOAD');
  const [loadingWalletData, setLoadingWalletData] = useState(true);
  //const [walletInformation, setWalletInformation] = useState({});
  const [commision, setCommision] = useState("");
  const [reward, setReward] = useState("");

  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [tranasctionUrl, setTransactionUrl] = useState("");
  const [walletUrl, setWalletUrl] = useState("");
  const [currentItem, setItem] = useState([]);
  const [modal, setModal] = useState(false);
  const [recentTransaction, setRecentTransaction] = useState({});
  //const [latestGame, setLatestGame] = useState({});
  useEffect(() => {
    getTransactionData(startDate, endDate, transactionActive);
  }, []);

  async function getTransactionData(start, end, active = "", selected = "") {
    var type = 1;
    let apiUrl = "";
    if (stringIsNullOrEmpty(active)) {
      active = transactionActive
    }
    if (active == 'RELOAD' || active == 'WITHDRAWAL') {

      if (active == 'RELOAD') {
        type = TransactionType._DEPOSIT;
      } else if (active == 'WITHDRAWAL') {
        type = TransactionType._WITHDRAWAL;
      }

      apiUrl =
        ApiUrl._API_GET_TRANSACTION_BY_MEMBER_DEPOSIT_AND_WITHDRAWAL +
        "?StartDate=" +
        moment(start).format("YYYY-MM-DD") +
        "&EndDate=" +
        moment(end).format("YYYY-MM-DD") +
        "&type=" +
        type;

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");

      }
    }
    else if (active == 'INTERNAL_TRANSFER') {
      apiUrl = ApiUrl._API_GET_INTERNAL_TRANSFER +
        "?StartDate=" +
        moment(start).format("YYYY-MM-DD") +
        "&EndDate=" +
        moment(end).format("YYYY-MM-DD") +
        "&v=" +
        Date.now();

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
      }
    }
    else if (active == 'WALLET_TRANSFER') {
      apiUrl = ApiUrl._API_GET_WALLET_TRANSFER +
        "?dateFrom=" +
        moment(start).format('YYYY-MM-DD HH:mm:ss') +
        "&dateTo=" + moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      if (!stringIsNullOrEmpty(selected)) {
        apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD HH:mm:ss");
      }
      setWalletUrl(apiUrl);
    }
    setTransactionUrl(apiUrl);
  }




  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getTransactionData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  const toggle = () => {
    setModal(!modal);
  };

  const bannerSlick = {
    slidesToShow: 1,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
  };
  const breadcrumb = [
    {
      value: 'HOME',
      active: false,
      link: WebUrl._URL_MAIN
    },
    {
      value: 'WALLET',
      active: true,
    },
  ];
  let walletList = [
    {
      title: 'MAIN_WALLET',
      value: _userData['walletBalance'],
      icon: "/img/version3/main-wallet/wallet.png",
      link: ""
    },
    {
      title: 'COMMISSION',
      value: commision,
      icon: "/img/version3/main-wallet/commission.png",
      link: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY
    },
    {
      title: 'REWARDS',
      value: reward,
      icon: "/img/version3/main-wallet/reward.png",
      link: WebUrl._URL_REWARDS
    },
    {
      title: 'GAME_POINTS',
      value: _userData['loyaltyPoints'],
      icon: "/img/version3/main-wallet/points.png",
      link: WebUrl._URL_LOYALTY_REDEMPTION
    }
  ]
  const walletButtonList = [
    {
      title: 'RELOAD',
      icon: "/img/version3/main-wallet/reload.png",
      link: WebUrl._URL_DEPOSIT
    },
    {
      title: 'WITHDRAWAL',
      icon: "/img/version3/main-wallet/withdraw.png",
      link: WebUrl._URL_WITHDRAWAL
    },
    {
      title: 'TRANSFER',
      icon: "/img/version3/main-wallet/transfer.png",
      link: WebUrl._URL_TRANSFER
    },
  ];

  const bannerData = [
  ];

  const historyTab = [
    {
      title: 'RELOAD',
    },
    {
      title: 'WITHDRAWAL',
    },
    {
      title: 'INTERNAL_TRANSFER',
    },
    {
      title: 'WALLET_TRANSFER',
    },
  ];

  useEffect(() => {
    //getWalletData();
    getRewardData();
    getCommissionData();
    getRecentTransaction();
    //getLatestGame();
  }, []);

  const getCommissionData = async () => {
    var commissionJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );
    let unclaimComm = "";
    if (commissionJson[ApiKey._API_SUCCESS_KEY]) {
      let data = commissionJson[ApiKey._API_DATA_KEY];
      unclaimComm = data;
    }
    setCommision(unclaimComm.totalClaimable);
  }

  const getRewardData = async () => {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      setReward(grandTotalReward);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  const getRecentTransaction = async () => {
    var recentTransactionJson = await ApiEngine.get(ApiUrl._API_GET_RECENT_TRANSACTION_RECORD);

    if (recentTransactionJson[ApiKey._API_SUCCESS_KEY]) {
      setRecentTransaction(recentTransactionJson[ApiKey._API_DATA_KEY]);
    }
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  return (
    <>
      <section className="section-padding" id="main-wallet-section">
        <Breadcrumb
          data={breadcrumb}
        // escButtonOnClick={() => window.history.go(-1)}
        />
        <div className="with-information-container">
          <div className="left">
            <div className="wallet-list-container">
              {walletList &&
                walletList.length &&
                map(walletList, (item, index) => {
                  return (
                    <div className="wallet-list-item" onClick={() => _history.push(item.link)}>
                      <div className="first">
                        <div className="">
                          <img
                            src={item.icon}
                            alt={item.title}
                          />
                        </div>
                      </div>
                      <div className="second">
                        <div className="title mb-1">
                          {t(item.title)}
                        </div>
                        <div className="value">
                          {item.title == "MAIN_WALLET" ? (
                            <>
                              $ {item.value === "" ? t("LOADING") + "... " : " " + numberWithCurrencyFormat2(parseFloat(item.value), 2, true)}
                            </>
                          ) : (
                            <>
                              {item.value === "" ? t("LOADING") + "... " : " " + numberWithCurrencyFormat2(parseFloat(item.value), 2, true)}
                            </>
                          )
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <hr className="hr-left-blue" />
            <div className="wallet-button-container">
              {walletButtonList &&
                walletButtonList.length &&
                map(walletButtonList, (item, index) => {
                  return (
                    item.title === "WITHDRAWAL" ? !_userData.isBlockWithdraw ?
                      <div className="wallet-button-item" onClick={() => _history.push(item.link)}>
                        <div className="first">
                          <div className="custom-icon-box">
                            <img
                              src={item.icon}
                              alt={item.title}
                              className="img-responsive"
                            />
                          </div>
                        </div>
                        <div className="second">
                          <div className="title">
                            {t(item.title)}
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                      :
                      <div className="wallet-button-item" onClick={() => _history.push(item.link)}>
                        <div className="first">
                          <div className="custom-icon-box">
                            <img
                              src={item.icon}
                              alt={item.title}
                              className="img-responsive"
                            />
                          </div>
                        </div>
                        <div className="second">
                          <div className="title">
                            {t(item.title)}
                          </div>
                        </div>
                      </div>
                  );
                })
              }
            </div>
            <Slider {...bannerSlick} className="promotion-slick mt-5">
              {bannerData &&
                bannerData.length &&
                map(bannerData, (item, index) => {
                  return (
                    <div key={index} className="promotion-items">
                      <img src={item.image} alt="promotion" className="img-100" />
                    </div>
                  );
                })}
            </Slider>
            <div className="main-wallet-history-container mt-5" id="transaction-history">
              <h2 className="title-with-blue-under">{t("TRANSACTION_HISTORY")}</h2>
              <div className="transaction-history-tab-nav-container mt-4">
                {historyTab &&
                  historyTab.length &&
                  map(historyTab, (item, index) => {
                    return (
                      <div
                        className={"transaction-history-tab-nav-item " + (transactionActive == item.title ? 'active' : '')}
                        onClick={() => (setTransactionActive(item.title), getTransactionData(startDate, endDate, item.title))}
                      >
                        {t(item.title)}
                      </div>
                    );
                  })
                }
              </div>
              <hr className="hr-under-tab-nav" />
              <div className="mt-4">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    getTransactionData(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
              </div>
              <div className="transaction-history-body">
                {(transactionActive == 'RELOAD' ||
                  transactionActive == 'WITHDRAWAL' ||
                  transactionActive == 'INTERNAL_TRANSFER'
                ) ? (
                  <>
                    <div className="card card-package">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table reportTable">
                            <thead>
                              <tr>
                                <th>{t("DATE")}</th>
                                <th>{t("STATUS")}</th>
                                <th>{t("AMOUNT")}</th>
                                <th>{t("DETAIL")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <DynamicLoadTable
                                pageSize={20}
                                apiQuery={tranasctionUrl}
                                searchEnabled={true}
                                isTable={true}
                                render={(item, i) => {
                                  var statusHtml = "";
                                  switch (item["approvalStatus"]) {
                                    case TransactionStatus._APPROVED:
                                      statusHtml = (
                                        <span style={{ color: "#03BB3A" }}>
                                          {t("APPROVED")}
                                        </span>
                                      );
                                      break;
                                    case TransactionStatus._REJECTED:
                                      statusHtml = (
                                        <span style={{ color: "red" }}>
                                          {t("REJECTED")}
                                        </span>
                                      );
                                      break;
                                    case TransactionStatus._OPEN:
                                      statusHtml = (
                                        <span style={{ color: "#3369FF" }}>
                                          {t("PROCESSING")}
                                        </span>
                                      );
                                      break;
                                    default:
                                      statusHtml = (
                                        <span style={{ color: "#3369FF" }}>
                                          {t("PROCESSING")}
                                        </span>
                                      );
                                      break;
                                  }
                                  return (
                                    <tr key={i}>
                                      <td>{item["date"]}</td>
                                      <td>{statusHtml}</td>
                                      <td>
                                        {numberWithCurrencyFormat2(
                                          parseFloat(item["amount"]),
                                          2,
                                          true
                                        )}
                                      </td>
                                      <td
                                        style={{ color: "#3369FF", cursor: "pointer" }}
                                        onClick={() => {
                                          setItem(item);
                                          toggle();
                                        }}
                                      >
                                        {" "}
                                        {t("VIEW")}{" "}
                                      </td>
                                    </tr>
                                  );
                                }}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card card-package">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table reportTable">
                            <thead>
                              <tr>
                                <th>{t("DATE")}</th>
                                <th>{t("AMOUNT")}</th>
                                <th>{t("FROMWALLET")}</th>
                                <th>{t("TOWALLET")}</th>
                                <th>{t("BONUS")}</th>
                                <th>{t("FROMWALLETBALANCEBEFORE")}</th>
                                <th>{t("FROMWALLETBALANCEAFTER")}</th>
                                <th>{t("TOWALLETBALANCEBEFORE")}</th>
                                <th>{t("TOWALLETBALANCEAFTER")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <DynamicLoadTable
                                pageSize={20}
                                apiQuery={walletUrl}
                                searchEnabled={true}
                                isTable={true}
                                render={(item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {moment(item["createdTime"]).format("YYYY-MM-DD")}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["depositAmount"]), 2, true)}
                                      </td>
                                      <td>
                                        {item["transferPerson"]}
                                      </td>
                                      <td>
                                        {item["productName"]}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["bonus"]), 2, true)}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["walletBefore"]), 2, true)}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["walletAfter"]), 2, true)}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["gameWalletBefore"]), 2, true)}
                                      </td>
                                      <td>
                                        {numberWithCurrencyFormat2(parseFloat(item["gameWalletAfter"]), 2, true)}
                                      </td>
                                    </tr>
                                  );
                                }} />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )
                }
              </div>
            </div>
          </div>
          <div className="right">
            <RightInformation
              info={true}
              //infoTitle="WALLET_INFO"
              //infoData={walletInformation}
              recent={true}
              recentTitle="RECENT_TRANSACTION"
              recentData={recentTransaction}
              games={true}
            //gamesTitle="LATEST_GAME"
            //gamesData={latestGame}
            />
          </div>
        </div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          wrapClassName="settings-modal"
          centered
        >
          <ModalHeader  >
            <div className="modal-header-wrapper">
              <h3 className="customFontMontserratsansserif">{t("DEPOSIT_RECORD_DETAIL")}</h3>

              <button
                type="button"
                className="modal-X-btn"
                onClick={() => setModal(!modal)}
                data-dismiss="modal"
                aria-hidden="true"
              >
                <span>&times;</span>
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="container">

              {currentItem["approvalStatus"] == TransactionStatus._APPROVED && (
                <div className="row">
                  <div
                    className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                  >
                    <Icon path={mdiCheckCircle} size={2.4} color="#03BB3A" />
                  </div>
                  <div
                    className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                  >

                    <div style={{ fontSize: "18px", color: "#03BB3A", fontWeight: "bold" }} className="customFontMontserratsansserif">
                      {t("APPROVED")}
                    </div>
                  </div>
                </div>
              )}
              {currentItem["approvalStatus"] == TransactionStatus._REJECTED && (
                <div className="row">
                  <div
                    className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                  >
                    <Icon path={mdiCloseCircle} size={2.4} color="red" />
                  </div>
                  <div
                    className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                  >

                    <div style={{ fontSize: "18px", color: "red", fontWeight: "bold" }} className="customFontMontserratsansserif">
                      {t("REJECTED")}
                    </div>
                  </div>
                </div>
              )}
              {currentItem["approvalStatus"] == TransactionStatus._OPEN && (
                <div className="row ">
                  <div
                    className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-1"
                  >
                    <Icon path={mdiDotsHorizontalCircle} size={2.4} color="white" />
                  </div>
                  <div
                    className=" col-12 d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-2"
                  >

                    <div style={{ fontSize: "18px", color: "white", fontWeight: "bold" }} className="customFontMontserratsansserif">
                      {t("PROCESSING")}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="d-flex align-items-center justify-content-center col-12 customFontMontserratsansserif mb-3"
                  style={{
                    fontSize: "17px",
                    color: "white"
                  }}
                >
                  {numberWithCurrencyFormat2(parseFloat(currentItem["amount"]), 2, true)}
                </div>
                <div
                  className="d-flex col-12 customFontMontserratsansserif mb-2"

                >
                  <div style={{ width: "50%", textAlign: "left", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                    {t("DATE_AND_TIME")}
                  </div>
                  <div style={{ width: "50%", textAlign: "right", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                    {currentItem["dateTime"]}
                  </div>
                </div>
                <div
                  className="d-flex col-12 customFontMontserratsansserif"
                >
                  <div style={{ width: "50%", textAlign: "left", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                    {t("NEW_BALANCE")}
                  </div>
                  <div style={{ width: "50%", textAlign: "right", color: "white", fontSize: "14px", }} className="customFontMontserratsansserif">
                    {numberWithCurrencyFormat2(
                      parseFloat(currentItem["postMemberPrimaryBalance"]),
                      2,
                      true
                    )}
                  </div>
                </div>
                <Button
                  className="btn btn-block btn-brand-gradient customFontMontserratsansserif mt-3"
                  onClick={toggle}
                >
                  {t("DONE")}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </section>
    </>
  );
};
export default MainWallet;
