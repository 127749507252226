import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { WebUrl } from "../../util/Constant";
import Breadcrumb from "components/custom/Breadcrumb";


const RewardsTnC = (props) => {
  let _history = useHistory();
  const { t } = useTranslation();

  const breadcrumb = [
    {
      value: 'REWARDS',
      active: false,
      link: WebUrl._URL_REWARDS
    },
    {
      value: 'REWARDS_TNC',
      active: true,
      link: WebUrl._URL_REWARDS_TNC
    },
  ];

  return (
    <>
        <section className="section-padding" id="terms-section">
          <Breadcrumb
            data={breadcrumb}
            // escButtonOnClick={() => window.history.go(-1)}
          />
          <div className="custom-description-container">
            <h3 class="mb-3">{t('TNC')}</h3>
            <div className="tnc-list mb-3"> 1.<div className="col-12">{t("REWARDS_1")}</div></div>
            <div className="tnc-list mb-3"> 2.<div className="col-12">{t("REWARDS_2")}</div></div>
            <div className="tnc-list mb-3"> 3.<div className="col-12">{t("REWARDS_3")}</div></div>
            <div className="tnc-list mb-3"> 4.<div className="col-12">{t("REWARDS_4")}</div></div>
            <div className="tnc-list mb-3"> 5.<div className="col-12">{t("REWARDS_5")}</div></div>
            <div className="tnc-list mb-3"> 6.<div className="col-12">{t("REWARDS_6")}</div></div>
            <div className="tnc-list mb-3"> 7.<div className="col-12">{t("REWARDS_7")}</div></div>
            <div className="tnc-list mb-3"> 8.<div className="col-12">{t("REWARDS_8")}</div></div>
          </div>
        </section>
    </>
  )
}

export default RewardsTnC;
