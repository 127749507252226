import React, { useState, useEffect, useRef } from "react";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, setBusy, setIdle, setDownlineQuery, setPageSize, setY } from "../../redux/AppAction.js";
import ApiEngine from "../../util/ApiEngine.js";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { numberWithCurrencyFormat2, createMultiPartFormBody } from "../../util/Util";
import { Accordion } from "react-bootstrap";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { updateUserData } from "../../redux/AuthAction";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useHistory } from "react-router-dom";

const Claim = (props) => {
  var _userData = useSelector((state) => state.authState.userData);
  var _history = useHistory();
    var { t } = useTranslation();
  const _dispatch = useDispatch();
  const [data, setData] = useState({});
  //const [latestGame, setLatestGame] = useState({});
  const [displayDataIndex, setDisplayDataIndex] = useState(-1);
  let _savedPageSize = useSelector((state) => state.appState.pageSize);
  let _savedQuery = useSelector((state) => state.appState.savedQuery);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [vipClaimHistory, setVipClaimHistory] = useState({});
    const breadcrumb = [
        {
          value: 'VIP_EVENT',
          active: false,
          link: WebUrl._URL_VIP +
            "?GAzobE73Vse4nr8WpufA=" +
            _userData.hideSummary
        },
        {
          value: 'CLAIM',
          active: true,
          link: WebUrl._URL_VIP_CLAIM
        },
      ];
    
  useEffect(() => {
    _dispatch(updateUserData());
    init();
    //getLatestGame();
    getLatestCliamHistory();
  }, []);

  async function init() {
    _dispatch(setBusy());
    _dispatch(setDownlineQuery(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM ));
    _dispatch(setPageSize(10));
    _dispatch(setY(0));

    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setData(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  const getLatestCliamHistory = async () => {
    var latestClaimHistoryJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_CLAIM_HISTORY);

    if (latestClaimHistoryJson[ApiKey._API_SUCCESS_KEY]) {
      setVipClaimHistory(latestClaimHistoryJson[ApiKey._API_DATA_KEY]["data"]);
    }
  }

  function preSubmit() {
    setShowConfirmation(true);
  }

  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(ApiUrl._API_CLAIM_SHAREHOLDER, createMultiPartFormBody({
    }));
    _dispatch(setIdle());

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {

       setShowConfirmation(false);
      _dispatch(updateUserData());
      window.location.reload();
    }
    else {
      _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
    }
  }

  return (
        <>
            <section className="section-padding" id="shareholder-section">
                <Breadcrumb
                    data={breadcrumb}
                    // escButtonOnClick={() => window.history.go(-1)}
                />
                <div className="with-information-container">
                    <div className="left">
                        <h3 className="mb-4">{t("CURRENT_BALANCE")}</h3>
                        <div className="custom-box-with-icon-container mb-4">
                            <div className="first">
                                <div className="custom-icon-box">
                                    <img src="/img/version3/main-wallet/dollar.png" alt="dollar" />
                                </div>
                                <div>
                                    GEM {data["totalclaimable"] ? numberWithCurrencyFormat2(parseFloat(data["totalclaimable"]), 2, true) : "0.00"}
                                </div>
                            </div>
                            <div className="second">
                                <button
                                  type="submit"
                                  className={data["totalclaimable"] === 0 ? "claim-btn-disable" : "claim-btn"}
                                  disabled={_userData.blockClaim === true || data["totalclaimable"] === 0}
                                  onClick={() => (_userData.blockClaim === false && data["totalclaimable"] != 0) && preSubmit()}>
                                  {t("CLAIM")}
                                </button>
                            </div>
                        </div>
                        {data["data"] && data["data"].length > 0 &&
                          <div className="claim-history-wrapper mb-4">
                              <h3>{t("CURRENT_SUMMARY")}</h3>
                              <div className="claim-history-btn" onClick={() => _history.push(WebUrl._URL_CLAIM_HISTORY)}>
                                  <div>{t("CLAIM_HISTORY")}</div>
                                  <Icon
                                    path={mdiHistory}
                                    title="User Profile"
                                    size={1}
                                  />
                              </div>
                          </div>
                        }
                         <Accordion className="downline-accordion">
                          <DynamicLoadTable pageSize={_savedPageSize} apiQuery={_savedQuery} searchEnabled={true} autoscrollEnabled={true} render={(item, i) => {
                            return (
                              <>
                                <Accordion.Item
                                  key={i}
                                  eventKey={i}
                                  className="downline-main-boxes"
                                >
                                  <Accordion.Header
                                    onClick={() => {
                                      if (displayDataIndex === i) {
                                        setDisplayDataIndex(-1);
                                      }
                                      else {
                                        setDisplayDataIndex(i);
                                      }
                                    }}>
                                      <div className="text-left">
                                      <div style={{ fontWeight:'bold' }}>{t("CLAIMABLE")} : {numberWithCurrencyFormat2(parseFloat(item["netProfit"]), 2, true)}</div>
                                        <div className="font11 mt-1">{item["date"]}</div>
                                      </div>
                                      <div className="hide-summary-box">
                                        <span class="badge badge-secondary badge-danger">{t("UNCLAIMED")}</span>
                                      </div>
                                  </Accordion.Header>
                                  <AccordionBody>
                                    <div className="vip-event-downline-data">
                                      <div className="shareholder-data shareholder-date">
                                        <div className="data-left">{t("DOWNLINE_SALES")}</div>
                                        <div className="data-right">{numberWithCurrencyFormat2(parseFloat(item["downlineSales"]), 2, true)}</div>
                                      </div>
                                      <div className="shareholder-data shareholder-percent">
                                        <div className="data-left">{t("MY_SALES")}</div>
                                        <div className="data-right">{numberWithCurrencyFormat2(parseFloat(item["ownSales"]), 2, true)}</div>
                                      </div>
                                    </div>
                                  </AccordionBody>
                                </Accordion.Item>
                              </>
                            )
                          }}
                          />
                         </Accordion>
                    </div>
                    <Modal
                          isOpen={showConfirmation}
                          id="modal-small-custom"
                          fade={true}
                          toggle={() => {
                            setShowConfirmation(false);
                          }}
                          centered
                        >
                          <ModalBody>
                            <div className="text-center">
                              <div className="confirm-div">
                                <img
                                  className="cop-code"
                                  src="/img/version3/error.svg"
                                  alt="error"
                                />
                                <div>{t(
                                  "CONFIRM_CLAIM"
                                )}</div>
                                <div className="confirm-btn-div">
                                  <button type="submit" className="btn custom-btn" style={{ background: '#FF334B' }} onClick={() => setShowConfirmation(false)}>{t("CANCEL")}</button>
                                  <button type="submit" className="btn custom-btn" onClick={() => { submitForm();  }}>{t("CONFIRM")}</button>

                                </div>
                              </div>
                            </div>
                          </ModalBody>
                    </Modal>

                    <div className="right">
                        <RightInformation
                            claimHistory={true}
                            claimHistoryTitle="CLAIM_HISTORY"
                            claimHistoryData={vipClaimHistory}
                            games={true}
                            //gamesTitle="LATEST_GAME"
                            //gamesData={latestGame}
                            promotion={true}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Claim;
