import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import ApiEngine from "../../util/ApiEngine";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";

const InternalTransferReport = (props) => {
  var _userData = useSelector((state) => state.authState.userData);
  const { t } = useTranslation();
  const [initPage, setInitPage] = useState(true);
  const [claimData, setClaimData] = useState([]);
  const [transferUrl, setTransferUrl] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [totalclaimable, setTotalClaimable] = useState("");
  //const [latestGame, setLatestGame] = useState({});
  const [unclaimedData, setUnclaimedData] = useState({});
  // useEffect(() => {
  //   // getTransferData();
  //   getTransferData(startDate, endDate);
  // }, []);

  useEffect(() => {
    getTransferData(startDate, endDate);
    getUnClaimed();
    //getLatestGame();
  }, []);

  async function getUnClaimed() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      //setTotalClaimable(responseJson[ApiKey._API_DATA_KEY]);
      setTotalClaimable(responseJson[ApiKey._API_DATA_KEY]["totalclaimable"]);
      setUnclaimedData(responseJson[ApiKey._API_DATA_KEY]["data"]);
    }
  }

  //const getLatestGame = async () => {
  //  var latestGameJson = await ApiEngine.get(ApiUrl._API_GET_LATEST_GAME);

  //  if (latestGameJson[ApiKey._API_SUCCESS_KEY]) {
  //    setLatestGame(latestGameJson[ApiKey._API_DATA_KEY]);
  //  }
  //}

  async function getTransferData(start, end, selected = "") {
    var apiUrl =
      ApiUrl._API_GET_CLAIM_HISTORY +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD")+
      " 00:00:00" +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD")+
      " 23:59:59" +
      "&v=" +
      Date.now();

    if (!stringIsNullOrEmpty(selected)) {
      apiUrl +=
        "&SelectedDate=" + moment(selected).format("YYYY-MM-DD HH:mm:ss");
    }

    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setClaimData(data["data"]);
    }
    setTransferUrl(apiUrl);
    setInitPage(false);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getTransferData(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  const breadcrumb = [
    {
      value: 'VIP_EVENT',
      active: false,
      link: WebUrl._URL_VIP +
        "?GAzobE73Vse4nr8WpufA=" +
        _userData.hideSummary
    },
    {
      value: 'CLAIM',
      active: false,
      link: WebUrl._URL_VIP_CLAIM
    },
    {
      value: 'CLAIM_HISTORY',
      active: true,
      link: WebUrl._URL_CLAIM_HISTORY
    },
  ];

  //const sampledata = [
  //  {
  //      sales: '0.144',
  //      date: '041282023',
  //  },
  //  {
  //      sales: '0.143',
  //      date: '041282023',
  //  },
  //  {
  //      sales: '-0.144',
  //      date: '041282023',
  //  },
  //  {
  //      sales: '-0.144',
  //      date: '041282023',
  //  },
  //  {
  //      sales: '0.144',
  //      date: '041282023',
  //  },
  //];

  //const unclaimedData = [
  //  {
  //      sales: '0.144',
  //  },
  //  {
  //      sales: '0.143',
  //  },
  //  {
  //      sales: '0.144',
  //  },
  //];
  return (
    <>
      <section className="section-padding" id="game-record-section">
        <Breadcrumb
          data={breadcrumb}
        />
        <div className="with-information-container">
            <div className="left">
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onSave={(start, end) => {
                  setStartDate(moment(start));
                  setEndDate(moment(end));
                  getTransferData(start, end);
                  setSelectedDate("");
                }}
                options={true}
              />
              {/* {sampledata &&
                sampledata.length &&
                map(sampledata, (item, index) => {
                return (
                    <div 
                    key={"unclaimed" + index}
                    className="template-box-style-1 mb-3 vip-claim-history-box"
                    >
                        <div className="first">
                            <div className="title color-gray">
                                {item.date}
                            </div>
                        </div>
                        <div className={
                          item.sales < 0
                            ? "rejected text-right"
                            : "approved text-right"
                        }>
                          {item.sales}
                        </div>
                    </div>
                );
                })
              } */}
              {claimData.length > 0 ? (
                  <DynamicLoadTable
                    pageSize={20}
                    apiQuery={transferUrl}
                    isTable={true}
                    searchEnabled={true}
                    render={(item, i) => {
                      return (
                        <div 
                          key={"claim_history_" + i}
                          className="template-box-style-1 mb-3 vip-claim-history-box"
                          >
                              <div className="first">
                                  <div className="title color-gray">
                                    {moment(item["date"]).format("DD-MM-YYYY")}
                                  </div>
                              </div>
                              <div 
                                className={
                                  item["netProfit"] < 0
                                    ? "rejected text-right"
                                    : "approved text-right"
                                }
                              >
                                  {numberWithCurrencyFormat(
                                    parseFloat(item["netProfit"]),
                                    3
                                  )}
                              </div>
                          </div>
                      );
                    }}
                  />
                ) : (
                  <div className="color-gray font-14">
                    {initPage ? t("CLICK_SEARCH_TO_CONTINUE") : t("NO_DATA_FOUND")}
                  </div>
                )}
            </div>
            <div className="right">
              <RightInformation
                  unclaimedBalance={true}
                  unclaimedBalanceAmount={totalclaimable}
                  unclaimedSummary={true}
                  unclaimedSummaryData={unclaimedData}
                  games={true}
                  //gamesTitle="LATEST_GAME"
                  //gamesData={latestGame}
                  promotion={true}
              />
            </div>
        </div>
      </section>
    </>
  );
};
export default InternalTransferReport;
