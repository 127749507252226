import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType } from "../../util/Constant";
import moment from "moment";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import ContentHeader from "components/content/header";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import LoyaltyRedemption from "./LoyaltyRedemption";
import RightInformation from "../../components/custom/RightInformation";
import Breadcrumb from "components/custom/Breadcrumb";

const LoyaltyRedemptionHistory = (props) => {
  const { t } = useTranslation();
  const [redeemUrl, setRedeemUrl] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("week").format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf("week").format('YYYY-MM-DD'));
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getRedemptionRecord(startDate, endDate, selectedDate);
  }, []);


  async function getRedemptionRecord(start, end, selected = "") {
    let apiUrl =
      ApiUrl._API_GET_LOYALTY_REDEMPTION_HISTORY +
      "?StartDate=" +
      moment(start).format("YYYY-MM-DD") +
      "&EndDate=" +
      moment(end).format("YYYY-MM-DD") +
      "&v=" +
      Date.now();
    if (!stringIsNullOrEmpty(selected)) {
      apiUrl += "&SelectedDate=" + moment(selected).format("YYYY-MM-DD");
    }
    setRedeemUrl(apiUrl);
  }

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedDate)) {
      getRedemptionRecord(startDate, endDate, selectedDate);
    }
  }, [selectedDate]);

  const breadcrumb = [
    {
      value: 'HOME',
      active: false,
      link: WebUrl._URL_MAIN
    },
    {
      value: 'WALLET',
      active: false,
      link: WebUrl._URL_MAIN_WALLET
    },
    {
      value: 'GAME_POINTS',
      active: false,
      link: WebUrl._URL_LOYALTY_REDEMPTION
    },
    {
      value: 'GAME_POINTS_REDEMPTION_RECORD',
      active: true
    }
  ];

  return (
    <>
      <section className="section-padding" id="loyalty-section1">

        <Breadcrumb
          data={breadcrumb}

        />

        <div className="with-information-container">
          <div className="left">

            <div id="loyalty-redemption-history">
              <div className="title-with-blue-under" style={{ marginBottom: "20px" }}>{t("GAME_POINTS_REDEMPTION_RECORD")}</div>
              <div className="mt-3" id="loyalty-history-box">
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSave={(start, end) => {
                    setStartDate(moment(start));
                    setEndDate(moment(end));
                    getRedemptionRecord(start, end);
                    setSelectedDate("");
                  }}
                  options={true}
                />
                <div className="card card-package">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table reportTable">
                        <thead>
                          <tr>
                            <th>{t("ITEM_NAME")}</th>
                            <th>{t("POINTS_NEEDED")}</th>
                            <th>{t("POINTS_BEFORE_REDEEM")}</th>
                            <th>{t("POINTS_AFTER_REDEEM")}</th>
                            <th>{t("REDEEM_DATE")}</th>
                            <th>{t("RECEIVED")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <DynamicLoadTable
                            pageSize={20}
                            apiQuery={redeemUrl}
                            searchEnabled={true}
                            isTable={true}
                            render={(item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item["itemName"]}</td>
                                  <td>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsNeeded"]),
                                      3
                                    )}
                                  </td>
                                  <td>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsBeforeRedeem"]),
                                      3
                                    )}
                                  </td>
                                  <td>
                                    {numberWithCurrencyFormat(
                                      parseFloat(item["pointsAfterRedeem"]),
                                      3
                                    )}
                                  </td>
                                  <td>{moment(item["redeemTime"]).format("YYYY-MM-DD HH:mm:ss")}</td>
                                  {item["itemReceived"] == true ?
                                    <td>
                                              <span style={{ color: "#03BB3A" }}>{ t("RECEIVED") }</span>
                                    </td>
                                    :
                                    <td></td>
                                  }
                                </tr>
                              )
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <RightInformation
              redemption={true}
              games={true}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default LoyaltyRedemptionHistory;