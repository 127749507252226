import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/custom/Header";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, SessionKey, TransactionType, PanelType } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine.js";
import Breadcrumb from "components/custom/Breadcrumb";
import { createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import RightInformation from "../../components/custom/RightInformation";
import { redemptionHistory } from "../../config/game-configs";
import ReactHtmlParser from "react-html-parser";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalHeader,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Carousel as ReactCarousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  CardSubtitle
} from "reactstrap";
import { Card as BTCard, Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from "react-bootstrap/Spinner";
import { keys, first, last } from 'lodash';
import ContentHeader from "../../components/content/header";
import Sidebar from "components/sidebar";
import InfoIcon from "../../assets/img/v3/icon.png";
import { updateUserData } from "../../redux/AuthAction";
import { redemptionHistoryFunction } from "../../redux/InformationAction.js";

const LoyaltyRedemption = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);


  const breadcrumb = [
    {
      value: 'HOME',
      active: false,
      link: WebUrl._URL_MAIN
    },
    {
      value: 'WALLET',
      active: false,
      link: WebUrl._URL_MAIN_WALLET
    },
    {
      value: 'GAME_POINTS',
      active: true,
    },
  ];

  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showClaimConfirmation, setShowClaimConfirmation] = useState(false);
  const [loyaltyItemData, setLoyaltyItemData] = useState([]);
  const [data, setData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [redemptionHistoryList, setRedemptionHistoryList] = useState([]);
  const [memberPoint, setMemberPoint] = useState(0);
  const [unclaimLoyaltyPoints, setUnclaimLoyaltyPoints] = useState(0);
  const [getPointsDone, setGetPointsDone] = useState(false);
  const activeTheme = useSelector(
    (state) => state.authState.userData.selectedTheme
  );
  const userId = useSelector(
    (state) => state.authState.userData.userId
  );
  const loyaltyPoints = useSelector(
    (state) => state.authState.userData.loyaltyPoints
  );

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'th')]);



  async function init() {
    _dispatch(setBusy());
    let languageCode = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ? localStorage.getItem(SessionKey._LANGUAGE) : 'en';
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_ALL_LOYALTY_ITEM + "?languageCode=" + languageCode);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setLoyaltyItemData(responseJson[ApiKey._API_DATA_KEY]);
    }
    getMemberLoyaltyPoints();
    getUnclaimLoyaltyPoints();
    _dispatch(setIdle());
  };

  const getMemberLoyaltyPoints = async () => {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_LOYALTY_POINTS_BY_ID + "?id=" + userId);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMemberPoint(responseJson[ApiKey._API_DATA_KEY]);
    }
    _dispatch(setIdle());
    setGetPointsDone(true);
  }

  const getUnclaimLoyaltyPoints = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_LOYALTY_POINTS
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["totalClaimable"];
      setUnclaimLoyaltyPoints(data);
    }
  };

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  /// <summary>
  /// Author : -
  /// </summary>

  function preRedeem(e, item) {
    e.stopPropagation();
    setData(item);
    setShowConfirmation(true);
  }

  const handleRedemption = async (data) => {
    _dispatch(setBusy())

    if (data["productType"] === "Voucher") {
      let transactionJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_VOUCHER + "?itemId=" + data.id
      );

      if (transactionJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(updateUserData());
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
        init();
      }
      else {
        _dispatch(
          showResponseMessage(
            transactionJson[ApiKey._API_SUCCESS_KEY],
            transactionJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
      _dispatch(redemptionHistoryFunction());
    }
    else {
      let responseJson = await ApiEngine.post(
        ApiUrl._API_REDEEM_LOYALTY_ITEM + "?itemId=" + data.id
      );

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        init();
      }
    }
    _dispatch(setIdle());
  };


  function preSubmit() {
    setShowClaimConfirmation(true);
  }

  function modalOnConfirm() {
    setShowConfirmation(false);
    handleRedemption(data);
  }

  return (
    <div id="loyalty-redemption">
      {showConfirmation && (
        //<SweetAlert
        //    info
        //    showCancel
        //    confirmBtnText={t("CONFIRM")}
        //    cancelBtnText={t("CANCEL")}
        //    confirmBtnBsStyle="info"
        //    title={t("CONFIRM_REDEEM")}
        //    onConfirm={() => {
        //        setShowConfirmation(false);
        //        handleRedemption(data);
        //    }}
        //    onCancel={() => {
        //        setShowConfirmation(false);
        //    }}
        //></SweetAlert>
        <Modal
          isOpen={true}
          id="loyalty-confirm-modal"
          fade={false}
          toggle={() => {
          }}
          centered
        >
          <ModalBody>
            <div className="text-center">
              <div className="loyalty-confirm-div">
                <div className="loyalty-title-container">
                  <div className="loyalty-confirm-modal-title">{ReactHtmlParser("Confirm to use <b>" + data.pointsNeeded + " points</b> to redeem")}</div>
                  <div className="loyalty-confirm-modal-item"> {t(data.name) + "?"}</div>
                </div>

                <div className="loyalty-confirm-btn-div">
                  <button type="submit" className="btn loyalty-custom-btn" style={{ background: '#FF334B' }} onClick={() => setShowConfirmation(false)} >{t("CANCEL")}</button>
                  <button type="submit" className="btn loyalty-custom-btn" onClick={() => modalOnConfirm()}>{t("REDEEM")}</button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

      )}

      <section className="section-padding" id="loyalty-section">
        <div>
          <div id="loyalty-redemption-homepage">
            <Breadcrumb
              data={breadcrumb}

            />
            <div className="with-information-container">
              <div className="left">
                <div>

                  <div className="game-points-container mb-3">
                    <div className="first">
                      <div className="custom-icon-box">
                        <img src="/img/version3/points.svg" alt="game-points" />
                      </div>
                      <div className="loyalty-point-display-header">
                        <b>
                          {t("GAME_POINTS_BALANCE")}
                        </b>
                      </div>
                    </div>


                    <div className="header-currency">
                      <div
                        className="header-wallet-balance"
                        onClick={() =>
                          _history.push(WebUrl._URL_LOYALTY_POINTS_HISTORY)
                        }
                      >
                        <div className="amount">
                          {getPointsDone
                            ? " " +
                            numberWithCurrencyFormat(
                              parseFloat(memberPoint),
                              2
                            ) + " " + t("POINTS")
                            : t("LOADING") + "... "}
                        </div>
                        <div className="tooltip-text">
                          {t("CLICK_TO") +
                            " " +
                            t("VIEW_EARNED_POINTS_HISTORY")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="loyalty-header-title">
                    {/* <ContentHeader title={t("GAME_POINTS_REDEMPTION")} />*/}
                    <div className="loyalty-title"> <h2 className="title-with-blue-under mb-3">{t("GAME_POINTS_REDEMPTION")}</h2></div>


                    <div className="loyalty-history-link-class">
                      <Link to={WebUrl._URL_LOYALTY_REDEMPTION_HISTORY}>
                        <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                          <div style={{ marginRight: "5px", fontSize: "12px" }}> {t("GAME_POINTS_REDEMPTION_RECORD")} </div>
                          <Icon
                            path={mdiHistory}
                            title="User Profile"
                            size={1}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>

                  <Row xs={4} md={4} className="g2 mb-1">
                    {loyaltyItemData &&
                      loyaltyItemData.length > 0 &&
                      loyaltyItemData.map((item, index) => {
                        return (
                          <>
                            <Col style={{ padding: "0 7px" }}>
                              <Card className="text-center my-1 game-pts-item-card" border="primary" onClick={() => { _history.push({ pathname: WebUrl._URL_LOYALTY_VOUCHER_DETAILS, state: { id: item.id } }) }} style={{ cursor: "pointer", padding: "10px" }}>
                                <img
                                  variant="middle"
                                  src={item.image}
                                  className="game-pts-item-img"
                                  alt="images"
                                />
                                <CardBody className="game-pts-item-card-body">
                                  <CardTitle className="game-pts-item-title">
                                    <div className="game-pts-item-name"> {item.name}</div>
                                    <div className="game-pts-item-image"> <img
                                      src={InfoIcon}
                                      className="dektop-info-icon"
                                      alt="info icon"
                                    /></div>

                                  </CardTitle>
                                  <CardText className="game-pts-item-text">
                                    <div className="game-pts-item-pointneeded">{numberWithCurrencyFormat(item.pointsNeeded, 2, true)}</div>

                                  </CardText>
                                  {item.redeemable === true && item.stock > 0 && memberPoint >= item.pointsNeeded ?
                                    <Button style={{ width: "100%", borderRadius: "10px", color: "#fff" }} variant="primary" onClick={(e) => preRedeem(e, item)}>{t("REDEEM")}</Button>
                                    //: item.redeemable === false && item.stock > 0 && (memberPoint > item.pointsNeeded || memberPoint < item.pointsNeeded) ?
                                    //    <Button style={{ width: "100%", borderRadius: "10px", color: "#fff" }} variant="primary" onClick={() => preRedeem(item)}>{t("REDEEM")}</Button>
                                    : item.redeemable === false && item.stock > 0 && (memberPoint > item.pointsNeeded || memberPoint < item.pointsNeeded) ?
                                      <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("REDEEMED")}</Button>
                                      : item.redeemable === true && item.stock > 0 && memberPoint < item.pointsNeeded ?
                                        <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("REDEEM")}</Button>
                                        :
                                        <Button className="loyalty-disabled" style={{ width: "100%", borderRadius: "10px" }} disabled variant="light">{t("OUT_OF_STOCK")}</Button>
                                  }
                                </CardBody>
                              </Card>
                            </Col>
                          </>
                        );
                      })}
                  </Row>
                </div>

              </div>
              <div className="right">
                <RightInformation
                  redemption={true}
                  //redemptionData={redemptionHistoryList}
                  games={true}
                //gamesTitle={"Game For You"}
                //gamesData={[]}
                />
              </div>
            </div>

          </div>

        </div>

      </section>

    </div>
  )
}


export default LoyaltyRedemption;