import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./redux/AppReducer";
import authReducer from "./redux/AuthReducer";
import gameReducer from "redux/GameReducer";
import languageReducer from "./redux/LanguageReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import informationReducer from "./redux/InformationReducer";

/// <summary>
/// Author :
/// </summary>
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    authState: authReducer,
    appState: appReducer,
    languageState: languageReducer,
    gameState: gameReducer,
    informationState: informationReducer
  })
);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

let persistor = persistStore(store);

export { store, persistor };
