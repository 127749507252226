import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { ApiKey, ApiUrl, WebUrl, TransactionType } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
  numberWithCurrencyFormat2,
  createMultiPartFormBody
} from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import ContentHeader from "components/content/header";
import Breadcrumb from "components/custom/Breadcrumb";
import RightInformation from "../../components/custom/RightInformation";
import { Accordion } from "react-bootstrap";
import { updateUserData } from "../../redux/AuthAction.js";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";

const UnclaimCommissionHistory = () => {
  let _history = useHistory();
  const { t, i18n } = useTranslation();
  var _dispatch = useDispatch();
  var _userData = useSelector((state) => state.authState.userData);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [data, setData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("");

  const { handleSubmit } = useForm();

  //const [memberData, setMemberData] = useState({});
  const [unclaimComm, setUnclaimComm] = useState("");
  const [recentTransaction, setRecentTransaction] = useState({});

  useEffect(() => {
    init();
    getRecentTransaction();
  }, []);

  useEffect(() => {
    if (!stringIsNullOrEmpty(selectedMonth)) {
      setSelectedIndex(
        data.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
      );
    }
  }, [selectedMonth]);

  async function init() {
    _dispatch(setBusy());
    //var responseJson = await ApiEngine.get(
    //  ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
    //);
    //if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
    //  throw responseJson[ApiKey._API_MESSAGE_KEY];
    //}
    //setMemberData(responseJson[ApiKey._API_DATA_KEY]);
    getUnclaimCommHistory();

    _dispatch(setIdle());
  }

  const getUnclaimCommHistory = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_UNCLAIM_COMMISSION_AMT
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      setUnclaimComm(data);
    }
  };

  function preSubmit() {
    setShowConfirmation(true);
  }

  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_COMMISSION,
      createMultiPartFormBody({})
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(updateUserData());
      window.location.reload();
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }
  const breadcrumb = [
    {
      value: 'Home',
      active: false,
      link: WebUrl._URL_MAIN
    },
    {
      value: 'Wallet',
      active: false,
      link: WebUrl._URL_MAIN_WALLET
    },
    {
      value: 'Commission',
      active: true,
    },
  ];

  const getRecentTransaction = async () => {
    var recentTransactionJson = await ApiEngine.get(ApiUrl._API_GET_RECENT_TRANSACTION_RECORD + "?transactionTypeId=" + TransactionType._COMMISSION);

    if (recentTransactionJson[ApiKey._API_SUCCESS_KEY]) {
      setRecentTransaction(recentTransactionJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <section className="section-padding" id="commission-section">
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}
      <Breadcrumb
        data={breadcrumb}
      />
      <div className="with-information-container">
        <div className="left">
          <div className="custom-box-with-icon-container mb-4">
            <div className="first">
                <div className="custom-icon-box">
                    <img src="/img/version3/main-wallet/dollar.png" alt="dollar" />
                </div>
                <div style={{ color: "#fff" }}>
                  {unclaimComm == ""
                    ? t("LOADING") + "... "
                    : " " +
                    numberWithCurrencyFormat2(
                      parseFloat(unclaimComm.totalClaimable),
                      2,
                      true
                  )}
                </div>
            </div>
            <div className="second">
              <button
                type="submit"
                className="claim-btn"
                disabled={unclaimComm == "" || unclaimComm["totalClaimable"] == 0}
                onClick={() => unclaimComm["totalClaimable"] != 0 && preSubmit()}
              >
                {t("CLAIM")}
              </button>
            </div>
          </div>
          <div className="comm-history-wrapper mb-4">
            <div>{t("CURRENT_SUMMARY")}</div>
            <div className="comm-history-btn" onClick={() => _history.push(WebUrl._URL_COMMISSION_HISTORY)}>
              <div>{t("COMMISSION_HISTORY")}</div>
              <Icon
                path={mdiHistory}
                title="User Profile"
                size={1}
              />
            </div>
          </div>
          {unclaimComm["data"] && unclaimComm["data"].length > 0 && (
            <Accordion>
              {unclaimComm["data"].map((row, i) => {
                var decimalPlace = 2;// numberWithCurrencyFormat2(parseFloat(row["totalComm"]), decimalPlace, true) == "0.00" ? 3 : 2;
                if (row && numberWithCurrencyFormat2(parseFloat(row["totalComm"]), decimalPlace, true) !== "0.00") {
                  return (
                    <Accordion.Item
                      key={i}
                      eventKey={i}
                      className={"comm-accordion-item item-" + i}
                    >
                      <Accordion.Header className="comm-accordion-header">
                        <div className="left">
                          <div className="title">
                            {t("CLAIMABLE")} : {numberWithCurrencyFormat2(parseFloat(row["totalComm"]), decimalPlace, true)}
                          </div>
                          <div className="date">
                            {moment(row["commDate"]).format("YYYY-MM-DD")}
                          </div>
                        </div>
                        <div className="unclaimed-tag right">
                          {t("UNCLAIMED")}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div key={i} className="comm-data-wrapper">
                          <div className="comm-data comm-date">
                            <div className="data-left">{t("DOWNLINE_COMM")}</div>
                            <div className="data-right">{numberWithCurrencyFormat2(parseFloat(row["downlineComm"]), decimalPlace, true)}</div>
                          </div>
                          <div className="comm-data comm-percent">
                            <div className="data-left">{t("MY_COMM")}</div>
                            <div className="data-right">{numberWithCurrencyFormat2(parseFloat(row["ownComm"]), decimalPlace, true)}</div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              })}
            </Accordion>
          )}
        </div>
        <div className="right">
          <RightInformation
            info={true}
            recent={true}
            recentTitle="RECENT_COMMISSION_TRANSACTION"
            recentData={recentTransaction}
            games={false}
            commButton={true}
          />
        </div>
      </div>
    </section>
  );
};

export default UnclaimCommissionHistory;
