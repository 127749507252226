import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import ContentHeader from "components/content/header";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, LanguageKey, SessionKey, WebUrl, TransactionType } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import {
  showResponseMessage,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useForm } from "react-hook-form";
import { createMultiPartFormBody, numberWithCurrencyFormat2 } from "../../util/Util";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { keys, first, last } from 'lodash';
import RightInformation from "../../components/custom/RightInformation";
import StyledSelect from "../../components/custom/StyledSelect";
import { Controller } from "react-hook-form";
import { updateUserData } from "../../redux/AuthAction";
import Icon from "@mdi/react";
import { mdiHistory } from "@mdi/js";
import { useSelector } from "react-redux";

/// <summary>
/// Author: -
/// </summary>
const Rewards = (props) => {
  var _history = useHistory();
  var _dispatch = useDispatch();
  var _location = useLocation();
  var { t } = useTranslation(LanguageKey._PRIMARY);
  var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en';

  const { handleSubmit, control, watch, setValue } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [bannerData, setBannerData] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rewardsData, setRewardData] = useState([]);
  const [activeKeyList, setActiveKeyList] = useState([]);
  const [showPageData, setShowPageData] = useState(false);
  const [isClaimAll, setIsClaimAll] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [grandTotalRewards, setGrandTotalRewards] = useState(0);
  const [gameCategoryData, setGameCategoryData] = useState([]);
  ;
  const [tabIndex, setTabIndex] = useState(0);
  const [activeCatTitle, setActiveCatTitle] = useState("All");
  //const [walletInformation, setWalletInformation] = useState({});
  const [recentTransaction, setRecentTransaction] = useState({});
  //const [latestGame, setLatestGame] = useState({});
  const _DAY_OF_WEEK = [
    { label: t("MON_L"), value: 1 },
    { label: t("TUE_L"), value: 2 },
    { label: t("WED_L"), value: 3 },
    { label: t("THU_L"), value: 4 },
    { label: t("FRI_L"), value: 5 },
    { label: t("SAT_L"), value: 6 },
    { label: t("SUN_L"), value: 0 },
  ];
  const DEFAULT_CAROUSEL_ITEMS = [
    {
      src: "/img/banner.png",
      altText: "",
      caption: "",
      header: "",
      key: "1",
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === bannerData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? bannerData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const setActiveCategory = (category, index) => {
    setActiveCatTitle(category);
    setTabIndex(index);
  }

  async function init() {
    _dispatch(setBusy());
    _dispatch(updateUserData());
    apiGetUserRewardBanner();
    apiGetProductCategoryDesktop();
    apiGetCategoryRewardRates();
    _dispatch(setIdle());
  }

  async function apiGetUserRewardBanner() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_REWARD_BANNER);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannerData(responseJson[ApiKey._API_DATA_KEY]);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  async function apiGetProductCategoryDesktop() {
    let categoryData = [];

    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
      "?selectedLanguage=" +
      selectedLanguage
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      categoryData = responseJson[ApiKey._API_DATA_KEY].filter(x => x['title'] !== "V.Sports");
      let tempActiveKeyList = [];
      let tempCategoryList = [];
      categoryData.map((category, index) => {
        tempActiveKeyList.push({ id: category.id, active: false });
        tempCategoryList.push({
          label: category.originalTitle,
          value: category.originalTitle,
        });
      });

      console.log(tempActiveKeyList);
      console.log(tempCategoryList);
      setActiveKeyList(tempActiveKeyList);
      setGameCategories(tempCategoryList);
    } else {
      _dispatch(
        showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
      );
    }
  }

  async function apiGetCategoryRewardRates() {
    setShowPageData(false);
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_REWARDS_SCHEDULES);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempScheduleData = responseJson[ApiKey._API_DATA_KEY];
      let tempSchDataObj = {};
      tempScheduleData.map((schData) => {
        if (!tempSchDataObj[schData["date"]]) {
          tempSchDataObj[schData["date"]] = {};
        }
        if (!tempSchDataObj[schData["date"]][schData["productName"]]) {
          tempSchDataObj[schData["date"]][schData["productName"]] = { rate: schData["comm"], productImage: schData["productImage"], categoryTitle: schData["categoryTitle"] };
        }
      })
      let tempDateList = Object.keys(tempSchDataObj).sort();
      setDateList(tempDateList);
      setScheduleData(tempSchDataObj);
      responseJson = await ApiEngine.get(
        ApiUrl._API_GET_CATEGORY_REWARD_RATES + "?all=true"
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
      let tempObj = {};
      let grandTotalReward = 0;
      let categoryTotal = {};
      data.map((rewardData, index) => {
        if (!tempObj[rewardData["date"]]) {
          tempObj[rewardData["date"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]] = { totalAmount: 0, totalAmountClaimable: 0 };
        }
        if (!tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]]) {
          tempObj[rewardData["date"]][rewardData["categoryTitle"]][rewardData["providerName"]] = { productImage: rewardData["productImage"], rate: rewardData["rewardRate"] };
        }
        tempObj[rewardData["date"]].totalAmount += rewardData["amount"];
        tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
        if (rewardData["claimed"] !== "True") {
          tempObj[rewardData["date"]].totalAmountClaimable += rewardData["amount"];
          tempObj[rewardData["date"]][rewardData["categoryTitle"]].totalAmountClaimable += rewardData["amount"];
          grandTotalReward += rewardData["amount"];

          if (!categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]].totalAmount += rewardData["amount"];
          }
          else if (categoryTotal[rewardData["categoryTitle"]]) {
            categoryTotal[rewardData["categoryTitle"]] =
            {
              category: rewardData["categoryTitle"],
              totalAmount: rewardData["amount"]
            };
          }
        }

      });
      let temp = Object.keys(tempObj).sort();
      setSelectedDate(moment(temp[temp.length - 1]));
      setRewardData(tempObj);
      setGrandTotalRewards(grandTotalReward);
      setGameCategoryData(categoryTotal);
      } else {
        _dispatch(
          showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
        );
      }
    }
    setShowPageData(true);
  }

  useEffect(() => {
    init();
  }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
    localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit(all) {
    setIsClaimAll(all);

    setShowConfirmation(true);
  }

  /// <summary>
  /// Author: -
  /// </summary>
  async function submitForm() {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CLAIM_REWARD,
      createMultiPartFormBody({
        date: selectedDate.clone().format("YYYY-MM-DD"),
        category: activeCatTitle,
        all: isClaimAll
      })
    );
    _dispatch(setIdle());

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(updateUserData());
      window.location.reload();
    }
    else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  }

  const handleSelectedDate = (days) => {
    _dispatch(setBusy());

    let tempSelectedDate = selectedDate.clone().add(days, "days");
    const dateKeys = keys(rewardsData).sort();
    const firstDate = first(dateKeys);
    const lastDate = last(dateKeys);
    if (
      tempSelectedDate.isSameOrBefore(currentDateTime.clone().add(1, "weeks")) &&
      tempSelectedDate.isSameOrAfter(currentDateTime.clone().add(-1, "weeks")) &&
        tempSelectedDate.isSameOrAfter(moment(dateList[0])) &&
        tempSelectedDate.isSameOrBefore(moment(dateList[dateList.length - 1]))
    ) {
      setSelectedDate(tempSelectedDate);
    }
    _dispatch(setIdle());
  };

  useEffect(() => {
    getRecentTransaction();
  }, []);

  const getRecentTransaction = async () => {
    var recentTransactionJson = await ApiEngine.get(ApiUrl._API_GET_RECENT_TRANSACTION_RECORD + "?transactionTypeId=" + TransactionType._REWARD);

    if (recentTransactionJson[ApiKey._API_SUCCESS_KEY]) {
      setRecentTransaction(recentTransactionJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <div id="rewards">
      {showConfirmation && (
        <SweetAlert
          info
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="info"
          title={t("CONFIRM_CLAIM")}
          onConfirm={() => {
            setShowConfirmation(false);
            handleSubmit(submitForm)();
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        ></SweetAlert>
      )}

      <section className="section-padding" id="rewards-section">
        <ContentHeader title={t("REWARDS")} />
        <div className="with-information-container">
          <div className="left">
            <div className="custom-box-with-icon-container mb-4">
              <div className="first">
                {t("GRAND_TOTAL_REWARD_CLAIMABLE")}
              </div>
              <div className="second">
                {numberWithCurrencyFormat2(grandTotalRewards, 2, true)}
              </div>
            </div>
            <div className="custom-two-column-divider">
              <div>
                <div>
                  <div className="title">
                    {t("TOTAL_EARNED")}
                  </div>
                  <div className="details mt-2">
                    {numberWithCurrencyFormat2(activeCatTitle !== "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 && Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0
                    ? rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmount']
                    : activeCatTitle === "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmount'] : "0", 2, true)}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="title">
                    {t("TOTAL_CLAIMABLE")}
                  </div>
                  <div className="details mt-2">
                    {numberWithCurrencyFormat2(activeCatTitle !== "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 && Object.keys(rewardsData[selectedDate.format('YYYY-MM-DD')]).filter(x => x === activeCatTitle).length > 0
                    ? rewardsData[selectedDate.format('YYYY-MM-DD')][activeCatTitle]['totalAmountClaimable']
                    : activeCatTitle === "All" && Object.keys(rewardsData).length > 0 && Object.keys(rewardsData).filter(x => x === selectedDate.format('YYYY-MM-DD')).length > 0 ? rewardsData[selectedDate.format('YYYY-MM-DD')]['totalAmountClaimable'] : "0", 2, true)}
                  </div>
                </div>
                <div>
                  {rewardsData && grandTotalRewards > 0 && numberWithCurrencyFormat2(grandTotalRewards, 2, true) != "0.00" &&
                    <button
                      className="claim-btn"
                      disabled={
                        !rewardsData && grandTotalRewards === 0
                      }
                      onClick={() =>
                        rewardsData && grandTotalRewards > 0 && preSubmit(true)
                      }
                      >
                        {t("CLAIM")}
                    </button>
                  }
                </div>
              </div>
            </div>
            <div className="rewards-history-wrapper mt-2">
              <div className="reminder rewards-reminder">
                {t("NOTICE_REWARDS", { startHour: 15, endHour: 16 })}
              </div>
              <div className="rewards-history-btn" onClick={() => _history.push(WebUrl._URL_REWARD_HISTORY)}>
                <div>{t("REWARDS_RECORD")}</div>
                <Icon
                  path={mdiHistory}
                  title="User Profile"
                  size={1}
                />
              </div>
            </div>
            <div className="rewards-calendar card card-package mt-4">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="rewards-calendar-week font-14" onClick={() => handleSelectedDate(-7)}
                      style={{
                        cursor: (selectedDate
                          .clone()
                          .add(-7, "days")
                          .isBefore(currentDateTime.clone().add(-1, "weeks"))
                          || selectedDate
                            .clone()
                            .add(-7, "days")
                            .isBefore(moment(dateList[0])))
                          ? "not-allowed"
                          : "pointer",
                        color: (selectedDate
                          .clone()
                          .add(-7, "days")
                          .isBefore(currentDateTime.clone().add(-1, "weeks"))
                          || selectedDate
                            .clone()
                            .add(-7, "days")
                            .isBefore(moment(dateList[0])))
                          ? "grey"
                          : "unset",
                      }}
                    >
                      <img src="/img/version3/prev-gray.png" alt="arrow"/>
                    </div>
                    <div className="rewards-calendar-month">{t(selectedDate.format("MMMM"))}</div>
                    <div className="rewards-calendar-week font-14" onClick={() => handleSelectedDate(7)}

                      // style={{
                      //     cursor: selectedDate.clone().add(7, 'days').isAfter(currentDateTime.clone().add(2, 'weeks')) ? "not-allowed" : "pointer",
                      //     color: selectedDate.clone().add(7, 'days').isAfter(currentDateTime.clone().add(2, 'weeks')) ? "grey" : "white"
                      // }}

                      style={{
                        cursor: selectedDate
                          .clone()
                          .add(7, "days")
                          .isAfter(currentDateTime.clone().add(1, "weeks")
                            || selectedDate
                              .clone()
                              .add(-7, "days")
                              .isAfter(moment(dateList[dateList.length - 1])))
                          ? "not-allowed"
                          : "pointer",
                        color: selectedDate
                          .clone()
                          .add(7, "days")
                          .isAfter(currentDateTime.clone().add(1, "weeks")
                            || selectedDate
                              .clone()
                              .add(-7, "days")
                              .isAfter(moment(dateList[dateList.length - 1])))
                          ? "grey"
                          : "white",
                      }}
                    >
                      <img src="/img/version3/next-gray.png" alt="arrow"/>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <div
                      className="rewards-calendar-day" //selectedDay - 3 days
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(-3, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-3, "days")
                            .isBefore(moment(dateList[0]))
                            ? "not-allowed"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(-3, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-3, "days")
                            .isBefore(moment(dateList[0]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(-3)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() - 3)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(-3, "days")
                          .format("DD")}
                      </div>
                    </div>

                    <div
                      className="rewards-calendar-day" //selectedDay - 2 days
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(-2, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-2, "days")
                            .isBefore(moment(dateList[0]))
                            ? "not-allowed"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(-2, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-2, "days")
                            .isBefore(moment(dateList[0]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(-2)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() - 2)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(-2, "days")
                          .format("DD")}
                      </div>
                    </div>

                    <div
                      className="rewards-calendar-day" //selectedDay - 1 day
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(-1, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-1, "days")
                            .isBefore(moment(dateList[0]))
                            ? "not-allowed"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(-1, "days")
                            .isBefore(
                              currentDateTime.clone().add(-1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(-1, "days")
                            .isBefore(moment(dateList[0]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(-1)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() - 1)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(-1, "days")
                          .format("DD")}
                      </div>
                    </div>

                    <div
                      className="rewards-calendar-day selected"
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value === (7 + selectedDate.day()) % 7
                          ).label
                        }
                      </div>
                      <div>{moment(selectedDate).format("DD")}</div>
                    </div>

                    <div
                      className="rewards-calendar-day" //selectedDay + 1 day
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(1, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(1, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "unset"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(1, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(1, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(1)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() + 1)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(1, "days")
                          .format("DD")}
                      </div>
                      {/* </>
                      )} */}
                    </div>

                    <div
                      className="rewards-calendar-day" //selectedDay + 2 days
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(2, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(2, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "unset"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(2, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(2, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(2)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() + 2)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(2, "days")
                          .format("DD")}
                      </div>
                      {/* </>
                      )} */}
                    </div>

                    <div
                      className="rewards-calendar-day" //selectedDay + 3 days
                      style={{
                        cursor:
                          selectedDate
                            .clone()
                            .add(3, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(3, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "unset"
                            : "pointer",
                        color:
                          selectedDate
                            .clone()
                            .add(3, "days")
                            .isSameOrAfter(
                              currentDateTime.clone().add(1, "weeks")
                          ) || selectedDate
                            .clone()
                            .add(3, "days")
                            .isAfter(moment(dateList[dateList.length - 1]))
                            ? "grey"
                            : "#FFFFFF",
                      }}
                      onClick={() => handleSelectedDate(3)}
                    >
                      <div>
                        {
                          _DAY_OF_WEEK.find(
                            (x) =>
                              x.value ===
                              (7 + (selectedDate.day() + 3)) % 7
                          ).label
                        }
                      </div>
                      <div>
                        {moment(selectedDate)
                          .add(3, "days")
                          .format("DD")}
                      </div>
                      {/* </>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-select-white-blend mt-4">
              <Controller
                control={control}
                name="game-category"
                render={({ onChange, value }) => {
                  return (
                    <StyledSelect
                      options={gameCategories}
                      value={value === undefined && gameCategories[0] !== undefined ?
                        gameCategories.filter(x => x.value == activeCatTitle)[0].value :
                        value}
                      onChange={(e) => {
                        onChange(e.value);
                        setActiveCategory(e.value)
                      }}
                      noOptionsMessage={() => t("NO_OPTIONS")}
                      loadingMessage={() => t("LOADING...")}
                    />
                  );
                }}
              />
            </div>
            <div className="rewards-game-list-container mt-4">
              {activeCatTitle === "All"  ?
                  <>
                    {Object.keys(scheduleData).length > 0 &&
                      scheduleData[selectedDate.format('YYYY-MM-DD')] != undefined &&
                      Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).length > 0 &&
                      Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).sort().map((productName, index) => {
                        if (scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate > 0) {
                          return (
                            <div className="rewards-game-item">
                              <div>
                                <img
                                  src={scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].productImage}
                                  alt={productName}
                                  className="w-100" />
                              </div>
                              <div className="title mt-2">
                                {productName}
                              </div>
                              <div className="details">
                                {scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate}%
                              </div>
                            </div>
                          )
                        }
                      })}
                  </> :
                  <>
                    {Object.keys(scheduleData).length > 0 &&
                      scheduleData[selectedDate.format('YYYY-MM-DD')] != undefined &&
                      Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).length > 0 &&
                      Object.keys(scheduleData[selectedDate.format('YYYY-MM-DD')]).sort().map((productName, index) => {
                        if (scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate > 0 && scheduleData[selectedDate.format('YYYY-MM-DD')][productName]["categoryTitle"] === activeCatTitle) {
                          return (
                            <div className="rewards-game-item">
                              <div>
                                <img
                                  src={scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].productImage}
                                  alt={productName}
                                  className="w-100" />
                              </div>
                              <div className="title mt-2">
                                {productName}
                              </div>
                              <div className="details">
                                {scheduleData[moment(selectedDate).format("YYYY-MM-DD")][productName].rate}%
                              </div>
                            </div>
                          )
                        }
                      })}
                  </>
              }
            </div>
          </div>
          <div className="right">
            <RightInformation
              button={true}
              buttonText="REWARDS_TNC"
              recent={true}
              recentTitle="RECENT_REWARD_CLAIMABLE"
              recentData={recentTransaction}
              games={true}
              rewardInfo={true}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default Rewards;
