import React, { useState, useEffect, useRef } from "react";
import GameMenu from "components/game-list/game-menu";
import Slider from "react-slick";
import { map } from "lodash";
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import ApiEngine from "../../util/ApiEngine";
import { ApiKey, ApiUrl, WebUrl, SessionKey, _MAIN_WALLET_ID, AlertTypes } from "../../util/Constant";
import { createFormBody, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage, showResponseMessage } from "../../redux/AppAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import reloadPasswordIcon from "../../assets/img/icon/reset_password.svg";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useHistory } from "react-router-dom";

const Lottery = (props) => {
  const _LOTTERY_CATEGORY_ID = 1;
  const _history = useHistory();
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  const [lotteryGameCount, setLotteryGameCount] = useState(0);
  const [memberData, setMemberData] = useState({});
  const [lotteryGames, setLotteryGames] = useState([]);
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [clubFee, setClubFee] = useState(0);
  const [selectedGameName, setSelectedGameName] = useState("");
  const [productGameId, setProductGameId] = useState(0);
  const [masterProductId, setMasterProductId] = useState(0);
  const [walletList, setWalletList] = useState([]);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [toLobbyBtn, setToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [appDeepLink, setAppDeepLink] = useState("");
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const [mpData, setMpData] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.authState);
  const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
  const [currentInput, setCurrentInput] = useState();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [showProductPasswordAlert, setShowProductPasswordAlert] =
    useState(false);
  const [isSpecialRollover, setIsSpecialRollover] = useState(false);
  const [playableCategory, setPlayableCategory] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  let usernameRef = useRef();
  let passwordRef = useRef();
  const videoEl = useRef(null);

  const _ALERT_TIMEOUT = 3000;

  const lotterySlider = {
    centerMode: lotteryGameCount <= 2 ? false : true,
    slidesToShow: lotteryGameCount > 3 ? 3 : lotteryGameCount,
    dots: false,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    centerPadding: "0",
    autoplay: false,
    swipe: lotteryGameCount <= 2 ? false : true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: lotteryGameCount,
        },
      },
    ],
  };

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getProductCategory();
      getMemberSpecialRollover();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isSpecialRollover) {
      getBonusDepositProductCategory();
    }
  }, [isSpecialRollover, productCategory])

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  // Note: Temporary static url link until BO ready
  /* 
   [
    {
      image: require("../../assets/img/lottery/1.png"),
      link: "/Game/StartGame?gameId=25&isApp=0", //JJGD GameId = 25
    },
    {
      image: require("../../assets/img/lottery/3.png"),
      link: "/Game/StartGame?gameId=9&isApp=0",
    },
    {
      image: require("../../assets/img/lottery/2.png"),
      link: "/Game/StartGame?gameId=1&isApp=0",
    },
    {
      image: require("../../assets/img/lottery/2.png"),
      link: "/Game/StartGame?gameId=1&isApp=0",
    },
  ]
  */

  useEffect(() => {
    init();
  }, [lotteryGameCount]);

  const init = async () => {
    let responseJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_GAME_LIST_BY_CATEGORY_ID + "?categoryName=Lottery&languageCode=" +

      (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en'));

    // localStorage.getItem(SessionKey._LANGUAGE));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY];
      let tempLotteryGames = [];
      data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row) => {
        tempLotteryGames.push(row);
      });

      setLotteryGameCount(tempLotteryGames.length);
      if (tempLotteryGames.length === lotterySlider.slidesToShow && tempLotteryGames.length >= 2) {

        /* if number of slides is equal to slidesToShow, need to add the same slides one more time for the sliding to work */
        data.filter(x => !stringIsNullOrEmpty(x['desktopImage'])).map((row) => {
          tempLotteryGames.push(row);
        });
      }
      setLotteryGames(tempLotteryGames);
    }
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    categoryTitle,
    isNewWindow,
    packageNameKeyword
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }

    if (isSpecialRollover) {
      let productCategoryListArr = [...playableCategory];

      if (hasOwnLobby !== true) {
        if (productCategoryListArr.includes(categoryTitle)) {
          if (isSeamless == false) {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(
              ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
              "?masterProductId=" +
              masterProductId
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              setWalletList(responseJson[ApiKey._API_DATA_KEY]);

              let tempFromOptions = [];
              let tempToOptions = [];

              responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                  label: product.productName,
                  value: product.productId,
                  amount: parseFloat(product.balance),
                };
                if (product.productId === _MAIN_WALLET_ID) {
                  tempFromOptions.push(option);
                } else if (product.productId === masterProductId) {
                  setBonus(product.bonus);
                  setCommission(product.commission);
                  if (product.productName === "D2 Poker") {
                    setClubFee(product.clubFee);
                  }
                  setGameWalletBalanceBeforeTransfer(product.balance);
                  tempToOptions.push(option);
                }
              });
              setFromOptions(tempFromOptions);
              setToOptions(tempToOptions);

              var mpJson = await ApiEngine(
                ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                "?masterProductId=" +
                masterProductId
              );
              if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                let data = mpJson[ApiKey._API_DATA_KEY];
                setMpData(data);
                if (window.ReactNativeWebView && isApp) {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      action: "deeplink",
                      url: appUrl,
                      deeplink: appDeepLink
                        .replace("[username]", data["username"])
                        .replace("[password]", data["password"]),
                      packageKeyword: packageNameKeyword,
                    })
                  );
                }
              }
            }
            setProductGameId(gameId);
            var isNewWindowJson = await ApiEngine(
              ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
            );
            setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
            setShowPregamePopup(true);

            _dispatch(setIdle());
          } else {
            window.open(
              `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
              }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
            );
          }
        }
        else {
          _dispatch(
            showMessage({
              type: AlertTypes._INFO,
              content: t("ROLLOVER_EXIST"),
              onConfirm: () => {
              },
            })
          );
        }
      } else {
        if (isSeamless == false) {
          _dispatch(setBusy());
          var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
            "?masterProductId=" +
            masterProductId
          );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

            let tempFromOptions = [];
            let tempToOptions = [];

            responseJson[ApiKey._API_DATA_KEY].map((product) => {
              let option = {
                label: product.productName,
                value: product.productId,
                amount: parseFloat(product.balance),
              };
              if (product.productId === _MAIN_WALLET_ID) {
                tempFromOptions.push(option);
              } else if (product.productId === masterProductId) {
                setBonus(product.bonus);
                setCommission(product.commission);
                if (product.productName === "D2 Poker") {
                  setClubFee(product.clubFee);
                }
                setGameWalletBalanceBeforeTransfer(product.balance);
                tempToOptions.push(option);
              }
            });
            setFromOptions(tempFromOptions);
            setToOptions(tempToOptions);

            var mpJson = await ApiEngine(
              ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
              "?masterProductId=" +
              masterProductId
            );
            if (mpJson[ApiKey._API_SUCCESS_KEY]) {
              let data = mpJson[ApiKey._API_DATA_KEY];
              setMpData(data);
            }
          }

          var isNewWindowJson = await ApiEngine(
            ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
          );
          setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
          setShowPregamePopup(true);
          setProductGameId(gameId);
          setShowPregamePopup(true);
          setToLobbyBtn(true);
          _dispatch(setIdle());
        }
      }
    }
    else {
      if (hasOwnLobby !== true) {
        if (isSeamless == false) {
          _dispatch(setBusy());
          var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
            "?masterProductId=" +
            masterProductId
          );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

            let tempFromOptions = [];
            let tempToOptions = [];

            responseJson[ApiKey._API_DATA_KEY].map((product) => {
              let option = {
                label: product.productName,
                value: product.productId,
                amount: parseFloat(product.balance),
              };
              if (product.productId === _MAIN_WALLET_ID) {
                tempFromOptions.push(option);
              } else if (product.productId === masterProductId) {
                setBonus(product.bonus);
                setCommission(product.commission);
                if (product.productName === "D2 Poker") {
                  setClubFee(product.clubFee);
                }
                setGameWalletBalanceBeforeTransfer(product.balance);
                tempToOptions.push(option);
              }
            });
            setFromOptions(tempFromOptions);
            setToOptions(tempToOptions);

            var mpJson = await ApiEngine(
              ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
              "?masterProductId=" +
              masterProductId
            );
            if (mpJson[ApiKey._API_SUCCESS_KEY]) {
              let data = mpJson[ApiKey._API_DATA_KEY];
              setMpData(data);
              if (window.ReactNativeWebView && isApp) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    action: "deeplink",
                    url: appUrl,
                    deeplink: appDeepLink
                      .replace("[username]", data["username"])
                      .replace("[password]", data["password"]),
                    packageKeyword: packageNameKeyword,
                  })
                );
              }
            }
          }
          setProductGameId(gameId);
          var isNewWindowJson = await ApiEngine(
            ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
          );
          setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
          setShowPregamePopup(true);

          _dispatch(setIdle());
        } else {
          window.open(
            `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
            }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
          );
        }
      } else {
        if (isSeamless == false) {
          _dispatch(setBusy());
          var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
            "?masterProductId=" +
            masterProductId
          );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWalletList(responseJson[ApiKey._API_DATA_KEY]);

            let tempFromOptions = [];
            let tempToOptions = [];

            responseJson[ApiKey._API_DATA_KEY].map((product) => {
              let option = {
                label: product.productName,
                value: product.productId,
                amount: parseFloat(product.balance),
              };
              if (product.productId === _MAIN_WALLET_ID) {
                tempFromOptions.push(option);
              } else if (product.productId === masterProductId) {
                setBonus(product.bonus);
                setCommission(product.commission);
                if (product.productName === "D2 Poker") {
                  setClubFee(product.clubFee);
                }
                setGameWalletBalanceBeforeTransfer(product.balance);
                tempToOptions.push(option);
              }
            });
            setFromOptions(tempFromOptions);
            setToOptions(tempToOptions);

            var mpJson = await ApiEngine(
              ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
              "?masterProductId=" +
              masterProductId
            );
            if (mpJson[ApiKey._API_SUCCESS_KEY]) {
              let data = mpJson[ApiKey._API_DATA_KEY];
              setMpData(data);
            }
          }

          var isNewWindowJson = await ApiEngine(
            ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
          );
          setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
          setShowPregamePopup(true);
          setProductGameId(gameId);
          setShowPregamePopup(true);
          setToLobbyBtn(true);
          _dispatch(setIdle());
        }
      }
    }
  }

const submitPopupForm = async (data, e) => {
  _dispatch(setBusy());
  let params = {
    fromWallet: _MAIN_WALLET_ID,
    toWallet: masterProductId,
    amount: data.amount,
  };

  let responseJson = await ApiEngine.post(
    ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
    createFormBody(params)
  );

  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    await updateWalletBalance(
      parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
    );
    setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
    setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
    setIsTransferSuccess(true);
    setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

    e.target.reset();
  } else {
    _dispatch(
      showResponseMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        responseJson[ApiKey._API_MESSAGE_KEY]
      )
    );
  }
  _dispatch(setIdle());
};

async function updateWalletBalance() {
  var responseJson = await ApiEngine.get(
    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
  );
  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    let tempFromOptions = [];
    let tempToOptions = [];
    responseJson[ApiKey._API_DATA_KEY].map((product) => {
      let option = {
        label: product.productName,
        value: product.productId,
        amount: parseFloat(product.balance),
      };
      if (product.productId === _MAIN_WALLET_ID) {
        setMemberMainWalletBalance(
          isNaN(parseFloat(product.balance), 3)
            ? 0.0
            : numberWithCurrencyFormat(parseFloat(product.balance), 2, true)
        );
        tempFromOptions.push(option);
      } else if (product.productId === masterProductId) {
        let gameWalletBalance = parseFloat(product.balance);
        setGameWalletBalanceBeforeTransfer(gameWalletBalance);
        tempToOptions.push(option);
      }
    });
    setFromOptions(tempFromOptions);
    setToOptions(tempToOptions);
  }
}

async function resetProductPassword(masterProductId) {
  setMasterProductId(masterProductId);
  let apiUrl =
    ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
    "?masterProductId=" +
    masterProductId;
  let responseJson = await ApiEngine.get(apiUrl);
  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    let data = responseJson[ApiKey._API_DATA_KEY];
    setMpData(data);
    setShowProductPasswordAlert(true);
    setTimeout(() => setShowProductPasswordAlert(false), [_ALERT_TIMEOUT]);
  }
}

function copyText(textType) {
  if (textType === "username") {
    usernameRef.current.select();
  } else if (textType === "password") {
    passwordRef.current.select();
  }
  document.execCommand("copy");
  setShowCopyAlert(true);
  setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
}

const getMemberSpecialRollover = async () => {
  let responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_IS_SPECIAL_ROLLOVER);
  let data = "";

  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    data = responseJson[ApiKey._API_DATA_KEY];
    setIsSpecialRollover(data);
  }
}

const getBonusDepositProductCategory = async () => {
  let responseJson = await ApiEngine.get(ApiUrl._API_GET_BONUS_DEPOSIT_PRODUCT_CATEGORY);

  if (responseJson[ApiKey._API_SUCCESS_KEY]) {
    let data = responseJson[ApiKey._API_DATA_KEY];
    let productCategoryArr = [];

    if (data.includes(",")) {
      productCategoryArr = responseJson[ApiKey._API_DATA_KEY].split(",");
    }
    else {
      productCategoryArr.push(data);
    }

    let tempArr = [];
    [...productCategory].forEach((x) => {
      if (productCategoryArr.includes(x.value)) {
        tempArr.push(x.originalTitle);
      }
    })

    setPlayableCategory(tempArr);
  }
}

const getProductCategory = async () => {
  let tempArr = [];
  var productCategoryJson = await ApiEngine.get(ApiUrl._API_GET_PRODUCT_CATEGORY);
  if (productCategoryJson[ApiKey._API_SUCCESS_KEY]) {
    let data = productCategoryJson[ApiKey._API_DATA_KEY];
    data.forEach(x => {
      if (x.id > 0) {
        tempArr.push({ value: x.idtoString(), label: x.title, originalTitle: x.originalTitle });
      }
    });
    setProductCategory(tempArr);
  }
}

return (
  <>
    <section className='category-section'>
      <GameMenu />
    </section>
    <section id='Lottery-section'>
      <video
        autoPlay='autoplay'
        loop
        className='controls'
        playsInline
        muted
        id='width-video'
        src={require("../../assets/img/webm/LotteryWeb.webm")}
        ref={videoEl}
      />
      {/* <MouseParallaxContainer
          className='parallax esport-banner'
          containerStyles={{
            width: "100%",
          }}
          resetOnLeave
        >
          <MouseParallaxChild className='lottery-banner-img'>
            <img
              src={require("../../assets/img/lottery/bg-top.png")}
              className='img-100'
              alt='lottery-main'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.1}
            factorY={0.02}
            className='lottery-card'
          >
            <img
              src={require("../../assets/img/lottery/Poker2.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.05}
            factorY={-0.09}
            className='lottery-dice'
          >
            <img
              src={require("../../assets/img/lottery/dice.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.01}
            factorY={-0.04}
            className='lottery-gold'
          >
            <img
              src={require("../../assets/img/lottery/gold.png")}
              className='img-responsive opacity-6'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.02}
            factorY={0.09}
            className='lottery-gold'
          >
            <img
              src={require("../../assets/img/lottery/gold.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.01}
            factorY={0.02}
            className='lottery-green '
          >
            <img
              src={require("../../assets/img/lottery/green.png")}
              className='img-responsive opacity-6'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.01}
            factorY={-0.04}
            className='lottery-green'
          >
            <img
              src={require("../../assets/img/lottery/green.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={0.01}
            factorY={-0.03}
            className='lottery-red opacity-6'
          >
            <img
              src={require("../../assets/img/lottery/red.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>

          <MouseParallaxChild
            factorX={-0.01}
            factorY={0.03}
            className='lottery-red'
          >
            <img
              src={require("../../assets/img/lottery/red.png")}
              className='img-responsive'
              alt='lottery-icon'
            />
          </MouseParallaxChild>
        </MouseParallaxContainer> */}
    </section>
    <section
      id='Esport-section2'
      style={{
        backgroundImage: `url(${require("../../assets/img/providers/Backdrop_Lottery.png")})`,
      }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Slider {...lotterySlider}>
              {lotteryGames &&
                lotteryGames.length &&
                map(lotteryGames, (game, index) => {
                  return (
                    <div className={'esport-item' + (isLoggedIn ? game['gameUm'] === true ? ' game-um' : '' : '') + (lotteryGameCount < 2 ? ' single-item' : '')} key={index}
                      onClick={() => {
                        if (isLoggedIn) {
                          startGame(
                            game["id"],
                            game["masterProductHasOwnLobby"],
                            game["masterProductId"],
                            game["gameName"],
                            game["masterProductIsSeamless"],
                            game["isApp"],
                            game["appUrl"],
                            game["appDeepLink"],
                            game["categoryTitle"]
                          );
                        } else {
                          _dispatch(
                            showMessage({
                              type: AlertTypes._INFO,
                              content: t("PLEASE_LOGIN_FIRST"),
                              onConfirm: () => {
                                console.log("hi");
                              },
                            })
                          );
                        }
                      }}>
                      <img
                        src={game.desktopImage}
                        className='img-100'
                        alt='games'
                      />
                      {isLoggedIn && <div className="um-icon">
                        <img
                          src={require("../../assets/img/maintenance.png")}
                          className='img-responsive'
                          alt='poker-icon'
                        />
                      </div>}
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
    <Modal
      id="pregame-popup"
      fade={false}
      contentClassName="pregame-popup"
      isOpen={showPregamePopup}
      toggle={() => {
        setShowPregamePopup(false);
        setToLobbyBtn(false);
      }}
      centered
    >
      <ModalHeader className="m-auto border-0">
        <div className="modal-header-wrapper">
          <span className="font-20 text-white font-bold">
            {selectedGameName}
          </span>
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "0px 16px 41px" }}>
        <form onSubmit={handleSubmitPopup(submitPopupForm)}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex wallet-info text-center text-white font-bold">
                <div className="col-6 border-right">
                  {fromOptions.length > 0 && (
                    <>
                      <span className="font-15">{fromOptions[0].label}</span>
                      <br />
                      <span className="font-16">
                        {numberWithCurrencyFormat(fromOptions[0].amount, 2)}
                      </span>
                    </>
                  )}
                </div>
                <div className="col-6">
                  {toOptions.length > 0 && (
                    <>
                      <span className="font-15">{toOptions[0].label}</span>
                      <br />
                      <span className="font-16">
                        {numberWithCurrencyFormat(toOptions[0].amount, 2)}
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 d-flex">
                <div className="col-8">
                  <div className="pregame-transfer-input">
                    <input
                      type="text"
                      className="custom-input-style-2"
                      placeholder={"0.00"}
                      name="amount"
                      onClick={(e) => {
                        setCurrentInput(e.target);
                      }}
                      ref={registerPopup({
                        required: "PLEASE_ENTER_AMOUNT",
                        validate: {
                          minValue: (value) =>
                            parseFloat(value) > 0 ||
                            "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                        },
                      })}
                    />
                    {errorsPopup.amount && (
                      <div className="invalid-feedback">
                        {t(errorsPopup.amount.message)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-4 pregame-transfer-btn">
                  <button type="submit" className="btn btn-brand-gradient">
                    {t("TRANSFER")}
                  </button>
                </div>
              </div>

              <div className="col-12 d-flex transfer-balance font-15 text-white font-bold">
                <div>{selectedGameName + " " + t("NEW_BALANCE") + " : "}</div>
                <div>
                  {watchPopup("amount") > 0 &&
                    numberWithCurrencyFormat(
                      // parseFloat(toOptions[0].amount) +
                      toOptions.length > 0 ? (
                        parseFloat(toOptions[0].amount) + parseFloat(watchPopup("amount"))
                      )
                        : (parseFloat(0)) +
                        parseFloat(watchPopup("amount")),
                      2,
                      true
                    )}{" "}
                  {watchPopup("amount") == "" && "-"}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="container">
          <div className="row">
            {(bonus > 0 || commission > 0 || clubFee > 0) && (
              <div className="col-12 d-flex bonus-commission font-12 text-white">
                {bonus > 0 && (
                  <div className="bonus">
                    {t("BONUS") +
                      " (%) : " +
                      numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                      "%"}
                    &nbsp;&nbsp;
                  </div>
                )}

                {bonus > 0 && commission > 0 && (<div>{"/"}&nbsp;&nbsp;</div>)}

                {commission > 0 && (
                  <div className="commission">
                    {t("COMMISSION") +
                      " (%) : " +
                      numberWithCurrencyFormat(
                        parseFloat(commission),
                        2,
                        true
                      ) +
                      "%"}
                    &nbsp;&nbsp;
                  </div>
                )}

                {commission > 0 && clubFee > 0 && (<div>{"/"}&nbsp;&nbsp;</div>)}

                {clubFee > 0 && (
                  <div className="clubFee">
                    {t("CLUB_FEE") +
                      " (%) : " +
                      numberWithCurrencyFormat(parseFloat(clubFee), 2, true) +
                      "%"}
                  </div>
                )}
              </div>
            )}
            {isApp && appDeepLink !== "" && (
              <>
                <div className="col-12" style={{ paddingBottom: "20px" }}>
                  <hr
                    style={{
                      backgroundColor: "#ffc159",
                      width: "90%",
                      padding: "0.5px 0",
                    }}
                  />
                  <div
                    className="text-center"
                    style={{
                      marginTop: "-28px",
                    }}
                  >
                    <span
                      className="font-16 text-gold font-bold"
                      style={{
                        backgroundColor: "#001A3D",
                        padding: "0 25px",
                      }}
                    >
                      {selectedGameName + " " + t("ACCOUNT")}
                    </span>
                  </div>
                </div>
                <div className="username-and-password">
                  <div className="details username">
                    <div className="details-label">
                      {t("USERNAME") + " : "}
                    </div>
                    <input
                      type="text"
                      className="username-input"
                      value={mpData["username"]}
                      ref={usernameRef}
                      readOnly
                    />
                    <div
                      style={{ marginLeft: "10px" }}
                      onClick={() => copyText("username")}
                    >
                      <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                    </div>
                  </div>
                  <div className="details password">
                    <div className="details-label">
                      {t("PASSWORD") + " : "}
                    </div>
                    <input
                      type="text"
                      className="password-input"
                      value={mpData["password"]}
                      ref={passwordRef}
                      readOnly
                    />
                    <div
                      style={{ marginLeft: "10px" }}
                      onClick={() => copyText("password")}
                    >
                      <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                    </div>
                    <div
                      className="refresh-password-btn"
                      style={{
                        backgroundImage: `url(${reloadPasswordIcon})`,
                      }}
                      onClick={() => resetProductPassword(masterProductId)}
                    >
                      <div className="tooltip-text">
                        <span>{t("REFRESH_PASSWORD")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-12 d-flex start-btn-wrapper">
              {!isApp || (isApp && appDeepLink != "") ? (
                <>
                  {!toLobbyBtn ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (!window.ReactNativeWebView) {
                          // _history.push({
                          //   pathname: WebUrl._URL_GAME_PAGE,
                          //   search: "?gameId=" + productGameId,
                          //   state: { url: window.location.pathname },
                          // });
                          window.open(
                            `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                            }?gameId=${productGameId}&isApp=0&device=d`
                          );
                        } else {
                          window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                              url:
                                window.location.origin +
                                WebUrl._URL_GAME_PAGE +
                                "?gameId=" +
                                productGameId,
                            })
                          );
                        }
                      }}
                      className="btn pregame-popup-btn btn-block btn-brand"
                    >
                      {t("START_GAME")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        _history.push(
                          WebUrl._URL_GAME_LOBBY +
                          "?name=" +
                          selectedGameName +
                          "&id=" +
                          productGameId +
                          "&languageId=" +
                          memberData["languageSetting"]
                        );
                      }}
                      className="btn pregame-popup-btn btn-block btn-brand"
                    >
                      {t("TO_LOBBY", { productName: selectedGameName })}
                    </button>
                  )}
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({ url: appUrl })
                      );
                    } else {
                      window.open(appUrl);
                    }
                  }}
                  className="btn pregame-popup-btn btn-block btn-brand"
                >
                  {t("DOWNLOAD_APP")}
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          className="pregame-popup-close-btn"
          onClick={() => {
            setShowPregamePopup(false);
            setToLobbyBtn(false);
          }}
        >
          <img
            src={require("../../assets/img/assets/close.svg")}
            className="img-responsive"
            alt="close"
          />
        </div>
      </ModalBody>
    </Modal>
    <BottomSuccessModal
      isOpen={isTransferSuccess}
      setIsOpen={setIsTransferSuccess}
      title={"TRANSFER_SUCCESS"}
      amount={paidAmount}
      date={transferDate}
      transactionNumber={transcactionNumber}
    />
  </>
);
};
export default Lottery;
